import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiWorker, ApiWorkerBehaviorConfig, BaseResponse } from './ApiWorker';
import { ApiWorkerInterceptor } from './ApiWorkerInterceptor';
import { store } from '../../redux';

export class ApiWorkerImp implements ApiWorker {

    woker: AxiosInstance

    interceptor: ApiWorkerInterceptor

    constructor(interceptor: ApiWorkerInterceptor, config: AxiosRequestConfig) {
        this.interceptor = interceptor
        this.woker = Axios.create(config)
        this.woker.interceptors.response.use(interceptor.interceptorResponse, interceptor.interceptorError);
    }

    setToken(token: string): void {
        this.woker.defaults.headers.common['x-access-token'] = token
    }

    setConfig(config?: AxiosRequestConfig): void {
        this.woker.defaults.headers.common['content-type'] = 'multipart/form-data; charset=utf-8;'
        this.woker.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
        // this.woker.defaults.headers.common['Accept-Encoding'] = 'gzip, deflate'
    }

    setConfigToken(username: string, password: string): void {
        this.woker.defaults.auth = {
            username: username,
            password: password
        }
        this.woker.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
    }

    setConfigPayment(): void {
        this.woker.defaults.auth = undefined
        this.woker.defaults.headers.common['Content-Type'] = "application/json"
    }

    post<T>(
        url: string,
        data?: { [key in string]: any },
        behaviorConfig?: AxiosRequestConfig
    ): Promise<BaseResponse<T>> {

        return this.woker.post(url, data, {
            ...behaviorConfig,
            headers: {
                order_type: store.getState().order.type
            }
        })
    }

    put<T>(
        url: string,
        data?: { [key in string]: any },
        behaviorConfig?: AxiosRequestConfig
    ): Promise<BaseResponse<T>> {

        return this.woker.put(url, data, {
            ...behaviorConfig,
            headers: {
                order_type: store.getState().order.type
            }
        })
    }

    get<T>(
        url: string,
        behaviorConfig?: ApiWorkerBehaviorConfig
    ): Promise<BaseResponse<T>> {
        return this.woker.get(url, {
            headers: {
                order_type: store.getState().order.type
            }
        })
    }
}