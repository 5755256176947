import React from "react";
import { SuccessBowlHandler } from "./SuccessBowl.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { ReduxState } from "../../redux";
import { LogoutModal, Footer } from "../../components";
import header from "../../assets/image/header.png";
import logo_main from "../../assets/image/logo_main.png";
import success_bowl from "../../assets/image/success_bowl.png";
// import cup from "../../assets/image/drinks/CUP@2x1.png";
// import bananabread from "../../assets/image/drinks/bananabread@2x1.png";
// import poke_bowl12 from "../../assets/image/drinks/poke_bowl12(1).png";
import { NavLink } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

class SuccessBowlScreen extends SuccessBowlHandler {
  render() {
    return (
      <div className="main_menu">
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo">
            <img src={logo_main} alt="Logo" />
          </div>
        </section>
        <section className="base_main main_bowl_summary order_list_main bowl_success">
          <div className="box_bowl_success text-center position-relative">
            <p>
              <FaCheckCircle
                style={{
                  lineHeight: 32,
                  color: "#1AA038",
                  marginBottom: -4,
                  marginRight: 7,
                }}
              />
              Your Bowl is built successfully
            </p>
            <img src={success_bowl} alt="" />
          </div>
          <div className="box_order_list">
            <div className="chose_menu">
              <div className="box_chose_menu">
                <NavLink to="/others/drinks">
                  <div className="items_menu text-center">
                    <img
                      src={this.props.drinkImage}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span className="btn btn_chose_menu">Drinks</span>
                </NavLink>
              </div>
              <div className="box_chose_menu">
                <NavLink to="/others/desserts">
                  <div className="items_menu text-center">
                    <img
                      src={this.props.dessertImage}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span className="btn btn_chose_menu">desserts</span>
                </NavLink>
              </div>
              <div className="box_chose_menu">
                <NavLink to="/order/base">
                  <div className="items_menu text-center">
                    <img
                      src={this.props.bowlImage}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span className="btn btn_chose_menu">BUILD OTHER BOWL</span>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <LogoutModal {...this.props} />
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    order: state.order,
    bowlImage: state.app.bowlImage,
    drinkImage: state.app.drinkImage,
    dessertImage: state.app.dessertImage,
    signatureImage: state.app.signatureImage,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProp)(SuccessBowlScreen);
