import React, { useCallback } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { menuRoutes, searchRoutes } from "../route";
import { ConfirmModal } from "../components";

import { store, cartAction, orderAction } from "../redux";
import { Unsubscribe } from "redux";
import { appAction } from "../redux/app";
let timeoutLogout = setTimeout(() => {}, 0);
let subcribeRedux: Unsubscribe;
let interval: any;
let stack: any = [];
let timeLeft = 10;

export function MenuRoutes() {
  let [location, setLocation] = React.useState(useLocation());
  let [timeLeftHook, setTimeLeftHook] = React.useState(10);
  let [continueSession, setContinueSession] = React.useState(false);
  let tempLocation = useLocation();
  let history = useHistory();
  const setTimeoutFunction = useCallback(() => {
    subcribeRedux = store.subscribe(() => {
      if (store.getState().order.name !== "") {
        clearTimeout(timeoutLogout);
        timeoutLogout = setTimeout(() => {
          if (store.getState().order.name !== "") {
            subcribeRedux();
            clearTimeout(timeoutLogout);
            //@ts-ignore
            $("#forceLogoutModal").modal("show"); // @ts-ignore
            $(".modal-backdrop").css("z-index", 1051);
            interval = setInterval(() => {
              if (timeLeft === 1) {
                clearInterval(interval);
                if (continueSession === true) {
                  //@ts-ignore
                  $("#forceLogoutModal").modal("hide");
                } else {
                  //@ts-ignore
                  $("#forceLogoutModal").modal("hide");
                  timeLeft = 10;
                  setTimeout(() => {
                    setTimeLeftHook(10);
                  }, 500);
                  store.dispatch(cartAction.resetCart());
                  store.dispatch(orderAction.resetOrder());
                  store.dispatch(appAction.resetApp());
                  history.push("/start");
                }
              } else {
                timeLeft = timeLeft - 1;
                setTimeLeftHook((timeLeftHook) => timeLeftHook - 1);
              }
            }, 1000);
          } else {
            clearTimeout(timeoutLogout);
          }
        }, 300000);
      }
    });
  }, [continueSession, history]);

  const onContinuePress = useCallback(() => {
    if (!subcribeRedux) {
      setTimeoutFunction();
    }
    clearInterval(interval);
    //@ts-ignore
    $("#forceLogoutModal").modal("hide");
    setContinueSession(false);
    setTimeout(() => {
      timeLeft = 10;
      setTimeLeftHook(10);
    }, 500);
    store.dispatch(appAction.updateSession(new Date().toISOString()));
  }, [setTimeoutFunction]);
  React.useEffect(() => {
    $("#forceLogoutModal").on("hidden.bs.modal", function () {
      $(".modal-backdrop").css("z-index", "");
      onContinuePress();
    });
  }, [onContinuePress]);
  React.useEffect(() => {
    setTimeoutFunction();
  });

  //   const onConfirmNoriModal = () => {
  //     //@ts-ignore
  //     $("#noriAlertModal").modal("toggle");
  //   };

  React.useEffect(() => {
    if (location !== tempLocation) {
      stack.push(tempLocation);
    }
    setLocation(tempLocation);
  }, [location, tempLocation]);

  if (window.innerWidth > 1090) {
    setTimeout(() => {
      //@ts-ignore
      $("#deviceAlertModal").modal("toggle");
    }, 200);
  }

  return (
    <>
      <ConfirmModal
        content="Please open Poké kiosk in mobile"
        title="Alert"
        onSubmit={() => {
          window.location.reload();
        }}
        id="deviceAlertModal"
        cancelText=""
        iconHide
        className="confirm-modal"
        confirmText="Please"
      />
      <ConfirmModal
        content="This item is out of stock"
        title="Alert"
        className="confirm-modal"
        onSubmit={() => {
          //@ts-ignore
          $("#outOfStockAlertModal").modal("toggle");
        }}
        id="outOfStockAlertModal"
        iconHide
        cancelText=""
        confirmText="Ok"
        style={{ zIndex: 1052 }}
      />

      {/* <ConfirmModal content='No Nori is only available for Sushi Rice or Rice Noodle' title="Alert" cancelText="" confirmText="Ok" id="noriAlertModal-hiden" className="confirm-modal" onSubmit={onConfirmNoriModal} /> */}
      <ConfirmModal
        content={`Kiosk will logout in ${timeLeftHook}`}
        title="Alert"
        onSubmit={onContinuePress}
        id="forceLogoutModal"
        className="confirm-modal"
        style={{ zIndex: 1053 }}
        cancelText=""
        iconHide
        confirmText="Continue"
      />
      <Switch location={location}>
        {searchRoutes.map((prop: any, key) => {
          return (
            <Route
              path={prop.path}
              key={key}
              render={(props) => <prop.component {...props} stack={stack} />}
            />
          );
        })}
        {menuRoutes.map((prop: any, key) => {
          return (
            <Route
              path={prop.path}
              key={key}
              render={(props) => <prop.component {...props} stack={stack} />}
            />
          );
        })}
      </Switch>
    </>
  );
}
