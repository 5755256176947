import React from "react";
import { OrderListHandler } from "./OrderList.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { ReduxState, orderAction, cartAction } from "../../redux";
import back from "../../assets/image/back.png";
import header from "../../assets/image/header.png";
import open from "../../assets/image/orderList/open.png";
import close from "../../assets/image/orderList/close.png";
import couponIcon from "../../assets/image/orderList/coupon-icon.png";
import logo_main from "../../assets/image/logo_main.png";
import { NavLink } from "react-router-dom";
import {
  MoneyComponent,
  LogoutModal,
  ConfirmModal,
  MyScrollBar,
  Loading,
} from "../../components";
import {
  IOrder,
  IFood,
  IFoodSize,
  IFoodQuantity,
  IFlavourBooster,
  ITopping,
  IOrderItem,
  IFoodBase,
  Coupon,
  IDrinkCartItem,
  ICategoriesItem,
  IDessertCartItem,
  IOutOfStockCheckQuantity,
  IOpeningHours,
} from "../../entities";
import { AppService } from "../../services";
import ClipLoader from "react-spinners/ClipLoader";
import { COUPON_TYPE } from "../../constants";
import coupon from "../../assets/image/orderList/coupon.png";
import editBtn from "../../assets/image/orderList/editBtn.png";
import topShadow from "../../assets/image/orderList/topShadow.png";
import botShadow from "../../assets/image/orderList/botShadow.png";
import checkout from "../../assets/image/orderList/checkoutIcon.png";
import scheduleBtn from "../../assets/image/orderList/scheduleBtn.png";
import PhoneInput from "react-phone-input-2";
// @ts-ignore
import DatePicker from "react-mobile-datepicker";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { truncateString } from "../../utils";
import ShaDowHeader from "../../assets/image/Header Bottom Scroll Shadow@2x 1.png";
import CarlenderIcon from "../../assets/image/calendar.png";
import CheckoutIcon from "../../assets/image/checkout.png";
import sushiBurrito from "../../assets/image/Sushi Burrito_App1.png";
import { BsX } from "react-icons/bs";

interface BowlDetailProps {
  item: IOrder;
  bowlImage: string;
  index: number;
  calculatePrice: (bowl: IOrderItem) => number;
  updateQuantity: (quantity: number, index: number, name: string) => void;
  updateBowl: (index: number) => void;
  getPriceAddon: (
    addon: Array<IFoodQuantity>,
    proteinSize: Array<IFoodSize>,
    index: number
  ) => number;
  getPriceFlavourBooster: (
    flavourBooster: Array<IFoodQuantity>,
    index: number
  ) => number;
}

interface DrinksProps {
  item: IDrinkCartItem | IFoodQuantity;
  index: number;
  updateQuantity: (quantity: number, index: number, name: string) => void;
}

const RenderBowlDetail = (data: BowlDetailProps) => {
  const {
    item,
    bowlImage,
    index,
    calculatePrice,
    updateQuantity,
    updateBowl,
    getPriceAddon,
    getPriceFlavourBooster,
  } = data;

  // console.log("all data: ", data); //for debug
  const [isCollapse, setCollapse] = React.useState(false);
  let hasShowNori = false;

  const handleBowlName = () => {
    const base = item?.item?.base;
    if (!base || !base?.length) return `BOWL ${index + 1}`;
    if (base[0]?.isBurrito) return "Burrito";
    return `BOWL ${index + 1}`;
  };

  return (
    <div className={`order_list_content ${index > 0 ? `mt-5` : ``} mb-4 `}>
      <div className="row my-0 border-top border-bottom order-content position-relative">
        <div className="col-6 my-auto bowl-header">
          <div className=" row my-0 pl-4">
            <div className="text-center image-box" style={{ width: 60 }}>
              <img src={item.item.base[0].isBurrito ? sushiBurrito : bowlImage} alt="" />
            </div>
            <div className="ml-3 my-auto">
              <p
                className="mb-0 bowl-title"
                style={item.bowlName ? {} : { marginTop: 0 }}
              >
                {handleBowlName()}
              </p>
              {item.bowlName && (
                <p className="mb-0 d-flex font-regular bowl-name-box">
                  (
                  <p className="bowl-name">
                    {truncateString(item.bowlName, 20)}
                  </p>
                  )
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-3 my-auto quantity-box">
          <button
            type="button"
            id="sub"
            className="sub"
            onClick={() =>
              updateQuantity(item.quantityBowl - 1, index, `Bowl ${index + 1}`)
            }
          >
            -
          </button>
          <input readOnly type="text" value={item.quantityBowl} />
          <button
            type="button"
            id="add"
            className="add"
            onClick={() =>
              updateQuantity(item.quantityBowl + 1, index, `Bowl ${index + 1}`)
            }
          >
            +
          </button>
        </div>
        <div className="col-3 my-auto text-right money-box">
          <div className="total_price my-auto">
            <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
              <MoneyComponent
                type={2}
                money={calculatePrice(item.item) * item.quantityBowl}
              />
            </p>
          </div>
        </div>

        <div
          className="collapse-button"
          onClick={() => setCollapse(!isCollapse)}
        >
          <img src={isCollapse === false ? close : open} alt="" />
        </div>
      </div>
      {isCollapse && (
        <>
          <div className="bowl-item position-relative">
            <img src={topShadow} className="top-shadow" alt="" />
            {item.item.base.map((a: IFoodBase, index: number) => {
              let isShowNori = false;
              if (
                item.noNori &&
                !hasShowNori &&
                (a.id === 471 || a.id === 473 || a.id === 474)
              ) {
                isShowNori = true;
                hasShowNori = true;
              }
              return (
                <div className="row">
                  <div className="col-9 food-name">
                    <img src={a.image} alt="" />
                    {!!a.origin_name ? a.origin_name : a.name}
                    {isShowNori && <span className="size">(No Nori)</span>}
                  </div>
                  <div className="col-3 money-box">
                    {Number(a.price) !== 0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent type={2} money={a.price} />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
            {item.item.proteinSize.map((a: IFoodSize) => {
              return (
                <div className="row">
                  <div className="col-9 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                    <span className="size">{a.name_size}</span>
                  </div>
                  <div className="col-3 money-box">
                    {Number(a.price) !== 0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent type={2} money={a.price} />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
            {item.item.extraProteins.map((a: IFoodQuantity) => {
              return (
                <div className="row">
                  <div className="col-6 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                    <span className="size">Extra</span>
                  </div>
                  <div className="col-3">
                    {a.quantity > 1 && (
                      <span className="size quantity">x {a.quantity}</span>
                    )}
                  </div>
                  <div className="col-3 money-box">
                    {Number(a.price) !== 0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent
                          type={2}
                          money={Number(a.price) * a.quantity}
                        />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
            {item.item.marinades.map((a: IFood) => {
              return (
                <div className="row">
                  <div className="col-9 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                  </div>
                </div>
              );
            })}
            {item.item.mixin.map((a: IFood) => {
              return (
                <div className="row">
                  <div className="col-9 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                  </div>
                </div>
              );
            })}
            {item.item.addon.map((a: IFoodQuantity, b: number) => {
              return (
                <div className="row">
                  <div className="col-6 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                    {getPriceAddon(item.item.addon, item.item.proteinSize, b) >
                      0 && <span className="size">Extra</span>}
                  </div>
                  <div className="col-3">
                    {a.quantity > 1 && (
                      <span className="size quantity">x {a.quantity}</span>
                    )}
                  </div>
                  <div className="col-3 money-box">
                    {getPriceAddon(item.item.addon, item.item.proteinSize, b) >
                      0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent
                          type={2}
                          money={getPriceAddon(
                            item.item.addon,
                            item.item.proteinSize,
                            b
                          )}
                        />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
            {item.item.flavourBooster.map((a: IFoodQuantity, b: number) => {
              return (
                <div className="row">
                  <div className="col-6 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                    {getPriceFlavourBooster(item.item.flavourBooster, b) >
                      0 && <span className="size">Extra</span>}
                  </div>
                  <div className="col-3">
                    {a.quantity > 1 && (
                      <span className="size quantity">x {a.quantity}</span>
                    )}
                  </div>
                  <div className="col-3 money-box">
                    {getPriceFlavourBooster(item.item.flavourBooster, b) >
                      0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent
                          type={2}
                          money={getPriceFlavourBooster(
                            item.item.flavourBooster,
                            b
                          )}
                        />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
            {item.item.topping.map((a: ITopping, b: number) => {
              return (
                <div className="row">
                  <div className="col-9 food-name">
                    <img src={a.image} alt="" />
                    {a.name}
                  </div>
                </div>
              );
            })}
            <div className="row px-4">
              <div className="col-md-12">
                <button
                  className="btn btn_edit w-100"
                  onClick={() => updateBowl(index)}
                >
                  Edit <img src={editBtn} alt="" />
                </button>
              </div>
            </div>
          </div>
          <img src={botShadow} className="w-100 position-absolute t-0" alt="" />
        </>
      )}
    </div>
  );
};

const RenderDrink = (data: DrinksProps) => {
  const { item, updateQuantity, index } = data;
  return (
    <div className="mb-4 order_list_content">
      <div className="row my-0 position-relative">
        <div className="col-6 my-auto bowl-header">
          <div className=" row my-0 pl-4">
            <div className="text-center" style={{ width: 60 }}>
              <img src={item.image} alt="" />
            </div>
            <div className="ml-3 my-auto">
              <p className="mb-0 bowl-title">{item.name}</p>
            </div>
          </div>
        </div>
        <div className="col-3 my-auto quantity-box">
          <button
            type="button"
            id="sub"
            className="sub"
            onClick={() =>
              updateQuantity(item.quantity - 1, index, `${data.item.name}`)
            }
          >
            -
          </button>
          <input readOnly type="text" value={item.quantity} />
          <button
            type="button"
            id="add"
            className="add"
            onClick={() => {
              if (item.quantity < item.count) {
                updateQuantity(item.quantity + 1, index, `${data.item.name}`);
              } else if (item.quantity >= item.count) {
                // @ts-ignore
                $("#outOfStockAlertModal").modal("toggle");
                $(".modal-backdrop").css("z-index", 1051);
                $("#outOfStockAlertModal").on("hidden.bs.modal", function () {
                  $(".modal-backdrop").css("z-index", "");
                });
              }
            }}
          >
            +
          </button>
        </div>
        <div className="col-3 my-auto text-right money-box">
          <div className="total_price my-auto">
            <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
              <MoneyComponent
                type={2}
                money={item.quantity * Number(item.price)}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// const DatepickerInput = ({ ...props }) => (
//   <input type="text" {...props} readOnly />
// );

class OrderListScreen extends OrderListHandler {
  render() {
    // const isWeekday = (date: Date) => {
    //   const data = new Date(date);
    //   const day = data.getDay();
    //   return day !== 0;
    // };
    console.log("all props: ", this.props); //for debug
    const { order } = this.props;
    return (
      <div className="main_menu">
        <Loading
          loading={this.state.isLoading}
          style={{ zIndex: 3, position: "fixed" }}
        >
          <div className="container text-center loading-container">
            {" "}
            <ClipLoader
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        </Loading>
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo">
            <img src={logo_main} alt="Logo" />
          </div>
          <div className="d-block flex-nowrap order_list_heading">
            <div
              onClick={this.onBack}
              className="btn btn-back"
              style={{ zIndex: 100 }}
            >
              <img src={back} alt="" />
            </div>
            <h2 className="build">MY ORDER</h2>
          </div>
          <img src={ShaDowHeader} alt="ShaDowHeader" />
        </section>
        <section className="base_main main_bowl_summary main_order_list">
          <div
            className="box_bowl_summary content box_order_list"
            style={{ height: 1200, maxHeight: "unset" }}
          >
            <MyScrollBar
              scrollToTopStyle={
                window.innerWidth > 768
                  ? { bottom: 280, right: 50 }
                  : { right: -20 }
              }
            >
              <div className="bowl_summary_base bowl_summary_protein bowl_order_list">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list_summary_base">
                      <div className="title_summary_base">
                        <div className="title_left pl-4">
                          <h4>BOWL</h4>
                        </div>
                        <div className="title_right pr-4">
                          <button
                            className="btn btn_change"
                            style={{ display: "inline-block" }}
                            onClick={this.addMoreBase}
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        {order.order.map((a: IOrder, b) => {
                          return (
                            <RenderBowlDetail
                              item={a}
                              bowlImage={this.props.bowlImage}
                              index={b}
                              calculatePrice={this.getBowlAmount}
                              updateQuantity={this.updateQuantity}
                              updateBowl={this.changeBowl}
                              getPriceAddon={this.getPriceAddon}
                              getPriceFlavourBooster={
                                this.getPriceFlavourBooster
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list_summary_base">
                      <div className="title_summary_base">
                        <div className="title_left pl-4">
                          <h4>DRINKS</h4>
                        </div>
                        <div className="title_right pr-4">
                          <NavLink
                            to="/others/drinks"
                            className="btn btn_change"
                            style={{ display: "inline-block" }}
                          >
                            Add +
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        {order.drinks.map((a: IDrinkCartItem, b) => {
                          return (
                            <RenderDrink
                              item={a}
                              index={b}
                              updateQuantity={this.updateDrinkQuantity}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list_summary_base">
                      <div className="title_summary_base">
                        <div className="title_left pl-4">
                          <h4>DESSERTS</h4>
                        </div>
                        <div className="title_right pr-4">
                          <NavLink
                            to="/others/desserts"
                            className="btn btn_change"
                            style={{ display: "inline-block" }}
                          >
                            Add +
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        {order.desserts.map((a: IDessertCartItem, b) => {
                          return (
                            <RenderDrink
                              item={a}
                              index={b}
                              updateQuantity={this.updateDessertQuantity}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list_summary_base">
                      <div className="title_summary_base">
                        <div className="title_left pl-4">
                          <h4>SIGNATURE</h4>
                        </div>
                        <div className="title_right pr-4">
                          <NavLink
                            to="/others/signature"
                            className="btn btn_change"
                            style={{ display: "inline-block" }}
                          >
                            Add +
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        {order.signature.map((a: IFoodQuantity, b) => {
                          return (
                            <RenderDrink
                              item={a}
                              index={b}
                              updateQuantity={this.updateSignatureQuantity}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MyScrollBar>
          </div>
          <div className="w-100 border-top checkout-container">
            <div className="total-responsive">
              <p className="total-title">Total:</p>
              {Number(this.props.order.couponValue) !== -1 && (
                <p className="price price_discount">
                  <MoneyComponent money={this.getTotalAmount().originAmount} />
                </p>
              )}
              <p className="price">
                <MoneyComponent money={this.getTotalAmount().totalAmount} />
              </p>
            </div>
            <div className="row py-3 mx-0">
              <div className="col-4 flex-fill my-auto coupon-container">
                {this.state.isToggleCoupon ? (
                  <div className="">
                    <div className="coupon_box">
                      <img src={couponIcon} alt="" />
                      <input
                        type="text"
                        placeholder="ENTER COUPON"
                        value={this.state.coupon_code}
                        readOnly={Number(this.props.order.couponValue) !== -1}
                        onChange={this.onChangeCoupon}
                        className={
                          Number(this.props.order.couponValue) === -1
                            ? ``
                            : `has_coupon`
                        }
                      />
                      {this.state.coupon_code === "" ? (
                        <></>
                      ) : (
                        <>
                          {Number(this.props.order.couponValue) === -1 ? (
                            <button
                              className="coupon_button"
                              onClick={this.onCheckCoupon}
                            >
                              OK
                            </button>
                          ) : (
                            <button
                              className="coupon_value"
                              onClick={this.toggleRemoveCouponModal}
                            >
                              {this.props.order.couponType ===
                              COUPON_TYPE.DISCOUNT_MONEY
                                ? `${this.props.order.couponValue} OFF`
                                : `${Number(
                                    this.props.order.couponValue
                                  )}% OFF`}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="text-center hover-cursor"
                    onClick={this.toggleCoupon}
                  >
                    <img src={coupon} alt="" />
                    <p className="mt-2 mb-2">COUPON/GIFTCARD</p>
                  </div>
                )}
              </div>
              <div
                className="col-4 text-center total-box px-0"
                style={{
                  borderLeft: "1px solid #a9acaf !important",
                  borderRight: "1px solid #a9acaf !important",
                }}
              >
                <p className="total-title">Total:</p>
                {Number(this.props.order.couponValue) !== -1 && (
                  <p className="price price_discount">
                    <MoneyComponent
                      money={this.getTotalAmount().originAmount}
                    />
                  </p>
                )}
                <p className="price">
                  <MoneyComponent money={this.getTotalAmount().totalAmount} />
                </p>
              </div>
              <div className="col-4 flex-fill text-center schedule-container">
                {!!this.props.scheduleHour ? (
                  <>
                    <p className="my-0 clear-text">Scheduled</p>
                    <span
                      className="time"
                      onClick={this.toggleInDevelopmentModal}
                    >
                      {moment(
                        this.props.scheduleHour,
                        "HH:mm:ss MM/DD/YYYY"
                      ).format("HH:mm - MM/DD")}
                    </span>
                    <p
                      className="my-0 clear-text"
                      onClick={() => {
                        this.props.addScheduleOrder("");
                      }}
                    >
                      Clear
                    </p>
                  </>
                ) : (
                  <img
                    onClick={this.toggleInDevelopmentModal}
                    className="hover-cursor"
                    src={scheduleBtn}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div
              className={`button_bowl_summary checkout_button ${
                this.state.isCheckoutAvailable ? `` : `disabled`
              }`}
              onClick={() => {
                this.state.isCheckoutAvailable && this.onCheckQuantity();
              }}
            >
              <p>
                Proceed to checkout <img src={checkout} alt="" />
              </p>
            </div>
          </div>
          <ConfirmModal
            modalContentStyle={{ overflow: "unset" }}
            content=""
            cancelText=""
            title=""
            id="checkoutConfirm"
            className="confirm-modal"
            onSubmit={this.checkoutOrder}
            children={
              <>
                <div
                  className="position-absolute icon-cancel-schedule"
                  onClick={() => {
                    //@ts-ignore
                    $("#checkoutConfirm").modal("hide");
                  }}
                >
                  <BsX />
                </div>
                <div className="heading-popup-checkout">
                <h5 className="heading" style={{ fontSize: 48, lineHeight: '64px' }}>Confirm to checkout</h5>
                  <div className="text-center mb-2">
                    <img
                      src={CheckoutIcon}
                      alt="CheckoutIcon"
                      width="50%"
                      height="50%"
                    />
                  </div>
                  <p
                    className="checkout-title"
                    style={{
                      color: "#000",
                      fontSize: 26,
                      fontFamily: "MM",
                      lineHeight: "26px",
                    }}
                  >
                    Enter your phone number for a faster check out next time, and for us to contact you if we have any questions about your order
                  </p>
                </div>
                <div className="col-md-12 text-center">
                  <div className="col-md-6 mx-auto">
                    {/* <input type="text" className="form-control text-center" placeholder="Enter your phone number" style={{ fontSize: 24, fontFamily: 'MM' }} value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} /> */}
                    <PhoneInput
                      country="cw"
                      placeholder="Enter your phone number"
                      inputClass="text-center"
                      value={this.state.phone}
                      preferredCountries={["cw", "nl", "us"]}
                      inputStyle={{
                        paddingLeft: 50,
                        fontSize: 24,
                        fontFamily: "MM",
                        width: "100%",
                      }}
                      onChange={(phone) => this.setState({ phone })}
                    />
                    {this.state.errPhoneRequiredMessage !== "" && (
                      <p className="error_message">
                        {this.state.errPhoneRequiredMessage}
                      </p>
                    )}
                  </div>
                </div>
              </>
            }
          />
          <ConfirmModal
            content={this.state.errContent}
            title="Coupon Invalid"
            cancelText=""
            iconHide
            id="orderErrorModal"
            className="confirm-modal"
            onSubmit={this.toggleErrorMessage}
          />
          <ConfirmModal
            content={this.state.successMessage}
            title="Successfully"
            cancelText=""
            iconHide
            id="successModal"
            className="confirm-modal"
            onSubmit={this.toggleSuccessMessage}
          />
          <ConfirmModal
            content={`Remove ${this.state.selectedRemoveFood} from your order list`}
            title="Confirm"
            id="foodRemoveModal"
            confirmText="Remove"
            cancelText=""
            iconHide
            className="confirm-modal"
            onSubmit={this.removeFood}
          />
          <ConfirmModal
            modalContentStyle={{ overflow: "inherit" }}
            content={``}
            title=""
            id="inDevelopmentModal"
            confirmText="Ok"
            cancelText=""
            className="confirm-modal"
            onSubmit={() => {
              this.toggleInDevelopmentModal();
              this.setState({ successMessage: "Scheduled your order!" });
              //@ts-ignore
              $("#successModal").modal("show");
              this.props.addScheduleOrder(
                moment(this.state.scheduleDate).format("HH:mm:ss MM/DD/YYYY")
              );
            }}
            children={
              <>
                <div
                  className="position-absolute icon-cancel-schedule"
                  onClick={() => {
                    //@ts-ignore
                    $("#inDevelopmentModal").modal("hide");
                  }}
                >
                  <BsX />
                </div>
                <div
                  className="col-md-12 text-center px-0"
                  style={{ height: 276, width: 240, margin: "auto" }}
                >
                  <div className="schedule-icon-container d-flex justify-content-center mb-2">
                    <img
                      src={CarlenderIcon}
                      alt="CarlenderIcon"
                      width="35%"
                      height="35%"
                    />
                  </div>
                  <div className="heading-schedule-container">
                    Schedule my order
                  </div>
                  <div
                    onClick={this.onOpen}
                    style={{
                      border: "1px solid #000",
                      padding: "12px 10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6 style={{ margin: "-0.5rem" }}>
                      {moment(this.state.scheduleDate).format("MMM DD | HH:mm")}
                    </h6>
                  </div>

                  <ConfirmModal
                    content={``}
                    title=""
                    id="dateModal"
                    hideFooter
                    confirmText="Ok"
                    cancelText=""
                    onSubmit={this.checkoutOrder}
                    styleModalDialog={{ marginTop: "386px" }}
                    children={
                      <>
                        <div
                          style={{ height: 419, width: 240, margin: "auto" }}
                          className="modal-children"
                        >
                          <div
                            className="position-absolute icon-cancel-schedule"
                            onClick={() => {
                              //@ts-ignore
                              $("#dateModal").modal("hide");
                            }}
                          >
                            <BsX />
                          </div>
                          <DatePicker
                            value={this.state.scheduleDate}
                            isOpen={true}
                            theme={"android"}
                            confirmText={"Set"}
                            cancelText={"Cancel"}
                            min={new Date(this.state.minDate)}
                            dateConfig={this.state.dateConfig}
                            minTime={this.state.minTime}
                            maxTime={this.state.maxTime}
                            onSelect={this.handleSelect}
                            onCancel={this.handleCancel}
                            onChange={this.handleChange}
                            ref={this.datePickerRef}
                            isPopup={false}
                            customHeader={"Set date and time"}
                          />
                        </div>
                      </>
                    }
                  />
                </div>
              </>
            }
          />
          <ConfirmModal
            content="Remove this coupon"
            title="Confirm"
            id="couponRemoveModal"
            cancelText=""
            iconHide
            className="confirm-modal"
            onSubmit={this.toggleRemoveCouponMessage}
          />

          <ConfirmModal
            content={``}
            title={"Out of stock"}
            id="quantityErrorModal"
            iconHide
            cancelText=""
            className="confirm-modal"
            onSubmit={this.toggleErrorQuantityMessage}
          >
            <p>{this.state.quantityOutOfStockCheckMessage}</p>
            <p>{this.state.quantityCheckMessage}</p>
          </ConfirmModal>
          <LogoutModal {...this.props} />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
    bowlImage: state.app.bowlImage,
    searchOrderFlag: state.order.isSearchingOrder,
    scheduleHour: state.order.scheduleOrder,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const addToOrder = (order: Array<IOrder>, callBack: () => void) => {
    dispatch(orderAction.addOrder(order));
    callBack();
  };

  const addBaseToCart = (item: Array<IFoodBase>, nonSelected: boolean) => {
    dispatch(cartAction.addBase(item, nonSelected));
  };

  const addProteinToCart = (
    item: Array<IFood>,
    itemSize: Array<IFoodSize>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addProteins(item, itemSize, nonSelected));
  };

  const addMarinadesToCart = (item: Array<IFood>, nonSelected: boolean) => {
    dispatch(cartAction.addMarinades(item, nonSelected));
  };

  const addExtraProtein = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addExtraProteins(item, nonSelected));
  };

  const addMixins = (item: Array<IFood>, nonSelected: boolean) => {
    dispatch(cartAction.addMixin(item, nonSelected));
  };

  const addAddonToCart = (item: Array<IFoodQuantity>, nonSelected: boolean) => {
    dispatch(cartAction.addAddon(item, nonSelected));
  };

  const addFlavourBoosterToCart = (
    item: Array<IFlavourBooster>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addFlavourBooster(item, nonSelected));
  };

  const addToppingToCart = (item: Array<ITopping>, nonSelected: boolean) => {
    dispatch(cartAction.addTopping(item, nonSelected));
  };

  const saveBowlName = (name: string, check: boolean) => {
    dispatch(cartAction.addBowlSavedStatus(check));
    dispatch(cartAction.addBowlName(name, -1));
  };

  const updateQuantityBowl = (quantity: number) => {
    dispatch(cartAction.addQuantityBowl(quantity));
  };

  const addUpdateIndex = (index: number) => {
    dispatch(cartAction.addUpdateFlag(index));
  };

  const addMixableBase = (mixable: boolean) => {
    dispatch(cartAction.addMixableBase(mixable));
  };

  const resetCart = (callBack: () => void) => {
    dispatch(cartAction.resetCart());
    callBack();
  };

  const createOrder = (body: any) => {
    const service = new AppService();
    service
      .createOrder(body)
      .then((response) => {})
      .catch(console.log);
  };

  const addDrinksToCart = (
    drinks: Array<IDrinkCartItem>,
    categories: Array<ICategoriesItem>
  ) => {
    dispatch(orderAction.addDrinks(drinks, categories));
  };

  const addDessertToCart = (
    dessert: Array<IDessertCartItem>,
    categories: Array<ICategoriesItem>
  ) => {
    dispatch(orderAction.addDesserts(dessert, categories));
  };

  const addSignature = (signature: Array<IFoodQuantity>) => {
    dispatch(orderAction.addSignature(signature));
  };

  const checkCoupon = (code: string, callBack: (coupon: Coupon) => void) => {
    const service = new AppService();
    service
      .checkCoupon(code)
      .then((response) => {
        callBack(response);
      })
      .catch(console.log);
  };

  const addCouponToOrder = (code: string, value: string, type: number) => {
    dispatch(orderAction.addCoupon(code, value, type));
  };

  const updateOrderInfo = (name: string, type: number, phone: string) => {
    dispatch(orderAction.addOrderInfo(name, type, phone));
  };

  const updateNoNori = (noNori: boolean) => {
    dispatch(cartAction.addNoNori(noNori));
  };
  const checkQuantity = (
    body: any,
    CallBack: (
      check: boolean,
      dataCheckOutOfStock: IOutOfStockCheckQuantity,
      dataCheckQuantity: IOutOfStockCheckQuantity
    ) => void
  ) => {
    const service = new AppService();
    service
      .checkQuantity(body)
      .then((response) => {
        CallBack(response.check, response.data, response.data_short);
      })
      .catch((err: Error) => {
        console.log("err", err);
      });
  };

  const getOpeningTime = (callBack: (data: Array<IOpeningHours>) => void) => {
    const service = new AppService();
    service
      .getOpeningTime()
      .then((response) => {
        callBack(response.data);
      })
      .catch((err) => {});
  };

  const addScheduleOrder = (scheduleOrder: string) => {
    dispatch(orderAction.addScheduleOrder(scheduleOrder));
  };

  return {
    addToOrder,
    addBaseToCart,
    addMarinadesToCart,
    addExtraProtein,
    addMixins,
    addProteinToCart,
    addAddonToCart,
    addFlavourBoosterToCart,
    addToppingToCart,
    saveBowlName,
    updateQuantityBowl,
    addUpdateIndex,
    resetCart,
    createOrder,
    checkCoupon,
    addCouponToOrder,
    addDessertToCart,
    addDrinksToCart,
    addSignature,
    updateOrderInfo,
    addMixableBase,
    checkQuantity,
    updateNoNori,
    getOpeningTime,
    addScheduleOrder,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(OrderListScreen);
