import StartScreen from '../screen/StartScreen/StartScreen'
import MenuScreen from '../screen/Menu/Menu'
import BaseOrderScreen from '../screen/BaseOrder/BaseOrder'
import ProteinOrderScreen from '../screen/ProteinOrder/ProteinOrder'
import AddOnOrderScreen from '../screen/AddOnOrder/AddOnOrder'
import FlavourBoostersScreen from '../screen/FlavourBoosters/FlavourBoosters'
import BowlSummaryScreen from '../screen/BowlSummary/BowlSummary'
import SuccessBowlScreen from '../screen/SuccessBowl/SuccessBowl'
import SuccessOrderScreen from '../screen/SuccessOrder/SuccessOrder'
import ToppingOrderScreen from '../screen/ToppingOrder/ToppingOrder'
import OrderListScreen from '../screen/OrderList/OrderList'
import OrderConfirm from '../screen/OrderConfirm/OrderConfirm'
import PaymentTypeScreen from '../screen/PaymentType/PaymentType'
import DrinkOrderScreen from '../screen/DrinkOrder/DrinkOrder'
import DessertOrderScreen from '../screen/DessertOrder/DessertOrder'
import SignatureOrderScreen from '../screen/SignatureOrder/SignatureOrder'
import SearchResultScreen from '../screen/SearchResult/SearchResult'
import SearchOrderScreen from '../screen/SearchOrderScreen/SearchOrderScreen'
import PaymentExistedOrderScreen from '../screen/PaymentExistedOrder/PaymentExistedOrder'

import { AppLayout } from "../layout";
import { SideFoodLayout } from '../layout/sideFoodLayout'

export const routes = [
    {
        path: '/order/base', name: "BaseOrderScreen", component: BaseOrderScreen, key: 1
    },
    {
        path: '/order/proteins', name: "ProteinOrderScreen", component: ProteinOrderScreen, key: 2
    },
    {
        path: '/order/addon', name: "AddOnOrderScreen", component: AddOnOrderScreen, key: 3
    },
    {
        path: '/order/flavour-boosters', name: "FlavourBoostersScreen", component: FlavourBoostersScreen, key: 4
    },
    {
        path: '/order/topping', name: "ToppingOrderScreen", component: ToppingOrderScreen, key: 5
    }
]

export const sideFood = [
    {
        path: '/others/drinks', name: "DRINKS", component: DrinkOrderScreen, key: 1, className: 'drink_menu'
    },
    {
        path: '/others/desserts', name: "DESSERTS", component: DessertOrderScreen, key: 2, className: 'dessert_menu'
    },
    {
        path: '/others/signature', name: "SIGNATURE", component: SignatureOrderScreen, key: 3, className: 'signature_menu'
    },
]

export const menuRoutes = [
    {
        path: '/order', name: "OrderScreen", component: AppLayout
    },
    {
        path: '/others', name: "SideFoodLayout", component: SideFoodLayout
    },
    {
        path: '/order-list', name: "OrderListScreen", component: OrderListScreen
    },
    {
        path: '/bowl-summary', name: "BowlSummaryScreen", component: BowlSummaryScreen
    },
    {
        path: '/payment', name: "PaymentTypeScreen", component: PaymentTypeScreen
    },
    {
        path: '/bowl-summary-success', name: "SuccessBowlScreen", component: SuccessBowlScreen
    },
    {
        path: '/order-success', name: "SuccessOrderScreen", component: SuccessOrderScreen
    },
    {
        path: '/order-confirm', name: "OrderConfirmScreen", component: OrderConfirm
    },
    {
        path: '/menu', name: "MenuScreen", component: MenuScreen
    },
    {
        path: '/start', name: "StartScreen", component: StartScreen
    },
    {
        path: '/', name: "StartScreen", component: StartScreen
    }
]

export const searchRoutes = [
    {
        path: '/search', name: "SearchOrderScreen", component: SearchOrderScreen
    }, {
        path: '/search-result', name: "SearchResultScreen", component: SearchResultScreen
    },
    {
        path: '/payment-order', name: "PaymentExistedOrderScreen", component: PaymentExistedOrderScreen
    },
]