import { ADD_ORDER, ADD_ORDER_INFO, RESET_ORDER, ADD_COUPON, ADD_DRINK, ADD_DESSERT, ADD_SIGNATURE, ADD_ORDER_SEARCHING_FLAG } from './types'
import { IOrder, IDrinkCartItem, ICategoriesItem, IDessertCartItem, IFoodQuantity, ISearchOrder } from '../../entities'


const action = {
    addOrder: (order: Array<IOrder>) => {
        return {
            type: ADD_ORDER,
            payload: { order }
        }
    },
    addOrderInfo: (name: string, type: number, phone: string) => {
        return {
            type: ADD_ORDER_INFO,
            payload: { name, type, phone }
        }
    },
    addOrderSearchingFlag: (isSearchingOrder: boolean) => {
        return {
            type: ADD_ORDER_SEARCHING_FLAG,
            payload: { isSearchingOrder }
        }
    },
    addSearchResult: (searchResult: ISearchOrder) => {
        return {
            type: ADD_ORDER_SEARCHING_FLAG,
            payload: { searchResult }
        }
    },
    resetOrder: () => {
        return {
            type: RESET_ORDER,
            payload: {}
        }
    },
    addDrinks: (drinks: Array<IDrinkCartItem>, drinkCategories: Array<ICategoriesItem>) => {
        return {
            type: ADD_DRINK,
            payload: { drinks, drinkCategories }
        }
    },
    addDesserts: (desserts: Array<IDessertCartItem>, dessertCategories: Array<ICategoriesItem>) => {
        return {
            type: ADD_DESSERT,
            payload: { desserts, dessertCategories }
        }
    },
    addCoupon: (couponCode: string, couponValue: string, couponType: number) => {
        return {
            type: ADD_COUPON,
            payload: { couponCode, couponValue, couponType }
        }
    },
    addSignature: (signature: Array<IFoodQuantity>) => {
        return {
            type: ADD_SIGNATURE,
            payload: { signature }
        }
    },
    addScheduleOrder: (scheduleOrder: string) => {
        return {
            type: ADD_SIGNATURE,
            payload: { scheduleOrder }
        }
    }
}

export default action