import React from "react";
import { NavLink } from "react-router-dom";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
//@ts-ignore
import Autosuggest from "react-autosuggest";
//@ts-ignore
import { CSSTransition } from "react-transition-group";
import ClipLoader from "react-spinners/ClipLoader";
import YouTube from "react-youtube";
import { BsX } from "react-icons/bs";

import {
  Footer,
  LogoutModal,
  Loading,
  ConfirmModal,
  // MoneyComponent,
} from "../../components";
import { ReduxState, cartAction, orderAction } from "../../redux";
import { AppService } from "../../services";
import {
  IBowl,
  SavedBowl,
  IFoodBase,
  IFoodQuantity,
  IFood,
  IFlavourBooster,
  IFoodSize,
  ITopping,
} from "../../entities";
import { MenuHandler } from "./Menu.logic";
import { appAction } from "../../redux/app";
import { getCensoredPhone } from "../../utils";
import header from "../../assets/image/header.png";
import logo_main from "../../assets/image/logo_main.png";
import PokeDashSign from "../../assets/image/PokeDashSign@2x.png";
import searchIcon from "../../assets/image/search_icon.png";
import guideIcon from "../../assets/image/guide_icon.png";
import logo from "../../assets/image/logo.png";

import { Box, Button, Typography } from "@mui/material";

const getSuggestionValue = (suggestion: any) => {
  if (suggestion === "__no_suggestion__") {
    return ""; // return null "No results found"
  }
  return suggestion.name;
};

class MenuScreen extends MenuHandler {
  renderSuggestion = (suggestion: any) => {
    console.log("suggestion: ", suggestion); // for debug
    if (suggestion === "__no_suggestion__") {
      return (
        <div
          style={{
            minHeight: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="text-center"
        >
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            No results found
          </p>
        </div>
      );
    } else {
      return (
        <Box
          // className="py-2 suggest-text"
          onClick={() => this.onSelectBowl(suggestion.id)}
          sx={{
            background: "#FFFFFF",
            padding: "16px",
            borderBottom: "1px solid #DFDFDF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{}}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: 600,
                    mr: "8px",
                  }}
                >
                  {suggestion?.client_name}
                </Typography>
                <Typography
                  sx={{ fontSize: "21px", fontWeight: 500, color: "#786960" }}
                >{`- (${getCensoredPhone(
                  suggestion.client_phone
                )})`}</Typography>
              </Box>

              <Box sx={{}}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#9D9290",
                    textAlign: "left",
                  }}
                >
                  {suggestion?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "#9D9290",
                    textAlign: "left",
                  }}
                >
                  {!!suggestion?.note ? suggestion?.note : "(No comment)"}
                </Typography>
              </Box>
            </Box>

            {!!suggestion?.paid ? (
              <Box>
                <Button
                  sx={{ px: "16px", background: "#786960", color: "#FFFFFF" }}
                >
                  Pay
                </Button>
              </Box>
            ) : null}
          </Box>

          {/* <img src={this.props.bowlImage} alt="" />
        <div className="info-box">
          <p className="my-0 suggest-phone white-space-pre-wrap">
            {suggestion.name}
          </p>
          <p className="my-0 suggest-name">
            (<p>{suggestion.client_name}</p> -{" "}
            {getCensoredPhone(suggestion.client_phone)})
          </p>
        </div>
        <p className="p2">
          <MoneyComponent money={suggestion.price} type={1} />
        </p> */}
        </Box>
      );
    }
  };

  onSuggestionSelectedNone = ({ suggestion }: any) => {
    if (suggestion === "__no_suggestion__") {
      return;
    }
  };

  render() {
    console.log("all props: ", this.props); //for debug
    const { toggleAnimation } = this.state;
    return (
      <div className="main_menu">
        <Loading
          loading={this.state.isLoading}
          style={{ zIndex: 3, position: "fixed" }}
        >
          <div className="container text-center loading-container">
            {" "}
            <ClipLoader
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        </Loading>
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo">
            <img src={logo_main} alt="Logo" />
          </div>
        </section>
        <section className="base_main menu_body main_menu_body">
          <div className="menu_text position-relative">
            <h2>MAIN MENU</h2>
            <p>SELECT AN OPTION</p>
            <div
              className="hover-cursor guide-button"
              onClick={this.toggleGuideModal}
            >
              <img src={guideIcon} alt="" />
            </div>
          </div>
          {this.props.bowlImage !== "" && (
            <CSSTransition
              timeout={200}
              in={toggleAnimation}
              classNames="menu-transaction"
            >
              <div
                className={`animated-carousel ${
                  this.state.toggleAnimation === true
                    ? `animated-carousel-inactive`
                    : ``
                }`}
              >
                <div className={`owl-carousel slider_food owl-theme`}>
                  <NavLink to="/others/drinks">
                    <div className="slider_items hover-cursor">
                      <div className="food_items position-relative">
                        <span
                          className="btn btn_chose_food"
                          style={{ bottom: 20 }}
                        >
                          Drinks
                        </span>
                        <div
                          className={`chopping_board`}
                          style={{ background: this.props.colorBackground }}
                        ></div>
                        <div className="box_menu">
                          <div className="img_food">
                            <img
                              src={this.props.drinkImage}
                              alt=""
                              onClick={() => this.onOptionPress(1)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="food_items_bottom"></div>
                    </div>
                  </NavLink>
                  <NavLink to="/order/base">
                    <div className="slider_items hover-cursor">
                      <div className="food_items position-relative">
                        <span
                          className="btn btn_chose_food"
                          style={{ bottom: 20 }}
                        >
                          Build my own!
                        </span>
                        <div
                          className={`chopping_board`}
                          style={{ background: this.props.colorBackground }}
                        ></div>
                        <div className="box_menu">
                          <div className="img_food">
                            <img src={this.props.bowlImage} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="food_items_bottom"></div>
                    </div>
                  </NavLink>
                  <NavLink to="/others/desserts">
                    <div className="slider_items hover-cursor">
                      <div className="food_items position-relative">
                        <span
                          className="btn btn_chose_food"
                          style={{ bottom: 20 }}
                        >
                          Desserts
                        </span>
                        <div
                          className={`chopping_board`}
                          style={{ background: this.props.colorBackground }}
                        ></div>
                        <div className="box_menu">
                          <div className="img_food">
                            {this.props.dessertImage && (
                              <img src={this.props.dessertImage} alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="food_items_bottom"></div>
                    </div>
                  </NavLink>
                  <NavLink to="/others/signature">
                    <div className="slider_items hover-cursor">
                      <div className="food_items position-relative">
                        <span
                          className="btn btn_chose_food"
                          style={{ bottom: 20 }}
                        >
                          Signature
                        </span>
                        <div
                          className={`chopping_board`}
                          style={{ background: this.props.colorBackground }}
                        ></div>
                        <div className="box_menu">
                          <div
                            className="img_food"
                            style={
                              window.innerWidth > 768 ? {} : { width: 290 }
                            }
                          >
                            <img src={this.props.signatureImage} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="food_items_bottom"></div>
                    </div>
                  </NavLink>
                </div>
                <div className="form-group has-search search-menu">
                  <img
                    src={searchIcon}
                    className="form-control-feedback"
                    alt=""
                  />
                  <Autosuggest
                    suggestions={this.state.suggestion}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={this.onSuggestionSelectedNone}
                    inputProps={{
                      placeholder: "Saved Meal",
                      value: this.state.searchText,
                      onChange: this.onChange,
                      className: "form-control",
                      style: { paddingLeft: 5, borderRadius: 0 },
                      maxLength: 30,
                    }}
                  />
                </div>
                <div className="logo_dashboard">
                  <img src={PokeDashSign} alt="" />
                </div>
              </div>
            </CSSTransition>
          )}
        </section>
        <ConfirmModal
          cancelText=""
          content=""
          title=""
          id="greetingModal"
          className="confirm-modal position-relative"
          onSubmit={this.toggleGreetingModal}
          noConfirmText={true}
        >
          <div
            className="position-absolute icon-cancel-greeting"
            onClick={this.removeGreetingModal}
          >
            <BsX />
          </div>
          <div className="d-flex justify-content-center mb-4">
            <img src={logo} alt="logo" width="40%" height="40%" />
          </div>
          <p className="text-center ml-0">{`Hello ${this.props.order.name} !`}</p>
          <p className="text-center ml-0 desc">
            Welcome to Poké Food Station ! <br /> Let's make an order.
          </p>
        </ConfirmModal>
        {this.props.videoIntro !== "" && (
          <ConfirmModal
            cancelText=""
            iconHide
            content=""
            title=""
            id="guideModal"
            className="guide-modal"
            onSubmit={this.toggleGuideModal}
            noConfirmText={true}
          >
            <YouTube
              videoId={this.youtube_parser(this.props.videoIntro)}
              opts={{
                height: window.innerWidth > 768 ? "390" : "290",
                width: "100%",
                playerVars: {
                  autoplay: 0,
                  controls: 0,
                  disablekb: 0,
                  fs: 0,
                  loop: 1,
                },
              }}
              onReady={this._onReady}
            />
          </ConfirmModal>
        )}
        <LogoutModal {...this.props} />
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    order: state.order,
    bowlImage: state.app.bowlImage,
    drinkImage: state.app.drinkImage,
    dessertImage: state.app.dessertImage,
    signatureImage: state.app.signatureImage,
    firstTimeAccess: state.app.firstTimeAccess,
    videoIntro: state.app.videoIntro,
    colorBackground: state.app.bowlColor,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getSuggestBowl = (
    text: string,
    callBack: (bowlList: Array<IBowl>) => void
  ) => {
    const service = new AppService();
    service
      .getSuggestBowl(text)
      .then((response) => {
        // dispatch(foodsAction.addProteinsFoods(response.food))
        callBack(response.bowls);
      })
      .catch(console.log);
  };

  const getBowl = (
    id: number,
    callBack: (savedBowl: SavedBowl) => void,
    callBackErr: () => void
  ) => {
    const service = new AppService();
    service
      .getBowl(id)
      .then((response) => {
        // dispatch(foodsAction.addProteinsFoods(response.food))
        dispatch(cartAction.addBowlSavedStatus(true));
        dispatch(cartAction.addBowlName(response.bowlName, response.id));
        callBack(response);
      })
      .catch((err) => {
        console.log(err);
        callBackErr && callBackErr();
      });
  };

  const getImage = (callBack: (images: Array<any>) => void) => {
    const service = new AppService();
    service
      .getMenuImage()
      .then((response) => {
        dispatch(appAction.addDrinkImage(response.images[1].image));
        dispatch(appAction.addDessertImage(response.images[0].image));
        dispatch(appAction.addBowlImage(response.images[2].image));
        dispatch(appAction.addSignatureImage(response.images[3].image));
        dispatch(appAction.addBowlColor(response.colorBackground));
        callBack(response.images);
      })
      .catch(console.log);
  };

  const addBowlNote = (note: string) => {
    dispatch(cartAction.addBowlNote(note));
  };

  const addBaseToCart = (item: Array<IFoodBase>, nonSelected: boolean) => {
    dispatch(cartAction.addBase(item, nonSelected));
  };

  const addProteinToCart = (
    item: Array<IFood>,
    itemSize: Array<IFoodSize>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addProteins(item, itemSize, nonSelected));
  };

  const addMarinadesToCart = (item: Array<IFood>, nonSelected: boolean) => {
    dispatch(cartAction.addMarinades(item, nonSelected));
  };

  const addExtraProtein = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addExtraProteins(item, nonSelected));
  };

  const addMixins = (item: Array<IFood>, nonSelected: boolean) => {
    dispatch(cartAction.addMixin(item, nonSelected));
  };

  const addAddonToCart = (item: Array<IFoodQuantity>, nonSelected: boolean) => {
    dispatch(cartAction.addAddon(item, nonSelected));
  };

  const addFlavourBoosterToCart = (
    item: Array<IFlavourBooster>,
    nonSelected: boolean
  ) => {
    dispatch(cartAction.addFlavourBooster(item, nonSelected));
  };

  const addToppingToCart = (item: Array<ITopping>, nonSelected: boolean) => {
    dispatch(cartAction.addTopping(item, nonSelected));
  };

  const resetCart = (callBack: () => void) => {
    dispatch(cartAction.resetCart());
    callBack();
  };

  const resetOrder = (callBack: () => void) => {
    dispatch(cartAction.resetCart());
    dispatch(orderAction.resetOrder());
    callBack();
  };

  const addMixableBase = (mixable: boolean) => {
    dispatch(cartAction.addMixableBase(mixable));
  };

  const updateFirstTimeAccess = (firstTimeAccess: boolean) => {
    dispatch(appAction.updateFirstTimeAccess(firstTimeAccess));
  };

  const updateNoriOption = (noriOption: boolean) => {
    dispatch(cartAction.addNoNori(noriOption));
  };

  const getVideo = () => {
    const service = new AppService();
    service
      .getVideoIntro()
      .then((response: any) => {
        dispatch(appAction.setVideoIntro(response.url.url));
      })
      .catch((err) => {});
  };

  return {
    getSuggestBowl,
    getBowl,
    getImage,
    addBaseToCart,
    addMarinadesToCart,
    addExtraProtein,
    addMixins,
    addProteinToCart,
    addAddonToCart,
    addFlavourBoosterToCart,
    addToppingToCart,
    resetCart,
    resetOrder,
    addMixableBase,
    updateFirstTimeAccess,
    getVideo,
    updateNoriOption,
    addBowlNote,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(MenuScreen);
