import React from "react";
import { BaseOrderHandler } from "./BaseOrder.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import ClipLoader from "react-spinners/ClipLoader";

import MixBase from "../../assets/image/MixBase@2x.png";
import {
  Loading,
  ConfirmModal,
  AnimationFood,
  MyScrollBar,
} from "../../components";
import { AppService } from "../../services";
import { IFood, IFoodBase, IFoodSize, IFoodQuantity } from "../../entities";
import { appAction } from "../../redux/app";
import { ReduxState, foodsAction, cartAction } from "../../redux";

class BaseOrderScreen extends BaseOrderHandler {
  render() {
    const { selectedItem, isMixable } = this.state;
    return (
      <>
        <div className="page position-relative main_right_child">
          <Loading
            loading={this.props.cart.isNonSelectedBase}
            style={{ right: -100 }}
          />
          <div className="">
            <p className="choose_your">CHOOSE YOUR</p>
            <h3>BASE</h3>
            <div className="switches switches_base">
              <div className="switch_box">
                <input
                  className="switch right hover-cursor"
                  type="checkbox"
                  checked={!this.props.cart.isNonSelectedBase}
                  onChange={this.toggleChange}
                />
              </div>
            </div>
          </div>
          <div className="list_food_main content">
            {this.state.isLoading ? (
              <div className="container text-center">
                <ClipLoader
                  css={
                    window.innerWidth > 768
                      ? ` margin-top: 200px `
                      : ` margin-top: 100px `
                  }
                  size={window.innerWidth > 768 ? 150 : 70}
                  color={"#D31710"}
                  loading={true}
                />
              </div>
            ) : (
              <MyScrollBar timeout={700}>
                <div className="list_food position-relative">
                  {this.props.foods.map((item: IFoodBase, b) => {
                    let selected = selectedItem.some((i: IFoodBase) => {
                      if (
                        item.id === i.id ||
                        item.size.some((x: IFood) => x.id === i.id)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    return (
                      <div
                        key={b}
                        className={`
                                                dish_list
                                                ${
                                                  item.count <= 0 ||
                                                  (isMixable &&
                                                    item.isBurrito === 1)
                                                    ? `disable-item`
                                                    : `hover-cursor`
                                                }
                                            `}
                      >
                        <div
                          className={`box_dish ${
                            selected ? `dish_active` : ``
                          } `}
                        >
                          <div
                            onClick={() => {
                              if (
                                item.count > 0 &&
                                ((isMixable && item.isBurrito === 0) ||
                                  isMixable === false)
                              ) {
                                this.onSelect(item);
                              }
                            }}
                          >
                            <div className="img_dish">
                              <img src={item.image} alt="" />
                            </div>
                            <h4>{item.name}</h4>
                          </div>
                        </div>
                        {item.mix === 1 && (
                          <div className="mix">
                            <img src={MixBase} alt="" />
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {this.state.animationFood &&
                    this.state.animationFood.map((a) => (
                      <AnimationFood img={a} />
                    ))}
                </div>
              </MyScrollBar>
            )}
          </div>
          {/* <div className="mix_number">
                        <label className="switch-toggle" htmlFor="checkbox">
                            <input type="checkbox" id="checkbox" checked={this.state.isMixable} onClick={this.toggleMixable}/>
                            <div className="slider round"></div>
                        </label>
                        <div className="d-flex hover-cursor">
                            <img src={MixBase} alt="" onClick={this.toggleMixable} /> 
                            <span onClick={this.toggleMixable}> = 50/50</span>
                        </div>
                    </div>
                    <div className="nori_option hover-cursor">
                        <label className="switch-toggle-nori" htmlFor="checkbox-nori">
                            <input type="checkbox" id="checkbox-nori" checked={this.props.noNori} onClick={() => this.props.updateNoNori(!this.props.noNori)} />
                            <div className="slider round"></div>
                        </label>
                        <span onClick={() => this.props.updateNoNori(!this.props.noNori)}>No Nori</span>
                    </div> */}
          <ConfirmModal
            content="Are you sure you didn't choose any base?"
            title="Confirm"
            id="baseConfirm"
            cancelText=""
            iconHide={true}
            className="confirm-modal"
            onSubmit={this.onConfirmModal}
          />
          <ConfirmModal
            content="You have to choose at least one protein or base food?"
            title=""
            id="atLeastConfirm"
            cancelText=""
            iconHide={true}
            className="confirm-modal"
            onSubmit={this.hideAtLeastConfirm}
          />
          <div
            className="modal fade"
            id="baseModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content modal-option">
                <div className="modal-header">
                  <div>
                    <h5
                      className="modal-title regular-font main-title"
                      id="exampleModalLabel"
                      style={{ fontSize: 32 }}
                    >
                      {this.state.selectedOptionName}
                    </h5>
                    <h5
                      className="modal-title sub-title"
                      style={{ fontSize: 52, lineHeight: "64px" }}
                    >
                      Choose Option
                    </h5>
                  </div>
                </div>
                <div className="modal-body-protein">
                  <div className="list_food position-relative">
                    {this.state.optionList.map((a: IFoodBase, b) => {
                      return (
                        <div
                          key={b}
                          className={`${
                            a.count <= 0 ? `disable-item` : `hover-cursor`
                          } protein_list base_option_list`}
                          onClick={() => a.count && this.onSelectFoodOption(a)}
                        >
                          <div className="img_box">
                            <img src={a.image} alt="" />
                          </div>

                          <div className="text_protein">
                            <h5 className="text-center">{a.name}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    foods: state.foods.baseFood,
    cart: state.cart,
    mixableBase: state.cart.mixableBase,
    noNori: state.cart.noNori,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getBase = async (callBack: () => void) => {
    const service = new AppService();
    service
      .getBaseFood()
      .then((response) => {
        dispatch(foodsAction.addBaseFoods(response.food));
        callBack();
      })
      .catch(console.log);
  };

  const addBaseToCart = (
    item: Array<IFoodBase>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addBase(item, nonSelected));
    callBack();
  };

  const resetSavedBowl = () => {
    dispatch(cartAction.addBowlName("", -1));
    dispatch(cartAction.addBowlSavedStatus(false));
  };

  const setNavigation = (
    backNavigation: string,
    nextNavigation: any,
    mainRightClassName: string
  ) => {
    dispatch(appAction.addNavigation(backNavigation, nextNavigation));
    dispatch(appAction.addMainRightClassName(mainRightClassName));
  };

  const getPriceMix = (
    mainId: number,
    mixId: number,
    callBack: (price: string) => void
  ) => {
    const service = new AppService();
    service
      .getPriceMix(mainId, mixId)
      .then((response) => {
        console.log(response);
        callBack(response.mix.price_article);
      })
      .catch(console.log);
  };

  const addProteinToCart = (
    item: Array<IFood>,
    itemSize: Array<IFoodSize>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addProteins(item, itemSize, nonSelected));
    callBack();
  };

  const addMarinadesToCart = (
    item: Array<IFood>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addMarinades(item, nonSelected));
    callBack();
  };

  const addExtraProtein = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addExtraProteins(item, nonSelected));
    callBack();
  };

  const addMixins = (
    item: Array<IFood>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addMixin(item, nonSelected));
    callBack();
  };

  const addMixableBase = (mixable: boolean) => {
    dispatch(cartAction.addMixableBase(mixable));
  };
  const addAddonToCart = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addAddon(item, nonSelected));
    callBack();
  };

  const updateNoNori = (noNori: boolean) => {
    dispatch(cartAction.addNoNori(noNori));
  };

  return {
    getBase,
    addBaseToCart,
    setNavigation,
    resetSavedBowl,
    getPriceMix,
    addExtraProtein,
    addProteinToCart,
    addMixins,
    addMarinadesToCart,
    addMixableBase,
    addAddonToCart,
    updateNoNori,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(BaseOrderScreen);
