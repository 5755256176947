import { AppServiceInterface } from "./AppInterface";
import { ApiWorker, ApiWorkerUltil } from "../../instances/axios";
import { AppUrlBuilder } from "../urlBuilder";
import {
  Food,
  IFoodList,
  FoodDetail,
  IFoodDetail,
  FlavourBooster,
  Topping,
  IFlavourBoosterList,
  IToppingList,
  FoodBase,
  SavedBowl,
  ISavedBowl,
  Coupon,
  ICoupon,
  Error,
  Categories,
  ICategories,
  Drink,
  IDrink,
  DessertCategories,
  IDessertCategories,
  Dessert,
  IDessert,
  OpenTIme,
  IOpenTime,
  CheckQuantity,
  ICheckQuantity,
  ICardResponse,
} from "../../entities";

export class AppService implements AppServiceInterface {
  api: ApiWorker;

  constructor(toke?: string, api: ApiWorker = ApiWorkerUltil.apiWorker) {
    this.api = api;
  }

  getBaseFood = async (): Promise<FoodBase> => {
    try {
      //   const url =
      //     process.env.REACT_APP_BASE_URL + "api/" + AppUrlBuilder.GetBase();

      const url = AppUrlBuilder.GetBase();
      const response = await this.api.get<any>(url);
      //   const response = await fetch(url);
      //   const data = await response.json();
      return response.data as FoodBase;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getProtein = async (idBase: string): Promise<Food> => {
    try {
      const url = AppUrlBuilder.GetProteins(idBase);
      const response = await this.api.get<any>(url);
      return response.data as IFoodList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getProteinDetail = async (
    id: number,
    base_id: string
  ): Promise<FoodDetail> => {
    try {
      const url = AppUrlBuilder.GetProteinsDetail(id, base_id);
      const response = await this.api.get<any>(url);
      return response.data as IFoodDetail;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getMarindate = async (): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetMarinades();
      const response = await this.api.get<any>(url);
      return response.data as any;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getAddOn = async (idBase: string): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetAddon(idBase);
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getFlavourBoosters = async (): Promise<FlavourBooster> => {
    try {
      const url = AppUrlBuilder.GetFlavourBoosters();
      const response = await this.api.get<any>(url);
      return response.data as IFlavourBoosterList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getTopping = async (): Promise<Topping> => {
    try {
      const url = AppUrlBuilder.GetTopping();
      const response = await this.api.get<any>(url);
      return response.data as IToppingList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  checkDuplicateName = async (name: string): Promise<any> => {
    try {
      const body = {
        bowlName: name,
      };
      const url = AppUrlBuilder.CheckDuplicateName();
      const response = await this.api.post<any>(url, body);
      return response.data as IToppingList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  saveBowl = async (body: any): Promise<any> => {
    try {
      const url = AppUrlBuilder.SaveBowl();
      const response = await this.api.post<any>(url, body);
      return response.data as IToppingList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getStartImages = async (): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetStartImages();
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getSuggestBowl = async (text: string): Promise<any> => {
    try {
      const body = {
        bowlName: text,
      };
      const url = AppUrlBuilder.GetSuggestBowl();
      const response = await this.api.post<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getSuggestClient = async (text: string): Promise<any> => {
    try {
      const body = {
        search: text,
      };
      const url = AppUrlBuilder.GetSuggestClient();
      const response = await this.api.post<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getBowl = async (id: number): Promise<SavedBowl> => {
    try {
      const url = AppUrlBuilder.GetBowl(id);
      const response = await this.api.get<any>(url);
      //@ts-ignore
      return response.data.detail as ISavedBowl;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getMenuImage = async (): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetMainMenuImage();
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  createOrder = async (body: any): Promise<any> => {
    try {
      const url = AppUrlBuilder.CreateOrder();
      const response = await this.api.post<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  creditOrder = async (body: any): Promise<any> => {
    try {
      const url = AppUrlBuilder.CreditOrder();
      const response = await this.api.post<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getPriceMix = async (firstId: number, secondId: number): Promise<any> => {
    try {
      const body = {
        aritlce_id: firstId,
        article_mix: secondId,
      };
      const url = AppUrlBuilder.GetPriceMix();
      const response = await this.api.post<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  checkCoupon = async (code: string): Promise<Coupon> => {
    try {
      const body = {
        code: code,
      };
      const url = AppUrlBuilder.CheckCoupon();
      const response = await this.api.post<any>(url, body);
      return response.data as ICoupon;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getCategories = async (): Promise<Categories> => {
    try {
      const url = AppUrlBuilder.GetCategories();
      const response = await this.api.get<any>(url);
      return response.data as ICategories;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getDrink = async (id: number): Promise<Drink> => {
    try {
      const url = AppUrlBuilder.GetDrink(id);
      const response = await this.api.get<any>(url);
      return response.data as IDrink;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getDessertCategories = async (): Promise<DessertCategories> => {
    try {
      const url = AppUrlBuilder.GetDessertsCategories();
      const response = await this.api.get<any>(url);
      return response.data as IDessertCategories;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getDessert = async (): Promise<Dessert> => {
    try {
      const url = AppUrlBuilder.GetDessert();
      const response = await this.api.get<any>(url);
      return response.data as IDessert;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getSignature = async (): Promise<Food> => {
    try {
      const url = AppUrlBuilder.GetSignature();
      const response = await this.api.get<any>(url);
      return response.data as IFoodList;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  checkOpenTime = async (): Promise<OpenTIme> => {
    try {
      const url = AppUrlBuilder.CheckOpenTime();
      const response = await this.api.get<any>(url);
      return response.data as IOpenTime;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  checkQuantity = async (body: any): Promise<CheckQuantity> => {
    try {
      const url = AppUrlBuilder.CheckQuantity();
      const response = await this.api.post<any>(url, body);
      return response.data as ICheckQuantity;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getCardPayment = async (): Promise<ICardResponse> => {
    try {
      const url = AppUrlBuilder.GetCard();
      const response = await this.api.get<any>(url);
      return response.data as ICardResponse;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getVideoIntro = async (): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetVideo();
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  searchOrderByID = async (id: number): Promise<any> => {
    try {
      const url = AppUrlBuilder.SearchOrder(id);
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  getOpeningTime = async (): Promise<any> => {
    try {
      const url = AppUrlBuilder.GetOpeningTime();
      const response = await this.api.get<any>(url);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };

  updatePaymentMethod = async (id: string, body: any): Promise<any> => {
    try {
      const url = AppUrlBuilder.UpdatePaymentMethod(id);
      const response = await this.api.put<any>(url, body);
      return response.data;
    } catch (ex:any) {
      throw new Error(ex);
    }
  };
}
