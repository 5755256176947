import React from 'react';
import { connect } from "react-redux";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import {
    TransitionGroup,
    CSSTransition
    //@ts-ignore
} from "react-transition-group";
import { routes } from "../route";
import { Footer, Cart, Header, LogoutModal } from '../components';
import { ReduxState, OrderState } from '../redux'
import { Dispatch, Action } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getPathDepth(location: any) {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter((n: any) => n !== "");
    return pathArr.length;
}

interface AppRoutesProps extends RouteComponentProps {
    back: string
    next: any
    mainRightClassName: string
    order: OrderState
    stack: Array<any>
}

//@ts-ignore
class AppRoutes extends React.Component<AppRoutesProps> {
    state = {
        prevDepth: this.getPathDepth(this.props.location)
    };
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: AppRoutesProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.order.name === '' || this.props.order.type === -1) {
            this.props.history.push('/start')
        }
    }

    componentWillReceiveProps() {
        setTimeout(() => {
            this.setState({ prevDepth: this.getPathDepth(this.props.location) });
        }, 1000)
    }

    getPathDepth(location: any) {
        let temp = routes.find(a => a.path === location.pathname)
        if (temp) {
            return temp.key
        } else {
            return -1
        }
        // let pathArr = location.pathname.split("/");
        // pathArr = pathArr.filter((n: any) => n !== "");
        // return pathArr.length;
    }
    render() {
        const { location } = this.props;
        const currentKey = location.pathname.split("/")[2] || "/";
        const timeout = { enter: 800, exit: 400 };

        return (
            <>
                <Header backLink={this.props.back} nextPress={this.props.next} {...this.props} />
                <section className="base_main">
                    <div className="base_main_title">
                        <Cart />
                        <div className={`main_right ${this.props.mainRightClassName}`}>

                            <TransitionGroup component="div" className="App">
                                <CSSTransition
                                    key={currentKey}
                                    timeout={timeout}
                                    classNames="pageSlider"
                                    mountOnEnter={false}
                                    unmountOnExit={true}
                                >
                                    <div className={
                                        this.getPathDepth(location) - this.state.prevDepth >= 0
                                            ? "left"
                                            : "right"
                                    } key={currentKey}>
                                        <Switch location={location}>
                                            {
                                                routes.map((prop: any, key) => {
                                                    return <Route path={prop.path} key={key} render={(props) => <prop.component {...props} stack={this.props.stack} />} />;
                                                })
                                            }
                                        </Switch>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>

                        </div>
                    </div>
                </section>
                <LogoutModal {...this.props} />
                <Footer {...this.props} />
            </>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        back: state.app.backNavigation,
        next: state.app.nextNavigation,
        mainRightClassName: state.app.mainRightClassName,
        order: state.order
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
    return {}
}

export const AppLayout = connect(mapStateToProps, mapDispatchToProp)(AppRoutes)