import React from "react";
import { OrderConfirmHandler } from "./OrderConfirm.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { ReduxState } from "../../redux";
import header from "../../assets/image/header.png";
import logo_main from "../../assets/image/logo_main.png";
import check_logo from "../../assets/image/CheckCircle.png";
import send_logo from "../../assets/image/send.png";
import confirmButton from "../../assets/image/confirmButton.png";

class OrderConfirmScreen extends OrderConfirmHandler {
  render() {
    return (
      <div className="main_menu">
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo">
            <img src={logo_main} alt="Logo" />
          </div>
        </section>
        <section className="base_main main_bowl_summary order_list_main bowl_success">
          <div className="box_order_success text-center position-relative">
            <img src={check_logo} alt="" className="check-logo icon" />
            <p className="text-center big-text">ORDER CONFIRMED</p>
            <p className="small-text">
              Your order is now in queue and will be ready in approx.{" "}
              <span className="strong">10</span> minutes
            </p>
          </div>
          <div className="box_order_pickup text-center">
            <img src={send_logo} alt="" className="icon" />
            <p className="medium-text">Wait for Pick-up Message</p>
            <p className="small_text">
              When your order is ready you will receive a Whatsapp message
            </p>
            <div className="line"></div>
            <p className="small_text italic">
              Did’nt enter your phone number?{" "}
              <span className="link">Click here</span>
            </p>
          </div>
          <div className="box-order-print text-center">
            <button className="btn-print">PRINT BILL</button>
            <p className="title">ORDER #441</p>
          </div>
          <div className="box-confirm text-center">
            <button className="btn-confirm" onClick={() => this.props.history.push("/order-success")}>
              <img src={confirmButton} alt="" />
            </button>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    order: state.order,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProp)(OrderConfirmScreen);
