import { ADD_ORDER, ADD_ORDER_INFO, RESET_ORDER, ADD_COUPON, ADD_DRINK, ADD_DESSERT, ADD_SIGNATURE, ADD_ORDER_SEARCHING_FLAG, ADD_SEARCH_RESULT, ADD_SCHEDULE_ORDER } from "./types";
import { IOrder, IDrinkCartItem, ICategoriesItem, IDessertCartItem, IFoodQuantity, ISearchOrder } from "../../entities";

export interface OrderState {
    order: Array<IOrder>
    name: string
    phone: string
    type: number
    couponValue: string,
    couponType: number
    couponCode: string
    drinks: Array<IDrinkCartItem>
    drinkCategories: Array<ICategoriesItem>
    desserts: Array<IDessertCartItem>
    dessertCategories: Array<ICategoriesItem>
    signature: Array<IFoodQuantity>
    isSearchingOrder: boolean,
    searchResult: ISearchOrder
    scheduleOrder: string
}

const initialState: OrderState = {
    order: [],
    name: '',
    phone: '',
    type: -1,
    couponValue: '-1',
    couponType: -1,
    couponCode: '',
    drinks: [],
    drinkCategories: [],
    desserts: [],
    dessertCategories: [],
    signature: [],
    isSearchingOrder: false,
    searchResult: {
        signature: [],
        bowls: [],
        client_name: '',
        client_phone: '',
        coupon_code: {
            code: '',
            discount: '',
            discount_usd: '',
            percent: 0,
            type: 0
        },
        dessert: [],
        discount: '',
        drinks: [],
        order_id: '',
        primary_price: '',
        status: '',
        total_price: '',
        type: ''
    },
    scheduleOrder: ''
}

function order(state = Object.assign({}, initialState), action: any) {
    let { type, payload } = action
    switch (type) {
        case ADD_ORDER:
            return Object.assign({}, state, payload)
        case ADD_ORDER_INFO:
            return Object.assign({}, state, payload)
        case ADD_DRINK:
            return Object.assign({}, state, payload)
        case ADD_DESSERT:
            return Object.assign({}, state, payload)
        case ADD_SIGNATURE:
            return Object.assign({}, state, payload)
        case ADD_COUPON:
            return Object.assign({}, state, payload)
        case ADD_ORDER_SEARCHING_FLAG:
            return Object.assign({}, state, payload)
        case ADD_SEARCH_RESULT:
            return Object.assign({}, state, payload)
        case ADD_SCHEDULE_ORDER:
            return Object.assign({}, state, payload)
        case RESET_ORDER:
            return Object.assign({}, state, {
                order: [],
                name: '',
                phone: '',
                type: -1,
                couponValue: '-1',
                couponType: -1,
                couponCode: '',
                drinks: [],
                drinkCategories: [],
                desserts: [],
                dessertCategories: [],
                signature: [],
                isSearchingOrder: false,
                searchResult: {
                    signature: [],
                    bowls: [],
                    client_name: '',
                    client_phone: '',
                    coupon_code: {
                        code: '',
                        discount: '',
                        discount_usd: '',
                        percent: 0,
                        type: 0
                    },
                    dessert: [],
                    discount: '',
                    drinks: [],
                    order_id: '',
                    primary_price: '',
                    status: '',
                    total_price: '',
                    type: ''
                },
                scheduleOrder: ''
            })
        default:
            return state
    }
}

export { order };