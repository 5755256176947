import * as React from "react";
import { Form, Field } from "react-final-form";

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from "../../utils/cardUtils";
import "./style.scss";

interface PaymentModelProps {
    onCheckoutCredit: (token: string) => void;
    onLoading: () => void;
    onError: (message: string) => void;
}

export const CreditCard = ({
    onCheckoutCredit,
    onLoading,
    onError,
}: PaymentModelProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [disable, setDisable] = React.useState(false);

    const tokenSuccess = (data: any) => {
        let token = data.response.token.token;
        let paymentInfo = {
            token,
        };
        console.log({ data, paymentInfo });
        onCheckoutCredit(token);
    };

    const onSubmit = async (values: any) => {
        //@ts-ignore
        $("#paymentMethod").modal("toggle");
        onLoading();
        let { expiry, cvv } = values;
        let ccNo = formatCreditCardNumber(values.ccNo);
        let expMonth = expiry.slice(0, 2);
        let expYear = expiry.slice(3, 5);
        let checkoutData = {
            sellerId: "250816872505",
            publishableKey: "7D703F9D-0208-45FE-A6D5-8207752290D4",
            ccNo,
            cvv,
            expMonth,
            expYear,
        };
        (window as any).TCO.requestToken(tokenSuccess, error, checkoutData);
    };
    var error = (error: any) => {
        onError(error.errorMsg);
    };

    const validateCredit = (values: any) => {
        const errors: any = {};
        if (!values.ccNo) {
            errors.ccNo = "Required";
        }
        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.expiry) {
            errors.expiry = "Required";
        }
        if (!values.cvv) {
            errors.cvv = "Required";
        }
        return errors;
    };
    return (
        <Form
            onSubmit={onSubmit}
            validate={validateCredit}
            render={({ handleSubmit, errors }) => {
                return (
                    <form className={"credit-card"} onSubmit={handleSubmit}>
                        <div>
                            <Field
                                className={
                                    "payment-input" +
                                    (!!errors
                                        ? errors.name
                                            ? " error"
                                            : ""
                                        : "")
                                }
                                name="name"
                                component="input"
                                type="text"
                                placeholder="Name"
                            />
                        </div>
                        <div>
                            <Field
                                className={
                                    "payment-input" +
                                    (!!errors
                                        ? errors.ccNo
                                            ? " error"
                                            : ""
                                        : "")
                                }
                                name="ccNo"
                                component="input"
                                type="text"
                                pattern="[\d| ]{16,22}"
                                placeholder="Card Number"
                                format={formatCreditCardNumber}
                            />
                        </div>
                        <div className="mb-3 flex">
                            <Field
                                className={
                                    "payment-valid payment-left" +
                                    (!!errors
                                        ? errors.expiry
                                            ? " error"
                                            : ""
                                        : "")
                                }
                                name="expiry"
                                component="input"
                                type="text"
                                pattern="\d\d/\d\d"
                                placeholder="Expire Date"
                                format={formatExpirationDate}
                            />
                            <Field
                                className={
                                    "payment-valid" +
                                    (!!errors
                                        ? errors.cvv
                                            ? " error"
                                            : ""
                                        : "")
                                }
                                name="cvv"
                                component="input"
                                type="text"
                                pattern="\d{3,4}"
                                placeholder="CVC"
                                format={formatCVC}
                            />
                        </div>
                        <div className="form-actions">
                            <button
                                disabled={disable}
                                className="btn btn-pay"
                            >
                                Pay
                            </button>
                        </div>
                    </form>
                );
            }}
        />
    );
};
