import * as React from 'react'
import logo_black from '../../assets/image/logo.png'
import back from '../../assets/image/back.png'
import next from '../../assets/image/next.png'
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { ReduxState, OrderState } from '../../redux';
import { Action } from 'redux';
import { connect } from 'react-redux';

interface HeaderProps extends RouteComponentProps {
    backLink?: string
    nextPress?: () => void
    order: OrderState,
    centerText?: string
}

export class HeaderComponent extends React.PureComponent<HeaderProps> {

    timeout = false
    setTimeout: any
    location = ''

    constructor(props: HeaderProps) {
        super(props)
        this.location = props.location.pathname
    }
    onNextPress = () => {
        if (this.timeout === false) {
            this.props.nextPress && this.props.nextPress()
        }
    }

    componentWillReceiveProps() {
        if (this.location !== this.props.location.pathname) {
            this.timeout = true
            this.location = this.props.location.pathname
            clearTimeout(this.setTimeout)
            this.setTimeout = setTimeout(() => {
                this.timeout = false
            }, 800)
        }
    }

    render() {
        const { backLink, nextPress, centerText = "BUILD YOUR OWN" } = this.props
        return (
            <section className="header">
                <div className="base_logo">
                    <img src={logo_black} alt="" />
                </div>
                <div className="base_heading">
                    {!!backLink && <NavLink to={backLink} className="btn btn-back"><img src={back} alt="" /></NavLink>}
                    <h2 className="build">{centerText}</h2>
                    <p className="name_customer">Name: <span>{this.props.order.name}</span></p>
                    {nextPress && <div onClick={this.onNextPress} className="btn next_page hover-cursor">
                        <img src={next} alt="" />
                    </div>}
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        cart: state.cart,
        order: state.order
    }
}

const mapDispatchToProp = (dispatch: React.Dispatch<Action>) => {

    return {

    }
}
export const Header = connect(mapStateToProps, mapDispatchToProp)(HeaderComponent)