import React from 'react'
import { IFood, IFoodBase, IFoodQuantity, IFoodSize } from '../../entities'
import { RouteComponentProps } from 'react-router-dom'
import { CartState } from '../../redux'
import { NoriItem } from '../../constants'
interface BaseOrderInterface extends RouteComponentProps {
    foods: Array<IFoodBase>
    getBase: (callBack: () => void) => void
    addBaseToCart: (item: Array<IFoodBase>, nonSelected: boolean, callBack: () => void) => void
    cart: CartState
    setNavigation: (backNavigation: string, nextNavigation: any, mainRightClassName: string) => void
    resetSavedBowl: () => void
    getPriceMix: (mainId: number, mixId: number, callBack: (price: string) => void) => void
    stack: any
    addProteinToCart: (item: Array<IFood>, itemSize: Array<IFoodSize>, nonSelected: boolean, callBack: () => void) => void
    addMarinadesToCart: (item: Array<IFood>, nonSelected: boolean, callBack: () => void) => void
    addExtraProtein: (item: Array<IFoodQuantity>, nonSelected: boolean, callBack: () => void) => void
    addMixins: (item: Array<IFood>, nonSelected: boolean, callBack: () => void) => void
    addMixableBase: (mixable: boolean) => void
    addAddonToCart: (item: Array<IFoodQuantity>, nonSelected: boolean, callBack: () => void) => void
    mixableBase: boolean
    noNori: boolean
    updateNoNori: (noNori: boolean) => void
}

interface BaseOrderState {
    selectedItem: Array<IFoodBase>
    isLoading: boolean
    optionList: Array<IFoodBase>
    selectedOptionName: string
    isMixable: boolean
    nextLink: string
    animationFood: Array<string>
    isSelecting: boolean
    hasBurrito: boolean
}

export class BaseOrderHandler extends React.Component<BaseOrderInterface, BaseOrderState> {

    nextLink: string
    params: any
    constructor(props: BaseOrderInterface) {
        super(props)
        this.state = {
            selectedItem: this.props.cart.base.slice(),
            isLoading: true,
            optionList: [],
            selectedOptionName: '',
            isMixable: this.props.mixableBase,
            nextLink: '/order/proteins',
            animationFood: [],
            isSelecting: false,
            hasBurrito: this.props.cart.base.length > 0 ? (this.props.cart.base[0].isBurrito === 1 ? true : false) : false
        }
        this.nextLink = '/order/proteins'
        this.params = {}
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            // this.nextLink = '/bowl-summary'
            this.params = { updateFlag: true }
        }
        if (this.props.stack.length === 0) {
            //@ts-ignore
            if (this.props.location.state && this.props.location.state.updateFlag) {
                this.props.setNavigation('/bowl-summary', this.onNext, 'position-relative')
            } else {
                this.props.setNavigation('/menu', this.onNext, 'position-relative')
            }
        } else {
            if (this.props.stack.length > 1) {
                if (this.props.stack[this.props.stack.length - 2].pathname.includes('/desserts') || this.props.stack[this.props.stack.length - 2].pathname.includes('/drinks')) {
                    this.props.setNavigation(this.props.stack[this.props.stack.length - 2].pathname, this.onNext, 'position-relative')
                } else {
                    //@ts-ignore
                    if (this.props.location.state && this.props.location.state.updateFlag) {
                        this.props.setNavigation('/bowl-summary', this.onNext, 'position-relative')
                    } else {
                        this.props.setNavigation('/menu', this.onNext, 'position-relative')
                    }
                }
            } else {
                if (this.props.stack[this.props.stack.length - 1].pathname.includes('/desserts') || this.props.stack[this.props.stack.length - 1].pathname.includes('/drinks')) {
                    this.props.setNavigation(this.props.stack[this.props.stack.length - 1].pathname, this.onNext, 'position-relative')
                } else {
                    //@ts-ignore
                    if (this.props.location.state && this.props.location.state.updateFlag) {
                        this.props.setNavigation('/bowl-summary', this.onNext, 'position-relative')
                    } else {
                        this.props.setNavigation('/menu', this.onNext, 'position-relative')
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.props.getBase(() => {
            let temp = this.state.selectedItem
            let removedIndex: Array<number> = []
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = this.props.foods.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        removedIndex.push(index)
                    }
                })
                if (removedIndex.length > 0) {
                    removedIndex.reverse().forEach(a => {
                        temp.splice(a, 1)
                    })
                    this.props.addBaseToCart(Array.from(temp), false, () => { })
                    this.props.resetSavedBowl()
                }
                this.setState({ selectedItem: temp })
            }
            this.setState({ isLoading: false })
        })
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#baseModal').modal('hide')
        //@ts-ignore
        $('#baseConfirm').modal('hide')
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }

    onSelect = (item: IFoodBase) => { 
        const { selectedItem, isMixable, isSelecting, hasBurrito } = this.state
        this.props.resetSavedBowl()
        if (isSelecting === false) {
            if (selectedItem.some(a => {
                return a.id === item.id
            })) {
                let temp = selectedItem.filter((a: IFoodBase) => a.id !== item.id)
                this.setState({
                    selectedItem: temp,
                })
                this.props.addBaseToCart(temp, false, () => { })
            } else {
                
                if (item.size.length > 0) {
                    if (item.size.some(a => {
                        return selectedItem.some(b => b.id === a.id)
                    })) {
                        let temp = selectedItem.filter((a: IFoodBase) => {
                            if (item.size.some((b: IFood) => b.id === a.id)) {
                                return false
                            } else {
                                return true
                            }
                        })
                        this.setState({
                            selectedItem: temp,
                        })
                        if (isMixable === true) {
                            this.props.addBaseToCart(temp, false, () => { })
                        }
                    } else {
                        if (selectedItem.length === 0 || (selectedItem.length > 0 && selectedItem[0].mix === 1 && item.mix === 1 && selectedItem.length < 2 && isMixable === true)) {
                            //@ts-ignore
                            $('#baseModal').modal('toggle')
                            this.setState({ optionList: item.size, selectedOptionName: item.name })
                        }

                    }
                } else {
                    //Select second item                   
                    if (selectedItem.length > 0 && selectedItem[0].mix === 1 && item.mix === 1 && selectedItem.length < 2 && isMixable === true) {
                        
                        let temp = selectedItem
                        temp.push(item)
                        if (temp.length === 2) {
                            this.props.getPriceMix(temp[0].id, temp[1].id, (price: string) => {
                                let tempSelected = temp
                                tempSelected[0].price = price
                                tempSelected[1].price = "0"
                                this.props.addBaseToCart(tempSelected, false, () => { })
                            })
                        } else {
                            this.props.addBaseToCart(temp, false, () => { })
                        }
                        this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image), isSelecting: true })
                        setTimeout(() => {
                            this.setState({ isSelecting: false })
                        }, 700)
                    }
                    //Select first item
                    else if (selectedItem.length === 0) {
                        let temp = selectedItem
                        temp.push(item)
                        this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image), isSelecting: true })
                        if (isMixable === false) {
                            setTimeout(() => {
                                this.onNext()
                            }, 600);
                            this.setState({ animationFood: this.state.animationFood.concat(item.image), isSelecting: true })
                        } else {
                            if ((hasBurrito === true && item.isBurrito === 0) || (hasBurrito === false && item.isBurrito === 1)) {
                                this.props.addProteinToCart([], [], false, () => {
                                    this.props.addMarinadesToCart([], false, () => { })
                                    this.props.addMixins([], false, () => { })
                                    this.props.addExtraProtein([], false, () => { })
                                    this.props.addAddonToCart([], false, () => { })
                                })
                            }
                            this.props.addBaseToCart(temp, false, () => { })
                        }
                        setTimeout(() => {
                            this.setState({ isSelecting: false })
                        }, 700)
                    }
                    else {
                    //Handle select base again
                        let temp = selectedItem
                        temp = [item]
                        this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image), isSelecting: true })
                        if (isMixable === false) {
                            setTimeout(() => {
                                this.onNext()
                            }, 600);
                            this.setState({ animationFood: this.state.animationFood.concat(item.image), isSelecting: true })
                        } else {
                            if ((hasBurrito === true && item.isBurrito === 0) || (hasBurrito === false && item.isBurrito === 1)) {
                                this.props.addProteinToCart([], [], false, () => {
                                    this.props.addMarinadesToCart([], false, () => { })
                                    this.props.addMixins([], false, () => { })
                                    this.props.addExtraProtein([], false, () => { })
                                    this.props.addAddonToCart([], false, () => { })
                                })
                            }
                            this.props.addBaseToCart(temp, false, () => { })
                        }
                        setTimeout(() => {
                            this.setState({ isSelecting: false })
                        }, 700)
                    }
                }
            }
        }
    }

    onSelectFoodOption = (item: IFoodBase) => {
        const { selectedItem, optionList, isMixable } = this.state
        if (selectedItem.some(a => {
            return a.id === item.id
        })) {
            this.setState({ selectedItem: selectedItem.filter((a: IFoodBase) => a.id !== item.id) })
        } else {
            let temp = selectedItem
            temp = temp.filter(a => {
                if (optionList.some(b => a.id === b.id)) {
                    return false
                } else {
                    return true
                }
            })
            temp.push({ ...item, size: [] })
            this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image), isSelecting: true })

            //@ts-ignore
            $('#baseModal').modal('toggle')

            if (isMixable === false) {
                setTimeout(() => {
                    this.onNext()
                }, 700);
            } else {
                setTimeout(() => {
                    this.props.addBaseToCart(temp, false, () => {
                        this.setState({ isSelecting: false })
                    })
                }, 700)
            }
        }
    }

    toggleMixable = () => {
        // eslint-disable-next-line no-mixed-operators
        if (this.state.isMixable === true && this.state.selectedItem.length > 0 || (this.state.isMixable === false && this.state.selectedItem.length > 0 && this.state.selectedItem[0].isBurrito === 1)) {
            this.props.resetSavedBowl()
            this.props.addBaseToCart([], this.props.cart.isNonSelectedBase, () => {
                this.setState({ selectedItem: [] })
            })
            this.props.addProteinToCart([], [], false, () => {
                this.props.addMarinadesToCart([], false, () => { })
                this.props.addMixins([], false, () => { })
                this.props.addExtraProtein([], false, () => { })
                this.props.addAddonToCart([], false, () => { })
            })
        }
        this.props.addMixableBase(!this.state.isMixable)
        this.setState({ isMixable: !this.state.isMixable })
    }

    hideAtLeastConfirm = () => {
        //@ts-ignore
        $('#atLeastConfirm').modal('hide');
    }

    toggleChange = () => {   
        if (this.props.cart.isNonSelectedBase === false && this.props.cart.isNonSelectedProtein === true) {
            //@ts-ignore
            $('#atLeastConfirm').modal('toggle')            
        } else {
            this.props.resetSavedBowl()
            this.props.addBaseToCart([], !this.props.cart.isNonSelectedBase, () => {
                this.setState({ selectedItem: [] })
            })
            if(this.props.cart.isNonSelectedBase === false) {
                this.props.history.push({
                    pathname: this.nextLink,
                });
            }
        }
    }

    clearNoriOption = () => {
        let { selectedItem } = this.state
        if (selectedItem.length > 0 && selectedItem.some(a => a.id === 471 || a.id === 473 || a.id === 474) === false && this.props.noNori === true) {
            this.props.updateNoNori(false)
            this.onConfirmNoriModal()
        }
    }

    onNext = () => {
        const { selectedItem, hasBurrito } = this.state
        this.clearNoriOption()
        if ((selectedItem.length > 0
            && this.props.cart.base.length > 0
            && this.props.cart.base[0].hasOwnProperty('isBurrito')
            && selectedItem[0].hasOwnProperty('isBurrito')
            // eslint-disable-next-line no-mixed-operators
            && this.props.cart.base[0].isBurrito !== selectedItem[0].isBurrito)
            || (selectedItem.length > 0 && selectedItem[0].hasOwnProperty('isBurrito')
            // eslint-disable-next-line no-mixed-operators
            && (selectedItem[0].isBurrito === 1 && hasBurrito === false 
            // eslint-disable-next-line no-mixed-operators
            || selectedItem[0].isBurrito === 0 && hasBurrito === true))) {
            this.props.addProteinToCart([], [], false, () => {
                this.props.addMarinadesToCart([], false, () => { })
                this.props.addMixins([], false, () => { })
                this.props.addExtraProtein([], false, () => { })
                this.props.addAddonToCart([], false, () => { })
            })
        }
        if (this.state.selectedItem.length > 0) {
            if (selectedItem.length === 2) {
                this.props.getPriceMix(selectedItem[0].id, selectedItem[1].id, (price: string) => {
                    let tempSelected = selectedItem
                    tempSelected[0].price = price
                    tempSelected[1].price = "0"
                    this.props.addBaseToCart(tempSelected, false, () => {
                        this.props.history.push({
                            pathname: this.nextLink,
                            state: this.params
                        })
                    })
                })
            } else {
                this.props.addBaseToCart(this.state.selectedItem, false, () => {
                    this.props.history.push({
                        pathname: this.nextLink,
                        state: this.params
                    })
                })
            }
        } else {
            if (this.props.cart.isNonSelectedProtein === true) {
                //@ts-ignore
                $('#atLeastConfirm').modal('toggle')
            } else {
                if (this.props.cart.isNonSelectedBase === false) {
                    //@ts-ignore
                    $('#baseConfirm').modal('toggle')
                } else {
                    this.props.addBaseToCart([], true, () => {
                        this.props.history.push({
                            pathname: this.nextLink,
                            state: this.params
                        })
                    })
                }

            }
        }

    }

    onConfirmModal = () => {
        //@ts-ignore
        $('#baseConfirm').modal('toggle')
        this.props.addBaseToCart([], true, () => {
            this.props.history.push({
                pathname: this.nextLink,
                state: this.params
            })
        })
    }

    onConfirmNoriModal = () => {
        //@ts-ignore
        $('#noriAlertModal').modal('toggle')
    }

    onSelectOption = () => {

    }

    checkFirstNori = (id: number) => {
        let numberOfNori = this.state.selectedItem.filter(a => NoriItem.includes(a.id))
        if (numberOfNori.findIndex(a => a.id === id) === 0) {
            return true
        } else {
            return false
        }
    }
}