import React from "react";

export const BowlNoteModalIcon = () => {
  return (
    <svg
      width="135"
      height="150"
      viewBox="0 0 135 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3073_312)">
        <path
          d="M113.55 129.5H16.3738C16.0029 129.5 15.6553 129.155 15.6553 128.705V13.7844C15.6553 13.3752 15.9645 13.0659 16.3738 13.0659H113.55C113.999 13.0659 114.345 13.4136 114.345 13.7844V128.705C114.345 129.115 113.959 129.5 113.55 129.5Z"
          fill="#FFF7F3"
          stroke="#EDE6DB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.6051 33.0521C27.8734 33.0521 24.8271 30.0059 24.8271 26.3503C24.8271 22.6186 27.8734 19.5724 31.6051 19.5724C35.2607 19.5724 38.3069 22.6186 38.3069 26.3503C38.3069 30.0059 35.3368 33.0521 31.6051 33.0521ZM98.3948 33.0521C94.6631 33.0521 91.693 30.0059 91.693 26.3503C91.693 22.6186 94.6631 19.5724 98.3948 19.5724C102.126 19.5724 105.097 22.6186 105.097 26.3503C105.097 30.0059 102.126 33.0521 98.3948 33.0521ZM76.1569 33.0521C72.4252 33.0521 69.379 30.0059 69.379 26.3503C69.379 22.6186 72.4252 19.5724 76.1569 19.5724C79.8125 19.5724 82.8587 22.6186 82.8587 26.3503C82.8587 30.0059 79.8125 33.0521 76.1569 33.0521ZM53.8429 33.0521C50.1113 33.0521 47.1411 30.0059 47.1411 26.3503C47.1411 22.6186 50.1113 19.5724 53.8429 19.5724C57.5746 19.5724 60.6209 22.6186 60.6209 26.3503C60.6209 30.0059 57.5746 33.0521 53.8429 33.0521Z"
          fill="url(#paint0_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4497 27.4444C30.8587 27.4444 30.3335 27.0394 30.3335 26.5187V7.48358C30.3335 6.905 30.8587 6.5 31.4497 6.5C32.0406 6.5 32.5002 6.905 32.5002 7.48358V26.5187C32.5002 27.0394 32.0406 27.4444 31.4497 27.4444Z"
          fill="url(#paint1_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.8055 27.4444C53.232 27.4444 52.7222 27.0394 52.7222 26.5187V7.48358C52.7222 6.905 53.232 6.5 53.8055 6.5C54.379 6.5 54.8888 6.905 54.8888 7.48358V26.5187C54.8888 27.0394 54.379 27.4444 53.8055 27.4444Z"
          fill="url(#paint2_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.2272 27.4444C75.5707 27.4444 75.1111 27.0394 75.1111 26.5187V7.48358C75.1111 6.905 75.5707 6.5 76.2272 6.5C76.8182 6.5 77.2778 6.905 77.2778 7.48358V26.5187C77.2778 27.0394 76.8182 27.4444 76.2272 27.4444Z"
          fill="url(#paint3_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2223 27.4444C97.4576 27.4444 96.7778 27.0394 96.7778 26.5187V7.48358C96.7778 6.905 97.4576 6.5 98.2223 6.5C98.987 6.5 99.6667 6.905 99.6667 7.48358V26.5187C99.6667 27.0394 98.987 27.4444 98.2223 27.4444Z"
          fill="url(#paint4_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5817 102.736C41.2009 102.736 40.744 102.583 40.5155 102.279C40.0586 101.898 39.9063 101.289 40.0586 100.756L42.5718 91.3122C42.7241 90.7791 43.1049 90.3222 43.638 90.1698C44.2472 90.0175 44.7803 90.1698 45.1611 90.6268L51.7106 97.1001C52.0914 97.4809 52.2437 98.0902 52.0914 98.6233C52.0152 99.1564 51.5583 99.5372 51.1013 99.7656L42.0387 102.66C41.8863 102.66 41.734 102.736 41.5817 102.736Z"
          fill="url(#paint5_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.7629 66.3328C84.306 66.3328 83.9252 66.1805 83.6206 65.8759L76.3857 58.7171C76.081 58.4125 75.9287 57.9556 75.9287 57.5748C75.9287 57.1178 76.081 56.737 76.3857 56.4324L78.975 53.8431C79.965 52.853 81.3359 52.3199 82.7067 52.3199C84.1537 52.3199 85.4483 52.853 86.4384 53.8431L88.4185 55.8993C89.4085 56.8894 90.0177 58.184 90.0177 59.5549C90.0177 61.0018 89.4085 62.2965 88.4185 63.2865L85.8291 65.8759C85.5245 66.1805 85.1437 66.3328 84.7629 66.3328Z"
          fill="url(#paint6_linear_3073_312)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9489 100.146C50.492 100.146 50.1112 99.994 49.8066 99.6893L42.6478 92.5306C42.3432 92.226 42.1147 91.769 42.1147 91.3882C42.1147 90.9313 42.3432 90.5505 42.6478 90.2459L76.3853 56.4323L85.8288 65.8757L52.0151 99.6893C51.7105 99.994 51.3297 100.146 50.9489 100.146Z"
          fill="url(#paint7_linear_3073_312)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3073_312"
          x="0"
          y="0"
          width="150"
          height="166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.470833 0 0 0 0 0.411259 0 0 0 0 0.376667 0 0 0 0.29 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3073_312"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3073_312"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3073_312"
          x1="100.285"
          y1="5"
          x2="100.285"
          y2="40.7125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3073_312"
          x1="32.3703"
          y1="-16.1421"
          x2="32.3684"
          y2="39.3469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3073_312"
          x1="54.759"
          y1="-16.1421"
          x2="54.7571"
          y2="39.3469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3073_312"
          x1="77.1479"
          y1="-16.1421"
          x2="77.146"
          y2="39.3469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3073_312"
          x1="99.4936"
          y1="-16.1421"
          x2="99.4922"
          y2="39.3469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3073_312"
          x1="51.4215"
          y1="76.4666"
          x2="51.4214"
          y2="109.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3073_312"
          x1="89.1732"
          y1="37.1713"
          x2="89.1731"
          y2="74.2962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3073_312"
          x1="83.2084"
          y1="9.17504"
          x2="83.208"
          y2="124.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F6D6B" />
          <stop offset="1" stopColor="#2D2725" />
        </linearGradient>
      </defs>
    </svg>
  );
};
