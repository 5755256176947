import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OrderState } from '../../redux'
import { AppState } from '../../redux/app'
import { } from "../../instances";
import { IBowl, ISearchOrder } from '../../entities'
interface SearchOrderScreenInterface extends RouteComponentProps {
    getImage: (callBack: (image: Array<string>) => void) => void
    updateOrderInfo: (name: string, type: number, phone: string) => void
    order: OrderState
    checkOpenTime: (callBack: (check: boolean) => void) => void
    app: AppState
    getSuggestClient: (text: string, callBack: (bowlList: Array<IBowl>) => void) => void
    searchOrder: (orderId: number, callBack: (searchOrder: ISearchOrder) => void, callBackErr: () => void) => void
    addSearchResult: (searchResult: ISearchOrder, callBack: () => void) => void
}

export class SearchOrderScreenHandler extends React.Component<SearchOrderScreenInterface> {

    input: any
    timeout: any

    state = {
        image: [],
        suggestion: [],
        searchText: '',
        isToggleStart: false,
        name: '',
        phone: '',
        zIndexFlag: false,
        isSearchingOrder: true,
        searchOrderText: ''
    }

    constructor(props: SearchOrderScreenInterface) {
        super(props)
        this.input = React.createRef()
    }

    componentDidMount() {
        this.setState({ isToggleStart: true })
        this.props.getImage((image: Array<string>) => {
            this.setState({ image })
            //@ts-ignore
            $('.slick_slide').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: window.innerWidth > 768 ? 0 : 200
            })
        })
        this.props.checkOpenTime((check: boolean) => {
            if (check === false) {
                this.onToggleOpeningModal()
            }
        })
    }

    toggleStart = () => {
        if (!!this.state.searchOrderText && this.state.searchOrderText.trim() !== '' && Number(this.state.searchOrderText)) {
            this.props.searchOrder(Number(this.state.searchOrderText), (searchOrder) => {
                this.props.addSearchResult(searchOrder, () => { })
                this.props.history.push('/search-result')
            }, () => {
                this.toggleOrderNotFoundModal()
            })
        }
    }

    onSuggestionsFetchRequested = (data: any) => {
        clearTimeout(this.timeout)
        this.setState({ suggestion: [] })
        this.timeout = setTimeout(() => {
            this.props.getSuggestClient(data.value, (clientList: Array<IBowl>) => {
                this.setState({ suggestion: clientList })
            })
        }, 200)
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestion: []
        });
    };

    onToggleOpeningModal = () => {
        //@ts-ignore
        $('#opening-modal').modal('toggle')
    }

    focus = () => {
        this.input.current?.focus();
    }

    onSelectOrderType = (type: number) => {
        if (this.state.name.trim() === '') {
            //@ts-ignore
            $('#startConfirm').modal('toggle')
        } else {
            this.props.updateOrderInfo(this.state.name.trim(), type, this.state.phone.trim())
            this.props.history.push('/menu')
        }
    }

    onChangeText = (e: any, { newValue }: any) => {
        this.setState({ name: e.target.value || newValue })
    }

    onSelectClient = (name: string, phone: string) => {
        this.setState({ phone })
    }

    onChangeSearchOrderText = (e: any) => {
        this.setState({ searchOrderText: e.target.value })
    }

    onConfirmModal = () => {
        //@ts-ignore
        $('#startConfirm').modal('toggle')
    }

    toggleOrderNotFoundModal = () => {
        //@ts-ignore
        $('#orderNotFound').modal('toggle')
    }

    onConfirmValidOrderIdModal = () => {
        //@ts-ignore
        $('#validOrderId').modal('toggle')
    }

    onSearchOrderToggle = () => {
        this.setState({ isSearchingOrder: !this.state.isSearchingOrder })
    }
}