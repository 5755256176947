import { ADD_BASE_FOOD, ADD_PROTEIN_FOOD, ADD_ADDON_FOOD, ADD_FLAVOUR_BOOSTER, ADD_TOPPING } from './types'
import { IFood, IFlavourBooster, IFoodBase } from '../../entities'
import { ITopping } from '../../entities/Topping'


const action = {
    addBaseFoods: (baseFood: Array<IFoodBase>) => {
        return {
            type: ADD_BASE_FOOD,
            payload: { baseFood }
        }
    },
    addProteinsFoods: (proteinFood: Array<IFood>) => {
        return {
            type: ADD_PROTEIN_FOOD,
            payload: { proteinFood }
        }
    },
    addAddOnFoods: (addonFood: Array<IFood>) => {
        return {
            type: ADD_ADDON_FOOD,
            payload: { addonFood }
        }
    },
    addFlavourBooster: (flavourBooster: Array<IFlavourBooster>) => {
        return {
            type: ADD_FLAVOUR_BOOSTER,
            payload: { flavourBooster }
        }
    },
    addTopping: (topping: Array<ITopping>) => {
        return {
            type: ADD_TOPPING,
            payload: { topping }
        }
    },

}

export default action