export const AppUrlBuilder = {

    GetBase: () => {
        return `kiosk/base-screen`
    },

    GetProteins: (idBase: string) => {
        return `kiosk/protein-screen?base_id=${idBase}`
    },

    GetProteinsDetail: (id: number, base_id: string) => {
        return `kiosk/protein-screen/${id}?base_id=${base_id}`
    },

    GetExtra: () => {
        return `kiosk/extra-screen`
    },

    // GetMarinades: () => {
    //     return `kiosk/marinades-screen`
    // },

    GetAddon: (idBase: string) => {
        return `kiosk/add-ons-screen?base_id=${idBase}`
    },

    GetFlavourBoosters: () => {
        return `kiosk/flavour-bootster-screen`
    },

    GetTopping: () => {
        return `kiosk/topping`
    },

    CheckDuplicateName: () => {
        return `kiosk/check-bowl-name`
    },

    SaveBowl: () => {
        return `kiosk/save-bowl`
    },

    GetStartImages: () => {
        return `kiosk/start-screen`
    },

    GetMarinades: () => {
        return `kiosk/marinades-protein-extra`
    },

    GetSuggestBowl: () => {
        return `kiosk/search-bowls`
    },

    GetSuggestClient: () => {
        return `kiosk/search-client`
    },

    GetBowl: (id: number) => {
        return `kiosk/get-bowl/${id}`
    },

    GetMainMenuImage: () => {
        return `kiosk/main-screen`
    },

    CreateOrder: () => {
        return `kiosk/order-client`
    },

    CreditOrder: () => {
        return `kiosk/order-card`
    },

    GetPriceMix: () => {
        return `kiosk/get-article-mix`
    },

    CheckCoupon: () => {
        return `kiosk/check-coupon`
    },

    GetCategories: () => {
        return `kiosk/categories-drink`
    },

    GetDrink: (id: number) => {
        return `kiosk/drinks/${id}`
    },

    GetDessertsCategories: () => {
        return `kiosk/categories-dessert`
    },

    GetDessert: () => {
        return `kiosk/dessert-screen`
    },

    GetSignature: () => {
        return `kiosk/get-signature`
    },

    CheckOpenTime: () => {
        return `kiosk/check-time-off`
    },

    CheckQuantity: () => {
        return `kiosk/check-quantity`
    },

    GetCard: () => {
        return `/kiosk/carts`
    },

    GetVideo: () => {
        return `/kiosk/video`
    },

    SearchOrder: (id: number) => {
        return `kiosk/order/${id}`
    },

    GetOpeningTime: () => {
        return `kiosk/open-time`
    },

    UpdatePaymentMethod: (id: string) => {
        return `kiosk/order/${id}`
    }
}