import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CartState, OrderState } from '../../redux'
import { IFood, IOrderItem, IFoodSize, IFoodQuantity, IOrder, IFlavourBooster, ITopping, IFoodBase, Coupon, IDrinkCartItem, ICategoriesItem, IDessertCartItem, IOutOfStockCheckQuantity, IOpeningHours } from '../../entities'
import { COUPON_TYPE, NumberFlavourBoosterFree } from '../../constants'
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
interface OrderListInterface extends RouteComponentProps {
    cart: CartState
    order: OrderState
    bowlImage: string
    stack: Array<any>
    addToOrder: (order: Array<IOrder>, callBack: () => void) => void
    addBaseToCart: (item: Array<IFoodBase>, nonSelected: boolean) => void
    addProteinToCart: (item: Array<IFood>, itemSize: Array<IFoodSize>, nonSelected: boolean) => void
    addMarinadesToCart: (item: Array<IFood>, nonSelected: boolean) => void
    addExtraProtein: (item: Array<IFoodQuantity>, nonSelected: boolean) => void
    addMixins: (item: Array<IFood>, nonSelected: boolean) => void
    addAddonToCart: (item: Array<IFoodQuantity>, nonSelected: boolean) => void
    addFlavourBoosterToCart: (item: Array<IFlavourBooster>, nonSelected: boolean) => void
    addToppingToCart: (item: Array<ITopping>, nonSelected: boolean) => void
    saveBowlName: (name: string, check: boolean) => void
    updateQuantityBowl: (quantity: number) => void
    addUpdateIndex: (index: number) => void
    resetCart: (callBack: () => void) => void
    createOrder: (body: any) => void
    checkCoupon: (code: string, callBack: (coupon: Coupon) => void) => void
    addCouponToOrder: (code: string, value: string, type: number) => void
    addDrinksToCart: (drinks: Array<IDrinkCartItem>, categories: Array<ICategoriesItem>) => void
    addDessertToCart: (dessert: Array<IDessertCartItem>, categories: Array<ICategoriesItem>) => void
    addSignature: (signature: Array<IFoodQuantity>) => void
    updateOrderInfo: (name: string, type: number, phone: string) => void
    addMixableBase: (mixable: boolean) => void
    updateNoNori: (noNori: boolean) => void
    checkQuantity: (body: any, CallBack: (check: boolean, dataCheckOutOfStock: IOutOfStockCheckQuantity, dataCheckQuantity: IOutOfStockCheckQuantity) => void) => void
    searchOrderFlag: boolean
    getOpeningTime: (callBack: (data: Array<IOpeningHours>) => void) => void
    addScheduleOrder: (scheduleOrder: string) => void
    scheduleHour: string
}
const FOOD_TYPE = {
    BOWL: 1,
    DRINKS: 2,
    DESSERT: 3,
    SIGNATURE: 4
}
export class OrderListHandler extends React.Component<OrderListInterface> {
    datePickerRef : React.RefObject<any>
    state = {
        coupon_code: this.props.order.couponCode,
        coupon_value: -1,
        errContent: '',
        isCheckoutAvailable: false,
        phone: this.props.order.phone,
        selectedRemoveFood: '',
        selectedRemoveIndex: 0,
        selectedRemoveType: 0,
        errPhoneRequiredMessage: '',
        isLoading: false,
        isOpen : false,
        quantityCheckMessage: '',
        quantityOutOfStockCheckMessage: '',
        isToggleCoupon: this.props.order.couponCode !== '' ? true : false,
        scheduleDate: new Date(),
        minDate : moment().add(10, 'minutes').toString(),
        minTime : new Date("Jul 11 2023 02:00:00"),
        maxTime : new Date("Jul 11 2023 23:00:00"),
        dateConfig : {},
        openingHour: [],
        successMessage: '',
        openingHourRaw: [],
    }

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: OrderListInterface) {
        super(props);
        this.datePickerRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.order.name === '' || this.props.order.type === -1) {
            this.props.history.push('/start')
        }
        if (this.props.order.order.length === 0 && this.props.order.drinks.length === 0 && this.props.order.desserts.length === 0 && this.props.order.signature.length === 0) {
            this.setState({ isCheckoutAvailable: false })
        } else {
            this.setState({ isCheckoutAvailable: true })
        }

        const monthMap:any = {
            '1': 'Jan',
            '2': 'Feb',
            '3': 'Mar',
            '4': 'Apr',
            '5': 'May',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Aug',
            '9': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec',
        };

        this.setState({
            dateConfig : {
                'year': {
                    format: 'YYYY',
                    caption: 'Year',
                    step: 1,
                },
                'month': {
                  format: (value : any) => {
                        return monthMap[value.getMonth() + 1];
                  },
                  caption: 'Mon',
                  step: 1,
                },
                'date': {
                    format: 'DD',
                    caption: 'Day',
                    step: 1,
                },
                'hour': {
                    format: 'hh',
                    caption: 'Hour',
                    step: 1,
                },
                'minute': {
                    format: 'mm',
                    caption: 'Min',
                    step: 1,
                },
            }
        })

        this.props.getOpeningTime((data) => {
            console.log("OrderListHandler -> componentDidMount -> data", data)
            let openingRange: any = []
            data.forEach(a => {
                let range = moment.range(moment(a.open_time, 'HH:mm:ss').toDate(), moment(a.close_time, 'HH:mm:ss').toDate())
                let acc = Array.from(range.by('minutes', { step: 10 })).filter(a => {
                    return a.diff(moment()) > 0
                }).map(a => a.toDate())
                openingRange = openingRange.concat(acc)
            })
 
            this.setState({ openingHour: openingRange, scheduleDate: openingRange[0], openingHourRaw: data, minTime: openingRange[0] , maxTime : openingRange[openingRange.length-1]})
        })
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
        //@ts-ignore
        $('#checkoutConfirm').modal('hide')
        //@ts-ignore
        $('#foodRemoveModal').modal('hide')
        //@ts-ignore
        $('#inDevelopmentModal').modal('hide')
        //@ts-ignore
        $('#inDevelopmentModal').modal('hide')
        //@ts-ignore
        $('#orderErrorModal').modal('hide')
        //@ts-ignore
        $('#couponRemoveModal').modal('hide')
        //@ts-ignore
        $('#quantityErrorModal').modal('hide')
        //@ts-ignore
        $('#dateModal').modal('hide')
    }

    onchangeDateSchedual = ()=>{
        let openingRange: any = [];
        if(this.state.openingHourRaw?.length < 1 || !this.state.scheduleDate) return;
        this.state.openingHourRaw.forEach((a) => {
          let range = moment.range(
            moment(this.state.scheduleDate).set({
                //@ts-ignore
                hour: moment(a.open_time, "HH:mm:ss").hours(),
                //@ts-ignore

                minute: moment(a.open_time, "HH:mm:ss").minutes(),
                //@ts-ignore

                second: moment(a.open_time, "HH:mm:ss").seconds(),
              }).toDate(),
            // moment(a.open_time, "HH:mm:ss").toDate(),
            //@ts-ignore
            moment(this.state.scheduleDate).set({
                //@ts-ignore
                hour: moment(a.close_time, "HH:mm:ss").hours(),
                //@ts-ignore

                minute: moment(a.close_time, "HH:mm:ss").minutes(),
                //@ts-ignore

                second: moment(a.close_time, "HH:mm:ss").seconds(),
            }).toDate(),
            // moment(a.close_time, "HH:mm:ss").toDate()
          );

          
          let acc = [];
          if (
            moment(this.state.scheduleDate)
              .startOf("day")
              .diff(moment().startOf("day"), "days") > 0
          ) {
            acc = Array.from(range.by("minutes", { step: 10 })).map((a) =>
              a.toDate()
            );
          } else {
            acc = Array.from(range.by("minutes", { step: 10 }))
              .filter((a) => {
                return a.diff(moment()) > 0;
              })
              .map((a) => a.toDate());
          }
          
            
          openingRange = openingRange.concat(acc);
        });
        
        this.setState({
            minTime: openingRange[0] , 
            maxTime : openingRange[openingRange.length-1], 
            openingHour: openingRange,
        });
    }
    handleSelect = (val: Date) => {
         this.setState({
            scheduleDate : val,
         });

         setTimeout(() => {
            this.onchangeDateSchedual();
          }, 0);

         //@ts-ignore
         $('#dateModal').modal('hide');

    };

    handleCancel = () => {
        //@ts-ignore
        $('#dateModal').modal('hide');
    }

    onOpen = () => {
        this.setState({
            minDate : moment().add(10, 'minutes').toString(),
       })

         //@ts-ignore
        $('#dateModal')[0].style.zIndex = 1000000000;
        $('#dateModal')[0].style.height = '1000px';
        $('#dateModal')[0].style.top = '-126px';
        
        //@ts-ignore
        $('#dateModal').modal('toggle')
    }

    handleChange = (val : Date) => {
        this.setState({
            scheduleDate : val,
         });

         setTimeout(() => {
            this.onchangeDateSchedual();
          }, 0); 
    };

    onChangeCoupon = (e: any) => {
        this.setState({ coupon_code: e.target.value })
    }

    onCancelOrder = () => {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('toggle')
    }

    toggleErrorMessage = () => {
        //@ts-ignore
        $('#orderErrorModal').modal('hide')
    }
    toggleSuccessMessage = () => {
        //@ts-ignore
        $('#successModal').modal('hide')
    }

    toggleErrorQuantityMessage = () => {
        //@ts-ignore
        $('#quantityErrorModal').modal('toggle')
    }

    toggleCoupon = () => {
        this.setState({ isToggleCoupon: !this.state.isToggleCoupon })
    }

    toggleInDevelopmentModal = () => {
        //@ts-ignore
        $('#inDevelopmentModal').modal('toggle')
    }

    onCheckCoupon = () => {
        // this.setState({ coupon_value: 0, coupon_code: 'My Demo Coupon' })
        this.props.checkCoupon(this.state.coupon_code, (coupon) => {
            if (coupon.check === true) {
                this.setState({ coupon_value: coupon.coupon.type === COUPON_TYPE.DISCOUNT_MONEY ? coupon.coupon.discount : coupon.coupon.percent })
                this.props.addCouponToOrder(this.state.coupon_code, coupon.coupon.type === COUPON_TYPE.DISCOUNT_MONEY ? coupon.coupon.discount : String(coupon.coupon.percent), coupon.coupon.type)
                this.setState({ successMessage: 'Coupon code applied successfully.' })
                //@ts-ignore
                $('#successModal').modal('show')
            } else {
                //@ts-ignore
                $('#orderErrorModal').modal('show')
                this.setState({ errContent: coupon.message })
            }
        })
    }

    onBack = () => {
        if (this.props.stack.length === 0) {
            this.props.history.push('/menu')
        } else {

            //@ts-ignore
            if (!!this.props.location.state && !!this.props.location.state.updateCart) {
                this.props.history.push('/menu')
                return;
            }
            let isAvailable = false
            let temp = this.props.stack
            temp.reverse()
            console.log("onBack -> temp", temp)
            let whiteListRoute = [
                '/order-list',
                '/payment',
                '/bowl-summary-success'
            ]
            for (let index = 0; index < temp.length; index++) {
                if (!whiteListRoute.some(a => a === temp[index].pathname)) {
                    this.props.history.push(temp[index].pathname)
                    isAvailable = true
                    break;
                }
            }
            if (isAvailable === false) {
                this.props.history.push('/menu')
            }
        }
    }

    getAmount = (addon: Array<IFoodQuantity>, proteinSize: Array<IFoodSize>) => {
        if (addon.length > 0) {
            let amount = 0
            let quantity = 0
            let amountAddonFree = proteinSize.length > 0 ? proteinSize[0].addons : 0
            addon.forEach((a: IFoodQuantity, b: number) => {

                if (a.premium === 1) {
                    amount += Number(a.price) * a.quantity
                    quantity -= a.quantity
                } else if (quantity < amountAddonFree && quantity + a.quantity > amountAddonFree) {
                    amount += Number(a.price) * (a.quantity + quantity - amountAddonFree)
                } else if (quantity < amountAddonFree) {

                } else {
                    amount += Number(a.price) * a.quantity
                }
                quantity = quantity + a.quantity
            })
            return amount
        } else {
            return 0
        }
    }

    getPriceAddon = (addon: Array<IFoodQuantity>, proteinSize: Array<IFoodSize>, index: number) => {
        let amount = 0
        let amountAddonFree = proteinSize.length > 0 ? proteinSize[0].addons : 0
        let quantity = 0
        addon.forEach((a, b) => {
            if (a.premium === 1) {
                if (b === index) {
                    amount += Number(a.price) * a.quantity
                }
                quantity -= a.quantity
            } else if (quantity < amountAddonFree && quantity + a.quantity > amountAddonFree) {
                if (b === index) {
                    amount = Number(a.price) * (a.quantity + quantity - amountAddonFree)
                }
            } else if (quantity < amountAddonFree) {

            } else {
                if (b === index) {
                    amount += Number(a.price) * a.quantity
                }
            }
            quantity = quantity + a.quantity
        })

        return amount
    }

    getPriceFlavourBooster = (flavourBooster: Array<IFoodQuantity>, index: number) => {
        let amount = 0
        let numberFree = NumberFlavourBoosterFree
        let quantity = 0
        flavourBooster.forEach((a, b) => {
            if (a.premium === 1) {
                if (b === index) {
                    amount += Number(a.price) * a.quantity
                }
                quantity -= a.quantity
            } else if (quantity < numberFree && quantity + a.quantity > numberFree) {
                if (b === index) {
                    amount = Number(a.price) * (a.quantity + quantity - numberFree)
                }
            } else if (quantity < numberFree) {

            } else {
                if (b === index) {
                    amount += Number(a.price) * a.quantity
                }
            }
            quantity = quantity + a.quantity
        })

        return amount
    }

    getAmountFlavourBooster = (flavourBooster: Array<IFoodQuantity>) => {
        if (flavourBooster.length > 0) {
            let amount = 0
            let quantity = 0
            let amountAddonFree = NumberFlavourBoosterFree
            flavourBooster.forEach((a: IFoodQuantity, b: number) => {
                if (a.premium === 1) {
                    amount += Number(a.price) * a.quantity
                    quantity -= a.quantity
                } else if (quantity < amountAddonFree && quantity + a.quantity > amountAddonFree) {
                    amount += Number(a.price) * (a.quantity + quantity - amountAddonFree)
                } else if (quantity < amountAddonFree) {

                } else {
                    amount += Number(a.price) * a.quantity
                }
                quantity = quantity + a.quantity
            })
            return amount
        } else {
            return 0
        }
    }

    getBowlAmount = (bowl: IOrderItem): number => {
        let baseAmount = 0
        let extraAmount = 0
        let addonAmount = this.getAmount(bowl.addon, bowl.proteinSize)
        let flavourBoosterAmount = this.getAmountFlavourBooster(bowl.flavourBooster)
        let sizeAmount = 0
        bowl.proteinSize.forEach((a: IFoodSize) => { sizeAmount += Number(a.price) })
        bowl.extraProteins.forEach((a: IFoodQuantity) => { extraAmount += (Number(a.price) * a.quantity); })
        bowl.base.forEach((a: IFood) => { baseAmount += Number(a.price) })
        return baseAmount + extraAmount + addonAmount + sizeAmount + flavourBoosterAmount
    }

    updateQuantity = (quantity: number, index: number, name: string) => {
        if (quantity > 0) {
            let order = this.props.order.order
            order[index] = Object.assign(order[index], {
                quantityBowl: quantity
            })
            this.props.addToOrder(order, () => { })
        } else if (quantity === 0) {
            this.setState({ selectedRemoveFood: name, selectedRemoveIndex: index, selectedRemoveType: FOOD_TYPE.BOWL })
            this.toggleRemoveFoodMessage()
        }
    }

    updateDrinkQuantity = (quantity: number, index: number, name: string) => {
        if (quantity > 0) {
            let drinks = this.props.order.drinks
            drinks[index] = Object.assign(drinks[index], {
                quantity: quantity
            })
            this.props.addDrinksToCart(drinks, this.props.order.drinkCategories)
        } else if (quantity === 0) {
            this.setState({ selectedRemoveFood: name, selectedRemoveIndex: index, selectedRemoveType: FOOD_TYPE.DRINKS })
            this.toggleRemoveFoodMessage()
        }
    }

    updateDessertQuantity = (quantity: number, index: number, name: string) => {
        if (quantity > 0) {
            let desserts = this.props.order.desserts
            desserts[index] = Object.assign(desserts[index], {
                quantity: quantity
            })
            this.props.addDessertToCart(desserts, this.props.order.dessertCategories)
        } else if (quantity === 0) {
            this.setState({ selectedRemoveFood: name, selectedRemoveIndex: index, selectedRemoveType: FOOD_TYPE.DESSERT })
            this.toggleRemoveFoodMessage()
        }
    }

    updateSignatureQuantity = (quantity: number, index: number, name: string) => {
        if (quantity > 0) {
            let signature = this.props.order.signature
            signature[index] = Object.assign(signature[index], {
                quantity: quantity
            })
            this.props.addSignature(signature)
        } else if (quantity === 0) {
            this.setState({ selectedRemoveFood: name, selectedRemoveIndex: index, selectedRemoveType: FOOD_TYPE.SIGNATURE })
            this.toggleRemoveFoodMessage()
        }
    }

    getAmountDrinks = (drinks?: Array<IDrinkCartItem>) => {
        if (!!drinks && drinks.length > 0) {
            let amount = 0
            let quantity = 0
            drinks.forEach((a: IDrinkCartItem) => {
                amount += (Number(a.price) * a.quantity)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }
    getAmountDessert = (desserts?: Array<IDessertCartItem>) => {
        if (!!desserts && desserts.length > 0) {
            let amount = 0
            let quantity = 0
            desserts.forEach((a: IDrinkCartItem) => {
                amount += (Number(a.price) * a.quantity)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }

    getAmountSignature = (signature?: Array<IFoodQuantity>) => {
        if (!!signature && signature.length > 0) {
            let amount = 0
            let quantity = 0
            signature.forEach((a: IFoodQuantity) => {
                amount += (Number(a.price) * a.quantity)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }

    getTotalAmount = (): {
        totalAmount: number,
        originAmount: number
    } => {
        let totalAmount = 0
        this.props.order.order.forEach((a: IOrder) => {
            let baseAmount = 0
            let extraAmount = 0
            let addonAmount = this.getAmount(a.item.addon, a.item.proteinSize)
            let flavourBoosterAmount = this.getAmountFlavourBooster(a.item.flavourBooster)
            let sizeAmount = 0
            a.item.proteinSize.forEach((a: IFoodSize) => { sizeAmount += Number(a.price) })
            a.item.extraProteins.forEach((a: IFoodQuantity) => { extraAmount += (Number(a.price) * a.quantity); })
            a.item.base.forEach((a: IFood) => { baseAmount += Number(a.price) })
            totalAmount += ((baseAmount + extraAmount + addonAmount + sizeAmount + flavourBoosterAmount) * a.quantityBowl)
        })
        totalAmount += this.getAmountDrinks(this.props.order.drinks).amount
        totalAmount += this.getAmountDessert(this.props.order.desserts).amount
        totalAmount += this.getAmountSignature(this.props.order?.signature).amount
        let couponValue = this.props.order.couponValue
        let originAmount = totalAmount
        if (this.props.order.couponType === COUPON_TYPE.DISCOUNT_MONEY) {
            totalAmount > Number(couponValue) ? totalAmount -= Number(couponValue) : totalAmount = 0
        } else if (this.props.order.couponType === COUPON_TYPE.DISCOUNT_PERCENT) {
            totalAmount = totalAmount * (100 - Number(this.props.order.couponValue)) / 100
        }
        return {
            totalAmount: totalAmount,
            originAmount: originAmount
        }
    }

    toggleRemoveCouponModal = () => {
        //@ts-ignore
        $('#couponRemoveModal').modal('show')
    }

    toggleRemoveCouponMessage = () => {
        //@ts-ignore
        $('#couponRemoveModal').modal('hide')
        this.props.addCouponToOrder('', '-1', -1)
        this.setState({ coupon_code: '', errContent: '' })
    }

    toggleRemoveFoodMessage = () => {
        //@ts-ignore
        $('#foodRemoveModal').modal('toggle')
    }

    removeFood = () => {
        const { selectedRemoveIndex, selectedRemoveType } = this.state
        if (selectedRemoveType === FOOD_TYPE.BOWL) {
            let order = this.props.order.order
            order.splice(selectedRemoveIndex, 1)
            this.props.addToOrder(order, () => { })
        } else if (selectedRemoveType === FOOD_TYPE.DRINKS) {
            let drinks = this.props.order.drinks
            drinks.splice(selectedRemoveIndex, 1)
            this.props.addDrinksToCart(drinks, this.props.order.drinkCategories)
        } else if (selectedRemoveType === FOOD_TYPE.DESSERT) {
            let desserts = this.props.order.desserts
            desserts.splice(selectedRemoveIndex, 1)
            this.props.addDessertToCart(desserts, this.props.order.dessertCategories)
        } else if (selectedRemoveType === FOOD_TYPE.SIGNATURE) {
            let signature = this.props.order.signature
            signature.splice(selectedRemoveIndex, 1)
            this.props.addSignature(signature)
        }
        this.toggleRemoveFoodMessage()
    }

    changeBowl = (index: number) => {
        let bowl = this.props.order.order[index]
        if (bowl.item.base.length > 0) {
            this.props.addBaseToCart(bowl.item.base, false)
        } else {
            this.props.addBaseToCart([], true)
        }
        if (bowl.item.proteinSize.length > 0) {
            if (bowl.item.proteins.length > 0) {
                this.props.addProteinToCart(bowl.item.proteins, bowl.item.proteinSize, false)
            } else {
                this.props.addProteinToCart([], [], true)
            }
            if (bowl.item.extraProteins.length > 0) {
                this.props.addExtraProtein(bowl.item.extraProteins, false)
            } else {
                this.props.addExtraProtein([], true)
            }
        } else {
            this.props.addProteinToCart([], [], true)
            this.props.addExtraProtein([], true)
        }
        if (bowl.item.marinades.length > 0) {
            this.props.addMarinadesToCart(bowl.item.marinades, false)
        } else {
            this.props.addMarinadesToCart([], true)
        }
        if (bowl.item.mixin.length > 0) {
            this.props.addMixins(bowl.item.mixin, false)
        } else {
            this.props.addMixins([], true)
        }
        if (bowl.item.addon.length > 0) {
            this.props.addAddonToCart(bowl.item.addon, false)
        } else {
            this.props.addAddonToCart([], true)
        }
        if (bowl.item.flavourBooster.length > 0) {
            this.props.addFlavourBoosterToCart(bowl.item.flavourBooster, false)
        } else {
            this.props.addFlavourBoosterToCart([], true)
        }
        if (bowl.item.topping.length > 0) {
            this.props.addToppingToCart(bowl.item.topping, false)
        } else {
            this.props.addToppingToCart([], true)
        }
        if (bowl.bowlName !== '') {
            this.props.saveBowlName(bowl.bowlName, true)
        } else {
            this.props.saveBowlName('', false)
        }
        this.props.addMixableBase(bowl.mixableBase)
        this.props.updateQuantityBowl(bowl.quantityBowl)
        this.props.addUpdateIndex(index)
        this.props.updateNoNori(bowl.noNori)
        this.props.history.push('/bowl-summary')
    }

    addMoreBase = () => {
        if (this.props.cart.updateIndex !== -1) {
            this.props.resetCart(() => {
                this.props.history.push('/menu')
            })
        } else {
            this.props.history.push('/menu')
        }

    }

    onConfirmCheckout = () => {
        this.setState({ errPhoneRequiredMessage: '' })
        //@ts-ignore
        $('#checkoutConfirm').modal('toggle')
    }

    handleDateChangeRaw = (e: any) => {
        e.preventDefault();
    }

    checkoutOrder = () => {
        if (window.innerWidth < 769) {
            if (this.state.phone === '') {
                this.setState({ errPhoneRequiredMessage: "You need to enter your phone number" })
            } else {
                this.setState({ errPhoneRequiredMessage: '' })
                this.props.updateOrderInfo(this.props.order.name, this.props.order.type, this.state.phone)
                //@ts-ignore
                $('#checkoutConfirm').modal('toggle')
                this.props.history.push('/payment')
                // } else {
                //     this.setState({ errPhoneRequiredMessage: 'You need to enter a valid phone number' })
                // }
            }
        } else {
            this.props.updateOrderInfo(this.props.order.name, this.props.order.type, this.state.phone)
            //@ts-ignore
            $('#checkoutConfirm').modal('toggle')
            this.props.history.push('/payment')
        }


    }

    onCheckQuantity = () => {
        this.setState({ isLoading: true })
        const { order } = this.props
        let item: Array<any> = []
        if (order.order.length > 0) {
            order.order.forEach(a => {
                if (a.item.base.length > 0) {
                    a.item.base.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
                if (a.item.proteinSize.length > 0) {
                    a.item.proteinSize.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
                if (a.item.extraProteins.length > 0) {
                    a.item.extraProteins.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: a.quantity,
                            name: a.name
                        })
                    })
                }
                if (a.item.marinades.length > 0) {
                    a.item.marinades.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
                if (a.item.addon.length > 0) {
                    a.item.addon.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: a.quantity,
                            name: a.name
                        })
                    })
                }
                if (a.item.mixin.length > 0) {
                    a.item.mixin.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
                if (a.item.flavourBooster.length > 0) {
                    a.item.flavourBooster.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
                if (a.item.topping.length > 0) {
                    a.item.topping.forEach(a => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name
                        })
                    })
                }
            })
        }
        if (order.desserts.length > 0) {
            order.desserts.forEach(a => {
                item.push({
                    id: a.id,
                    quantity: a.quantity,
                    name: a.name
                })
            })
        }
        if (order.signature.length > 0) {
            order.signature.forEach(a => {
                item.push({
                    id: a.id,
                    quantity: a.quantity,
                    name: a.name
                })
            })
        }
        let tempItem: Array<any> = []
        item.forEach(a => {
            let findIndex = tempItem.findIndex((b: any) => a.id === b.id)
            if (findIndex !== -1) {
                tempItem[findIndex].quantity = tempItem[findIndex].quantity + a.quantity
            } else {
                tempItem.push(a)
            }
        })
        const body = {
            items: tempItem
        }
        this.props.checkQuantity(body, (check: boolean, dataCheckOutOfStock: IOutOfStockCheckQuantity, dataCheckQuantity: IOutOfStockCheckQuantity) => {
            this.setState({ isLoading: false })
            if (check === true) {
                this.onConfirmCheckout()
            } else {
                let messageOutOfStock = ''
                dataCheckOutOfStock.data.forEach((a, b) => {
                    if (messageOutOfStock === '') {
                        messageOutOfStock += a.name
                    } else {
                        messageOutOfStock += ', ' + a.name
                    }
                    if (b === dataCheckOutOfStock.data.length - 1) {
                        if (b === 0) {
                            messageOutOfStock += ' is out of stock'
                        } else {
                            messageOutOfStock += ' are out of stock'
                        }
                    }
                })
                let messageLimit = ''
                dataCheckQuantity.data.forEach((a, b) => {
                    if (messageLimit === '') {
                        messageLimit += a.name
                    } else {
                        messageLimit += ', ' + a.name
                    }
                    if (b === dataCheckQuantity.data.length - 1) {
                        if (b === 0) {
                            messageLimit += ' in stock is less than the number you selected'
                        } else {
                            messageLimit += ' in stock are less than the number you selected'
                        }
                    }
                })
                this.setState({ quantityCheckMessage: messageLimit, quantityOutOfStockCheckMessage: messageOutOfStock })
                this.toggleErrorQuantityMessage()
            }
        })

    }
}