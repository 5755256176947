export const ADD_BASE = "CART/ADD_BASE";
export const ADD_FLAVOUR_BOOSTER = "CART/ADD_FLAVOUR_BOOSTER";
export const ADD_PROTEIN = "CART/ADD_PROTEIN";
export const ADD_EXTRA_PROTEIN = "CART/ADD_EXTRA_PROTEIN";
export const ADD_MARINADES = "CART/ADD_MARINADES";
export const ADD_ADDON = "CART/ADD_ADDON";
export const ADD_BOWL_NAME = "CART/ADD_BOWL_NAME";
export const ADD_TOPPING = "CART/ADD_TOPPING";
export const ADD_BOWL_SAVED_STATUS = "CART/ADD_BOWL_SAVED_STATUS";
export const ADD_QUANTITY_BOWL = "CART/ADD_QUANTITY_BOWL";
export const ADD_MIXIN = "CART/ADD_MIXIN";
export const RESET_SAVED_BOWL = "CART/RESET_SAVED_BOWL";
export const UPDATE_INDEX = "CART/UPDATE_INDEX";
export const RESET = "CART/RESET";
export const ADD_MIXABLE_BASE = "CART/ADD_MIXABLE_BASE";
export const ADD_NO_NORI = "CART/ADD_NO_NORI";
export const ADD_BOWL_NOTE = "CART/ADD_BOWL_NOTE";
