import * as React from 'react'
import cart_1 from '../../assets/image/cart_1.png'
import Vector from '../../assets/image/Vector.png'
import fallback from '../../assets/image/fallback.jpg'
import Bowl from '../../assets/image/Bowl@2x1.png'
import { connect } from "react-redux";
import { ReduxState, CartState } from '../../redux';
import { Action } from 'redux';
import { IFood, IFoodQuantity, IFoodSize } from '../../entities';
import { NavLink } from 'react-router-dom';
import { getAmountFromCart } from "../../utils";

interface CartProps {
    cart: CartState
}
class CartComponent extends React.PureComponent<CartProps> {

    renderNonSelected = () => {
        return (
            <div className="border_base">
                <img src={Vector} alt="" />
            </div>
        )
    }

    render() {
        const { cart } = this.props
        let extraCount = 0
        let addonCount = 0
        let flavourBoosterCount = 0
        let marinadeCount = 0
        let baseAmount = 0
        let extraAmount = 0
        let addonAmount = getAmountFromCart(1).amount
        let flavourBoosterAmount = getAmountFromCart(2).amount
        let sizeAmount = 0

        cart.flavourBooster.forEach((a: IFoodQuantity) => flavourBoosterCount += a.quantity)
        cart.addon.forEach((a: IFoodQuantity) => { addonCount += a.quantity })
        cart.proteinSize.forEach((a: IFoodSize) => { sizeAmount += Number(a.price) })
        cart.extraProteins.forEach((a: IFoodQuantity) => { extraCount += a.quantity; extraAmount += (Number(a.price) * a.quantity) })
        cart.base.forEach((a: IFood) => { baseAmount += Number(a.price) })

        const totalAmount = baseAmount + extraAmount + addonAmount + sizeAmount + flavourBoosterAmount
        marinadeCount = cart.marinades.length + cart.mixin.length
        return (
            <div className="sidebar_left">
                <div className="bg_list">
                    <div className="box_sidebar">

                        {/* Topping Start */}
                        {cart.topping.length > 0 ? <NavLink to="/order/topping">
                            <div className="sidebar_base">
                                <div className={`${cart.topping.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {cart.topping.length > 0 && <><img src={cart.topping[0].image || fallback} alt={cart.topping[0].name} />
                                        {cart.topping.length > 1 && <p className="number_size">{cart.topping.length}</p>}</>}
                                </div>
                                <p>Toppings</p>
                            </div>
                        </NavLink> : <div className="sidebar_base">
                                {cart.isNonSelectedTopping === true ?
                                    this.renderNonSelected()
                                    :
                                    <div className={`${cart.topping.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                        {cart.topping.length > 0 && <><img src={cart.topping[0].image || fallback} alt={cart.topping[0].name} />
                                            {cart.topping.length > 1 && <p className="number_size">{cart.topping.length}</p>}</>}
                                    </div>}
                                <p>Toppings</p>
                            </div>}
                        {/* Topping End */}

                        {/* Flavor booster Start */}
                        {flavourBoosterCount > 0 ? <NavLink to="/order/flavour-boosters">
                            <div className="sidebar_base " style={{}}>
                                <div className={`${flavourBoosterCount > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {flavourBoosterCount > 0 && <><img src={cart.flavourBooster[0].image || fallback} alt={cart.flavourBooster[0].name} />
                                        {flavourBoosterCount > 1 && <p className="number_size">{flavourBoosterCount}</p>}</>}
                                </div>
                                <p>Sauces</p>
                            </div>
                        </NavLink> : <div className="sidebar_base " style={{}}>
                                {cart.isNonSelectedFlavourBoosters === true ?
                                    this.renderNonSelected()
                                    :
                                    <div className={`${flavourBoosterCount > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                        {flavourBoosterCount > 0 && <><img src={cart.flavourBooster[0].image || fallback} alt={cart.flavourBooster[0].name} />
                                            {flavourBoosterCount > 1 && <p className="number_size">{flavourBoosterCount}</p>}</>}
                                    </div>}
                                <p>Sauces</p>
                            </div>}
                        {/* Flavor Booster End */}

                        {/* Add on */}
                        {addonCount > 0 ? <NavLink to="/order/addon">
                            <div className="sidebar_base">
                                <div className={`${cart.addon.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {addonCount > 0 && <><img src={cart.addon[0].image || fallback} alt={cart.addon[0].name} />
                                        {addonCount > 1 && <p className="number_size">{addonCount}</p>}</>}
                                </div>
                                <p>Add ons</p>
                            </div>
                        </NavLink> :
                            <div className="sidebar_base">
                                {cart.isNonSelectedAddOn === true ?
                                    this.renderNonSelected()
                                    :
                                    <div className={`${cart.addon.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                        {addonCount > 0 && <><img src={cart.addon[0].image || fallback} alt={cart.addon[0].name} />
                                            {addonCount > 1 && <p className="number_size">{addonCount}</p>}</>}
                                    </div>}
                                <p>Add ons</p>
                            </div>}
                        {/* Add on End */}

                        {/* Marinade  */}
                        {marinadeCount > 0 ? <NavLink to={{
                            pathname: "/order/proteins",
                            state: {
                                isOpenModal: true
                            }
                        }}>
                            <div className="sidebar_base">
                                <div className={`${marinadeCount > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {marinadeCount > 0 &&
                                        <>
                                            {cart.marinades.length > 0 ? <img src={cart.marinades[0].image || fallback} alt={cart.marinades[0].name} /> :
                                                <img src={cart.mixin[0].image || fallback} alt={cart.mixin[0].name} />
                                            }
                                            {marinadeCount > 1 && <p className="number_size">{marinadeCount}</p>}
                                        </>}
                                </div>
                                <p>Marinade</p>
                            </div>
                        </NavLink> : 
                            // <div className="sidebar_base">
                            //     {cart.isNoneSelectedMarinades === true && cart.isNonSelectedMixin === true ?
                            //         this.renderNonSelected()
                            //         :
                            //         <div className={`${marinadeCount > 0 ? `selected_food_sidebar` : `border_base`}`}>
                            //             {marinadeCount > 0 &&
                            //                 <>
                            //                     {cart.marinades.length > 0 ? <img src={cart.marinades[0].image} alt={cart.marinades[0].name} /> :
                            //                         <img src={cart.mixin[0].image} alt={cart.mixin[0].name} />
                            //                     }
                            //                     {marinadeCount > 1 && <p className="number_size">{marinadeCount}</p>}
                            //                 </>}
                            //         </div>}
                            //     <p>Marinade</p>
                            // </div>
                            null
                        }
                        {/* Marinade End */}

                        {/* Extra */}
                        {extraCount > 0 ? <NavLink to={{
                            pathname: "/order/proteins",
                            state: {
                                isOpenModal: true
                            }
                        }}>
                            <div className="sidebar_base">
                                <div className={`${cart.extraProteins.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {extraCount > 0 && <><img src={cart.extraProteins[0].image || fallback} alt={cart.extraProteins[0].name} />
                                        {extraCount > 1 && <p className="number_size">{extraCount}</p>}</>}
                                </div>
                                <p>Extra</p>
                            </div>
                        </NavLink> : null
                        
                            // <div className="sidebar_base">
                            //     {cart.isNoneSelectedExtra === true ?
                            //         this.renderNonSelected()
                            //         :
                            //         <div className={`${cart.extraProteins.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                            //             {extraCount > 0 && <><img src={cart.extraProteins[0].image} alt={cart.extraProteins[0].name} />
                            //                 {extraCount > 1 && <p className="number_size">{extraCount}</p>}</>}
                            //         </div>}
                            //     <p>Extra</p>
                            // </div>
                        }
                        {/* Extra End */}

                        {/* Proteins */}
                        {cart.proteins.length > 0 ? <NavLink to="/order/proteins">
                            <div className="sidebar_base">
                                <div className={`${cart.proteins.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {cart.proteins.length > 0 && <><img src={cart.proteins[0].image || fallback} alt={cart.proteins[0].name} />
                                        {cart.proteins.length > 1 && <p className="number_size">{cart.proteins.length}</p>}</>}
                                </div>
                                <p>Protein</p>
                            </div>
                        </NavLink> :
                            <div className="sidebar_base">
                                {cart.isNonSelectedProtein === true ?
                                    this.renderNonSelected()
                                    :
                                    <div className={`${cart.proteins.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                        {cart.proteins.length > 0 && <><img src={cart.proteins[0].image || fallback} alt={cart.proteins[0].name} />
                                            {cart.proteins.length > 1 && <p className="number_size">{cart.proteins.length}</p>}</>}
                                    </div>}
                                <p>Protein</p>
                            </div>}
                        {/* Proteins End */}

                        {/* Base */}
                        {cart.base.length > 0 ? <NavLink to="/order/base">
                            <div className="sidebar_base">
                                <div className={`${cart.base.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                    {cart.base.length > 0 && <><img src={cart.base[0].image || fallback} alt={cart.base[0].name} />
                                        {cart.base.length > 1 && <p className="number_size">{cart.base.length}</p>}</>}
                                </div>
                                <p>Base</p>
                            </div>
                        </NavLink> : <div className="sidebar_base">
                                {cart.isNonSelectedBase === true ?
                                    this.renderNonSelected()
                                    :
                                    <div className={`${cart.base.length > 0 ? `selected_food_sidebar` : `border_base`}`}>
                                        {cart.base.length > 0 && <><img src={cart.base[0].image || fallback} alt={cart.base[0].name} />
                                            {cart.base.length > 1 && <p className="number_size">{cart.base.length}</p>}</>}
                                    </div>}
                                <p>Base</p>
                            </div>}
                        {/* Base End */}

                    </div>

                </div>
                <div className="food_sidebar">
                    <img src={Bowl} alt="" />
                </div>

                <div className="cart">
                    <p className="cart_price">FL.</p>
                    <img src={cart_1} alt="" /><sup>{totalAmount}</sup>
                    <h5>CURRENT</h5>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        cart: state.cart
    }
}

const mapDispatchToProp = (dispatch: React.Dispatch<Action>) => {

    return {

    }
}
export const Cart = connect(mapStateToProps, mapDispatchToProp)(CartComponent)