import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { store, orderAction } from '../../redux'
import { ISearchOrder, ISearchItem } from '../../entities'
import { COUPON_TYPE } from '../../constants'
interface SearchResultInterface extends RouteComponentProps {
    searchOrder: ISearchOrder
    stack: Array<any>
    searchOrderFlag: boolean
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FOOD_TYPE = {
    BOWL: 1,
    DRINKS: 2,
    DESSERT: 3,
    SIGNATURE: 4
}
export class SearchResultHandler extends React.Component<SearchResultInterface> {

    state = {
        coupon_code: '',
        coupon_value: -1,
        errContent: '',
        isCheckoutAvailable: false,
        phone: '',
        selectedRemoveFood: '',
        selectedRemoveIndex: 0,
        selectedRemoveType: 0,
        errPhoneRequiredMessage: '',
        isLoading: false,
        quantityCheckMessage: '',
        quantityOutOfStockCheckMessage: '',
        isToggleCoupon: '',
        scheduleDate: new Date()
    }

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: SearchResultInterface) {
        super(props)
    }

    componentDidMount() {
        if (this.props.searchOrderFlag === false) {
            this.props.history.push('/start')
        }
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
        //@ts-ignore
        $('#checkoutConfirm').modal('hide')
    }

    toggleErrorMessage = () => {
        //@ts-ignore
        $('#orderErrorModal').modal('hide')
    }

    onBack = () => {
        this.props.history.push('/search')
        store.dispatch(orderAction.resetOrder())
    }

    getBowlAmount = (bowl: Array<ISearchItem>): number => {
        let total = 0
        bowl.forEach(a => {
            total += Number(a.total_price)
        })
        return total
    }

    getAmountDrinks = (drinks?: Array<ISearchItem>) => {
        if (!!drinks && drinks.length > 0) {
            let amount = 0
            let quantity = 0
            drinks.forEach((a: ISearchItem) => {
                amount += Number(a.total_price)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }
    getAmountDessert = (desserts?: Array<ISearchItem>) => {
        if (!!desserts && desserts.length > 0) {
            let amount = 0
            let quantity = 0
            desserts.forEach((a: ISearchItem) => {
                amount += Number(a.total_price)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }

    getAmountSignature = (signature?: Array<ISearchItem>) => {
        if (!!signature && signature.length > 0) {
            let amount = 0
            let quantity = 0
            signature.forEach((a: ISearchItem) => {
                amount += Number(a.total_price)
                quantity += a.quantity
            })
            return { amount, quantity }
        } else {
            return { amount: 0, quantity: 0 }
        }
    }

    getTotalAmount = (): {
        totalAmount: number,
        originAmount: number
    } => {
        let totalAmount = 0
        let { searchOrder } = this.props
        if (!!searchOrder.bowls && searchOrder.bowls.length > 0) {
            searchOrder.bowls.forEach(a => {
                totalAmount += this.getBowlAmount(a.items)
            })
        }
        if (!!searchOrder.dessert && searchOrder.dessert.length > 0) {
            totalAmount += this.getAmountDessert(searchOrder.dessert).amount
        }
        if (!!searchOrder.drinks && searchOrder.drinks.length > 0) {
            totalAmount += this.getAmountDrinks(searchOrder.drinks).amount
        }
        if (!!searchOrder.signature && searchOrder.signature.length > 0) {
            totalAmount += this.getAmountSignature(searchOrder.signature).amount
        }
        let originAmount = totalAmount
        if (!!searchOrder.coupon_code) {
            if (searchOrder.coupon_code.type === COUPON_TYPE.DISCOUNT_MONEY) {
                totalAmount > Number(searchOrder.coupon_code.discount) ? totalAmount -= Number(searchOrder.coupon_code.discount) : totalAmount = 0
            } else if (searchOrder.coupon_code.type === COUPON_TYPE.DISCOUNT_PERCENT) {
                totalAmount = totalAmount * (100 - Number(searchOrder.coupon_code.percent)) / 100
            }
        }
        return {
            totalAmount: totalAmount,
            originAmount: originAmount
        }
    }

    checkoutOrder = () => {
        if (window.innerWidth < 769) {
            if (this.state.phone === '') {
                this.setState({ errPhoneRequiredMessage: "You need to enter your phone number" })
            } else {
                this.setState({ errPhoneRequiredMessage: '' })
                //@ts-ignore
                $('#checkoutConfirm').modal('toggle')
                this.props.history.push('/payment')
            }
        } else {
            //@ts-ignore
            $('#checkoutConfirm').modal('toggle')
            this.props.history.push('/payment')
        }


    }

    onPayment = () => {
        this.props.history.push('/payment-order')
    }
}