import * as React from "react";
import { connect } from "react-redux";
import { ReduxState, CartState, OrderState } from "../../redux";
import { Action } from "redux";
import { NavLink, RouteComponentProps } from "react-router-dom";

import {
  IFood,
  IFoodQuantity,
  IFoodSize,
  IOrder,
  IDrinkCartItem,
  IDessertCartItem,
} from "../../entities";
import { COUPON_TYPE, NumberFlavourBoosterFree } from "../../constants";
import styled from "styled-components";
// img imports
import speaker from "../../assets/image/speaker.png";
import switch_on from "../../assets/image/switch_on.png";
import btn_circle from "../../assets/image/btn_circle.png";
import red_pointer from "../../assets/image/red_pointer.png";

interface CartProps extends RouteComponentProps {
  cart?: CartState;
  order?: OrderState;
  reset?: (callBack: () => void) => void;
}
class FooterComponent extends React.PureComponent<CartProps> {
  componentDidMount() {}

  onCancelOrder = () => {
    //@ts-ignore
    $("#cancelOrderConfirm").modal("toggle");
  };

  getAmount = (addon: Array<IFoodQuantity>, proteinSize: Array<IFoodSize>) => {
    if (addon.length > 0) {
      let amount = 0;
      let quantity = 0;
      let amountAddonFree = proteinSize.length > 0 ? proteinSize[0].addons : 0;
      addon.forEach((a: IFoodQuantity, b: number) => {
        if (a.premium === 1) {
          amount += Number(a.price) * a.quantity;
          quantity -= a.quantity;
        } else if (
          quantity < amountAddonFree &&
          quantity + a.quantity > amountAddonFree
        ) {
          amount += Number(a.price) * (a.quantity + quantity - amountAddonFree);
        } else if (quantity < amountAddonFree) {
        } else {
          amount += Number(a.price) * a.quantity;
        }
        quantity = quantity + a.quantity;
      });
      return amount;
    } else {
      return 0;
    }
  };

  getAmountFlavourBooster = (flavourBooster: Array<IFoodQuantity>) => {
    if (flavourBooster.length > 0) {
      let amount = 0;
      let quantity = 0;
      let amountAddonFree = NumberFlavourBoosterFree;
      flavourBooster.forEach((a: IFoodQuantity, b: number) => {
        if (a.premium === 1) {
          amount += Number(a.price) * a.quantity;
          quantity -= a.quantity;
        } else if (
          quantity < amountAddonFree &&
          quantity + a.quantity > amountAddonFree
        ) {
          amount += Number(a.price) * (a.quantity + quantity - amountAddonFree);
        } else if (quantity < amountAddonFree) {
        } else {
          amount += Number(a.price) * a.quantity;
        }
        quantity = quantity + a.quantity;
      });
      return amount;
    } else {
      return 0;
    }
  };

  getAmountDrinks = (drinks?: Array<IDrinkCartItem>) => {
    if (!!drinks && drinks.length > 0) {
      let amount = 0;
      let quantity = 0;
      drinks.forEach((a: IDrinkCartItem) => {
        amount += Number(a.price) * a.quantity;
        quantity += a.quantity;
      });
      return { amount, quantity };
    } else {
      return { amount: 0, quantity: 0 };
    }
  };

  getAmountDessert = (desserts?: Array<IDessertCartItem>) => {
    if (!!desserts && desserts.length > 0) {
      let amount = 0;
      let quantity = 0;
      desserts.forEach((a: IDrinkCartItem) => {
        amount += Number(a.price) * a.quantity;
        quantity += a.quantity;
      });
      return { amount, quantity };
    } else {
      return { amount: 0, quantity: 0 };
    }
  };

  getAmountSignature = (signature?: Array<IFoodQuantity>) => {
    if (!!signature && signature.length > 0) {
      let amount = 0;
      let quantity = 0;
      signature.forEach((a: IFoodQuantity) => {
        amount += Number(a.price) * a.quantity;
        quantity += a.quantity;
      });
      return { amount, quantity };
    } else {
      return { amount: 0, quantity: 0 };
    }
  };

  onClickBaseReponsive = () => {
    if (this.props.location.pathname === "/order/base") {
      setTimeout(() => {
        this.props.history.push("/menu");
      }, 200);
    }
  };

  render() {
    const { order } = this.props;
    let quantity = 0;
    let totalAmount = 0;
    order?.order.forEach((a: IOrder) => {
      quantity += a.quantityBowl;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let extraCount = 0;
      let baseAmount = 0;
      let extraAmount = 0;
      let addonAmount = this.getAmount(a.item.addon, a.item.proteinSize);
      let flavourBoosterAmount = this.getAmountFlavourBooster(
        a.item.flavourBooster
      );
      let sizeAmount = 0;
      a.item.proteinSize.forEach((a: IFoodSize) => {
        sizeAmount += Number(a.price);
      });
      a.item.extraProteins.forEach((a: IFoodQuantity) => {
        extraCount += a.quantity;
        extraAmount += Number(a.price) * a.quantity;
      });
      a.item.base.forEach((a: IFood) => {
        baseAmount += Number(a.price);
      });
      totalAmount +=
        (baseAmount +
          extraAmount +
          addonAmount +
          sizeAmount +
          flavourBoosterAmount) *
        a.quantityBowl;
    });
    totalAmount += this.getAmountDrinks(order?.drinks).amount;
    quantity += this.getAmountDrinks(order?.drinks).quantity;
    totalAmount += this.getAmountDessert(order?.desserts).amount;
    quantity += this.getAmountDessert(order?.desserts).quantity;
    totalAmount += this.getAmountSignature(order?.signature).amount;
    quantity += this.getAmountSignature(order?.signature).quantity;

    let couponValue = this.props.order?.couponValue;
    if (this.props.order?.couponType === COUPON_TYPE.DISCOUNT_MONEY) {
      totalAmount > Number(couponValue)
        ? (totalAmount -= Number(couponValue))
        : (totalAmount = 0);
    } else if (this.props.order?.couponType === COUPON_TYPE.DISCOUNT_PERCENT) {
      totalAmount =
        (totalAmount * (100 - Number(this.props.order.couponValue))) / 100;
    }

    let totalString = String(totalAmount).split(".");

    return (
      <>
        {/* new footer */}
        <StyledFooter>
          <div className="speaker_container">
            <img src={speaker} alt="speaker" />
          </div>

          <div className="controller_container">
            <div className="list_menu">
              <ul>
                <li>
                  <NavLink
                    to="/others/drinks"
                    activeClassName="footer-active-link"
                  >
                    <img
                      src={red_pointer}
                      alt="red_pointer"
                      className={`${
                        this.props.location.pathname.includes("/drinks")
                          ? `active`
                          : `inactive`
                      }`}
                    />
                    Drinks
                  </NavLink>
                </li>
                <div className="vertical_line"></div>
                <li>
                  <NavLink
                    to="/order/base"
                    activeClassName="footer-active-link"
                  >
                    <img
                      src={red_pointer}
                      alt="red_pointer"
                      className={`${
                        this.props.location.pathname.includes("/order") ||
                        this.props.location.pathname.includes("/menu") ||
                        this.props.location.pathname.includes("/bowl-summary")
                          ? `active`
                          : `inactive`
                      }`}
                    />
                    Build Your Own
                  </NavLink>
                </li>

                <div className="vertical_line"></div>

                <li>
                  <NavLink
                    to="/others/desserts"
                    activeClassName="footer-active-link"
                  >
                    <img
                      src={red_pointer}
                      alt="red_pointer"
                      className={`${
                        this.props.location.pathname.includes("/desserts")
                          ? `active`
                          : `inactive`
                      }`}
                    />
                    Desserts
                  </NavLink>
                </li>

                <div className="vertical_line"></div>

                <li>
                  <NavLink
                    to="/others/signature"
                    activeClassName="footer-active-link"
                  >
                    <img
                      src={red_pointer}
                      alt="red_pointer"
                      className={`${
                        this.props.location.pathname.includes("/signature")
                          ? `active`
                          : `inactive`
                      }`}
                    />
                    signature
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="btns_container">
              <div className="btns_container_cancel_order">
                <img
                  alt="switch_on"
                  src={switch_on}
                  onClick={this.onCancelOrder}
                />
              </div>

              <div className="btns_container_features">
                <div className="btns_container_features_item">
                  <p>Search</p>
                  <NavLink to="/menu">
                    <img alt="search" src={btn_circle} />
                  </NavLink>
                </div>
                <div className="btns_container_features_item">
                  <p>Main Menu</p>
                  <NavLink to="/menu">
                    <img alt="main_menu" src={btn_circle} />
                  </NavLink>
                </div>
                <div className="btns_container_features_item">
                  <p>My Order</p>
                  <NavLink to="/order-list">
                    <img alt="my_order" src={btn_circle} />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="total_container">
            <div className="bg_top_price"></div>
            <div className="bg_bottom_price"></div>
            <div
              className="total_price"
              onClick={() => this.props.history.push("/order-list")}
            >
              <p className="number">
                {`${totalString[0]}.${
                  totalString[1]
                    ? totalString[1].length === 1
                      ? Number(totalString[0]) > 999
                        ? `${totalString[1]}`
                        : `${totalString[1]}0`
                      : totalString[1]
                    : Number(totalString[0]) > 999
                    ? `0`
                    : `00`
                }`}
              </p>
            </div>
          </div>
        </StyledFooter>
        <section className="footer" style={{ display: "none" }}>
          <div className="footer_left">
            <div className="bg_general"></div>
            <div className="bg_footer_general"></div>
            <div className="general">
              <div className="bg_view_order_pc"></div>
              <div className="bg_cancel_order"></div>
              <div className="bg_bottom_cancel_order"></div>
              <NavLink className="btn btn_view_order" to="/order-list">
                View order
                {quantity > 0 && <p className="number_order">{quantity}</p>}
              </NavLink>
              <div
                onClick={this.onCancelOrder}
                className="btn btn_cancel_order hover-cursor"
              >
                Cancel Order
              </div>
            </div>
          </div>
          <div className="footer_center">
            <div className="bg_top_byo"></div>
            <div className="bg_bottom_byo"></div>
            <div className="footer_breadcrumb">
              <div className="list_menu">
                <div className="list_nav">
                  <ul>
                    <li>
                      <NavLink
                        to="/others/drinks"
                        activeClassName="footer-active-link"
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/drinks")
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Drinks
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/order/base"
                        activeClassName="footer-active-link"
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/order") ||
                            this.props.location.pathname.includes("/menu") ||
                            this.props.location.pathname.includes(
                              "/bowl-summary"
                            )
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Build Your Own
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/others/desserts"
                        activeClassName="footer-active-link"
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/desserts")
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Desserts
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <NavLink
                className="btn footer_main"
                to="/menu"
                activeClassName="menu-active"
              >
                Main menu
              </NavLink>
              <NavLink className="btn text_check" to="/order-list">
                CHECK OUT
              </NavLink>
            </div>
          </div>
          <div className="footer_right">
            <div className="bg_top_price"></div>
            <div className="bg_bottom_price"></div>
            <div
              className="total_price"
              onClick={() => this.props.history.push("/order-list")}
            >
              <p className="number">
                {`${totalString[0]}.${
                  totalString[1]
                    ? totalString[1].length === 1
                      ? Number(totalString[0]) > 999
                        ? `${totalString[1]}`
                        : `${totalString[1]}0`
                      : totalString[1]
                    : Number(totalString[0]) > 999
                    ? `0`
                    : `00`
                }`}
              </p>
            </div>
          </div>
        </section>
        <section
          className="footer footer_responsive"
          style={{ display: "none" }}
        >
          <div className="footer_center">
            <div className="footer_breadcrumb">
              <div className="list_menu">
                <div className="list_nav">
                  <ul>
                    <li>
                      <NavLink
                        to="/others/drinks"
                        activeClassName="footer-active-link"
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/drinks")
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Drinks
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/order/base"
                        activeClassName="footer-active-link"
                        onClick={this.onClickBaseReponsive}
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/order") ||
                            this.props.location.pathname.includes("/menu") ||
                            this.props.location.pathname.includes(
                              "/bowl-summary"
                            )
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Build Your Own
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/others/desserts"
                        activeClassName="footer-active-link"
                      >
                        <span
                          className={`${
                            this.props.location.pathname.includes("/desserts")
                              ? `active`
                              : ``
                          }`}
                        ></span>
                        Desserts
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div onClick={this.onCancelOrder} className="btn footer_main">
                CANCEL ORDER
              </div>
              <NavLink className="btn text_check" to="/order-list">
                VIEW ORDER
              </NavLink>
            </div>
          </div>
          <div className="footer_right">
            <div
              className="total_price"
              onClick={() => this.props.history.push("/order-list")}
            >
              <p className="number">
                {`${totalString[0]}.${
                  totalString[1]
                    ? totalString[1].length === 1
                      ? Number(totalString[0]) > 999
                        ? `${totalString[1]}`
                        : `${totalString[1]}0`
                      : totalString[1]
                    : Number(totalString[0]) > 999
                    ? `0`
                    : `00`
                }`}
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
  };
};

const mapDispatchToProp = (dispatch: React.Dispatch<Action>) => {
  return {};
};
export const Footer = connect(
  mapStateToProps,
  mapDispatchToProp
)(FooterComponent);

const StyledFooter = styled.section`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #bb2616;
  /* box-shadow: inset 0px 14px 2px rgb(0 0 0 / 17%);
   */
  box-shadow: inset 0 0 40px #000000;
  padding: 47px 23px;

  .speaker_container {
    img {
      width: 100%;
    }
  }

  .controller_container {
    background: #fff3ed;
    /* box-shadow: inset 6px 13px 9px rgba(0, 0, 0, 0.32); */
    box-shadow: 0 2px 0px -1px #d8d8d8, 0 4px 0px -2px #c7c7c7, 0 6px 0px -3px #d8d8d8, 0 8px 0px -4px #c6c6c6, 0 10px 0px -5px #d6d6d6, 0 12px 0px -6px #c9c9c9, 0 14px 0px -7px #d8d8d8, 0 14px 23px -9px rgba(0, 0, 0, 0.8);
    border-radius: 12px;
    width: 62%;

    .list_menu {
      padding: 14px 17px;
      padding-bottom: 0;
      ul {
        display: flex;
        list-style: none;
        padding: 0;
        justify-content: space-between;
        border-bottom: 5px solid #000000;
        align-items: flex-end;

        .vertical_line {
          border-left: 2px solid;
          height: 10px;
        }
        li {
          line-height: 36px;
          position: relative;

          .active {
            /* opacity: 1; */
            position: absolute;
            top: -10px;
            left: 40%;
          }

          .inactive {
            display: none;
          }

          a {
            font-size: 22px;
            line-height: 32px;
            text-transform: uppercase;
            color: #000000;
            font-family: "MM";
            text-decoration: none;
          }
        }
      }
    }

    .btns_container {
      display: flex;
      padding: 0px 17px;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: -5px;

      .btns_container_cancel_order {
        img {
          width: 100%;
        }
      }

      .btns_container_features {
        display: flex;
        justify-content: space-evenly;
        width: 70%;

        .btns_container_features_item {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 60px;
            height: 60px;
          }

          p {
            font-family: "MM";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            text-align: center;
            padding: 0;
            margin: 0;
            margin-bottom: -10px;
          }
        }
      }
    }
  }

  .total_container {
    width: 20%;
    position: relative;

    .bg_top_price {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 202px;
      height: 170px;
      /* background: linear-gradient(
        180deg,
        #ff0000 0%,
        rgba(187, 38, 22, 0) 46.24%
      ); */
      filter: blur(7px);
      border-radius: 32px;
    }

    .bg_bottom_price {
      position: absolute;
      width: 202px;
      height: 170px;
      left: -10px;
      bottom: -14px;
      /* background: linear-gradient(
        180deg,
        #540f07 0%,
        rgba(112, 23, 13, 0) 46.24%
      ); */
      filter: blur(14px);
      border-radius: 25px;
      transform: rotate(180deg);
    }

    .total_price {
      height: 148px;
      /* box-shadow: rgba(0, 0, 0, 0.4) 0px 15px 9px, rgba(0, 0, 0, 1.3) 0px 14px 15px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
      border-radius: 20px;
      box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.2), 2px 2px 0px 0px rgba(0,0,0,0.2), 3px 3px 0px 0px rgba(0,0,0,0.2), 5px 7px 0px 0px rgba(0,0,0,0.3);
      background: url("/static/media/Total@2x 1.2da9f5bc.png") no-repeat 50% 50%;
      background-size: 100% 100%;
      position: relative;

      .number {
        font-family: MM;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: 27px;
        line-height: 32px;
        text-align: right;
        color: #000000;
      }
    }
  }

  @media (max-width: 1079px) and (min-width: 768px) {
    .speaker_container {
      /* display: none; */
      width: 12%;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 15px;
    align-items: center;
    .speaker_container {
      display: none;
    }

    .controller_container {
      width: 70%;
      border-radius: 5px;

      .list_menu {
        padding: 0 10px;
        ul {
          border-bottom: 2px solid #000000;
          li {
            a {
              font-size: 8px;
            }

            .active {
              width: 18px;
              top: 16px;
              left: 30%;
            }
          }
        }
      }

      .btns_container {
        padding: 0 5px;
        margin-top: -15px;
        align-items: center;

        .btns_container_cancel_order {
          width: 80px;
        }

        .btns_container_features {
          .btns_container_features_item {
            img {
              width: 35px;
              height: 35px;
            }

            p {
              font-size: 10px;
            }
          }
        }
      }
    }
    .total_container {
      width: 91px;
      margin-left: 6px;
      text-align: right;

      .bg_top_price {
        display: none;
      }

      .bg_bottom_price {
        display: none;
      }

      .total_price {
        background: url("/static/media/Total@2x 1.2da9f5bc.png") no-repeat 25%
          25%;
        width: 91px;
        height: 72px;
        background-size: contain;
        border-radius: 10px;
        box-shadow:1px 1px 0px 0px rgba(0,0,0,0.2),
        2px 2px 0px 0px rgba(0,0,0,0.2),
        3px 3px 0px 0px rgba(0,0,0,0.2),
        4px 6px 0px 0px rgba(0,0,0,0.3);
  
        .number {
          font-size: 14px;
          transform: translate(-50%, 17%);
        }
      }
    }
  }
`;
