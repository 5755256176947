import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CartState, OrderState } from "../../redux";
import {
    IOrder,
    IFood,
    IFoodQuantity,
    ITopping,
    IFoodSize,
    IDessertCartItem,
    IDrinkCartItem,
    IOutOfStockCheckQuantity,
    ICard,
    ISearchOrder,
} from "../../entities";
import {
    COUPON_TYPE,
    NumberFlavourBoosterFree,
    PaymentMethod,
} from "../../constants";
interface PaymentTypeInterface extends RouteComponentProps {
    cart: CartState;
    order: OrderState;
    createOrder: (
        body: any,
        CallBack: () => void,
        CallBackError: (message: string) => void
    ) => void;
    creditOrder: (
        body: any,
        CallBack: () => void,
        CallBackError: (message: string) => void
    ) => void;
    reset: (callBack: () => void) => void;
    checkQuantity: (
        body: any,
        CallBack: (
            check: boolean,
            dataCheckOutOfStock: IOutOfStockCheckQuantity,
            dataCheckQuantity: IOutOfStockCheckQuantity
        ) => void
    ) => void;
    getCard: (callBack: (cards: Array<ICard>, status: boolean) => void) => void;
    searchResult: ISearchOrder;
}

interface State {
    isLoading: boolean;
    errContent: string;
    errTitle: string;
    card: Array<ICard>;
    cardStatus: boolean;
}

export class PaymentTypeHandler extends React.Component<
    PaymentTypeInterface,
    State
> {
    state = {
        isLoading: false,
        errContent: "",
        errTitle: "Order failed",
        card: [],
        cardStatus: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: PaymentTypeInterface) {
        super(props);
    }

    componentDidMount() {
        console.log("test", this.props.searchResult.order_id === "");

        (window as any).TCO.loadPubKey("production");
        if (
            (this.props.order.name === "" || this.props.order.type === -1) &&
            this.props.searchResult.order_id === ""
        ) {
            this.props.history.push("/start");
        }
        if (
            this.props.order.order.length === 0 &&
            this.props.order.drinks.length === 0 &&
            this.props.order.signature.length === 0 &&
            this.props.order.desserts.length === 0
        ) {
            this.props.history.push("/order-list");
        }
        this.props.getCard((cards: Array<ICard>, status: boolean) => {
            this.setState({ card: cards, cardStatus: status });
        });
    }

    componentWillUnmount() {
        //@ts-ignore
        $("#cancelOrderConfirm").modal("hide");
        //@ts-ignore
        $("#orderErrorModal").modal("hide");
    }

    toggleErrorMessage = () => {
        //@ts-ignore
        $("#orderErrorModal").modal("toggle");
    };

    onPaymentMethod = () => {
        //@ts-ignore
        $("#paymentMethod").modal("toggle");
    };

    onLoading = () => {
        this.setState({ isLoading: true });
    };

    orderError = (message: string) => {
        //@ts-ignore
        $("#orderErrorModal").modal("show");
        this.setState({
            isLoading: false,
            errContent: message,
            errTitle: "Order failed",
        });
    };

    getAmount = (
        addon: Array<IFoodQuantity>,
        proteinSize: Array<IFoodSize>
    ) => {
        if (addon.length > 0) {
            let amount = 0;
            let quantity = 0;
            let amountAddonFree =
                proteinSize.length > 0 ? proteinSize[0].addons : 0;
            addon.forEach((a: IFoodQuantity, b: number) => {
                if (a.premium === 1) {
                    amount += Number(a.price) * a.quantity;
                    quantity -= a.quantity;
                } else if (
                    quantity < amountAddonFree &&
                    quantity + a.quantity > amountAddonFree
                ) {
                    amount +=
                        Number(a.price) *
                        (a.quantity + quantity - amountAddonFree);
                } else if (quantity < amountAddonFree) {
                } else {
                    amount += Number(a.price) * a.quantity;
                }
                quantity = quantity + a.quantity;
            });
            return amount;
        } else {
            return 0;
        }
    };

    paypalPayment = (details: any, data: any) => {
        if (details.status === "COMPLETED") {
            this.onPaymentMethod();
            this.onCheckout(PaymentMethod.CREDIT_CARD);
        }
    };

    getAmountFlavourBooster = (flavourBooster: Array<IFoodQuantity>) => {
        if (flavourBooster.length > 0) {
            let amount = 0;
            let quantity = 0;
            let amountAddonFree = NumberFlavourBoosterFree;
            flavourBooster.forEach((a: IFoodQuantity, b: number) => {
                if (a.premium === 1) {
                    amount += Number(a.price) * a.quantity;
                    quantity -= a.quantity;
                } else if (
                    quantity < amountAddonFree &&
                    quantity + a.quantity > amountAddonFree
                ) {
                    amount +=
                        Number(a.price) *
                        (a.quantity + quantity - amountAddonFree);
                } else if (quantity < amountAddonFree) {
                } else {
                    amount += Number(a.price) * a.quantity;
                }
                quantity = quantity + a.quantity;
            });
            return amount;
        } else {
            return 0;
        }
    };

    getAmountDrinks = (drinks?: Array<IDrinkCartItem>) => {
        if (!!drinks && drinks.length > 0) {
            let amount = 0;
            let quantity = 0;
            drinks.forEach((a: IDrinkCartItem) => {
                amount += Number(a.price) * a.quantity;
                quantity += a.quantity;
            });
            return { amount, quantity };
        } else {
            return { amount: 0, quantity: 0 };
        }
    };

    getAmountDessert = (desserts?: Array<IDessertCartItem>) => {
        if (!!desserts && desserts.length > 0) {
            let amount = 0;
            let quantity = 0;
            desserts.forEach((a: IDrinkCartItem) => {
                amount += Number(a.price) * a.quantity;
                quantity += a.quantity;
            });
            return { amount, quantity };
        } else {
            return { amount: 0, quantity: 0 };
        }
    };

    getAmountSignature = (signature?: Array<IFoodQuantity>) => {
        if (!!signature && signature.length > 0) {
            let amount = 0;
            let quantity = 0;
            signature.forEach((a: IFoodQuantity) => {
                amount += Number(a.price) * a.quantity;
                quantity += a.quantity;
            });
            return { amount, quantity };
        } else {
            return { amount: 0, quantity: 0 };
        }
    };

    getTotalAmount = () => {
        let totalAmount = 0;
        this.props.order.order.forEach((a: IOrder) => {
            let baseAmount = 0;
            let extraAmount = 0;
            let addonAmount = this.getAmount(a.item.addon, a.item.proteinSize);
            let flavourBooster = this.getAmountFlavourBooster(
                a.item.flavourBooster
            );
            let sizeAmount = 0;
            a.item.proteinSize.forEach((a: IFoodSize) => {
                sizeAmount += Number(a.price);
            });
            a.item.extraProteins.forEach((a: IFoodQuantity) => {
                extraAmount += Number(a.price) * a.quantity;
            });
            a.item.base.forEach((a: IFood) => {
                baseAmount += Number(a.price);
            });
            totalAmount +=
                (baseAmount +
                    extraAmount +
                    addonAmount +
                    sizeAmount +
                    flavourBooster) *
                a.quantityBowl;
        });
        totalAmount += this.getAmountDrinks(this.props.order?.drinks).amount;
        totalAmount += this.getAmountDessert(this.props.order?.desserts).amount;
        totalAmount += this.getAmountSignature(this.props.order?.signature)
            .amount;

        let couponValue = this.props.order?.couponValue;
        if (this.props.order?.couponType === COUPON_TYPE.DISCOUNT_MONEY) {
            totalAmount > Number(couponValue)
                ? (totalAmount -= Number(couponValue))
                : (totalAmount = 0);
        } else if (
            this.props.order.couponType === COUPON_TYPE.DISCOUNT_PERCENT
        ) {
            totalAmount =
                (totalAmount * (100 - Number(this.props.order.couponValue))) /
                100;
        }
        return totalAmount;
    };

    onCheckQuantity = (type: number) => {
        this.setState({ isLoading: true });
        const { order } = this.props;
        let item: Array<any> = [];
        if (order.order.length > 0) {
            order.order.forEach((a) => {
                if (a.item.base.length > 0) {
                    a.item.base.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
                if (a.item.proteinSize.length > 0) {
                    a.item.proteinSize.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
                if (a.item.extraProteins.length > 0) {
                    a.item.extraProteins.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: a.quantity,
                            name: a.name,
                        });
                    });
                }
                if (a.item.marinades.length > 0) {
                    a.item.marinades.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
                if (a.item.addon.length > 0) {
                    a.item.addon.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: a.quantity,
                            name: a.name,
                        });
                    });
                }
                if (a.item.mixin.length > 0) {
                    a.item.mixin.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
                if (a.item.flavourBooster.length > 0) {
                    a.item.flavourBooster.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
                if (a.item.topping.length > 0) {
                    a.item.topping.forEach((a) => {
                        item.push({
                            id: a.id,
                            quantity: 1,
                            name: a.name,
                        });
                    });
                }
            });
        }
        if (order.desserts.length > 0) {
            order.desserts.forEach((a) => {
                item.push({
                    id: a.id,
                    quantity: a.quantity,
                    name: a.name,
                });
            });
        }
        if (order.signature.length > 0) {
            order.signature.forEach((a) => {
                item.push({
                    id: a.id,
                    quantity: a.quantity,
                    name: a.name,
                });
            });
        }
        let tempItem: Array<any> = [];
        item.forEach((a) => {
            let findIndex = tempItem.findIndex((b: any) => a.id === b.id);
            if (findIndex !== -1) {
                tempItem[findIndex].quantity =
                    tempItem[findIndex].quantity + a.quantity;
            } else {
                tempItem.push(a);
            }
        });
        const body = {
            items: tempItem,
        };
        this.props.checkQuantity(
            body,
            (
                check: boolean,
                dataCheckOutOfStock: IOutOfStockCheckQuantity,
                dataCheckQuantity: IOutOfStockCheckQuantity
            ) => {
                this.setState({ isLoading: false });
                if (check === true) {
                    if (type === PaymentMethod.CASH) this.onCheckout(type);
                    else {
                        this.onPaymentMethod();
                    }
                } else {
                    let messageOutOfStock = "";
                    dataCheckOutOfStock.data.forEach((a, b) => {
                        if (messageOutOfStock === "") {
                            messageOutOfStock += a.name;
                        } else {
                            messageOutOfStock += ", " + a.name;
                        }
                        if (b === dataCheckOutOfStock.data.length - 1) {
                            if (b === 0) {
                                messageOutOfStock += " is out of stock";
                            } else {
                                messageOutOfStock += " are out of stock";
                            }
                        }
                    });
                    let messageLimit = "";
                    dataCheckQuantity.data.forEach((a, b) => {
                        if (messageLimit === "") {
                            messageLimit += a.name;
                        } else {
                            messageLimit += ", " + a.name;
                        }
                        if (b === dataCheckQuantity.data.length - 1) {
                            if (b === 0) {
                                messageLimit +=
                                    " in stock is less than the number you selected";
                            } else {
                                messageLimit +=
                                    " in stock are less than the number you selected";
                            }
                        }
                    });
                    this.setState({
                        errContent: `${messageOutOfStock}\n${messageLimit}`,
                        errTitle: "Out of Stock",
                        isLoading: false,
                    });
                    this.toggleErrorMessage();
                }
            }
        );
    };

    onCheckout = (type: number) => {
        const { order } = this.props;
        let bowl: any = [];
        let drinks: any = [];
        let desserts: any = [];
        let signature: any = [];
        order.order.forEach((x: IOrder) => {
            let item = [];
            if (x.item.base.length > 0) {
                item.push({
                    id: x.item.base[0].id,
                    quantity: 1,
                    price: x.item.base[0].price,
                    mix: x.item.base.length > 1 ? x.item.base[1].id : 0,
                    free: 0,
                });
            }
            if (x.item.proteinSize.length > 0) {
                item.push({
                    id: x.item.proteinSize[0].id,
                    quantity: 1,
                    price: x.item.proteinSize[0].price,
                    mix: 0,
                    free: 0,
                });
            }
            if (x.item.marinades.length > 0) {
                x.item.marinades.forEach((a: IFood) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.mixin.length > 0) {
                x.item.mixin.forEach((a: IFood) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.extraProteins.length > 0) {
                x.item.extraProteins.forEach((a: IFoodQuantity) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.addon.length > 0) {
                let freeNumber =
                    x.item.proteinSize.length > 0
                        ? x.item.proteinSize[0].addons
                        : 0;
                x.item.addon.forEach((a: IFoodQuantity, b: number) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free:
                            a.premium === 1
                                ? 0
                                : freeNumber > 0
                                ? freeNumber >= a.quantity
                                    ? a.quantity
                                    : freeNumber
                                : 0,
                    });
                    if (a.premium !== 1) {
                        freeNumber -= a.quantity;
                    }
                });
            }
            if (x.item.flavourBooster.length > 0) {
                let freeNumber = NumberFlavourBoosterFree;
                x.item.flavourBooster.forEach((a: IFoodQuantity, b) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price,
                        mix: 0,
                        free:
                            a.premium === 1
                                ? 0
                                : freeNumber > 0
                                ? freeNumber >= a.quantity
                                    ? a.quantity
                                    : freeNumber
                                : 0,
                    });
                    if (a.premium !== 1) {
                        freeNumber -= a.quantity;
                    }
                });
            }
            if (x.item.topping.length > 0) {
                x.item.topping.forEach((a: ITopping) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            bowl.push({
                items: item,
                bowlQuantity: x.quantityBowl,
                bowlName: x.bowlName,
                bowlNote: x.bowlNote ? x.bowlNote : '',
                is_nori: x.noNori === true ? 1 : 0,
            });
        });
        order.drinks.forEach((a) => {
            drinks.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        order.desserts.forEach((a) => {
            desserts.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        order.signature.forEach((a) => {
            signature.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        this.setState({ isLoading: true });
        let bowlId: any = [];
        this.props.order.order.forEach((a) => {
            if (a.bowlId !== -1) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let extraCount = 0;
                let baseAmount = 0;
                let extraAmount = 0;
                let addonAmount = this.getAmount(
                    a.item.addon,
                    a.item.proteinSize
                );
                let sizeAmount = 0;
                a.item.proteinSize.forEach((a: IFoodSize) => {
                    sizeAmount += Number(a.price);
                });
                a.item.extraProteins.forEach((a: IFoodQuantity) => {
                    extraCount += a.quantity;
                    extraAmount += Number(a.price) * a.quantity;
                });
                a.item.base.forEach((a: IFood) => {
                    baseAmount += Number(a.price);
                });
                const totalAmount =
                    baseAmount + extraAmount + addonAmount + sizeAmount;
                bowlId.push({
                    id: a.bowlId,
                    price: totalAmount,
                });
            }
        });
        // console.log('order',{
        //     clientName: this.props.order.name,
        //     bowls: bowl,
        //     drinks: drinks,
        //     dessert: desserts,
        //     bowl_id: bowlId,
        //     type: this.props.order.type,
        //     coupon: this.props.order.couponCode,
        //     phone: this.props.order.phone,
        //     signature: signature,
        //     scheduledTime: this.props.order.scheduleOrder
        // })
        this.props.createOrder(
            {
                clientName: this.props.order.name,
                bowls: bowl,
                drinks: drinks,
                dessert: desserts,
                bowl_id: bowlId,
                type: this.props.order.type,
                coupon: this.props.order.couponCode,
                phone: this.props.order.phone,
                signature: signature,
                scheduledTime: this.props.order.scheduleOrder,
                payment_method: type,
            },
            () => {
                // alert('order successfully')
                this.setState({ isLoading: false });
                this.props.reset(() => {
                    this.props.history.push("/order-confirm");
                });
            },
            (message: string) => {
                this.orderError(message);
            }
        );
    };
    onCheckoutCredit = (token: string) => {
        const { order } = this.props;
        let bowl: any = [];
        let drinks: any = [];
        let desserts: any = [];
        let signature: any = [];
        order.order.forEach((x: IOrder) => {
            let item = [];
            if (x.item.base.length > 0) {
                item.push({
                    id: x.item.base[0].id,
                    quantity: 1,
                    price: x.item.base[0].price,
                    mix: x.item.base.length > 1 ? x.item.base[1].id : 0,
                    free: 0,
                });
            }
            if (x.item.proteinSize.length > 0) {
                item.push({
                    id: x.item.proteinSize[0].id,
                    quantity: 1,
                    price: x.item.proteinSize[0].price,
                    mix: 0,
                    free: 0,
                });
            }
            if (x.item.marinades.length > 0) {
                x.item.marinades.forEach((a: IFood) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.mixin.length > 0) {
                x.item.mixin.forEach((a: IFood) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.extraProteins.length > 0) {
                x.item.extraProteins.forEach((a: IFoodQuantity) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            if (x.item.addon.length > 0) {
                let freeNumber =
                    x.item.proteinSize.length > 0
                        ? x.item.proteinSize[0].addons
                        : 0;
                x.item.addon.forEach((a: IFoodQuantity, b: number) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price ? a.price : 0,
                        mix: 0,
                        free:
                            a.premium === 1
                                ? 0
                                : freeNumber > 0
                                ? freeNumber >= a.quantity
                                    ? a.quantity
                                    : freeNumber
                                : 0,
                    });
                    if (a.premium !== 1) {
                        freeNumber -= a.quantity;
                    }
                });
            }
            if (x.item.flavourBooster.length > 0) {
                let freeNumber = NumberFlavourBoosterFree;
                x.item.flavourBooster.forEach((a: IFoodQuantity, b) => {
                    item.push({
                        id: a.id,
                        quantity: a.quantity,
                        price: a.price,
                        mix: 0,
                        free:
                            a.premium === 1
                                ? 0
                                : freeNumber > 0
                                ? freeNumber >= a.quantity
                                    ? a.quantity
                                    : freeNumber
                                : 0,
                    });
                    if (a.premium !== 1) {
                        freeNumber -= a.quantity;
                    }
                });
            }
            if (x.item.topping.length > 0) {
                x.item.topping.forEach((a: ITopping) => {
                    item.push({
                        id: a.id,
                        quantity: 1,
                        price: 0,
                        mix: 0,
                        free: 0,
                    });
                });
            }
            bowl.push({
                items: item,
                bowlQuantity: x.quantityBowl,
                bowlName: x.bowlName,
                is_nori: x.noNori === true ? 1 : 0,
            });
        });
        order.drinks.forEach((a) => {
            drinks.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        order.desserts.forEach((a) => {
            desserts.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        order.signature.forEach((a) => {
            signature.push({
                id: a.id,
                quantity: a.quantity,
                price: 0,
                mix: 0,
                free: 0,
            });
        });
        this.setState({ isLoading: true });
        let bowlId: any = [];
        this.props.order.order.forEach((a) => {
            if (a.bowlId !== -1) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let extraCount = 0;
                let baseAmount = 0;
                let extraAmount = 0;
                let addonAmount = this.getAmount(
                    a.item.addon,
                    a.item.proteinSize
                );
                let sizeAmount = 0;
                a.item.proteinSize.forEach((a: IFoodSize) => {
                    sizeAmount += Number(a.price);
                });
                a.item.extraProteins.forEach((a: IFoodQuantity) => {
                    extraCount += a.quantity;
                    extraAmount += Number(a.price) * a.quantity;
                });
                a.item.base.forEach((a: IFood) => {
                    baseAmount += Number(a.price);
                });
                const totalAmount =
                    baseAmount + extraAmount + addonAmount + sizeAmount;
                bowlId.push({
                    id: a.bowlId,
                    price: totalAmount,
                });
            }
        });

        this.props.creditOrder(
            {
                clientName: this.props.order.name,
                bowls: bowl,
                drinks: drinks,
                dessert: desserts,
                bowl_id: bowlId,
                type: this.props.order.type,
                coupon: this.props.order.couponCode,
                phone: this.props.order.phone,
                signature: signature,
                scheduledTime: this.props.order.scheduleOrder,
                payment_method: PaymentMethod.CREDIT_CARD,
                token: token,
            },
            () => {
                this.setState({ isLoading: false });
                this.props.reset(() => {
                    this.props.history.push("/order-confirm");
                });
            },
            (message: string) => {
                this.orderError(message);
            }
        );
    };
}
