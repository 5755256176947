import { BaseResponse } from './ApiWorker';
import { AxiosResponse, AxiosError } from 'axios';
import { ApiWorkerInterceptor } from "./ApiWorkerInterceptor";
import { EventEmitter } from 'events';

export class ApiWorkerInterceptorImp extends EventEmitter implements ApiWorkerInterceptor {

    static EVENT_UNAUTHEN = "EVENT_UNAUTHEN"

    interceptorResponse = (response: AxiosResponse<BaseResponse<any>>): Promise<BaseResponse<any>> => {
        const { data } = response
        console.log("interceptorResponse === ", response)
        if (data.code === 200 || response.status === 200 || response.status === 201) {
            return Promise.resolve(data)
        }
        if (data.code === 403) {
            console.log('emit')
            this.emit(ApiWorkerInterceptorImp.EVENT_UNAUTHEN, response.config)
        }
        return Promise.reject(response)
    }

    interceptorError = (errors: AxiosError): Promise<any> => {
        console.log("interceptorError", errors.response)
        if (errors.response) return this.handleResponseError(errors)

        if (errors.request) return this.handleResponseError(errors.request)

        return Promise.reject(errors)
    }


    handleResponseError = (errors: AxiosError): Promise<any> => {
        if (errors.response && errors.response.data.code === 403) {
            console.log('emit error')
            this.emit(ApiWorkerInterceptorImp.EVENT_UNAUTHEN, errors.response.config)
        }
        if (errors.response) {
            return Promise.reject(errors.response.data)
        }
        return Promise.reject(errors)
    }

    handleRequestError = (request: any) => {

    }

}