import * as React from 'react'
import { BsX } from "react-icons/bs";
interface ConfirmModalProps {
    title: string
    content: string
    onSubmit: () => void
    confirmText?: string
    cancelText?: string
    id: string
    className?: string
    reverseButton?: boolean
    children?: any
    style?: any
    noConfirmText?: boolean
    modalContentClassname?: string
    modalContentStyle?: any
    styleFooter?: any
    styleModalDialog?: any
    hideFooter ?: boolean;
    iconHide ?: boolean;
}

export const ConfirmModal = (data: ConfirmModalProps) => {
    let {styleModalDialog, styleFooter, title, content, onSubmit, confirmText = 'Confirm', cancelText = 'Cancel', id, className, reverseButton = false, children, style, noConfirmText = false, modalContentClassname, modalContentStyle, hideFooter, iconHide } = data
    return (
        <div className="modal fade" id={id} role="dialog" aria-labelledby={id}
            aria-hidden="true" style={style}>
            <div className={`modal-dialog ${className}`} role="document" style={styleModalDialog ? styleModalDialog : {}}>
                <div className={`modal-content position-relative ${modalContentClassname}`} style={modalContentStyle}>
                    {
                    iconHide && <div
                        className="position-absolute modal-icon-bsx"
                        onClick={() => {
                        //@ts-ignore
                            $(`#${id}`).modal("hide");
                        }}
                        >
                            <BsX />
                        </div>
                    }
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel" style={{ fontSize: 48, lineHeight: '64px' }}>{title}</h5>
                    </div>
                    <div className="modal-body">
                        <p style={{ color: '#000', fontSize: 26, fontFamily: 'MM', lineHeight: '26px' }}>{content}</p>
                        {children}
                    </div>
                    {!hideFooter ? reverseButton ? 
                        <div className="modal-footer" style={styleFooter ? styleFooter : {}} >
                            <button type="button" className="btn btn-primary" data-dismiss="modal">{cancelText}</button>
                            <button type="button" className="btn btn-secondary" onClick={onSubmit}>{confirmText}</button>
                        </div> :
                        <div className="modal-footer" style={styleFooter ? styleFooter : {}}>
                            {cancelText !== '' && <button type="button" className={`btn btn-secondary ${noConfirmText && 'full-size'}`} data-dismiss="modal">{cancelText}</button>}
                            {noConfirmText === false && <button type="button" className={`btn btn-primary ${!cancelText && 'full-size'}`} onClick={onSubmit}>{confirmText}</button>}
                        </div> : ''}
                </div>
            </div>
        </div>
    )
}