import React, { ChangeEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CartState, OrderState } from "../../redux";
import {
  IFood,
  IFoodQuantity,
  ITopping,
  IOrderItem,
  IOrder,
  IOutOfStockCheckQuantity,
  IFoodSize,
} from "../../entities";
import { getAmountFromCart } from "../../utils";
interface BowlSummaryInterface extends RouteComponentProps {
  cart: CartState;
  setNavigation: (
    backNavigation: string,
    nextNavigation: any,
    mainRightClassName: string
  ) => void;
  checkDuplicateName: (
    name: string,
    CallBack: (check: boolean, message: string) => void,
    CallBackErr: () => void
  ) => void;
  saveBowl: (
    name: string,
    body: any,
    CallBack: () => void,
    CallBackError: (message: string) => void
  ) => void;
  updateQuantityBowl: (quantity: number) => void;
  addToOrder: (order: Array<IOrder>, callBack: () => void) => void;
  addUpdateIndex: (index: number) => void;
  order: OrderState;
  checkQuantity: (
    body: any,
    CallBack: (
      check: boolean,
      dataCheckOutOfStock: IOutOfStockCheckQuantity,
      dataCheckQuantity: IOutOfStockCheckQuantity
    ) => void
  ) => void;
  updateOrderInfo: (name: string, type: number, phone: string) => void;
  bowlImage: string;
  addBowlNote: (note: string) => void;
}

export class BowlSummaryHandler extends React.Component<BowlSummaryInterface> {
  queueRequest: any;

  nameInput: any;
  phoneInput: any;

  state = {
    name: "",
    phone: this.props.order.phone,
    checkDuplicate: false,
    isChecking: false,
    message: "",
    errMessage: "",
    isChecked: false,
    isSavingBowl: false,
    isAddBowlAvailable: false,
    isLoading: false,
    quantityCheckMessage: "",
    quantityOutOfStockCheckMessage: "",
    isFirstStep: true,
    canvasSize: 0,
    country: "",
    isCommentModalVisible: false,
    bowlNoteValue: "",
  };

  constructor(props: BowlSummaryInterface) {
    super(props);
    this.props.setNavigation(
      "/order/flavour-boosters",
      () => {},
      "main_right_protein detail_protein main_add_on summary"
    );
  }

  componentDidMount() {
    if (this.props.order.name === "" || this.props.order.type === -1) {
      this.props.history.push("/start");
    }
    this.nameInput = React.createRef();
    this.phoneInput = React.createRef();
    let cart = this.props.cart;
    if (
      cart.base.length > 0 ||
      cart.proteinSize.length > 0 ||
      cart.marinades.length > 0 ||
      cart.mixin.length > 0 ||
      cart.addon.length > 0 ||
      cart.flavourBooster.length > 0 ||
      cart.topping.length > 0
    ) {
      this.setState({ isAddBowlAvailable: true });
    }
    this.setState({ canvasSize: 864 });

    // bowl notes
    if (this.props.cart?.bowlNote) {
      this.setState({ bowlNoteValue: this.props.cart?.bowlNote });
    }
  }

  componentWillUnmount() {
    //@ts-ignore
    $("#cancelOrderConfirm").modal("hide");
    //@ts-ignore
    $("#saveBowlModal").modal("hide");
  }

  selectCountry(val: any) {
    this.setState({ country: val });
  }

  reset = () => {
    this.setState({
      name: "",
      checkDuplicate: false,
      isChecking: false,
      message: "",
      errMessage: "",
      isChecked: false,
      isSavingBowl: false,
      isFirstStep: true,
    });
  };

  onChangeText = (e: any) => {
    this.setState({
      name: e.target.value,
      isChecked: false,
      checkDuplicate: false,
    });
    if (e.target.value !== "") {
      clearTimeout(this.queueRequest);
      this.queueRequest = setTimeout(() => {
        this.setState({ isChecking: true });
        this.props.checkDuplicateName(
          this.state.name,
          (check: boolean, message: string) => {
            this.setState({
              checkDuplicate: check,
              message: message,
              isChecking: false,
              isChecked: true,
            });
          },
          () => {
            this.setState({
              checkDuplicate: false,
              message: "",
              isChecking: false,
              isChecked: false,
            });
          }
        );
      }, 700);
    } else {
      this.setState({
        checkDuplicate: false,
        message: "",
        isChecking: false,
        isChecked: false,
      });
    }
  };

  changeItem = (link: string) => {
    this.props.history.push({
      pathname: link,
      state: {
        updateFlag: true,
      },
    });
  };

  onChangePhone = (phone: any) => {
    this.setState({ phone: phone });
  };

  toggleErrorMessage = () => {
    //@ts-ignore
    $("#bowlSummaryErrorModal").modal("toggle");
  };

  onSaveBowl = () => {
    const { cart } = this.props;
    let item: any = [];
    if (cart.base.length > 0) {
      item.push({
        id: cart.base[0].id,
        quantity: 1,
        mix: cart.base.length > 1 ? cart.base[1].id : 0,
        size: 0,
      });
    }
    if (cart.proteinSize.length > 0) {
      item.push({
        id: cart.proteinSize[0].id,
        quantity: 1,
        mix: 0,
        size: cart.proteins[0].id,
      });
    }
    if (cart.marinades.length > 0) {
      cart.marinades.forEach((a: IFood) => {
        item.push({
          id: a.id,
          quantity: 1,
          mix: 0,
          size: 0,
        });
      });
    }
    if (cart.mixin.length > 0) {
      cart.mixin.forEach((a: IFood) => {
        item.push({
          id: a.id,
          quantity: 1,
          mix: 0,
          size: 0,
        });
      });
    }
    if (cart.extraProteins.length > 0) {
      cart.extraProteins.forEach((a: IFoodQuantity) => {
        item.push({
          id: a.id,
          quantity: a.quantity,
          mix: 0,
          size: 0,
        });
      });
    }
    if (cart.addon.length > 0) {
      cart.addon.forEach((a: IFoodQuantity) => {
        item.push({
          id: a.id,
          quantity: a.quantity,
          mix: 0,
          size: 0,
        });
      });
    }
    if (cart.flavourBooster.length > 0) {
      cart.flavourBooster.forEach((a: IFoodQuantity) => {
        item.push({
          id: a.id,
          quantity: a.quantity,
          mix: 0,
          size: 0,
        });
      });
    }
    if (cart.topping.length > 0) {
      cart.topping.forEach((a: ITopping) => {
        item.push({
          id: a.id,
          quantity: 1,
          mix: 0,
          size: 0,
        });
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let extraCount = 0;
    let baseAmount = 0;
    let extraAmount = 0;
    let sizeAmount = 0;
    cart.proteinSize.forEach((a: IFoodSize) => {
      sizeAmount += Number(a.price);
    });
    cart.extraProteins.forEach((a: IFoodQuantity) => {
      extraCount += a.quantity;
      extraAmount += Number(a.price) * a.quantity;
    });
    cart.base.forEach((a: IFood) => {
      baseAmount += Number(a.price);
    });
    const totalAmount =
      baseAmount +
      extraAmount +
      getAmountFromCart(1).amount +
      sizeAmount +
      getAmountFromCart(2).amount;
    const body = {
      clientName: this.state.name,
      name: this.props.order.name,
      items: item,
      bowlQuantity: 1,
      phone: this.state.phone,
      price: totalAmount,
      is_nori: cart.noNori === true ? 1 : 0,
      bowlNote: cart?.bowlNote || "",
    };

    this.setState({
      isSavingBowl: true,
      errMessage: "",
    });
    this.props.saveBowl(
      this.state.name,
      body,
      () => {
        //@ts-ignore
        $("#saveBowlModal").modal("toggle");
        this.setState({ isSavingBowl: false });
        if (this.props.order.phone === "") {
          this.props.updateOrderInfo(
            this.props.order.name,
            this.props.order.type,
            this.state.phone
          );
        }
        this.onAddToOrder();
      },
      (message: string) => {
        this.setState({
          errMessage: message,
          isSavingBowl: false,
          message: "",
        });
      }
    );
  };

  updateQuantityBowl = (quantity: number) => {
    this.props.updateQuantityBowl(quantity > 0 ? quantity : 1);
  };

  nextStepSaveBowl = () => {
    this.setState({ isFirstStep: false });
  };

  onChangeBowlNoteValue = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ bowlNoteValue: e?.target?.value });
  };

  onEditBowl = () => {
    this.changeItem("/order/base");
  };

  onClickEditBowlBtn = () => {
    this.setState({ isCommentModalVisible: true });
  };

  addBowlNote = (value?: string) => {
    if (value) this.props.addBowlNote(value);
  };

  checkQuantity = () => {
    const { cart } = this.props;
    let item: any = [];
    if (cart.base.length > 0) {
      cart.base.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    if (cart.proteinSize.length > 0) {
      cart.proteinSize.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    if (cart.extraProteins.length > 0) {
      cart.extraProteins.forEach((a) => {
        item.push({
          id: a.id,
          quantity: a.quantity,
          name: a.name,
        });
      });
    }
    if (cart.marinades.length > 0) {
      cart.marinades.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    if (cart.addon.length > 0) {
      cart.addon.forEach((a) => {
        item.push({
          id: a.id,
          quantity: a.quantity,
          name: a.name,
        });
      });
    }
    if (cart.mixin.length > 0) {
      cart.mixin.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    if (cart.flavourBooster.length > 0) {
      cart.flavourBooster.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    if (cart.topping.length > 0) {
      cart.topping.forEach((a) => {
        item.push({
          id: a.id,
          quantity: 1,
          name: a.name,
        });
      });
    }
    const body = {
      items: item,
    };
    this.setState({ isLoading: true });
    this.props.checkQuantity(
      body,
      (
        check: boolean,
        dataCheckOutOfStock: IOutOfStockCheckQuantity,
        dataCheckQuantity: IOutOfStockCheckQuantity
      ) => {
        this.setState({ isLoading: false });
        if (check === true) {
          if (this.props.cart.bowlSavedStatus === true) {
            this.onAddToOrder();
          } else {
            this.reset();
            //@ts-ignore
            $("#saveBowlModal").modal("toggle");
          }
        } else {
          let messageOutOfStock = "";
          dataCheckOutOfStock.data.forEach((a, b) => {
            if (messageOutOfStock === "") {
              messageOutOfStock += a.name;
            } else {
              messageOutOfStock += ", " + a.name;
            }
            if (b === dataCheckOutOfStock.data.length - 1) {
              if (b === 0) {
                messageOutOfStock += " is out of stock";
              } else {
                messageOutOfStock += " are out of stock";
              }
            }
          });
          let messageLimit = "";
          dataCheckQuantity.data.forEach((a, b) => {
            if (messageLimit === "") {
              messageLimit += a.name;
            } else {
              messageLimit += ", " + a.name;
            }
            if (b === dataCheckQuantity.data.length - 1) {
              if (b === 0) {
                messageLimit +=
                  " in stock is less than the number you selected";
              } else {
                messageLimit +=
                  " in stock are less than the number you selected";
              }
            }
          });
          this.setState({
            quantityCheckMessage: messageLimit,
            quantityOutOfStockCheckMessage: messageOutOfStock,
          });
          this.toggleErrorMessage();
        }
      }
    );
  };

  onAddToOrder = () => {
    const { cart, order } = this.props;
    const item: IOrderItem = {
      base: cart.base,
      proteins: cart.proteins,
      proteinSize: cart.proteinSize,
      extraProteins: cart.extraProteins,
      marinades: cart.marinades,
      addon: cart.addon,
      mixin: cart.mixin,
      flavourBooster: cart.flavourBooster,
      topping: cart.topping,
    };
    const data: IOrder = {
      item,
      quantityBowl: cart.quantityBowl,
      bowlName: cart.bowlName,
      bowlId: cart.bowlId,
      mixableBase: cart.mixableBase,
      noNori: cart.noNori,
    };
    if (cart?.bowlNote) data.bowlNote = cart.bowlNote;

    let newOrder = order.order;
    if (this.props.cart.updateIndex !== -1) {
      newOrder[this.props.cart.updateIndex] = data;
      this.props.addToOrder(newOrder, () => {
        this.props.history.push("/order-list", {
          updateCart: true,
        });
      });
    } else {
      newOrder.push(data);
      this.props.addToOrder(newOrder, () => {
        this.props.history.push("/bowl-summary-success");
      });
    }
  };
}
