import {
  ADD_BASE,
  ADD_FLAVOUR_BOOSTER,
  ADD_PROTEIN,
  ADD_EXTRA_PROTEIN,
  ADD_MARINADES,
  ADD_ADDON,
  ADD_TOPPING,
  ADD_BOWL_NAME,
  UPDATE_INDEX,
  ADD_BOWL_SAVED_STATUS,
  ADD_QUANTITY_BOWL,
  RESET,
  ADD_MIXIN,
  RESET_SAVED_BOWL,
  ADD_MIXABLE_BASE,
  ADD_NO_NORI,
  ADD_BOWL_NOTE,
} from "./types";
import {
  IFood,
  IFoodQuantity,
  IFoodSize,
  IFlavourBooster,
  IFoodBase,
} from "../../entities";
import { ITopping } from "../../entities/Topping";

const action = {
  addBase: (base: Array<IFoodBase>, isNonSelectedBase: boolean) => {
    return {
      type: ADD_BASE,
      payload: { base, isNonSelectedBase },
    };
  },

  addProteins: (
    proteins: Array<IFood>,
    proteinSize: Array<IFoodSize>,
    isNonSelectedProtein: boolean
  ) => {
    return {
      type: ADD_PROTEIN,
      payload: { proteins, proteinSize, isNonSelectedProtein },
    };
  },

  addAddon: (addon: Array<IFoodQuantity>, isNonSelectedAddOn: boolean) => {
    return {
      type: ADD_ADDON,
      payload: { addon, isNonSelectedAddOn },
    };
  },

  addTopping: (topping: Array<ITopping>, isNonSelectedTopping: boolean) => {
    return {
      type: ADD_TOPPING,
      payload: { topping, isNonSelectedTopping },
    };
  },

  addFlavourBooster: (
    flavourBooster: Array<IFlavourBooster>,
    isNonSelectedFlavourBoosters: boolean
  ) => {
    return {
      type: ADD_FLAVOUR_BOOSTER,
      payload: { flavourBooster, isNonSelectedFlavourBoosters },
    };
  },

  addExtraProteins: (
    extraProteins: Array<IFoodQuantity>,
    isNoneSelectedExtra: boolean
  ) => {
    return {
      type: ADD_EXTRA_PROTEIN,
      payload: { extraProteins, isNoneSelectedExtra },
    };
  },

  addMarinades: (marinades: Array<IFood>, isNoneSelectedMarinades: boolean) => {
    return {
      type: ADD_MARINADES,
      payload: { marinades, isNoneSelectedMarinades },
    };
  },

  addMixin: (mixin: Array<IFood>, isNonSelectedMixin: boolean) => {
    return {
      type: ADD_MIXIN,
      payload: { mixin, isNonSelectedMixin },
    };
  },

  addBowlName: (bowlName: string, bowlId: number) => {
    return {
      type: ADD_BOWL_NAME,
      payload: { bowlName, bowlId },
    };
  },

  addBowlSavedStatus: (bowlSavedStatus: boolean) => {
    return {
      type: ADD_BOWL_SAVED_STATUS,
      payload: { bowlSavedStatus },
    };
  },

  resetSavedBowl: () => {
    return {
      type: RESET_SAVED_BOWL,
      playload: {
        bowlName: "",
        bowlSavedStatus: false,
      },
    };
  },

  addQuantityBowl: (quantityBowl: number) => {
    return {
      type: ADD_QUANTITY_BOWL,
      payload: { quantityBowl },
    };
  },

  addUpdateFlag: (updateIndex: number) => {
    return {
      type: UPDATE_INDEX,
      payload: { updateIndex },
    };
  },

  addMixableBase: (mixableBase: boolean) => {
    return {
      type: ADD_MIXABLE_BASE,
      payload: { mixableBase },
    };
  },
  addNoNori: (noNori: boolean) => {
    return {
      type: ADD_NO_NORI,
      payload: { noNori },
    };
  },
  addBowlNote: (bowlNote: string) => {
    return {
      type: ADD_BOWL_NOTE,
      payload: { bowlNote },
    };
  },
  resetCart: () => {
    return {
      type: RESET,
      payload: {},
    };
  },
};

export default action;
