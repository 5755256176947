import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OrderState } from '../../redux'
// import { User } from '../../entities';
interface SuccessOrderInterface extends RouteComponentProps {
    order: OrderState
}

export class SuccessOrderHandler extends React.Component<SuccessOrderInterface> {

    state = {

    }

    // constructor(props: SuccessOrderInterface) {
    //     super(props)
    // }

    componentDidMount() {

        setTimeout(() => {
            this.props.history.push('/start')
        }, 7000)

    }
    
    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }
}