
export const ADD_ORDER = 'ORDER/ADD_ORDER'
export const ADD_ORDER_INFO = 'ORDER/ADD_ORDER_INFO'
export const ADD_COUPON = 'ORDER/ADD_COUPON'
export const RESET_ORDER = 'ORDER/RESET_ORDER'
export const ADD_DRINK = 'ORDER/ADD_DRINK'
export const ADD_DESSERT = 'ORDER/ADD_DESSERT'
export const ADD_SIGNATURE = 'ORDER/ADD_SIGNATURE'
export const ADD_ORDER_SEARCHING_FLAG = 'ORDER/ADD_ORDER_SEARCHING_FLAG'
export const ADD_SEARCH_RESULT = 'ORDER/ADD_SEARCH_RESULT'
export const ADD_SCHEDULE_ORDER = 'ORDER/ADD_SCHEDULE_ORDER'
