import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { extendMoment } from "moment-range";
import * as Moment from "moment";

import { OrderState } from "../../redux";
import { AppState } from "../../redux/app";
import {} from "../../instances";
import { IBowl, ISearchOrder, IOpeningHours } from "../../entities";
interface StartScreenInterface extends RouteComponentProps {
  getImage: (callBack: (image: Array<string>) => void) => void;
  updateOrderInfo: (name: string, type: number, phone: string) => void;
  order: OrderState;
  checkOpenTime: (callBack: (check: boolean) => void) => void;
  app: AppState;
  getSuggestClient: (
    text: string,
    callBack: (bowlList: Array<IBowl>) => void
  ) => void;
  searchOrder: (
    orderId: number,
    callBack: (searchOrder: ISearchOrder) => void,
    callBackErr: () => void
  ) => void;
  addSearchResult: (searchResult: ISearchOrder, callBack: () => void) => void;
  getOpeningTime: (callBack: (data: Array<IOpeningHours>) => void) => void;
  addScheduleOrder: (scheduleOrder: string) => void;
}

const moment = extendMoment(Moment);

export class StartScreenHandler extends React.Component<StartScreenInterface> {
  input: any;
  timeout: any;
  state = {
    image: [],
    suggestion: [],
    searchText: "",
    isToggleStart: false,
    name: "",
    phone: "",
    zIndexFlag: false,
    isOpen: false,
    minDate: moment().add(10, "minutes").toString(),
    minTime : new Date("Jul 11 2023 02:00:00"),
    maxTime : new Date("Jul 11 2023 23:00:00"),
    dateConfig: {},
    isSearchingOrder: false,
    searchOrderText: "",
    scheduleDate: new Date(),
    openingHour: [],
    openingHourRaw: [],
  };

  constructor(props: StartScreenInterface) {
    super(props);
    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.props.order.name !== "" && this.props.order.type !== -1) {
      this.props.history.push("/menu");
    }

    const monthMap: any = {
      "1": "Jan",
      "2": "Feb",
      "3": "Mar",
      "4": "Apr",
      "5": "May",
      "6": "Jun",
      "7": "Jul",
      "8": "Aug",
      "9": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    };

    this.setState({
      dateConfig: {
        year: {
          format: "YYYY",
          caption: "Year",
          step: 1,
        },
        month: {
          format: (value: any) => {
            return monthMap[value.getMonth() + 1];
          },
          caption: "Mon",
          step: 1,
        },
        date: {
          format: "DD",
          caption: "Day",
          step: 1,
        },
        hour: {
          format: "hh",
          caption: "Hour",
          step: 1,
        },
        minute: {
          format: "mm",
          caption: "Min",
          step: 1,
        },
      },
    });

    this.props.getImage((image: Array<string>) => {
      this.setState({ image });
      //@ts-ignore
      $(".slick_slide").slick({
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: window.innerWidth > 768 ? 0 : 200,
      });
    });
    this.props.checkOpenTime((check: boolean) => {
      if (!check) {
        this.onToggleOpeningModal();
      }
    });
    this.props.getOpeningTime((data) => {
      console.log("OrderListHandler -> componentDidMount -> data", data);
      let openingRange: any = [];
      data.forEach((a) => {
        let range = moment.range(
          moment(a.open_time, "HH:mm:ss").toDate(),
          moment(a.close_time, "HH:mm:ss").toDate()
        );
        let acc = Array.from(range.by("minutes", { step: 10 }))
          .filter((a) => {
            return a.diff(moment()) > 0;
          })
          .map((a) => a.toDate());
        openingRange = openingRange.concat(acc);
      });
      this.setState({ openingHour: openingRange, scheduleDate: openingRange[0], openingHourRaw: data, minTime: openingRange[0] , maxTime : openingRange[openingRange.length-1]})
    });
  }

  componentWillUnmount(): void {
      //@ts-ignore
      $('#dateModal').modal('hide')
  }

  onchangeDateSchedual = () => {
    let openingRange: any = [];
    if (this.state.openingHourRaw?.length < 1 || !this.state.scheduleDate)
      return;
    this.state.openingHourRaw.forEach((a) => {
      let range = moment.range(
        moment(this.state.scheduleDate).set({
            //@ts-ignore
            hour: moment(a.open_time, "HH:mm:ss").hours(),
            //@ts-ignore

            minute: moment(a.open_time, "HH:mm:ss").minutes(),
            //@ts-ignore

            second: moment(a.open_time, "HH:mm:ss").seconds(),
          }).toDate(),
        // moment(a.open_time, "HH:mm:ss").toDate(),
        //@ts-ignore
        moment(this.state.scheduleDate).set({
            //@ts-ignore
            hour: moment(a.close_time, "HH:mm:ss").hours(),
            //@ts-ignore

            minute: moment(a.close_time, "HH:mm:ss").minutes(),
            //@ts-ignore

            second: moment(a.close_time, "HH:mm:ss").seconds(),
        }).toDate(),
        // moment(a.close_time, "HH:mm:ss").toDate()
      );
      let acc = [];
      if (
        moment(this.state.scheduleDate)
          .startOf("day")
          .diff(moment().startOf("day"), "days") > 0
      ) {
        acc = Array.from(range.by("minutes", { step: 10 })).map((a) =>
          a.toDate()
        );
      } else {
        acc = Array.from(range.by("minutes", { step: 10 }))
          .filter((a) => {
            return a.diff(moment()) > 0;
          })
          .map((a) => a.toDate());
      }

      openingRange = openingRange.concat(acc);
    });
    this.setState({
      minTime: openingRange[0], 
      maxTime : openingRange[openingRange.length-1], 
      openingHour: openingRange,
    });
  };

  handleSelect = (val: Date) => {
    this.setState({
      scheduleDate: val,
    });

    setTimeout(() => {
      this.onchangeDateSchedual();
    }, 0);

    //@ts-ignore
    $("#dateModal").modal("hide");
  };

  handleCancel = () => {
    //@ts-ignore
    $("#dateModal").modal("hide");
  };

  onOpen = () => {
    this.setState({
      minDate: moment().add(10, "minutes").toString(),
    });

    //@ts-ignore
    $("#dateModal")[0].style.zIndex = 1000000000;
    $('#dateModal')[0].style.height = '1000px';
    $('#dateModal')[0].style.top = '-126px';

    //@ts-ignore
    $("#dateModal").modal("toggle");
  };

  handleChange = (val : Date) => {
    this.setState({
        scheduleDate : val,
     });

     setTimeout(() => {
        this.onchangeDateSchedual();
      }, 0); 
};


  toggleStart = () => {
    if (this.state.isToggleStart === false) {
      this.setState({ isToggleStart: true }, () => {
        setTimeout(() => {
          this.setState({ zIndexFlag: true });
        }, 800);
      });
    } else {
      if (this.state.isSearchingOrder === true) {
        if (
          !!this.state.searchOrderText &&
          this.state.searchOrderText.trim() !== "" &&
          Number(this.state.searchOrderText)
        ) {
          this.props.searchOrder(
            Number(this.state.searchOrderText),
            (searchOrder) => {
              console.log(
                "StartScreenHandler -> toggleStart -> searchOrder",
                searchOrder
              );
              this.props.addSearchResult(searchOrder, () => {});
              this.props.history.push("/search-result");
            },
            () => {
              this.toggleOrderNotFoundModal();
            }
          );
        } else {
          //@ts-ignore
          $("#validOrderId").modal("toggle");
        }
      } else {
        this.focus();
      }
    }
  };

  onSuggestionsFetchRequested = (data: any) => {
    clearTimeout(this.timeout);
    this.setState({ suggestion: [] });
    this.timeout = setTimeout(() => {
      this.props.getSuggestClient(data.value, (clientList: Array<IBowl>) => {
        this.setState({ suggestion: clientList });
      });
    }, 200);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestion: [],
    });
  };

  onToggleOpeningModal = () => {
    //@ts-ignore
    $("#opening-modal").modal("toggle");
  };

  focus = () => {
    this.input.current?.focus();
  };

  onSelectOrderType = (type: number) => {
    if (this.state.name.trim() === "") {
      //@ts-ignore
      $("#startConfirm").modal("toggle");
    } else {
      this.props.updateOrderInfo(
        this.state.name.trim(),
        type,
        this.state.phone.trim()
      );
      this.props.history.push("/menu");
    }
  };

  onChangeText = (e: any, { newValue }: any) => {
    this.setState({ name: e.target.value || newValue });
  };

  onSelectClient = (name: string, phone: string) => {
    this.setState({ phone });
  };

  onChangeSearchOrderText = (e: any) => {
    this.setState({ searchOrderText: e.target.value });
  };

  onConfirmModal = () => {
    //@ts-ignore
    $("#startConfirm").modal("toggle");
  };

  toggleOrderNotFoundModal = () => {
    //@ts-ignore
    $("#orderNotFound").modal("toggle");
  };

  onConfirmValidOrderIdModal = () => {
    //@ts-ignore
    $("#validOrderId").modal("toggle");
  };

  onToggleChooseOrderType = () => {
    if (this.state.name.trim() === "") {
      //@ts-ignore
      $("#startConfirm").modal("toggle");
    } else {
      //@ts-ignore
      $("#chooseOderType").modal("toggle");
    }
  };

  toggleInDevelopmentModal = () => {
    //@ts-ignore
    $("#inDevelopmentModal").modal("toggle");
  };

  onSearchOrderToggle = () => {
    this.setState({ isSearchingOrder: !this.state.isSearchingOrder });
  };

  handleDateChangeRaw = (e: any) => {
    e.preventDefault();
  };
}
