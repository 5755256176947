import React from "react";
import { ProteinOrderHandler } from "./ProteinOrder.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { BsX } from "react-icons/bs";

import { ReduxState, foodsAction, cartAction } from "../../redux";
import shadowProteinSize from "../../assets/image/shadow_protein_size.png";
import back from "../../assets/image/back.png";
import {
  AnimationFood,
  Loading,
  ConfirmModal,
  MoneyComponent,
  MyScrollBar,
} from "../../components";
import { AppService } from "../../services";
import { IFood, FoodDetail, IFoodQuantity, IFoodSize } from "../../entities";
import ClipLoader from "react-spinners/ClipLoader";
import { appAction } from "../../redux/app";
import { NumberOfMaxExtra } from "../../constants";
import bowl from "../../assets/image/rice.png";

interface ExtraProteinProps {
  item: IFood;
  onAddExtraProtein: (item: IFood, quantity: number) => void;
  onRemoveExtraProtein: (item: IFood) => void;
  selectedExtraProtein: Array<IFoodQuantity>;
  selectedLength: number;
}

const RenderExtraProtein = (data: ExtraProteinProps) => {
  const {
    item,
    onAddExtraProtein,
    onRemoveExtraProtein,
    selectedExtraProtein,
    selectedLength,
  } = data;
  let count = 1;
  let active = false;
  selectedExtraProtein.forEach((a: IFoodQuantity) => {
    if (a.id === item.id) {
      count = a.quantity;
      active = true;
    }
  });
  const [isActive, setActive] = React.useState(active);
  const [quantity, setQuantity] = React.useState(count);
  return (
    <div
      className={`box_add_extra ${
        item.count <= 0 ? `disable-item` : `hover-cursor`
      }`}
    >
      <div className="img_add_extra">
        <div
          onClick={() => {
            if (
              quantity <= 1 &&
              item.count > 0 &&
              selectedLength < NumberOfMaxExtra
            ) {
              setActive(true);
              setQuantity(1);
              onAddExtraProtein(item, 1);
            } else if (selectedLength >= NumberOfMaxExtra && item.count > 0) {
              // @ts-ignore
              $("#extraLimitConfirm").modal("toggle");
              $(".modal-backdrop").css("z-index", 1051);
              $("#extraLimitConfirm").on("hidden.bs.modal", function () {
                $(".modal-backdrop").css("z-index", "");
              });
            }
          }}
        >
          <img src={item.image} className="hover-cursor" alt="" />
        </div>
        {isActive === true && (
          <div style={{ zIndex: 2 }}>
            <div className="active_bg"></div>
            <div className="field1">
              <div className="position-relative text-center">
                <button
                  type="button"
                  id="sub"
                  className="sub"
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                      onAddExtraProtein(item, quantity - 1);
                    } else {
                      setQuantity(quantity - 1);
                      setActive(false);
                      onRemoveExtraProtein(item);
                    }
                  }}
                >
                  -
                </button>
                <input readOnly type="text" value={quantity} />
                <button
                  type="button"
                  id="add"
                  className="add"
                  onClick={() => {
                    if (
                      quantity < item.count &&
                      quantity < NumberOfMaxExtra &&
                      selectedLength < NumberOfMaxExtra
                    ) {
                      setQuantity(quantity + 1);
                      onAddExtraProtein(item, quantity + 1);
                    } else if (quantity >= NumberOfMaxExtra) {
                      // @ts-ignore
                      $("#extraLimitCountConfirm").modal("toggle");
                      $(".modal-backdrop").css("z-index", 1051);
                      $("#extraLimitCountConfirm").on(
                        "hidden.bs.modal",
                        function () {
                          $(".modal-backdrop").css("z-index", "");
                        }
                      );
                    } else if (quantity >= item.count) {
                      // @ts-ignore
                      $("#outOfStockAlertModal").modal("toggle");
                      $(".modal-backdrop").css("z-index", 1051);
                      $("#outOfStockAlertModal").on(
                        "hidden.bs.modal",
                        function () {
                          $(".modal-backdrop").css("z-index", "");
                        }
                      );
                    } else if (selectedLength >= NumberOfMaxExtra) {
                      // @ts-ignore
                      $("#extraLimitConfirm").modal("toggle");
                      $(".modal-backdrop").css("z-index", 1051);
                      $("#extraLimitConfirm").on(
                        "hidden.bs.modal",
                        function () {
                          $(".modal-backdrop").css("z-index", "");
                        }
                      );
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text_add_extra">
        <h4>{item.name}</h4>
        <p className="p2">
          <MoneyComponent money={item.price} type={1} />
        </p>
      </div>
    </div>
  );
};

class ProteinOrderScreen extends ProteinOrderHandler {
  render() {
    const { foodDetail, extraMarinadeFlag } = this.state;
    const numberOfExtra = this.getNumberOfExtra();
    return (
      <div className="page position-relative main_right_child">
        <Loading
          loading={this.props.cart.isNonSelectedProtein}
          style={{ right: -100 }}
        />
        <div className="">
          <p className="choose_your">CHOOSE YOUR</p>
          <h3>PROTEIN</h3>
          <div
            className="switches switches_protein"
            style={{ left: 46, top: 33 }}
          >
            <div className="switch_box">
              <input
                className="switch right hover-cursor"
                type="checkbox"
                checked={!this.props.cart.isNonSelectedProtein}
                onChange={this.toggleChange}
              />
            </div>
            {this.props.cart.isNonSelectedProtein === true && (
              <span className="no_protein">(No Protein)</span>
            )}
          </div>
        </div>
        <div className="list_food_main protein_main content">
          {this.state.animationFood &&
            this.state.animationFood.map((a) => <AnimationFood img={a} />)}

          {this.state.isLoading === true ? (
            <div className="container text-center">
              {" "}
              <ClipLoader
                css={
                  window.innerWidth > 768
                    ? ` margin-top: 200px `
                    : ` margin-top: 100px `
                }
                size={window.innerWidth > 768 ? 150 : 70}
                color={"#D31710"}
                loading={true}
              />
            </div>
          ) : (
            <MyScrollBar>
              <div className="list_food">
                {this.props.foods.map((a: IFood, b) => {
                  return (
                    <div
                      key={b}
                      className={`protein_list ${
                        a.count <= 0 ? `disable-item` : `hover-cursor`
                      } ${
                        this.state.selectedProtein.some(
                          (i: IFood) => a.id === i.id
                        )
                          ? `active_protein`
                          : ``
                      }`}
                      onClick={() => a.count !== 0 && this.onOrder(a)}
                    >
                      <div className="img_box">
                        <img src={a.image} alt="" />
                      </div>
                      <div className="text_protein protein_order">
                        <h4>{a.name}</h4>
                        <p className="p2">
                          <span className="from-price">from</span>
                          <MoneyComponent money={a.price} type={1} />
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </MyScrollBar>
          )}
        </div>
        <ConfirmModal
          content="You have to choose at least one protein or base food?"
          title=""
          id="atLeastConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.hideAtLeastConfirm}
        />
        <ConfirmModal
          content="Are you sure you didn't choose any protein?"
          title="Confirm"
          cancelText=""
          iconHide
          id="proteinConfirm"
          className="confirm-modal"
          onSubmit={this.onConfirmModal}
        />

        <div
          className="modal fade"
          id="basicModal"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{ transform: "translate(-50%, 2%)" }}
            >
              {this.state.foodDetailLoading ? (
                <div
                  className="modal-body-protein text-center"
                  style={{ height: 1300 }}
                >
                  <ClipLoader
                    css={
                      window.innerWidth > 768
                        ? ` margin-top: 200px `
                        : ` margin-top: 100px `
                    }
                    size={window.innerWidth > 768 ? 150 : 70}
                    color={"#D31710"}
                    loading={true}
                  />
                </div>
              ) : (
                <div
                  className="modal-body-protein modal-protein-detail"
                  style={{ height: 1650, paddingRight: 10 }}
                >
                  <MyScrollBar
                    classNameScrollToTop="scroll-to-top-protein-detail"
                    ref={this.proteinModalList}
                    scrollToTopStyle={
                      window.innerWidth > 768
                        ? { bottom: 80, right: 20, overFlowX: "hidden" }
                        : { bottom: 30, right: -20, overFlowX: "hidden" }
                    }
                  >
                    {window.innerWidth > 768 ? (
                      <p className="protein_title">Proteins</p>
                    ) : (
                      <div className="d-flex">
                        <div
                          className="btn btn-back mr-3"
                          style={{ width: 13, marginTop: 15 }}
                          onClick={this.toggleDetail}
                        >
                          <img src={back} alt="" style={{ width: 13 }} />
                        </div>
                        <p className="protein_title">Proteins</p>
                      </div>
                    )}
                    <div className="avatar_protein position-relative">
                      <div className="image_chose_size">
                        <img
                          src={foodDetail.food.image}
                          alt={foodDetail.food.name}
                        />
                      </div>
                      {foodDetail.size.length > 0 && (
                        <div className="box_starting">
                          <div className="position-relative">
                            <div className="price_starting text-center">
                              {/* {this.state.selectedSize.length === 0 && (
                                <p className="start_text">Starting from</p>
                              )} */}
                              <p className="start_text">Starting from</p>
                              <p className="p2">
                                {/* <span>Starting from</span> */}
                                <MoneyComponent
                                  money={
                                    this.state.selectedSize.length > 0
                                      ? this.state.selectedSize[0].price
                                      : foodDetail.size[0].price
                                  }
                                  type={1}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="chose_size_protein">
                      <div className="label_left w-50">
                        <p className="name-protein mb-1">
                          {foodDetail.food.name}
                        </p>
                        <h4 className="choose-size">Choose Size</h4>
                      </div>

                      {foodDetail.size && foodDetail.size.length > 0 && (
                        <div className="button_chose_size w-50">
                          {foodDetail.size.map((a, b) => {
                            // TODO
                            // console.log(foodDetail.size)
                            return (
                              <div key={a?.id}
                                className={`
                                                                    box_size 
                                                                    ${
                                                                      a.count <=
                                                                      0
                                                                        ? `disable-item`
                                                                        : `hover-cursor`
                                                                    } 
                                                                    ${
                                                                      this.state.selectedSize.some(
                                                                        (
                                                                          i: IFood
                                                                        ) =>
                                                                          a.id ===
                                                                          i.id
                                                                      )
                                                                        ? `box_size_active`
                                                                        : ``
                                                                    }
                                                                `}
                                onClick={() =>
                                  a.count > 0 && this.onSelectSize(a)
                                }
                              >
                                {this.state.selectedSize.some(
                                  (i: IFood) => a.id === i.id
                                ) ? (
                                  <div className="shadow">
                                    <img src={shadowProteinSize} alt="" />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div
                                  className={`${
                                    this.state.selectedSize.some(
                                      (i: IFood) => a.id === i.id
                                    )
                                      ? `items_size_active`
                                      : `items_size`
                                  }`}
                                >
                                  <div className="text_size">
                                    <p>
                                      {a.name_size.length > 1
                                        ? ""
                                        : a.name_size}
                                    </p>
                                  </div>
                                  <div className="number_size">
                                    <h4>{b + 1}</h4>
                                    <p>scoop</p>
                                  </div>
                                </div>

                                <div className="box_text_price">
                                  <div className="start_price">
                                    <div className="p2">
                                      <MoneyComponent
                                        money={a.price}
                                        type={1}
                                      />
                                    </div>
                                  </div>
                                  {a.addons && a.addons > 0 && (
                                    <p>
                                      Includes <br></br>
                                      {a.addons} ADD-ONS
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {foodDetail.marinades && foodDetail.marinades.length > 0 && (
                      <div className="chose_marinade" ref={this.marinade}>
                        <h4>CHOOSE YOUR MARINADES</h4>
                        <div className="chose_marinade_body">
                          <div className="list_marinade_protein">
                            {foodDetail.marinades.map((a) => {
                              return (
                                <div key={a?.id}
                                  className={`
                                                                    box_marinade
                                                                    ${
                                                                      a.count <=
                                                                      0
                                                                        ? `disable-item`
                                                                        : `hover-cursor`
                                                                    }
                                                                    ${
                                                                      this.state.selectedMarinades.some(
                                                                        (
                                                                          b: IFood
                                                                        ) =>
                                                                          a.id ===
                                                                          b.id
                                                                      )
                                                                        ? `active_marinade`
                                                                        : ``
                                                                    }
                                                                `}
                                  onClick={() => {
                                    a.count > 0 && this.onSelectMarinades(a);
                                  }}
                                >
                                  <div className="img_marinade">
                                    <img src={a.image} alt="" />
                                  </div>
                                  <div className="text_marinade">
                                    <p>{a.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                            {foodDetail.mixIns &&
                              foodDetail.mixIns.length > 0 &&
                              foodDetail.mixIns.map((a) => {
                                return (
                                  <div
                                    key={a?.id}
                                    className={`box_marinade ${
                                      a.count <= 0
                                        ? `disable-item`
                                        : `hover-cursor`
                                    }  ${
                                      this.state.selectedMixins.some(
                                        (b: IFood) => a.id === b.id
                                      )
                                        ? `active_marinade`
                                        : ``
                                    }`}
                                    onClick={() => {
                                      a.count > 0 && this.onSelectMixins(a);
                                    }}
                                  >
                                    <div className="img_marinade">
                                      <img src={a.image} alt="" />
                                    </div>
                                    <div className="text_marinade">
                                      <p>{a.name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                    {foodDetail.addExtraProtein &&
                      foodDetail.addExtraProtein.length > 0 && (
                        <div className="add_extra_protein">
                          <h2 className="mb-4">ADD EXTRA PROTEIN</h2>
                          <div className="list_extra_protein">
                            {foodDetail.addExtraProtein.map((a: IFood) => {
                              return (
                                <RenderExtraProtein
                                  key={a?.id}
                                  item={a}
                                  onAddExtraProtein={this.onAddExtraProtein}
                                  onRemoveExtraProtein={
                                    this.onRemoveExtraProtein
                                  }
                                  selectedExtraProtein={
                                    this.state.selectedExtraProtein
                                  }
                                  selectedLength={numberOfExtra}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {extraMarinadeFlag === true &&
                      this.state.marinade &&
                      this.state.marinade.length > 0 && (
                        <div className="chose_marinade">
                          <h4>CHOOSE YOUR MARINADES</h4>
                          <div className="chose_marinade_body">
                            <div className="list_marinade_protein">
                              {this.state.marinade.map((a) => {
                                return (
                                  <div
                                    className={`box_marinade ${
                                      a.count <= 0
                                        ? `disable-item`
                                        : `hover-cursor`
                                    }  ${
                                      this.state.selectedMarinades.some(
                                        (b: IFood) => a.id === b.id
                                      )
                                        ? `active_marinade`
                                        : ``
                                    }`}
                                    onClick={() => {
                                      a.count !== 0 &&
                                        this.onSelectMarinades(a);
                                    }}
                                  >
                                    <div className="img_marinade">
                                      <img src={a.image} alt="" />
                                    </div>
                                    <div className="text_marinade">
                                      <p>{a.name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                              {extraMarinadeFlag === true &&
                                this.state.mixins &&
                                this.state.mixins.length > 0 &&
                                this.state.mixins.map((a) => {
                                  return (
                                    <div
                                      className={`box_marinade ${
                                        a.count <= 0
                                          ? `disable-item`
                                          : `hover-cursor`
                                      }  ${
                                        this.state.selectedMixins.some(
                                          (b: IFood) => a.id === b.id
                                        )
                                          ? `active_marinade`
                                          : ``
                                      }`}
                                      onClick={() => {
                                        a.count !== 0 && this.onSelectMixins(a);
                                      }}
                                    >
                                      <div className="img_marinade">
                                        <img src={a.image} alt="" />
                                      </div>
                                      <div className="text_marinade">
                                        <p>{a.name}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                  </MyScrollBar>
                </div>
              )}
              <div className="modal-footer">
                <div className="icon-cancel-protein" data-dismiss="modal">
                  <BsX />
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.checkBeforeConfirm}
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          content=""
          title=""
          id="marinadeConfirm"
          cancelText=""
          className="confirm-modal position-relative"
          onSubmit={() => {
            this.toggleMarinadeModal();
            this.onConfirm();
          }}
          style={{ zIndex: 1052 }}
        >
          <div
            className="cancel-icon-marinade"
            onClick={() => {
              this.toggleMarinadeModal();
            }}
          >
            <BsX />
          </div>
          <div className="d-flex justify-content-center mt-5 mb-5">
            <img src={bowl} alt="bowl" width="25%" height="25%" />
          </div>
          <p className="text-center ml-0 title">
            For the best Poke experience <br />
            We highly recommend selecting marinades. <br />
            <span>Please select "Add Marinade"</span>
            <br />
            or "Proceed to continue."
          </p>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-footer-marinade"
              onClick={() => {
                this.toggleMarinadeModal();
              }}
            >
              Add Marinade
            </button>
            <span className="row-modal-footer"></span>
            <button
              type="button"
              className="btn btn-footer-marinade"
              onClick={() => {
                this.toggleMarinadeModal();
                this.onConfirm();
              }}
            >
              Proceed
            </button>
          </div>
        </ConfirmModal>
        <ConfirmModal
          content="You can choose up to 3 extra protein"
          title="Alert"
          id="extraLimitConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.toggleExtraLimitModal}
          style={{ zIndex: 1052 }}
        />
        <ConfirmModal
          content="You can choose up to 3 of this extra protein"
          title="Alert"
          iconHide
          id="extraLimitCountConfirm"
          cancelText=""
          className="confirm-modal"
          onSubmit={this.toggleExtraLimitCountModal}
          style={{ zIndex: 1052 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    foods: state.foods.proteinFood,
    cart: state.cart,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getProtein = async (idBase: string, callBack: () => void) => {
    const service = new AppService();
    service
      .getProtein(idBase)
      .then((response) => {
        dispatch(foodsAction.addProteinsFoods(response.food));
        callBack();
      })
      .catch(console.log);
  };

  const getProteinDetail = async (
    id: number,
    idBase: string,
    callBack: (foodDetail: FoodDetail) => void
  ) => {
    const service = new AppService();
    service
      .getProteinDetail(id, idBase)
      .then((response) => {
        // dispatch(foodsAction.addProteinsFoods(response.food))
        callBack(response);
      })
      .catch(console.log);
  };

  const addProteinToCart = (
    item: Array<IFood>,
    itemSize: Array<IFoodSize>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addProteins(item, itemSize, nonSelected));
    callBack();
  };

  const addMarinadesToCart = (
    item: Array<IFood>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addMarinades(item, nonSelected));
    callBack();
  };

  const addExtraProtein = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addExtraProteins(item, nonSelected));
    callBack();
  };

  const addMixins = (
    item: Array<IFood>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addMixin(item, nonSelected));
    callBack();
  };

  const setNavigation = (
    backNavigation: any,
    nextNavigation: any,
    mainRightClassName: string
  ) => {
    dispatch(appAction.addNavigation(backNavigation, nextNavigation));
    dispatch(appAction.addMainRightClassName(mainRightClassName));
  };

  const addAddonToCart = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addAddon(item, nonSelected));
    callBack();
  };

  const getMarinade = (
    callBack: (marinade: Array<IFoodQuantity>, mixins: Array<IFood>) => void
  ) => {
    const service = new AppService();
    service
      .getMarindate()
      .then((response) => {
        // dispatch(foodsAction.addProteinsFoods(response.food))
        callBack(response.marinades, response.mixIns);
      })
      .catch(console.log);
  };
  const resetSavedBowl = () => {
    dispatch(cartAction.addBowlName("", -1));
    dispatch(cartAction.addBowlSavedStatus(false));
  };

  return {
    getProtein,
    addProteinToCart,
    getProteinDetail,
    addMarinadesToCart,
    addExtraProtein,
    setNavigation,
    addAddonToCart,
    getMarinade,
    addMixins,
    resetSavedBowl,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(ProteinOrderScreen);
