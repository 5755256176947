import React from 'react'
import { ToppingOrderHandler } from './ToppingOrder.logic';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { ReduxState, foodsAction, cartAction } from '../../redux';

import { Loading, ConfirmModal, MyScrollBar, AnimationFood } from '../../components';
import { AppService } from '../../services';
import { appAction } from '../../redux/app';
import { ITopping } from '../../entities/Topping';
import ClipLoader from "react-spinners/ClipLoader";
import confirmButton from '../../assets/image/confirmButton.png'

class ToppingOrderScreen extends ToppingOrderHandler {


    render() {
        const { selectedItem } = this.state
        return (
            <div className="page position-relative main_right_child">
                <Loading loading={this.props.cart.isNonSelectedTopping} style={{ right: -100 }} />
                <div className="switches switches_toppings">
                    <div className="switch_box">
                        <input className="switch right" type="checkbox" checked={!this.props.cart.isNonSelectedTopping} onChange={this.toggleChange} />
                    </div>
                </div>
                <div className="title_heading">
                    <p className="title_chose">CHOOSE YOUR </p>
                    <h2>TOPPINGS</h2>
                    <p className="title_include mt-3"></p>

                </div>
                <div className="list_body_flavour content">
                    {this.state.animationFood && this.state.animationFood.map(a =>
                        <AnimationFood img={a} />)}
                    {this.state.isLoading === true ? <div className="container text-center">
                        <ClipLoader
                            css={window.innerWidth > 768 ? ` margin-top: 200px ` : ` margin-top: 100px `}
                            size={window.innerWidth > 768 ? 150 : 70}
                            color={"#D31710"}
                            loading={true}
                        />
                    </div> :
                        <MyScrollBar timeout={700}>
                            <div style={{ maxHeight: 1100, display: 'flex', flexWrap: 'wrap' }}>
                                {this.props.foods.map((a: ITopping) => {
                                    return (
                                        <div className={`box_flavour hover-cursor ${a.count <= 0 ? `disable-item` : `hover-cursor`}`} onClick={() => a.count !== 0 && this.onSelect(a)}>
                                            <div className={`items_flavour ${selectedItem.some((i: ITopping) => a.id === i.id) ? `active_flavour` : ``}`}>
                                                <div className="img_flavour">
                                                    <img src={a.image} alt="" />
                                                </div>
                                                <h4>{a.name}</h4>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="w-100 text-center">
                                    <button className="confirm-next-button" onClick={this.onNext}>
                                        <img src={confirmButton} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </MyScrollBar>}
                </div>
                <ConfirmModal content="Are you sure you didn't choose any topping?" title="Confirm" id="toppingConfirm" className="confirm-modal" onSubmit={this.onConfirmModal} cancelText='' iconHide/>
            </div>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        foods: state.foods.topping,
        cart: state.cart
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    const getTopping = async (callBack: () => void) => {
        const service = new AppService()
        service.getTopping()
            .then((response) => {
                dispatch(foodsAction.addTopping(response.topping))
                callBack()
            })
            .catch(console.log)
    }

    const addToppingToCart = (item: Array<ITopping>, nonSelected: boolean, callBack: () => void) => {
        dispatch(cartAction.addTopping(item, nonSelected))
        callBack()
    }

    const setNavigation = (backNavigation: string, nextNavigation: any, mainRightClassName: string) => {
        dispatch(appAction.addNavigation(backNavigation, nextNavigation))
        dispatch(appAction.addMainRightClassName(mainRightClassName))
    }
    const resetSavedBowl = () => {
        dispatch(cartAction.addBowlName('', -1))
        dispatch(cartAction.addBowlSavedStatus(false))
    }
    return {
        getTopping,
        addToppingToCart,
        setNavigation,
        resetSavedBowl
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(ToppingOrderScreen)
// export default (ToppingOrderScreen)