export const ORDER_TYPE = {
    DINE_IN: 1,
    TAKE_OUT: 0,
    DRIVE_THRU: 2
}

export const COUPON_TYPE = {
    DISCOUNT_PERCENT: 1,
    DISCOUNT_MONEY: 0
}

export const NumberOfMaxExtra = 3

export const NumberFlavourBoosterFree = 3

export const NoriItem = [473, 474, 471]

export enum PaymentMethod {
    CASH = 1,
    CREDIT_CARD = 2
}