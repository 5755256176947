import React from "react";
import { PaymentExistedOrderHandler } from "./PaymentExistedOrder.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import back from "../../assets/image/back.png";
import header from "../../assets/image/header.png";
import logo_main from "../../assets/image/logo_main.png";
import shadow from "../../assets/image/payment/shadow_2.png";
import cash from "../../assets/image/payment/cash.png";
import { ReduxState, cartAction, orderAction } from "../../redux";
import { AppService } from "../../services";
import {
  Loading,
  MoneyComponent,
  ConfirmModal,
  MyScrollBar,
} from "../../components";
import ClipLoader from "react-spinners/ClipLoader";
import { Error, ICard } from "../../entities";
import { appAction } from "../../redux/app";
import { PaymentMethod } from "../../constants";

class PaymentExistedOrderScreen extends PaymentExistedOrderHandler {
  render() {
    console.log("payment exist state: ", this.state); // for debug
    console.log("payment exist props: ", this.props); // for debug

    const { card } = this.state;
    return (
      <div className="main_menu">
        <Loading
          loading={this.state.isLoading}
          style={{ zIndex: 3, position: "fixed" }}
        >
          <div className="container text-center loading-container">
            {" "}
            <ClipLoader
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        </Loading>
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo payment-back">
            <img src={logo_main} alt="Logo" />
          </div>
          <div className="d-block flex-nowrap order_list_heading order_list_search_result">
            <a
              href="/#"
              onClick={() => this.props.history.push("/search-result")}
              className="btn btn-back"
              style={{ bottom: "60px !important" }}
            >
              <img src={back} alt="" />
            </a>
            <h2 className="build payment-title" style={{}}>
              PLEASE SELECT A PAYMENT TYPE
            </h2>
          </div>
        </section>
        <section
          className="base_main main_bowl_summary box_order_list payment"
          style={{ paddingTop: 470 }}
        >
          <div className="box_bowl_summary content box_bowl_summary_payment">
            <MyScrollBar>
              <div>
                {window.innerWidth > 768 &&
                  card.length > 0 &&
                  this.state.cardStatus === true && (
                    <div className="payment-item mt-3">
                      <div className="payment-content-card payment-content">
                        <p>Credit/ Debit Card</p>
                        <p className="sub-text">Accept card type</p>
                        <div className="card-list">
                          {card.map((a: ICard) => (
                            <div
                              className="card-item"
                              key={`card-${a.id}`}
                              onClick={() =>
                                this.onCheckout(PaymentMethod.CREDIT_CARD)
                              }
                            >
                              <img src={a.image} alt="" />
                              <p>{a.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="payment-item-box-shadow">
                        <img src={shadow} alt="" />
                      </div>
                    </div>
                  )}
                <div
                  className={`payment-item ${
                    card.length > 0 && this.state.cardStatus === true
                      ? ``
                      : `mt-3`
                  }`}
                  onClick={() => this.onCheckout(PaymentMethod.CASH)}
                >
                  <div className="payment-content-cash payment-content">
                    <img src={cash} alt="" />
                    <p>
                      Cash <p className="sub-text">(Pay at counter)</p>
                    </p>
                  </div>
                  <div className="payment-item-box-shadow payment-cash-shadow">
                    <img src={shadow} alt="" />
                  </div>
                </div>
              </div>
            </MyScrollBar>
          </div>
        </section>
        <section className="footer_payment">
          <div className="bottom_right text-right col-md-6 pl-0">
            <div className="total_price">
              <p className="p2">
                <span className="title_total" style={{ fontSize: 29 }}>
                  Total:
                </span>{" "}
                <MoneyComponent
                  money={this.props.searchResult.total_price}
                  classProps="text_price"
                  type={1}
                />
              </p>
            </div>
          </div>
        </section>
        <ConfirmModal
          content={this.state.errContent}
          title={this.state.errTitle}
          id="orderErrorModal"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.toggleErrorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
    searchResult: state.order.searchResult,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const createOrder = (
    id: string,
    body: any,
    CallBack: () => void,
    CallBackError: (message: string) => void
  ) => {
    const service = new AppService();
    service
      .updatePaymentMethod(id, body)
      .then((response) => {
        CallBack();
      })
      .catch((err: Error) => {
        if (err && err.data && err.data.hasOwnProperty("errors")) {
          CallBackError(
            err.data.errors.hasOwnProperty("payment_method")
              ? err.data.errors.payment_method[0]
              : ``
          );
        } else {
          CallBackError(err.message);
        }
      });
  };

  const reset = (callBack: () => void) => {
    dispatch(cartAction.resetCart());
    dispatch(orderAction.resetOrder());
    dispatch(appAction.resetApp());
    callBack();
  };

  const getCard = (
    callBack: (cards: Array<ICard>, status: boolean) => void
  ) => {
    const service = new AppService();
    service
      .getCardPayment()
      .then((response) => {
        callBack(response.list, response.status.status === 1);
      })
      .catch((err: Error) => {
        console.log("err", err);
      });
  };

  return {
    createOrder,
    reset,
    getCard,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProp
)(PaymentExistedOrderScreen);
