import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { OrderState } from "../../redux";
import {
  IBowl,
  SavedBowl,
  IFoodBase,
  IFoodSize,
  IFood,
  IFoodQuantity,
  IFlavourBooster,
  ITopping,
  ISavedBowlFood,
} from "../../entities";

interface MenuInterface extends RouteComponentProps {
  order: OrderState;
  getSuggestBowl: (
    text: string,
    callBack: (bowlList: Array<IBowl>) => void
  ) => void;
  getBowl: (
    id: number,
    callBack: (savedBowl: SavedBowl) => void,
    callBackErr: () => void
  ) => void;
  getImage: (callBack: (images: Array<any>) => void) => void;
  addBowlNote: (note: string) => void;
  addBaseToCart: (item: Array<IFoodBase>, nonSelected: boolean) => void;
  addProteinToCart: (
    item: Array<IFood>,
    itemSize: Array<IFoodSize>,
    nonSelected: boolean
  ) => void;
  addMarinadesToCart: (item: Array<IFood>, nonSelected: boolean) => void;
  addExtraProtein: (item: Array<IFoodQuantity>, nonSelected: boolean) => void;
  addMixins: (item: Array<IFood>, nonSelected: boolean) => void;
  addAddonToCart: (item: Array<IFoodQuantity>, nonSelected: boolean) => void;
  addFlavourBoosterToCart: (
    item: Array<IFlavourBooster>,
    nonSelected: boolean
  ) => void;
  addToppingToCart: (item: Array<ITopping>, nonSelected: boolean) => void;
  resetCart: (callBack: () => void) => void;
  resetOrder: (callBack: () => void) => void;
  addMixableBase: (mixable: boolean) => void;
  updateFirstTimeAccess: (firstTimeAccess: boolean) => void;
  updateNoriOption: (noriOption: boolean) => void;
  getVideo: () => void;
  bowlImage: string;
  drinkImage: string;
  dessertImage: string;
  signatureImage: string;
  firstTimeAccess: boolean;
  videoIntro: string;
  colorBackground: string;
}

interface MenuState {
  suggestion: Array<IBowl> | any;
  searchText: string;
  images: Array<any>;
  isLoading: boolean;
  slide: number;
  toggleAnimation: boolean;
  youtubePlayer: any;
  isPlayerAvailable: boolean;
}

export class MenuHandler extends React.Component<MenuInterface, MenuState> {
  timeout: any;
  timeoutBlur: any;
  timeoutSwipe: any;
  timeoutLogout: any;
  subcribeRedux: any;

  constructor(props: MenuInterface) {
    super(props);
    this.state = {
      suggestion: [],
      searchText: "",
      images: [],
      isLoading: false,
      slide: 0,
      toggleAnimation: !this.props.firstTimeAccess,
      youtubePlayer: {},
      isPlayerAvailable: false,
    };
  }

  componentDidMount() {
    console.log("type:", this.props.order.type); //for debug
    if (this.props.firstTimeAccess) {
      this.toggleGreetingModal();
      setTimeout(() => {
        this.setState({ toggleAnimation: true });
      }, 400);
      setTimeout(() => {
        //@ts-ignore
        $("#greetingModal").modal("hide");
        this.props.updateFirstTimeAccess(false);
      }, 5000);
    }
    let slickRenderFlag = false;
    if (this.props.order.name === "" || this.props.order.type === -1) {
      this.props.history.push("/start");
    }
    if (this.props.bowlImage !== "") {
      slickRenderFlag = true;
      //@ts-ignore
      $(".slider_food").owlCarousel({
        center: true,
        items: 1,
        margin: window.innerWidth > 768 ? 130 : 30,
        startPosition: 1,
        dots: true,
        loop: true,
        stagePadding: window.innerWidth > 768 ? 270 : 80,
        onDragged: this.setActiveSlide,
      });
    }
    this.props.getImage((images) => {
      this.setState({ images }, () => {
        if (slickRenderFlag === false) {
          //@ts-ignore
          $(".slider_food").owlCarousel({
            center: true,
            items: 1,
            margin: window.innerWidth > 768 ? 130 : 30,
            startPosition: 1,
            dots: true,
            loop: true,
            stagePadding: window.innerWidth > 768 ? 270 : 80,
            onDragged: this.setActiveSlide,
          });
        }
      });
    });
    this.props.getVideo();

    $("#guideModal").on("hidden.bs.modal", () => {
      this.state.youtubePlayer.stopVideo();
    });

    $("#guideModal").on("shown.bs.modal", () => {
      this.state.youtubePlayer.playVideo();
    });
  }

  setActiveSlide = (event: any) => {
    clearTimeout(this.timeoutSwipe);
    this.timeoutSwipe = setTimeout(() => {
      this.setState({ slide: event.page.index });
    }, 200);
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutBlur);
    //@ts-ignore
    $("#cancelOrderConfirm").modal("hide");
  }

  toggleGreetingModal = () => {
    //@ts-ignore
    $("#greetingModal").modal("toggle");
  };

  removeGreetingModal = () => {
    // @ts-ignore
    $("#greetingModal").modal("hide");
    this.props.updateFirstTimeAccess(false);
  };

  toggleGuideModal = () => {
    if (this.state.isPlayerAvailable) {
      //@ts-ignore
      $("#guideModal").modal("toggle");
    }
  };

  _onReady = (event: any) => {
    this.setState({ youtubePlayer: event.target, isPlayerAvailable: true });
  };

  //@ts-ignore
  onChange = (e: any, { newValue }) => {
    this.setState({
      searchText: newValue,
    });
  };

  onSuggestionsFetchRequested = (data: any) => {
    clearTimeout(this.timeout);
    
    this.timeout = setTimeout(() => {
      this.props.getSuggestBowl(data.value, (bowlList: Array<IBowl>) => {
        if(bowlList.length > 0) {
          this.setState({ suggestion: bowlList });
        }
        else {          
          this.setState({ suggestion: ['__no_suggestion__']});
        }
      });
    }, 400);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestion: [],
    });
  };

  youtube_parser = (url: string) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : url;
  };

  getInfoSavedBowl = (bowl: Array<ISavedBowlFood>, type: number): any => {
    //Base
    if (type === 1) {
      let result: Array<IFoodBase> = bowl.map((a) => {
        return {
          id: a.article.id,
          image: a.article.articleImage,
          name: a.article.articleName,
          price: a.article.price,
          size: [],
          count: 0,
          mix: a.article.mix,
          isBurrito: a.article.is_burrito,
        };
      });
      return result;
    }
    //Protein
    else if (type === 2) {
      let protein: Array<IFood> = [];
      let size: Array<IFoodSize> = [];
      let extra: Array<IFoodQuantity> = [];
      if (bowl.length > 0) {
        size.push({
          id: bowl[0].article.id,
          image: bowl[0].article.articleImage,
          name: bowl[0].article.articleName,
          price: bowl[0].article.price,
          addons: bowl[0].article.addons ? bowl[0].article.addons : 0,
          name_size: bowl[0].article.size ? bowl[0].article.size : "",
          count: 0,
        });
        if (bowl[0].articleOrigin) {
          protein.push({
            id: bowl[0].articleOrigin.id,
            image: bowl[0].articleOrigin.articleImage,
            name: bowl[0].articleOrigin.articleName,
            price: bowl[0].articleOrigin.price,
            count: 0,
          });
        }
        if (bowl.length > 1) {
          for (let index = 1; index < bowl.length; index++) {
            const element = bowl[index];
            extra.push({
              id: element.article.id,
              image: element.article.articleImage,
              name: element.article.articleName,
              price: element.article.price,
              quantity: element.quantity,
              protein_mix: element.article.protein_mix,
              count: 0,
            });
          }
        }
        return {
          protein,
          size,
          extra,
        };
      }
    }
    // Marinade, Mixins, Addon
    else if (type === 3) {
      let result: Array<IFood> = bowl.map((a) => {
        return {
          id: a.article.id,
          image: a.article.articleImage,
          name: a.article.articleName,
          price: a.article.price,
          count: 0,
        };
      });
      return result;
    }
    //Flavour Booster
    else if (type === 4) {
      let result: Array<IFoodQuantity> = bowl.map((a) => {
        return {
          id: a.article.id,
          name: a.article.articleName,
          image: a.article.articleImage,
          count: 0,
          price: a.article.price,
          quantity: a.quantity,
        };
      });
      return result;
    }
    //Topping
    else if (type === 5) {
      let result: Array<ITopping> = bowl.map((a) => {
        return {
          id: a.article.id,
          name: a.article.articleName,
          image: a.article.articleImage,
          count: 0,
        };
      });
      return result;
    } else if (type === 6) {
      let result: Array<IFoodQuantity> = bowl.map((a) => {
        return {
          id: a.article.id,
          image: a.article.articleImage,
          name: a.article.articleName,
          price: a.article.price,
          quantity: a.quantity,
          count: 0,
        };
      });
      return result;
    }
  };

  onSelectBowl = (id: number) => {
    this.setState({ isLoading: true });
    this.props.resetCart(() => {});
    this.timeoutBlur = setTimeout(() => {
      this.setState({ isLoading: false });
    }, 10000);
    this.props.getBowl(
      id,
      (savedBowl: SavedBowl) => {
        this.props.updateNoriOption(savedBowl.is_nori === 1 ? true : false);
        if (savedBowl.bowlItems.base.length > 0) {
          let result: Array<IFoodBase> = this.getInfoSavedBowl(
            savedBowl.bowlItems.base,
            1
          );
          if (result.length > 1) {
            this.props.addMixableBase(true);
          } else {
            this.props.addMixableBase(false);
          }
          this.props.addBaseToCart(result, false);
        } else {
          this.props.addBaseToCart([], true);
        }
        if (savedBowl.bowlItems.protein.length > 0) {
          let result = this.getInfoSavedBowl(savedBowl.bowlItems.protein, 2);
          if (result.protein.length > 0) {
            this.props.addProteinToCart(result.protein, result.size, false);
          } else {
            this.props.addProteinToCart(result.protein, result.size, true);
          }
          if (result.extra.length > 0) {
            this.props.addExtraProtein(result.extra, false);
          } else {
            this.props.addExtraProtein(result.extra, true);
          }
        } else {
          this.props.addProteinToCart([], [], true);
          this.props.addExtraProtein([], true);
        }
        if (savedBowl.bowlItems.marinades.length > 0) {
          let result: Array<IFood> = this.getInfoSavedBowl(
            savedBowl.bowlItems.marinades,
            3
          );
          this.props.addMarinadesToCart(result, false);
        } else {
          this.props.addMarinadesToCart([], true);
        }
        if (savedBowl.bowlItems.mixins.length > 0) {
          let result: Array<IFood> = this.getInfoSavedBowl(
            savedBowl.bowlItems.mixins,
            3
          );
          this.props.addMixins(result, false);
        } else {
          this.props.addMixins([], true);
        }
        if (savedBowl.bowlItems.addons.length > 0) {
          let result: Array<IFoodQuantity> = this.getInfoSavedBowl(
            savedBowl.bowlItems.addons,
            6
          );
          this.props.addAddonToCart(result, false);
        } else {
          this.props.addAddonToCart([], true);
        }
        if (savedBowl.bowlItems.boosters.length > 0) {
          let result: Array<IFlavourBooster> = this.getInfoSavedBowl(
            savedBowl.bowlItems.boosters,
            4
          );
          this.props.addFlavourBoosterToCart(result, false);
        } else {
          this.props.addFlavourBoosterToCart([], true);
        }
        if (savedBowl.bowlItems.topping.length > 0) {
          let result: Array<ITopping> = this.getInfoSavedBowl(
            savedBowl.bowlItems.topping,
            5
          );
          this.props.addToppingToCart(result, false);
        } else {
          this.props.addToppingToCart([], true);
        }
        if (savedBowl?.note) {
          this.props.addBowlNote(savedBowl?.note);
        }
        this.setState({ isLoading: false });
        this.props.history.push("/bowl-summary");
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  onOptionPress(index: number) {
    if (index === this.state.slide) {
      this.props.history.push("/order/base");
    }
  }
}
