import {
  ADD_BASE,
  ADD_FLAVOUR_BOOSTER,
  ADD_PROTEIN,
  ADD_EXTRA_PROTEIN,
  ADD_MARINADES,
  ADD_ADDON,
  ADD_TOPPING,
  ADD_BOWL_NAME,
  ADD_MIXIN,
  ADD_BOWL_SAVED_STATUS,
  ADD_QUANTITY_BOWL,
  RESET,
  RESET_SAVED_BOWL,
  UPDATE_INDEX,
  ADD_MIXABLE_BASE,
  ADD_NO_NORI,
  ADD_BOWL_NOTE,
} from "./types";
import { IFood, IFoodQuantity, IFoodSize, IFoodBase } from "../../entities";
import { ITopping } from "../../entities/Topping";

export interface CartState {
  base: Array<IFoodBase>;
  isNonSelectedBase: boolean;
  flavourBooster: Array<IFoodQuantity>;
  isNonSelectedFlavourBoosters: boolean;
  proteins: Array<IFood>;
  proteinSize: Array<IFoodSize>;
  isNonSelectedProtein: boolean;
  extraProteins: Array<IFoodQuantity>;
  isNoneSelectedExtra: boolean;
  marinades: Array<IFood>;
  isNoneSelectedMarinades: boolean;
  addon: Array<IFoodQuantity>;
  isNonSelectedAddOn: boolean;
  topping: Array<ITopping>;
  isNonSelectedTopping: boolean;
  bowlName: string;
  bowlSavedStatus: boolean;
  quantityBowl: number;
  mixin: Array<IFood>;
  isNonSelectedMixin: boolean;
  updateIndex: number;
  mixableBase: boolean;
  bowlId: number;
  noNori: boolean;
  bowlNote?: string;
}

const initialState: CartState = {
  base: [],
  isNonSelectedBase: false,
  flavourBooster: [],
  isNonSelectedFlavourBoosters: false,
  proteins: [],
  proteinSize: [],
  isNonSelectedProtein: false,
  extraProteins: [],
  isNoneSelectedExtra: false,
  marinades: [],
  isNoneSelectedMarinades: false,
  addon: [],
  isNonSelectedAddOn: false,
  topping: [],
  isNonSelectedTopping: false,
  bowlName: "",
  bowlSavedStatus: false,
  quantityBowl: 1,
  mixin: [],
  isNonSelectedMixin: false,
  updateIndex: -1,
  mixableBase: false,
  bowlId: -1,
  noNori: false,
  bowlNote: "",
};

function cart(state = initialState, action: any) {
  let { type, payload } = action;
  switch (type) {
    case ADD_BASE:
      return Object.assign({}, state, payload);
    case ADD_BOWL_NAME:
      return Object.assign({}, state, payload);
    case ADD_FLAVOUR_BOOSTER:
      return Object.assign({}, state, payload);
    case ADD_ADDON:
      return Object.assign({}, state, payload);
    case ADD_TOPPING:
      return Object.assign({}, state, payload);
    case ADD_BOWL_SAVED_STATUS:
      return Object.assign({}, state, payload);
    case ADD_PROTEIN:
      return Object.assign({}, state, payload);
    case ADD_EXTRA_PROTEIN:
      return Object.assign({}, state, payload);
    case ADD_MARINADES:
      return Object.assign({}, state, payload);
    case RESET_SAVED_BOWL:
      return Object.assign({}, state, payload);
    case ADD_QUANTITY_BOWL:
      return Object.assign({}, state, payload);
    case ADD_MIXIN:
      return Object.assign({}, state, payload);
    case UPDATE_INDEX:
      return Object.assign({}, state, payload);
    case ADD_MIXABLE_BASE:
      return Object.assign({}, state, payload);
    case ADD_NO_NORI:
      return Object.assign({}, state, payload);
    case ADD_BOWL_NOTE:
      return Object.assign({}, state, payload);
    case RESET:
      return Object.assign({}, state, {
        base: [],
        isNonSelectedBase: false,
        flavourBooster: [],
        isNonSelectedFlavourBoosters: false,
        proteins: [],
        proteinSize: [],
        isNonSelectedProtein: false,
        extraProteins: [],
        isNoneSelectedExtra: false,
        marinades: [],
        isNoneSelectedMarinades: false,
        addon: [],
        isNonSelectedAddOn: false,
        topping: [],
        isNonSelectedTopping: false,
        bowlName: "",
        bowlSavedStatus: false,
        quantityBowl: 1,
        mixin: [],
        isNonSelectedMixin: false,
        updateIndex: -1,
        mixableBase: false,
        bowlId: -1,
        noNori: false,
        bowlNote: "",
      });
    default:
      return state;
  }
}

export { cart };
