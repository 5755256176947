import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { IFood, IFoodQuantity } from '../../entities'
import { CartState } from '../../redux'
import { getAmountFromCart } from '../../utils'
interface AddOnOrderInterface extends RouteComponentProps {
    getAddOn: (idBase: string, callBack: () => void) => void
    foods: Array<IFood>
    cart: CartState
    addAddonToCart: (item: Array<IFoodQuantity>, nonSelected: boolean, callBack: () => void) => void
    setNavigation: (backNavigation: any, nextNavigation: any, mainRightClassName: string) => void
    resetSavedBowl: () => void
}
interface AddOnOrderState {
    selectedItem: Array<IFoodQuantity>
    amountAddonFree: number
    isLoading: boolean
    isAddRecently: boolean
    animationFood: Array<string>
    animationExtra: Array<string>
    isSelecting: boolean
    opacity: number
}

export class AddOnOrderHandler extends React.Component<AddOnOrderInterface, AddOnOrderState> {

    nextLink: string
    param: any
    constructor(props: AddOnOrderInterface) {
        super(props)
        this.state = {
            selectedItem: [],
            amountAddonFree: 0,
            isLoading: true,
            isAddRecently: false,
            animationFood: [],
            animationExtra: [],
            isSelecting: false,
            opacity: 0
        }
        this.nextLink = '/order/flavour-boosters'
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            // this.nextLink = '/bowl-summary'
            this.param = { updateFlag: true }
            this.props.setNavigation({
                pathname: "/order/proteins",
                state: {
                    updateFlag: true
                }
            }, this.onNext, 'main_right_protein detail_protein main_add_on')
        } else {
            this.props.setNavigation('/order/proteins', this.onNext, 'main_right_protein detail_protein main_add_on')
        }
    }

    componentDidUpdate() {
        if (getAmountFromCart(1).amount > 0 && this.state.opacity === 0) {
            this.setState({ opacity: 1 })
        } else if (getAmountFromCart(1).amount <= 0 && this.state.opacity === 1) {
            this.setState({ opacity: 0 })
        }
    }

    componentDidMount() {
        this.props.getAddOn(this.props.cart.base.length === 1 ? String(this.props.cart.base[0].id) : '', () => {
            let temp: Array<IFoodQuantity> = Array.from(this.props.cart.addon)
            let removedIndex: Array<number> = []
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = this.props.foods.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        removedIndex.push(index)
                    } else if (item && item.count < a.quantity) {
                        a.quantity = item.count
                    }
                })
                if (removedIndex.length > 0) {
                    removedIndex.reverse().forEach(a => {
                        temp.splice(a, 1)
                    })
                    this.props.resetSavedBowl()
                    this.props.addAddonToCart(Array.from(temp), false, () => { })
                }
            }
            this.setState({ selectedItem: temp, amountAddonFree: this.props.cart.proteinSize.length > 0 ? this.props.cart.proteinSize[0].addons : 0, isLoading: false })

        })

        //@ts-ignore
        if (this.props.location.state && this.props.location.state.isSizeChanged) {
            // this.nextLink = '/bowl-summary'
            this.props.setNavigation({
                pathname: "/order/proteins",
                state: {
                    updateFlag: true
                }
            }, this.onNext, 'main_right_protein detail_protein main_add_on')
            setTimeout(() => {
                //@ts-ignore
                $('#freeAddonChangedConfirm').modal('toggle')
            }, 700)
        }
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#addonConfirm').modal('hide')
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }

    toggleChange = () => {
        this.props.resetSavedBowl()
        this.props.addAddonToCart([], !this.props.cart.isNonSelectedAddOn, () => {
            this.setState({ selectedItem: [] })
        })
        if(this.props.cart.isNonSelectedAddOn === false){
            this.props.history.push({
                pathname: this.nextLink,
            })
        }
    }


    onSelect = (item: IFood, quantity: number) => {
        this.props.resetSavedBowl()
        const { selectedItem } = this.state
        if (selectedItem.some(a => {
            return a.id === item.id
        })) {
            let temp = this.state.selectedItem
            temp.forEach((a: IFoodQuantity) => {
                if (a.id === item.id) {
                    a.quantity = quantity
                }
            })
            this.setState({ selectedItem: temp })

            this.props.addAddonToCart(temp, false, () => { })
        } else {
            let temp = selectedItem
            temp.push({ ...item, quantity: 1 })
            // this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image) })
            this.setState({
              selectedItem: temp,
            });
            // check extra
            if (getAmountFromCart(1).amount > 0) {
              console.log("extra..."); //for debug
              this.setState({
                animationExtra: this.state.animationExtra.concat(item.image),
              });
            } else {
              console.log("no extra..."); //for debug
              this.setState({
                animationFood: this.state.animationFood.concat(item.image),
              });
            }
              this.props.addAddonToCart(temp, false, () => {});
        }
    }

    onRemove = (item: IFood) => {
        this.props.resetSavedBowl()
        let temp = this.state.selectedItem.filter((a: IFoodQuantity) => a.id !== item.id)
        this.setState({ selectedItem: temp })
        this.props.addAddonToCart(temp, false, () => { })
    }

    onNext = () => {
        if (this.state.selectedItem.length > 0 && getAmountFromCart(1).quantity >= this.state.amountAddonFree) {
            this.props.addAddonToCart(this.state.selectedItem, false, () => {
                this.props.history.push({
                    pathname: this.nextLink,
                    state: this.param
                })
            })
        } else {
            if (this.state.selectedItem.length > 0 && getAmountFromCart(1).quantity < this.state.amountAddonFree) {
                //@ts-ignore
                $('#freeAddonConfirm').modal('toggle')
            }
            else {
                if (this.props.cart.isNonSelectedAddOn === false) {
                    //@ts-ignore
                    $('#addonConfirm').modal('toggle')
                } else {
                    this.props.history.push({
                        pathname: this.nextLink,
                        state: this.param
                    })
                }
            }
        }
    }

    onConfirmModal = () => {
        this.props.addAddonToCart([], true, () => {
            //@ts-ignore
            $('#addonConfirm').modal('toggle')
            this.props.history.push({
                pathname: this.nextLink,
                state: this.param
            })
        })
    }

    onConfirmFreeAddonModal = () => {
        //@ts-ignore
        $('#freeAddonConfirm').modal('toggle')
        this.props.history.push({
            pathname: this.nextLink,
            state: this.param
        })
    }

    onConfirmFreeAddonChangedModal = () => {
        //@ts-ignore
        $('#freeAddonChangedConfirm').modal('toggle')
    }
}