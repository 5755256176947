import { ADD_NAVIGATION, ADD_MAIN_RIGHT_CLASSNAME, ADD_BOWL_IMAGE, ADD_DRINKS_IMAGE, ADD_DESSERT_IMAGE, ADD_SIGNATURE_IMAGE, RESET, UPDATE_SESSION, FIRST_TIME_ACCESS, SET_VIDEO_INTRO, ADD_BOWL_COLOR } from './types'


const action = {
    addNavigation: (backNavigation: any, nextNavigation: any) => {
        return {
            type: ADD_NAVIGATION,
            payload: { backNavigation, nextNavigation }
        }
    },
    addMainRightClassName: (mainRightClassName: string) => {
        return {
            type: ADD_MAIN_RIGHT_CLASSNAME,
            payload: { mainRightClassName }
        }
    },
    addBowlImage: (bowlImage: string) => {
        return {
            type: ADD_BOWL_IMAGE,
            payload: { bowlImage }
        }
    },
    addSignatureImage: (signatureImage: string) => {
        return {
            type: ADD_SIGNATURE_IMAGE,
            payload: { signatureImage }
        }
    },
    addDrinkImage: (drinkImage: string) => {
        return {
            type: ADD_DRINKS_IMAGE,
            payload: { drinkImage }
        }
    },
    addDessertImage: (dessertImage: string) => {
        return {
            type: ADD_DESSERT_IMAGE,
            payload: { dessertImage }
        }
    },
    addOpeningChecking: (opening: boolean, openingMessage: string, lastCheck: string) => {
        return {
            type: ADD_DESSERT_IMAGE,
            payload: { opening, openingMessage, lastCheck }
        }
    },
    updateSession: (time: string) => {
        return {
            type: UPDATE_SESSION,
            payload: { time }
        }
    },
    updateFirstTimeAccess: (firstTimeAccess: boolean) => {
        return {
            type: FIRST_TIME_ACCESS,
            payload: { firstTimeAccess }
        }
    },
    setVideoIntro: (videoIntro: string) => {
        return {
            type: SET_VIDEO_INTRO,
            payload: { videoIntro }
        }
    },
    addBowlColor: (bowlColor: string) => {
        return {
            type: ADD_BOWL_COLOR,
            payload: { bowlColor }
        }
    },
    resetApp: () => {
        return {
            type: RESET,
            payload: {}
        }
    }
}

export default action