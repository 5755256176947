import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';


import { persistReducer } from 'redux-persist'
import persistStore from "redux-persist/es/persistStore";
import storage from 'redux-persist/lib/storage'
import { Persistor } from 'redux-persist/es/types';
import logger from 'redux-logger'

const persistConfig = {
    key: 'root',
    storage,
    // transforms: [encryptor],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
    persistedReducer,

    applyMiddleware(logger)
);
//@ts-ignore
const persistor: Persistor = persistStore(store)
export * from './reducers'
export * from './food'
export * from './cart'
export * from './order'
export { store, persistor }