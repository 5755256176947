import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { IFood, FoodDetail, IFoodQuantity, IFoodSize } from '../../entities'
import { CartState } from '../../redux'

interface ProteinOrderInterface extends RouteComponentProps {
    getProtein: (idBase: string, callBack: () => void) => void
    foods: Array<IFood>
    cart: CartState
    addProteinToCart: (item: Array<IFood>, itemSize: Array<IFoodSize>, nonSelected: boolean, callBack: () => void) => void
    addMarinadesToCart: (item: Array<IFood>, nonSelected: boolean, callBack: () => void) => void
    addExtraProtein: (item: Array<IFoodQuantity>, nonSelected: boolean, callBack: () => void) => void
    getProteinDetail: (id: number, idBase: string, callBack: (foodDetail: FoodDetail) => void) => void
    addAddonToCart: (item: Array<IFoodQuantity>, nonSelected: boolean, callBack: () => void) => void
    setNavigation: (backNavigation: any, nextNavigation: any, mainRightClassName: string) => void
    getMarinade: (callBack: (marinade: Array<IFoodQuantity>, mixins: Array<IFood>) => void) => void
    addMixins: (item: Array<IFood>, nonSelected: boolean, callBack: () => void) => void
    resetSavedBowl: () => void
}

interface ProteinOrderState {
    selectedItem: Array<IFood>,
    isModalOpen: boolean
    isLoading: boolean
    foodDetailLoading: boolean
    foodDetail: FoodDetail
    selectedProtein: Array<IFood>
    selectedSize: Array<IFoodSize>
    selectedMarinades: Array<IFood>
    selectedMixins: Array<IFood>
    selectedExtraProtein: Array<IFoodQuantity>
    tempSelectedProtein: Array<IFood>
    extraMarinadeFlag: boolean,
    marinade: Array<IFoodQuantity>
    mixins: Array<IFood>
    isSizeChanged: boolean
    animationFood: Array<string>
    isSelecting: boolean
    isSeletingNewProtein: boolean
}
export class ProteinOrderHandler extends React.Component<ProteinOrderInterface, ProteinOrderState> {

    marinade: React.RefObject<any>
    proteinModalList: React.RefObject<any>

    constructor(props: ProteinOrderInterface) {
        super(props)
        this.state = {
            selectedItem: this.props.cart.proteins,
            isLoading: true,
            isModalOpen: false,
            foodDetailLoading: false,
            foodDetail: {
                addExtraProtein: [],
                food: {
                    id: 0,
                    image: '',
                    name: '',
                    price: '',
                    count: 0
                },
                marinades: [],
                size: [],
                mixIns: []
            },
            selectedSize: this.props.cart.proteinSize,
            selectedProtein: this.props.cart.proteins.slice(),
            selectedMarinades: this.props.cart.marinades,
            selectedMixins: this.props.cart.mixin,
            selectedExtraProtein: this.props.cart.extraProteins,
            tempSelectedProtein: [],
            extraMarinadeFlag: false,
            marinade: [],
            mixins: [],
            isSizeChanged: false,
            animationFood: [],
            isSelecting: false,
            isSeletingNewProtein: false

        }
        this.marinade = React.createRef()
        this.proteinModalList = React.createRef()
        console.log('state', this.props.location.state)
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            this.props.setNavigation({
                pathname: "/order/base",
                state: {
                    updateFlag: true
                }
            }, this.onNext, 'position-relative')
        } else {
            this.props.setNavigation('/order/base', this.onNext, 'position-relative')
        }
    }

    componentDidUpdate() {
        if (this.state.selectedExtraProtein.length > 0 && this.state.extraMarinadeFlag === false && this.state.foodDetail.food.protein_mix === 0) {
            if (this.state.selectedExtraProtein.some(a => a.protein_mix === 1)) {
                this.props.getMarinade((marinade, mixin) => {
                    this.setState({ marinade, mixins: mixin, extraMarinadeFlag: true })
                })
            }
        } else if (this.state.extraMarinadeFlag === true && this.state.selectedExtraProtein.length === 0) {
            this.setState({ marinade: [], mixins: [], selectedMarinades: [], selectedMixins: [], extraMarinadeFlag: false })
        } else if (this.state.extraMarinadeFlag === true && this.state.selectedExtraProtein.length > 0) {
            if (this.state.selectedExtraProtein.some(a => a.protein_mix === 1)) {

            } else {
                this.setState({ marinade: [], mixins: [], selectedMarinades: [], selectedMixins: [], extraMarinadeFlag: false })
            }
        }
    }

    componentDidMount() {
        this.props.getProtein(this.props.cart.base.length === 1 ? String(this.props.cart.base[0].id) : '', () => {
            this.setState({ isLoading: false })

            let temp = this.state.selectedItem
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = this.props.foods.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        object.splice(index, 1)
                    }
                })
                if (temp.length === 0) {
                    this.setState({ selectedItem: temp })
                    this.props.addProteinToCart([], [], false, () => {
                        this.props.addMarinadesToCart([], false, () => { })
                        this.props.addMixins([], false, () => { })
                        this.props.addExtraProtein([], false, () => {
                            this.setState({
                                selectedSize: [],
                                selectedProtein: [],
                                selectedMarinades: [],
                                selectedMixins: [],
                                selectedExtraProtein: [],
                                tempSelectedProtein: [],
                            })
                        })
                    })
                } else {
                    this.props.getProteinDetail(temp[0].id, this.props.cart.base.length > 0 ? String(this.props.cart.base[0].id) : '', (foodDetail: FoodDetail) => {
                        let tempMarinades = this.state.selectedMarinades
                        if (tempMarinades.length > 0 && foodDetail.marinades) {
                            let removedIndex: Array<number> = []
                            tempMarinades.forEach((a, index, object) => {
                                let item = foodDetail.marinades.find((b) => b.id === a.id)
                                if (item && item.count <= 0) {
                                    removedIndex.push(index)
                                }
                            })
                            if (removedIndex.length > 0) {
                                removedIndex.reverse().forEach(a => {
                                    tempMarinades.splice(a, 1)
                                })
                                this.props.resetSavedBowl()
                            }
                            this.setState({ selectedMarinades: tempMarinades })
                            this.props.addMarinadesToCart(tempMarinades, tempMarinades.length > 0 ? false : true, () => { })
                        }
                        let tempMixin = this.state.selectedMixins
                        if (tempMixin.length > 0 && foodDetail.mixIns) {
                            let removedIndex: Array<number> = []
                            tempMixin.forEach((a, index, object) => {
                                let item = foodDetail.mixIns.find((b) => b.id === a.id)
                                if (item && item.count <= 0) {
                                    removedIndex.push(index)
                                }
                            })
                            if (removedIndex.length > 0) {
                                removedIndex.reverse().forEach(a => {
                                    tempMixin.splice(a, 1)
                                })
                                this.props.resetSavedBowl()
                            }
                            this.setState({ selectedMixins: tempMixin })
                            this.props.addMixins(tempMixin, tempMixin.length > 0 ? false : true, () => { })
                        }
                        let tempExtra = this.state.selectedExtraProtein
                        if (tempExtra.length > 0) {
                            let removedIndex: Array<number> = []
                            tempExtra.forEach((a, index, object) => {
                                let item = foodDetail.addExtraProtein.find((b) => b.id === a.id)
                                if (item && (item.count <= 0 || a.quantity > item.count)) {
                                    removedIndex.push(index)
                                }
                            })
                            if (removedIndex.length > 0) {
                                removedIndex.reverse().forEach(a => {
                                    tempExtra.splice(a, 1)
                                })
                                this.props.resetSavedBowl()
                            }
                            this.setState({ selectedExtraProtein: tempExtra })
                            this.props.addExtraProtein(tempExtra, tempExtra.length > 0 ? false : true, () => { })
                            if (tempExtra.length === 0 && !foodDetail.marinades) {
                                this.props.addMarinadesToCart([], true, () => { })
                                this.props.addMixins([], true, () => { })
                                this.setState({ selectedMarinades: [], selectedMixins: [] })
                            } else if (tempExtra.length > 0 && !foodDetail.marinades) {
                                let check = false
                                tempExtra.forEach(a => {
                                    if (a.protein_mix === 1) {
                                        check = true
                                    }
                                })
                                if (check === false) {
                                    this.props.addMarinadesToCart([], true, () => { })
                                    this.props.addMixins([], true, () => { })
                                    this.setState({ selectedMarinades: [], selectedMixins: [] })
                                } else {
                                    this.props.getMarinade((marinade, mixin) => {
                                        let tempMarinades = this.state.selectedMarinades
                                        if (tempMarinades.length > 0) {
                                            let removedIndex: Array<number> = []
                                            tempMarinades.forEach((a, index, object) => {
                                                let item = marinade.find((b) => b.id === a.id)
                                                if (item && item.count <= 0) {
                                                    removedIndex.push(index)
                                                }
                                            })
                                            if (removedIndex.length > 0) {
                                                removedIndex.reverse().forEach(a => {
                                                    tempMarinades.splice(a, 1)
                                                })
                                                this.props.resetSavedBowl()
                                            }
                                            this.setState({ selectedMarinades: tempMarinades })
                                            this.props.addMarinadesToCart(tempMarinades, tempMarinades.length > 0 ? false : true, () => { })
                                        }
                                        let tempMixin = this.state.selectedMixins
                                        if (tempMixin.length > 0) {
                                            let removedIndex: Array<number> = []
                                            tempMixin.forEach((a, index, object) => {
                                                let item = mixin.find((b) => b.id === a.id)
                                                if (item && item.count <= 0) {
                                                    removedIndex.push(index)
                                                }
                                            })
                                            if (removedIndex.length > 0) {
                                                removedIndex.reverse().forEach(a => {
                                                    tempMixin.splice(a, 1)
                                                })
                                                this.props.resetSavedBowl()
                                            }
                                            this.setState({ selectedMixins: tempMixin })
                                            this.props.addMixins(tempMixin, tempMixin.length > 0 ? false : true, () => { })
                                        }
                                    })
                                }
                            }

                        }
                    })
                }
            }
        })

        setTimeout(() => {
            //@ts-ignore
            if (this.props.location.state && this.props.location.state.isOpenModal && this.state.selectedProtein.length > 0) {
                this.onOrder(this.state.selectedProtein[0])
            }
        }, 700)

    }

    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
        //@ts-ignore
        $('#proteinConfirm').modal('hide')
        //@ts-ignore
        $('#basicModal').modal('hide')
    }

    onNext = () => {

        if (this.state.selectedProtein.length > 0) {
            this.props.history.push('/order/addon')
        } else {
            if (this.props.cart.isNonSelectedBase === true) {
                //@ts-ignore
                $('#atLeastConfirm').modal('toggle')
            } else {
                if (this.props.cart.isNonSelectedProtein === false) {
                    //@ts-ignore
                    $('#proteinConfirm').modal('toggle')
                } else {
                    this.props.history.push('/order/addon')
                }
            }
        }
    }

    getNumberOfExtra = () => {
        let number = 0
        this.state.selectedExtraProtein.forEach((a) => {
            number = number + a.quantity
        })
        return number
    }

    hideAtLeastConfirm = () => {
        //@ts-ignore
        $('#atLeastConfirm').modal('toggle')
    }

    toggleChange = () => {

        if (this.props.cart.isNonSelectedBase === true && this.props.cart.isNonSelectedProtein === false) {
            //@ts-ignore
            $('#atLeastConfirm').modal('toggle')
        } else {
            this.props.resetSavedBowl()
            let boolean = this.props.cart.isNonSelectedProtein
            this.props.addProteinToCart([], [], !boolean, () => {
                this.props.addMarinadesToCart([], !boolean, () => { })
                this.props.addMixins([], !boolean, () => { })
                this.props.addExtraProtein([], !boolean, () => {
                    this.setState({
                        selectedSize: [],
                        selectedProtein: [],
                        selectedMarinades: [],
                        selectedMixins: [],
                        selectedExtraProtein: [],
                        tempSelectedProtein: [],
                    })
                })

                if(this.props.cart.isNonSelectedProtein === false) {
                    this.props.history.push('/order/addon')
                }
            })
        }
    }

    toggleDetail = () => {
        //@ts-ignore
        $('#basicModal').modal('toggle')

        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    onOrder = (item: IFood) => {
        if (this.state.isSelecting === false) {
            if (this.props.cart.proteins.length > 0 && this.props.cart.proteins.some((a: IFood) => a.id === item.id)) {
                this.setState({
                    foodDetailLoading: true,
                    tempSelectedProtein: [{ ...item }],
                    selectedMarinades: this.props.cart.marinades,
                    selectedExtraProtein: this.props.cart.extraProteins,
                    selectedMixins: this.props.cart.mixin,
                    selectedSize: this.props.cart.proteinSize,
                    extraMarinadeFlag: false,
                    isSeletingNewProtein: false
                })
            } else {
                this.setState({
                    foodDetailLoading: true, tempSelectedProtein: [{ ...item }],
                    selectedSize: [],
                    selectedMarinades: [],
                    selectedMixins: [],
                    selectedExtraProtein: [],
                    extraMarinadeFlag: false,
                    marinade: [],
                    mixins: [],
                    isSeletingNewProtein: true
                })
            }

            this.props.getProteinDetail(item.id, this.props.cart.base.length === 1 ? String(this.props.cart.base[0].id) : '', (foodDetail: FoodDetail) => {
                if (this.state.selectedSize.length === 0) {
                    this.setState({ selectedSize: foodDetail.size.filter((a, b) => b === 0 && a.count !== 0), foodDetailLoading: false, foodDetail: foodDetail })
                } else {

                    this.setState({ foodDetailLoading: false, foodDetail: foodDetail })
                }
            })
            this.toggleDetail()
        }
    }

    onSelectSize = (item: IFoodSize) => {
        const temp = this.state.selectedSize
        temp[0] = item
        this.props.resetSavedBowl()
        this.setState({ selectedSize: temp })
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            this.setState({ isSizeChanged: true })
        }
        setTimeout(() => {
            this.marinade.current?.scrollIntoView({
                behavior: "smooth",
                block: window.innerWidth > 768 ? 'center' : 'start',
                inline: 'center',
            })
        }, 200)
    }

    onSelectMarinades = (item: IFood) => {
        this.props.resetSavedBowl()
        if (this.state.selectedMarinades.some(a => {
            return a.id === item.id
        })) {
            this.setState({ selectedMarinades: this.state.selectedMarinades.filter((a: IFood) => a.id !== item.id) })
        } else {
            const temp = this.state.selectedMarinades
            temp.push(item)
            this.setState({ selectedMarinades: temp })

        }
    }

    onSelectMixins = (item: IFood) => {
        this.props.resetSavedBowl()
        if (this.state.selectedMixins.some(a => {
            return a.id === item.id
        })) {
            this.setState({ selectedMixins: this.state.selectedMixins.filter((a: IFood) => a.id !== item.id) })
        } else {
            const temp = this.state.selectedMixins
            temp.push(item)
            this.setState({ selectedMixins: temp })
        }
    }

    onAddExtraProtein = (item: IFood, quantity: number) => {
        this.props.resetSavedBowl()
        if (this.state.selectedExtraProtein.some(a => {
            return a.id === item.id
        })) {
            let temp = this.state.selectedExtraProtein
            temp.forEach((a: IFoodQuantity) => {
                if (a.id === item.id) {
                    a.quantity = quantity
                }
            })
        } else {
            const temp = this.state.selectedExtraProtein
            temp.push({ ...item, quantity: quantity })
            this.setState({ selectedExtraProtein: temp })
        }
    }

    onRemoveExtraProtein = (item: IFood) => {
        this.props.resetSavedBowl()
        this.setState({ selectedExtraProtein: this.state.selectedExtraProtein.filter((a: IFoodQuantity) => a.id !== item.id) })
    }

    checkBeforeConfirm = () => {
        let { foodDetail, selectedMixins, selectedMarinades, marinade, mixins } = this.state
        if (this.state.selectedSize.length > 0) {
            if ((marinade.length > 0 || mixins.length > 0) && (selectedMarinades.length > 0 || selectedMixins.length > 0)) {
                this.onConfirm()
            } else if ((((!!foodDetail.marinades && foodDetail.marinades.length > 0) || (!!foodDetail.mixIns && foodDetail.mixIns.length > 0))) && (selectedMarinades.length > 0 || selectedMixins.length > 0)) {
                this.onConfirm()
            } else if (((marinade.length > 0 || mixins.length > 0) || ((!!foodDetail.marinades && foodDetail.marinades.length > 0) || (!!foodDetail.mixIns && foodDetail.mixIns.length > 0))) && (selectedMarinades.length === 0 && selectedMixins.length === 0)) {
                //@ts-ignore
                $('#marinadeConfirm').modal('toggle')
                $('.modal-backdrop').css('z-index', 1051)
                $('#marinadeConfirm').on('hidden.bs.modal', function () {
                    $('.modal-backdrop').css('z-index', '')
                })
            } else if ((!foodDetail.marinades || foodDetail.marinades.length === 0) && (!foodDetail.mixIns || foodDetail.mixIns.length === 0)) {
                this.onConfirm()
            } else {
            }
        } else {
            alert('You must select size')
        }
    }

    onConfirm = () => {
        this.setState({ animationFood: this.state.animationFood.concat(this.state.selectedSize[0].image), isSelecting: true })
        this.props.addProteinToCart(this.state.tempSelectedProtein, this.state.selectedSize, false, () => {

            //@ts-ignore
            $('#basicModal').modal('toggle')
            setTimeout(() => {
                //@ts-ignore
                if (this.props.location.state && this.props.location.state.updateFlag) {
                    console.log('update flag')
                    if (this.state.isSizeChanged === true || this.state.isSeletingNewProtein === true) {
                        this.props.history.push({
                            pathname: '/order/addon',
                            state: {
                                isSizeChanged: true,
                                updateFlag: true
                            }
                        })
                    } else {
                        this.props.history.push('/bowl-summary')
                    }
                } else {
                    // this.props.addAddonToCart([], false, () => { })
                    this.props.history.push('/order/addon')
                }
            }, 600);
        })
        if (this.state.selectedMarinades.length > 0) {
            this.setState({ animationFood: this.state.animationFood.concat(this.state.selectedMarinades.map(a => a.image)) })
            this.props.addMarinadesToCart(this.state.selectedMarinades, false, () => { })
        } else {
            this.props.addMarinadesToCart([], true, () => { })
        }
        if (this.state.selectedExtraProtein.length > 0) {
            this.setState({ animationFood: this.state.animationFood.concat(this.state.selectedExtraProtein.map(a => a.image)) })
            this.props.addExtraProtein(this.state.selectedExtraProtein, false, () => { })
        } else {
            this.props.addExtraProtein([], true, () => { })
        }
        if (this.state.selectedMixins.length > 0) {
            this.setState({ animationFood: this.state.animationFood.concat(this.state.selectedMixins.map(a => a.image)) })
            this.props.addMixins(this.state.selectedMixins, false, () => { })
        } else {
            this.props.addMixins([], true, () => { })
        }
    }

    toggleExtraLimitModal = () => {
        // @ts-ignore
        $('#extraLimitConfirm').modal('toggle')
    }

    toggleExtraLimitCountModal = () => {
        // @ts-ignore
        $('#extraLimitCountConfirm').modal('toggle')
    }

    toggleMarinadeModal = () => {
        //@ts-ignore
        $('#marinadeConfirm').modal('toggle')
    }

    onConfirmModal = () => {

        // @ts-ignore
        $('#proteinConfirm').modal('toggle')
        this.props.resetSavedBowl()
        this.props.addProteinToCart([], [], true, () => { })
        this.props.addMarinadesToCart([], true, () => { })
        this.props.addMixins([], true, () => { })
        this.props.addExtraProtein([], true, () => {
            this.props.history.push('/order/addon')
        })
    }
}