import React from "react";
import { DrinkOrderHandler } from "./DrinkOrder.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { ReduxState, orderAction } from "../../redux";
import ClipLoader from "react-spinners/ClipLoader";
import { BsX } from "react-icons/bs";

import { MyScrollBar, MoneyComponent, AnimationFood } from "../../components";
import { AppService } from "../../services";
import { ICategoriesItem, IDrinkItem, IDrinkCartItem } from "../../entities";
import { truncateString } from "../../utils";

interface DrinksProps {
  item: IDrinkItem;
  onAddDrinks: (item: IDrinkItem, quantity: number) => void;
  onRemoveDrinks: (item: IDrinkItem) => void;
  selectedDrinks: Array<IDrinkCartItem>;
}

const RenderExtraProtein = (data: DrinksProps) => {
  const { item, onAddDrinks, onRemoveDrinks, selectedDrinks } = data;
  let count = 1;
  let active = false;
  selectedDrinks.forEach((a: IDrinkCartItem) => {
    if (a.id === item.id) {
      count = Number(a.quantity);
      active = true;
    }
  });
  const [isActive, setActive] = React.useState(active);
  const [quantity, setQuantity] = React.useState(count);

  return (
    <div
      className={`box_add_extra ${
        item.count <= 0 ? `disable-item` : `hover-cursor`
      }`}
    >
      <div
        className={`img_add_extra_box img_add_extra ${
          item.image ? `d-table` : `d-block w-100`
        } ${isActive === true ? `active-drink` : ``}`}
      >
        <div
          className={`img_add_extra ${item.image ? `` : `d-block w-100`}`}
          onClick={() => {
            if (quantity <= 1 && item.count > 0) {
              setActive(true);
              setQuantity(1);
              onAddDrinks(item, 1);
            }
          }}
        >
          <img src={item.image} className="hover-cursor" alt="" />
        </div>
        {isActive === true && (
          <div style={{ zIndex: 2 }}>
            <div className="active_bg"></div>
            <div className="field1">
              <div className="position-relative text-center">
                <button
                  type="button"
                  id="sub"
                  className="sub"
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                      onAddDrinks(item, Number(quantity - 1));
                    } else {
                      setQuantity(Number(quantity - 1));
                      setActive(false);
                      onRemoveDrinks(item);
                    }
                  }}
                >
                  -
                </button>
                <input readOnly type="text" value={quantity} />
                <button
                  type="button"
                  id="add"
                  className="add"
                  onClick={() => {
                    if (quantity < item.count) {
                      setQuantity(quantity + 1);
                      onAddDrinks(item, Number(quantity + 1));
                    } else if (quantity >= item.count) {
                      // @ts-ignore
                      $("#outOfStockAlertModal").modal("toggle");
                      $(".modal-backdrop").css("z-index", 1051);
                      $("#outOfStockAlertModal").on(
                        "hidden.bs.modal",
                        function () {
                          $(".modal-backdrop").css("z-index", "");
                        }
                      );
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text_add_extra">
        <h4>{item.name}</h4>
        <p className="p2">
          <MoneyComponent money={item.price} type={1} />
        </p>
      </div>
    </div>
  );
};

class DrinkOrderScreen extends DrinkOrderHandler {
  render() {
    return (
      <>
        <div className="page position-relative main_right_child main_right_drink">
          <div className="">
            <p className="choose_your">Choose your</p>
            <h3>Drinks</h3>
          </div>
          <div className="list_food_main protein_main content">
            {this.state.isLoading === true ? (
              <div className="container text-center">
                {" "}
                <ClipLoader
                  css={
                    window.innerWidth > 768
                      ? ` margin-top: 200px `
                      : ` margin-top: 100px `
                  }
                  size={window.innerWidth > 768 ? 150 : 70}
                  color={"#D31710"}
                  loading={true}
                />
              </div>
            ) : (
              <MyScrollBar>
                <div className="list_food">
                  {this.state.categories.map((a: ICategoriesItem, b) => {
                    return (
                      <div
                        key={b}
                        className={`protein_list ${
                          this.props.drinks.some((d) => a.id === d.category_id)
                            ? `active_protein`
                            : ``
                        }`}
                        onClick={() => this.onSelectCategory(a)}
                      >
                        <div
                          className="img_box text-center"
                          style={{ height: 190, width: "auto" }}
                        >
                          <img
                            src={a.image}
                            style={{ maxHeight: 190, width: "auto" }}
                            alt=""
                          />
                        </div>
                        <div className="text_protein pt-3">
                          <h4>{a.name}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </MyScrollBar>
            )}
            {this.state.animationFood &&
              this.state.animationFood.map((a) => <AnimationFood img={a} />)}
          </div>
          <div
            className="modal fade"
            id="drinkModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content modal-drinks">
                <div className="modal-header">
                  <div>
                    <h5
                      className="modal-title bold-font"
                      id="exampleModalLabel"
                      style={{ fontSize: 32 }}
                    >
                      {truncateString(this.state.selectedOptionName, 30)}
                    </h5>
                    <h5
                      className="modal-title"
                      style={{ fontSize: 52, lineHeight: "64px" }}
                    >
                      Choose Option
                    </h5>
                  </div>
                </div>
                <div className="modal-body-protein">
                  <div className="add_extra_protein">
                    <div className="drink_modal_list" style={{ height: 900 }}>
                      {this.state.isLoadingDrinks === true ? (
                        <div
                          className="container text-center"
                          style={{ height: 500 }}
                        >
                          {" "}
                          <ClipLoader
                            css={` margin-top: 100px, margin-bottom: 100px `}
                            size={window.innerWidth > 768 ? 150 : 70}
                            color={"#D31710"}
                            loading={true}
                          />
                        </div>
                      ) : (
                        <MyScrollBar
                          classNameScrollToTop="scroll-to-top-protein-detail"
                          scrollToTopStyle={
                            window.innerWidth > 768
                              ? { bottom: 80, right: 40 }
                              : {}
                          }
                        >
                          <div
                            className="list_extra_protein position-relative list_drinks"
                            ref={this.drinkList}
                          >
                            <>
                              {this.state.drinks.map((a: IDrinkItem, b) => {
                                return (
                                  <RenderExtraProtein
                                    item={a}
                                    onAddDrinks={this.onAddDrink}
                                    onRemoveDrinks={this.onRemoveDrinks}
                                    selectedDrinks={
                                      this.state.tempSelectedDrinks
                                    }
                                  />
                                );
                              })}
                            </>
                          </div>
                        </MyScrollBar>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div
                    className="icon-cancel"
                    data-dismiss="modal"
                    onClick={this.onCancelOption}
                  >
                    <BsX />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onConfirmOption}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
    drinks: state.order.drinks,
    drinkCategories: state.order.drinkCategories,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getCategories = (
    callBack: (categories: Array<ICategoriesItem>) => void
  ) => {
    const service = new AppService();
    service
      .getCategories()
      .then((response) => {
        callBack(response.categories);
      })
      .catch(console.log);
  };

  const getDrink = (
    id: number,
    callBack: (drinks: Array<IDrinkItem>) => void
  ) => {
    const service = new AppService();
    service
      .getDrink(id)
      .then((response) => {
        callBack(response.drinks);
      })
      .catch(console.log);
  };

  const addDrinksToCart = (
    drinks: Array<IDrinkCartItem>,
    categories: Array<ICategoriesItem>
  ) => {
    dispatch(orderAction.addDrinks(drinks, categories));
  };

  return {
    getCategories,
    getDrink,
    addDrinksToCart,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(DrinkOrderScreen);
