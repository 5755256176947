import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { OrderState } from '../../redux'
// import { User } from '../../entities';
interface OrderConfirmInterface extends RouteComponentProps {
    order: OrderState
}

export class OrderConfirmHandler extends React.Component<OrderConfirmInterface> {

    state = {

    }

    componentDidMount() {
    }
    
    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }
}