import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CartState, OrderState } from '../../redux'
import { ICategoriesItem, IDrinkItem, IDrinkCartItem } from '../../entities'
interface DrinkOrderInterface extends RouteComponentProps {
    cart: CartState
    order: OrderState
    getCategories: (callBack: (categories: Array<ICategoriesItem>) => void) => void
    getDrink: (id: number, callBack: (drinks: Array<IDrinkItem>) => void) => void
    addDrinksToCart: (drinks: Array<IDrinkCartItem>, categories: Array<ICategoriesItem>) => void
    drinks: Array<IDrinkCartItem>
    drinkCategories: Array<ICategoriesItem>
}

interface DrinkOrderState {
    categories: Array<ICategoriesItem>
    isLoading: boolean
    isLoadingDrinks: boolean
    selectedOptionName: string
    selectedCategory: number
    drinks: Array<IDrinkItem>
    tempSelectedDrinks: Array<IDrinkCartItem>
    selectedDrinks: Array<IDrinkCartItem>
    animationFood: Array<string>
    isSelecting: boolean
}

export class DrinkOrderHandler extends React.Component<DrinkOrderInterface, DrinkOrderState> {

    drinkList: React.RefObject<any>


    constructor(props: DrinkOrderInterface) {
        super(props)
        this.drinkList = React.createRef()
        this.state = {
            categories: [],
            isLoading: true,
            isLoadingDrinks: false,
            selectedOptionName: '',
            selectedCategory: -1,
            drinks: [],
            tempSelectedDrinks: [],
            selectedDrinks: Array.from(this.props.drinks),
            animationFood: [],
            isSelecting: false
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getCategories((categories: Array<ICategoriesItem>) => {
            this.setState({ categories, isLoading: false })
        })
    }

    componentDidUpdate() {
        if (this.props.drinks !== this.state.selectedDrinks) {
            this.setState({ selectedDrinks: this.props.drinks })
        }
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#drinkModal').modal('hide')
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }

    onSelectCategory = (item: ICategoriesItem) => {
        //@ts-ignore
        $('#drinkModal').modal('toggle')
        // setTimeout(() => {
        //     this.drinkList.current?.scrollIntoView({
        //         behavior: "smooth",
        //         block: 'start',
        //         inline: 'center',
        //     })
        // }, 500)
        this.setState({ selectedCategory: item.id, drinks: [], selectedOptionName: item.name, isLoadingDrinks: true, tempSelectedDrinks: [...this.state.selectedDrinks] })
        this.props.getDrink(item.id, (drinks => {
            let temp = this.state.selectedDrinks
            let tempCategories = Array.from(this.props.drinkCategories)
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = drinks.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        object.splice(index, 1)
                        if (object.some(c => c.category_id === this.state.selectedCategory) === false) {
                            let indexCate = tempCategories.findIndex(c => c.id === this.state.selectedCategory)
                            if (indexCate !== -1) {
                                tempCategories.splice(indexCate, 1)
                            }
                        }
                        this.setState({ selectedDrinks: temp, tempSelectedDrinks: temp })
                        this.props.addDrinksToCart(temp, tempCategories)
                    }
                })
            }
            this.setState({ drinks, isLoadingDrinks: false })
        }))
    }

    onAddDrink = (item: IDrinkItem, quantity: number) => {
        if (this.state.tempSelectedDrinks.some((a: IDrinkCartItem) => {
            return a.id === item.id
        })) {
            let temp = JSON.parse(JSON.stringify(this.state.tempSelectedDrinks))
            for (let index = 0; index < temp.length; index++) {
                const element: IDrinkCartItem = temp[index];
                if (element.id === item.id) {
                    element.quantity = quantity
                    break;
                }
            }
            this.setState({ tempSelectedDrinks: temp })
        } else {
            const temp: Array<IDrinkCartItem> = this.state.tempSelectedDrinks
            temp.push({ ...item, quantity: quantity, category_id: this.state.selectedCategory })
            this.setState({ tempSelectedDrinks: temp })
        }
    }

    onRemoveDrinks = (item: IDrinkItem) => {
        this.setState({ tempSelectedDrinks: this.state.tempSelectedDrinks.filter((a: IDrinkCartItem) => a.id !== item.id) })
    }

    onCancelOption = () => {
        this.setState({ tempSelectedDrinks: [] })
    }

    onConfirmOption = () => {
        let tempCate = this.props.drinkCategories
        if (this.state.tempSelectedDrinks.some((a: IDrinkCartItem) => {
            return this.state.drinks.some((b: IDrinkItem) => a.id === b.id)
        })) {
            if (tempCate.some((a) => a.id === this.state.selectedCategory) === false) {
                tempCate.push({ id: this.state.selectedCategory, name: '', image: '' })
            }
        } else {
            tempCate = tempCate.filter(a => a.id !== this.state.selectedCategory)
        }
        //@ts-ignore
        $('#drinkModal').modal('toggle')
        let animation: Array<string> = []
        this.state.tempSelectedDrinks.forEach((a) => {
            let findItem = this.props.order.drinks.find(b => a.id === b.id)
            if (findItem && a.quantity > findItem.quantity) {
                animation.push(findItem.image)
            } else if (!findItem) {
                animation.push(a.image)
            }
        })
        this.setState({ selectedDrinks: this.state.tempSelectedDrinks, animationFood: this.state.animationFood.concat(animation) })
        this.props.addDrinksToCart(this.state.tempSelectedDrinks, tempCate)
    }
}