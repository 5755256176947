import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MenuRoutes } from "./layout/menuLayout";
import { persistor, store } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

function App() {
  var pjson = require("../package.json");
  let savedVersion = sessionStorage.getItem("version");
  if (savedVersion && savedVersion !== pjson.version) {
    persistor.purge();
    sessionStorage.setItem("version", pjson.version);
  } else if (savedVersion === null) {
    persistor.purge();
    sessionStorage.setItem("version", pjson.version);
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <div className="wrap">
            <div className="base">
              <Router basename="/">
                <MenuRoutes />
              </Router>
            </div>
          </div>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
