import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CartState, OrderState } from '../../redux'
import { IFood, IFoodQuantity } from '../../entities'
// import { User } from '../../entities';
interface SignatureOrderInterface extends RouteComponentProps {
    cart: CartState
    order: OrderState
    getSignature: (callBack: (signature: Array<IFood>) => void) => void
    signature: Array<IFoodQuantity>
    addSignature: (signature: Array<IFoodQuantity>) => void
}

interface SignatureOrderState {
    signatures: Array<IFood>
    isLoading: boolean
    animationFood: Array<string>
    isSelecting: boolean
}

export class SignatureOrderHandler extends React.Component<SignatureOrderInterface, SignatureOrderState> {



    constructor(props: SignatureOrderInterface) {
        super(props)
        this.state = {
            isLoading: true,
            signatures: [],
            animationFood: [],
            isSelecting: false
        }
    }

    componentDidMount() {
        this.props.getSignature(signatures => {
            let temp = this.props.signature
            let removedIndex: Array<number> = []
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = signatures.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        removedIndex.push(index)
                    }
                })
                if (removedIndex.length > 0) {
                    removedIndex.reverse().forEach(a => {
                        temp.splice(a, 1)
                    })
                    this.props.addSignature(Array.from(temp))
                }
            }
            this.setState({ signatures, isLoading: false })
        })
    }

    onSelectSignature = (item: IFood) => {
        if (this.props.signature.some((a: IFood) => {
            return a.id === item.id
        })) {
            let temp = [...this.props.signature]
            temp = temp.filter(a => a.id !== item.id)
            this.props.addSignature(temp)
        } else {
            const temp: Array<IFoodQuantity> = this.props.signature
            temp.push({ ...item, quantity: 1 })
            this.setState({ animationFood: this.state.animationFood.concat(item.image) })
            this.props.addSignature(temp)
        }
    }

}