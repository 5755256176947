import React from "react";
import { AddOnOrderHandler } from "./AddOnOrder.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { BsX } from "react-icons/bs";

import { ReduxState, foodsAction, cartAction } from "../../redux";
import { AppService } from "../../services";
import { IFood, IFoodQuantity } from "../../entities";
import { appAction } from "../../redux/app";
import ClipLoader from "react-spinners/ClipLoader";
import {
  ConfirmModal,
  Loading,
  MoneyComponent,
  MyScrollBar,
  AnimationFood,
  AnimationExtra,
} from "../../components";
import confirmButton from "../../assets/image/confirmButton.png";
import { getAmountFromCart } from "../../utils";
import bowl from "../../assets/image/rice-bowl.png";

class AddOnOrderScreen extends AddOnOrderHandler {
  handleBowlName = () => {
    const { cart } = this.props;
    if (!cart) return "Standard bowl";
    if (cart?.base[0]?.isBurrito) return "Burrito";
    if (cart?.proteinSize?.length) {
      switch (cart?.proteinSize[0]?.name_size) {
        case "S":
          return "Small bowl";
        case "M":
          return "Standard bowl";
        case "L":
          return "Large bowl";

        default:
          return "Standard bowl";
      }
    }
  };
  render() {
    const { foods } = this.props;
    const amount = getAmountFromCart(1).amount;
    console.log("AddOnOrderScreen -> render -> amount", amount);

    return (
      <div className="page position-relative main_right_child">
        <Loading
          loading={this.props.cart.isNonSelectedAddOn}
          style={{ right: -100 }}
        />
        <div className="switches switches_addon" style={{ top: 30, left: 80 }}>
          <div className="switch_box">
            <input
              className="switch right"
              type="checkbox"
              checked={!this.props.cart.isNonSelectedAddOn}
              onChange={this.toggleChange}
            />
          </div>
        </div>
        <div className="title_heading">
          <p className="title_chose">CHOOSE YOUR </p>
          <h2>ADD-ONS</h2>
          <p className="title_include mt-3">
            {this.props.cart.proteinSize.length > 0
              ? `${
                  this.props.cart.proteinSize[0].addons
                } included with your ${this.handleBowlName()}`
              : `You should choose protein first`}{" "}
          </p>
          <div className="price_add_on" style={{ opacity: this.state.opacity }}>
            <div className="title_price_add_on">
              <h4>extra</h4>
              <p>add-ons</p>
            </div>
            <div className="number_price_add_on">
              <MoneyComponent money={amount} type={1} />
            </div>
          </div>
        </div>
        {this.state.isLoading ? (
          <div
            className="modal-body-protein text-center"
            style={{ height: 900 }}
          >
            <ClipLoader
              css={
                window.innerWidth > 768
                  ? ` margin-top: 200px `
                  : ` margin-top: 100px `
              }
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        ) : (
          <div className="list_body_add_on content">
            {this.state.animationFood &&
              this.state.animationFood.map((a) => <AnimationFood img={a} />)}
            {this.state.animationExtra &&
              this.state.animationExtra.map((a) => <AnimationExtra img={a} />)}
            <MyScrollBar timeout={700}>
              <div
                style={{ maxHeight: 1100, display: "flex", flexWrap: "wrap" }}
              >
                {foods.map((a: IFood, b) => {
                  let selected = this.state.selectedItem.find(
                    (i: IFood) => a.id === i.id
                  );
                  return (
                    <div key={a?.id} className="box_list_add_on overflow-hidden">
                      <div
                        className={`items_add_on ${
                          a.count <= 0 ? `disable-item` : `hover-cursor`
                        } ${selected ? `active_add_on` : ``}`}
                      >
                        <input
                          type="checkbox"
                          id={`checkbox${a.id}`}
                          style={{ display: "none" }}
                        />
                        <label
                          onClick={() => {
                            if (!selected && a.count > 0) {
                              this.onSelect(a, 1);
                              this.setState({ isAddRecently: true });
                              setTimeout(() => {
                                this.setState({ isAddRecently: false });
                              }, 500);
                            }
                          }}
                        >
                          {/* {a.premium === 1 && <div className="premium">
                                                        <img src={premium}  alt=""/>
                                                    </div>} */}
                          <div className="img_add_on">
                            <img src={a.image} alt="" />
                          </div>
                          {selected && (
                            <div
                              style={{
                                zIndex: 3,
                              }}
                            >
                              <div className="field1">
                                <div className="position-relative text-center">
                                  <button
                                    type="button"
                                    id="sub"
                                    className="sub"
                                    onClick={(e) => {
                                      if (selected && selected.quantity > 1) {
                                        this.onSelect(a, selected.quantity - 1);
                                      } else {
                                        if (
                                          this.state.isAddRecently === false
                                        ) {
                                          this.onRemove(a);
                                        }
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    readOnly
                                    type="text"
                                    value={selected.quantity}
                                  />
                                  <button
                                    type="button"
                                    id="add"
                                    className="add"
                                    onClick={(e) => {
                                      if (
                                        selected &&
                                        selected.quantity < a.count
                                      ) {
                                        this.onSelect(a, selected.quantity + 1);
                                      } else if (
                                        selected &&
                                        selected.quantity >= a.count
                                      ) {
                                        // @ts-ignore
                                        $("#outOfStockAlertModal").modal(
                                          "toggle"
                                        );
                                        $(".modal-backdrop").css(
                                          "z-index",
                                          1051
                                        );
                                        $("#outOfStockAlertModal").on(
                                          "hidden.bs.modal",
                                          function () {
                                            $(".modal-backdrop").css(
                                              "z-index",
                                              ""
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* {(index + 1) > amountAddonFree && <p className="p2"><MoneyComponent money={a.price} type={1} /></p>} */}
                          <div className="text_add_on">
                            <h4>{a.name}</h4>
                          </div>
                        </label>
                      </div>
                    </div>
                  );
                })}

                <div className="w-100 text-center">
                  <button className="confirm-next-button" onClick={this.onNext}>
                    <img src={confirmButton} alt="" />
                  </button>
                </div>
              </div>
            </MyScrollBar>
          </div>
        )}
        <ConfirmModal
          content="Are you sure you didn't choose any addon?"
          title="Confirm"
          id="addonConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.onConfirmModal}
        />
        <ConfirmModal
          content=""
          title=""
          id="freeAddonConfirm"
          cancelText=""
          className="confirm-modal"
          onSubmit={this.onConfirmFreeAddonModal}
        >
          <div
            className="cancel-icon-addon"
            onClick={() => {
              //@ts-ignore
              $("#freeAddonConfirm").modal("toggle");
            }}
          >
            <BsX />
          </div>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <img src={bowl} alt="bowl" width="30%" height="30%" />
          </div>
          <p className="text-center ml-0 title">
            Are you sure you want to
            <br />
            proceed without selecting all your <br />
            free add-ons ?<br />
          </p>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-footer-marinade"
              onClick={() => {
                //@ts-ignore
                $("#freeAddonConfirm").modal("toggle");
              }}
            >
              No
            </button>
            <span className="row-modal-footer row-add-on"></span>
            <button
              type="button"
              className="btn btn-footer-marinade"
              onClick={this.onConfirmFreeAddonModal}
            >
              Yes
            </button>
          </div>
        </ConfirmModal>
        <ConfirmModal
          content="Your included add-ons has been changed"
          title="Confirm"
          id="freeAddonChangedConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.onConfirmFreeAddonChangedModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    foods: state.foods.addonFood,
    cart: state.cart,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getAddOn = async (idBase: string, callBack: () => void) => {
    const service = new AppService();
    service
      .getAddOn(idBase)
      .then((response) => {
        dispatch(foodsAction.addAddOnFoods(response.addons));
        callBack();
      })
      .catch(console.log);
  };

  const addAddonToCart = (
    item: Array<IFoodQuantity>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addAddon(item, nonSelected));
    callBack();
  };

  const setNavigation = (
    backNavigation: any,
    nextNavigation: any,
    mainRightClassName: string
  ) => {
    dispatch(appAction.addNavigation(backNavigation, nextNavigation));
    dispatch(appAction.addMainRightClassName(mainRightClassName));
  };
  const resetSavedBowl = () => {
    dispatch(cartAction.addBowlName("", -1));
    dispatch(cartAction.addBowlSavedStatus(false));
  };

  return {
    getAddOn,
    addAddonToCart,
    setNavigation,
    resetSavedBowl,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(AddOnOrderScreen);
// export default (AddOnOrderScreen)
