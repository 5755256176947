import { combineReducers } from 'redux';


import { cart, CartState } from "./cart";
import { foods, FoodState } from "./food";
import { app, AppState } from './app'
import { order, OrderState } from './order'

export interface ReduxState {
    app: AppState,
    cart: CartState,
    foods: FoodState,
    order: OrderState
}

const reducers = combineReducers({
    app,
    cart,
    foods,
    order
});

export default reducers
