import * as React from 'react'
import thumb from "../../assets/image/Ellipse29.png";
import arrowUp from "../../assets/image/arrow-up.png";
import arrowDown from "../../assets/image/arrow-down-white.png";

import { Scrollbars } from 'react-custom-scrollbars';
interface CustomScrollProps {
    children: any
    ref?: any
    timeout?: number
    scrollToTopStyle?: any
    scrollToBottomStyle?: any
    classNameScrollToTop?: string
    classNameScrollToBottom?: string
    scrollToTopVisible?: boolean
    scrollToBottomVisible?: boolean
    alwaysShowScrollToBottom?: boolean
    isHide?: boolean
}
const renderThumb = ({ style, ...props }: any) => {
    const thumbStyle = {
        borderRadius: 6,

    };
    return <div style={{ ...style, ...thumbStyle }} {...props} className="thumb-vertical">
        <img src={thumb} className="custom-thumb" alt="" />
    </div>;
};
const renderTrack = ({ style, ...props }: any) => {
    const viewStyle = {
        height: '100%',
        right: 14,
    };
    return <div style={{ ...style, ...viewStyle }} className="track-vertical" {...props} />;
};
const renderHorizontalTrack = ({ style, ...props }: any) => {
    const viewStyle = {
        display: 'none'
    };
    return <div style={{ ...style, ...viewStyle }} className="thumb-horizontal" {...props} />;
}
const renderView = ({ style, ...props }: any) => {
    const viewStyle = {
        paddingRight: window.innerWidth > 768 ? 40 : 0
    };
    return <div style={{ ...style, ...viewStyle }} {...props} />;
};

export const MyScrollBar = (data: CustomScrollProps) => {
    let {
        children,
        scrollToTopStyle,
        classNameScrollToTop,
        scrollToBottomStyle,
        alwaysShowScrollToBottom,
        scrollToTopVisible,
        classNameScrollToBottom,
        scrollToBottomVisible,
        isHide=true,
        timeout,
    } = data;

    let refScrollBar: React.RefObject<Scrollbars> = React.createRef();
    let [visibleScrollToTop, setVisible] = React.useState(false)
    let [visibleScrollToBottom, setVisibleScrollToBottom] = React.useState(alwaysShowScrollToBottom ? alwaysShowScrollToBottom : false)
    let [firstTimeScroll, setFirstTimeScroll] = React.useState(true)
    let [count, setCount] = React.useState(0);

    React.useEffect(() => {
        let height = refScrollBar.current?.getScrollHeight()
        if (!!height && height > 0 && firstTimeScroll === true && !!refScrollBar.current) {
            setFirstTimeScroll(false)
            const scrollBar = refScrollBar.current
            scrollBar?.scrollToBottom()
            setTimeout(() => {
                //@ts-ignore
                if (scrollBar && scrollBar.view) {
                    //@ts-ignore
                    scrollBar?.view.scroll({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            }, timeout ? timeout : 300)

        } else if (firstTimeScroll && count < 10) {
            setTimeout(() => {
                setCount(count + 1)
            }, 100)
        }
    }, [refScrollBar, firstTimeScroll, count, timeout])

    const onScroll = (event: any) => {
        if (event.srcElement.scrollTop > 100) {
            setVisible(true)
            setVisibleScrollToBottom(false)
        } else {
            setVisible(false)
            setVisibleScrollToBottom(true)
        }
    }

    const scrollToTop = () => {
        if (!refScrollBar || !refScrollBar.current) {
            return;
        } else {
            //@ts-ignore
            refScrollBar.current.view.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const scrollToBottom = () => {
        if (!refScrollBar || !refScrollBar.current) {
            return;
        } else {
            //@ts-ignore
            refScrollBar.current.view.scroll({
                top: window.innerHeight / 2,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Scrollbars
            renderThumbVertical={renderThumb}
            renderTrackVertical={renderTrack}
            renderTrackHorizontal={renderHorizontalTrack}
            autoHeightMin={1000}
            autoHide={isHide}
            renderView={renderView}
            ref={refScrollBar}
            className="position-relative my-custom-scrollbar" onScroll={onScroll}
        >
            {children}
            {scrollToTopVisible && 
            <div 
                onClick={() => {scrollToTop()}} 
                className={`scroll-to-top ${classNameScrollToTop}`} 
                style={visibleScrollToTop ? { opacity: 1, ...scrollToTopStyle } : { opacity: 0, ...scrollToTopStyle }}
            >
                <img src={arrowUp} alt="" />
            </div>
            }
            {scrollToBottomVisible && window.innerWidth < 769 &&
                <div 
                    onClick={() => { scrollToBottom()}} 
                    className={`scroll-to-top ${classNameScrollToBottom}`}
                    style={visibleScrollToBottom ? { opacity: 1, ...scrollToBottomStyle } : { opacity: 0, ...scrollToBottomStyle }}>
                    <img src={arrowDown} alt="" />
                </div>
            }
        </Scrollbars >
    )
}