import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CartState, OrderState } from '../../redux'
import { ICategoriesItem, IDessertItem, IDessertCartItem } from '../../entities'
// import { User } from '../../entities';
interface DessertOrderInterface extends RouteComponentProps {
    cart: CartState
    order: OrderState
    getDessert: (callBack: (drinks: Array<IDessertItem>) => void) => void
    addDessertToCart: (dessert: Array<IDessertCartItem>, categories: Array<ICategoriesItem>) => void
    desserts: Array<IDessertCartItem>
    dessertCategories: Array<ICategoriesItem>
}

interface DessertOrderState {
    categories: Array<ICategoriesItem>
    isLoading: boolean
    isLoadingDessert: boolean
    selectedOptionName: string
    selectedCategory: number
    desserts: Array<IDessertItem>
    tempSelectedDessert: Array<IDessertCartItem>
    selectedDessert: Array<IDessertCartItem>
    animationFood: Array<string>
    isSelecting: boolean
}
export class DessertOrderHandler extends React.Component<DessertOrderInterface, DessertOrderState> {



    constructor(props: DessertOrderInterface) {
        super(props)
        this.state = {
            categories: [],
            isLoading: true,
            isLoadingDessert: false,
            selectedOptionName: '',
            selectedCategory: -1,
            desserts: [],
            tempSelectedDessert: [],
            selectedDessert: [],
            animationFood: [],
            isSelecting: false
        }
    }

    componentDidMount() {
        // this.props.getCategories((categories: Array<ICategoriesItem>) => {
        //     this.setState({ categories, isLoading: false })
        // })
        this.setState({ isLoading: true })
        this.props.getDessert((desserts => {
            let temp = this.props.desserts
            let tempCategories = this.props.dessertCategories
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = desserts.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        object.splice(index, 1)
                        if (object.some(c => c.category_id === this.state.selectedCategory) === false) {
                            let indexCate = tempCategories.findIndex(c => c.id === this.state.selectedCategory)
                            if (indexCate !== -1) {
                                tempCategories.splice(indexCate, 1)
                            }
                        }
                    }
                })
                this.setState({ selectedDessert: temp, tempSelectedDessert: temp })
                this.props.addDessertToCart(temp, tempCategories)
            }
            this.setState({ desserts, isLoading: false })
        }))
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#dessertModal').modal('hide')
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
    }

    onSelectCategory = (item: ICategoriesItem) => {
        //@ts-ignore
        $('#dessertModal').modal('toggle')
        this.setState({ selectedCategory: item.id, selectedOptionName: item.name, isLoadingDessert: true, tempSelectedDessert: [...this.props.desserts] })
        this.props.getDessert((desserts => {
            let temp = this.props.desserts
            let tempCategories = this.props.dessertCategories
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = desserts.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        object.splice(index, 1)
                        if (object.some(c => c.category_id === this.state.selectedCategory) === false) {
                            let indexCate = tempCategories.findIndex(c => c.id === this.state.selectedCategory)
                            if (indexCate !== -1) {
                                tempCategories.splice(indexCate, 1)
                            }
                        }
                    }
                })
                this.setState({ selectedDessert: temp, tempSelectedDessert: temp })
                this.props.addDessertToCart(temp, tempCategories)
            }
            this.setState({ desserts, isLoadingDessert: false })
        }))
    }

    onAddDessert = (item: IDessertItem, quantity: number) => {
        if (this.state.tempSelectedDessert.some((a: IDessertCartItem) => {
            return a.id === item.id
        })) {
            let temp = JSON.parse(JSON.stringify(this.state.tempSelectedDessert))
            for (let index = 0; index < temp.length; index++) {
                const element: IDessertCartItem = temp[index];
                if (element.id === item.id) {
                    element.quantity = quantity
                }
            }
            this.setState({ tempSelectedDessert: temp })
            this.props.addDessertToCart(temp, [])
        } else {
            const temp: Array<IDessertCartItem> = this.state.tempSelectedDessert
            temp.push({ ...item, quantity: quantity, category_id: this.state.selectedCategory })
            this.setState({ tempSelectedDessert: temp, animationFood: this.state.animationFood.concat(item.image) })
            this.props.addDessertToCart(temp, [])
        }
    }

    onRemoveDessert = (item: IDessertItem) => {
        this.setState({ tempSelectedDessert: this.state.tempSelectedDessert.filter((a: IDessertCartItem) => a.id !== item.id) })
        this.props.addDessertToCart(this.state.tempSelectedDessert.filter((a: IDessertCartItem) => a.id !== item.id), [])
    }


    onConfirmOption = () => {
        let tempCate = this.props.dessertCategories
        if (this.state.tempSelectedDessert.some((a: IDessertCartItem) => {
            return this.state.desserts.some((b: IDessertItem) => a.id === b.id)
        })) {
            if (tempCate.some((a) => a.id === this.state.selectedCategory) === false) {
                tempCate.push({ id: this.state.selectedCategory, name: '', image: '' })
            }
        } else {
            tempCate = tempCate.filter(a => a.id !== this.state.selectedCategory)
        }
        //@ts-ignore
        $('#dessertModal').modal('toggle')
        this.props.addDessertToCart(this.state.tempSelectedDessert, tempCate)
    }
}