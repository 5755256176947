import React from 'react'
import { SignatureOrderHandler } from './SignatureOrder.logic';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { ReduxState, orderAction } from '../../redux';
import { MyScrollBar, AnimationFood } from '../../components';
import ClipLoader from "react-spinners/ClipLoader";
import { AppService } from '../../services';
import { IFood, IFoodQuantity } from '../../entities';

class SignatureOrderScreen extends SignatureOrderHandler {


    render() {
        return (

            <>
                <div className="page position-relative main_right_child main_right_drink">
                    <div className="list_food_main protein_main content">
                        <MyScrollBar>
                            {this.state.isLoading === true ? <div className="container text-center"> <ClipLoader
                                css={window.innerWidth > 768 ? ` margin-top: 200px ` : ` margin-top: 100px `}
                                size={window.innerWidth > 768 ? 150 : 70}
                                color={"#D31710"}
                                loading={true}
                            /></div> : <div className="list_food list_signature">
                                    {this.state.signatures.map((a: IFood, b) => {
                                        return (
                                            <div key={b} className={`protein_list signature_item ${a.count <= 0 ? `disable-item` : `hover-cursor`} ${this.props.signature.some((d) => a.id === d.id) ? `active_protein` : ``}`} onClick={() => a.count !== 0 && this.onSelectSignature(a)} >
                                                <div className="img_box text-center" style={{ height: 240, width: '100%' }}>
                                                    <img src={a.image} style={{ width: '100%' }} alt=""/>
                                                </div>
                                                <div className="text_protein pt-3">
                                                    <h4>{a.name}</h4>
                                                </div>

                                            </div>
                                        )
                                    })}
                                    {this.state.animationFood && this.state.animationFood.map(a =>
                                        <AnimationFood img={a} />)}
                                </div>}
                        </MyScrollBar>
                    </div>
                </div>
            </>

        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        cart: state.cart,
        order: state.order,
        signature: state.order.signature
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    const getSignature = (callBack: (signature: Array<IFood>) => void) => {
        const service = new AppService()
        service.getSignature()
            .then((response) => {
                callBack(response.food)
            })
            .catch(console.log)
    }

    const addSignature = (signature: Array<IFoodQuantity>) => {
        dispatch(orderAction.addSignature(signature))
    }

    return {
        getSignature,
        addSignature
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(SignatureOrderScreen)
