import React from "react";
import { SearchResultHandler } from "./SearchResult.logic";
import { connect } from "react-redux";
import { ReduxState } from "../../redux";
import back from "../../assets/image/back.png";
import header from "../../assets/image/header.png";
import open from "../../assets/image/orderList/open.png";
import close from "../../assets/image/orderList/close.png";
import couponIcon from "../../assets/image/orderList/coupon-icon.png";
import logo_main from "../../assets/image/logo_main.png";
import { MoneyComponent, MyScrollBar, Loading } from "../../components";
import { ISearchOrderItem, ISearchItem } from "../../entities";
import ClipLoader from "react-spinners/ClipLoader";
import { COUPON_TYPE } from "../../constants";
import topShadow from "../../assets/image/orderList/topShadow.png";
import botShadow from "../../assets/image/orderList/botShadow.png";
import checkout from "../../assets/image/orderList/checkoutIcon.png";
import "react-datepicker/dist/react-datepicker.css";
import { truncateString } from "../../utils";

interface BowlDetailProps {
  item: ISearchOrderItem;
  bowlImage: string;
  index: number;
  calculatePrice: (bowl: Array<ISearchItem>) => number;
}

interface DrinksProps {
  item: ISearchItem;
  index: number;
}

const RenderBowlDetail = (data: BowlDetailProps) => {
  const { item, index, calculatePrice } = data;

  const [isCollapse, setCollapse] = React.useState(false);
  let hasNoNori = false;
  return (
    <div className={`order_list_content ${index > 0 ? `mt-5` : ``} mb-4 `}>
      <div className="row my-0 border-top border-bottom order-content position-relative">
        <div className="col-6 my-auto bowl-header">
          <div className=" row my-0 pl-4">
            <div className="text-center image-box" style={{ width: 60 }}>
              <img src="https://poke-food-api.adamo.tech/uploads/1589171575-food.png" alt=""/>
            </div>
            <div className="ml-3 my-auto">
              <p
                className="mb-0 bowl-title"
                style={item.bowlName ? {} : { marginTop: 0 }}
              >
                BOWL {index + 1}
              </p>
              {item.name && (
                <p className="mb-0 d-flex font-regular bowl-name-box">
                  (<p className="bowl-name">{truncateString(item.name, 20)}</p>)
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-3 row mx-0">
          {item.bowl_quantity > 0 && (
            <p className="size quantity" style={{ marginTop: 35 }}>
              x {item.bowl_quantity}
            </p>
          )}
        </div>
        <div className="col-3 my-auto text-right money-box">
          <div className="total_price my-auto">
            <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
              <MoneyComponent type={2} money={calculatePrice(item.items)} />
            </p>
          </div>
        </div>

        <div
          className="collapse-button"
          onClick={() => setCollapse(!isCollapse)}
        >
          <img src={isCollapse === false ? close : open} alt=""/>
        </div>
      </div>
      {isCollapse && (
        <>
          <div className="bowl-item position-relative">
            <img src={topShadow} className="top-shadow" alt=""/>
            {item.items.map((a: ISearchItem, index: number) => {
              let showNori = false;
              if (
                ["Base"].includes(a.menu_name) &&
                (a.id === 471 || a.id === 473 || a.id === 474) &&
                item.is_nori === 1 &&
                hasNoNori === false
              ) {
                hasNoNori = true;
                showNori = true;
              }
              return (
                <div className="row" key={index}>
                  <div className="col-6 food-name">
                    <img src={a.image} alt=""/>
                    {a.name}
                    {["Add-ons", "Sauces"].includes(a.menu_name) &&
                      Number(a.total_price) !== 0 && (
                        <span className="size">Extra</span>
                      )}
                    {showNori && <span className="size">(No Nori)</span>}
                    {a.extra && <span className="size">Extra</span>}
                  </div>
                  <div className="col-3">
                    {a.quantity > 1 && (
                      <span className="size quantity">x {a.quantity}</span>
                    )}
                  </div>
                  <div className="col-3 money-box">
                    {Number(a.total_price) > 0 && (
                      <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
                        <MoneyComponent type={2} money={a.total_price} />
                      </p>
                    )}
                    <div className="total_price my-auto"></div>
                  </div>
                </div>
              );
            })}
          </div>
          <img src={botShadow} className="w-100 position-absolute t-0" alt=""/>
        </>
      )}
    </div>
  );
};

const RenderDrink = (data: DrinksProps) => {
  const { item } = data;
  return (
    <div className="mb-4 order_list_content">
      <div className="row my-0 position-relative">
        <div className="col-6 my-auto bowl-header">
          <div className=" row my-0 pl-4">
            <div className="text-center" style={{ width: 60 }}>
              <img src={item.image} alt=""/>
            </div>
            <div className="ml-3 my-auto">
              <p className="mb-0 bowl-title">{item.name}</p>
            </div>
          </div>
        </div>

        <div className="col-3 row mx-0">
          {item.quantity > 1 && (
            <p className="size quantity">x {item.quantity}</p>
          )}
        </div>
        <div className="col-3 my-auto text-right money-box">
          <div className="total_price my-auto">
            <p className="p2 mb-0  d-flex flex-row-reverse custom-price">
              <MoneyComponent
                type={2}
                money={item.quantity * Number(item.price)}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

class SearchResultScreen extends SearchResultHandler {
  render() {
    const { searchOrder } = this.props;
    return (
      <div className="main_menu">
        <Loading
          loading={this.state.isLoading}
          style={{ zIndex: 3, position: "fixed" }}
        >
          <div className="container text-center loading-container">
            {" "}
            <ClipLoader
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        </Loading>
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo">
            <img src={logo_main} alt="Logo" />
          </div>
          <div className="d-block flex-nowrap order_list_heading order_list_search_result">
            <div onClick={this.onBack} className="btn btn-back">
              <img src={back} alt="" />
            </div>
            <h2 className="build">MY ORDER</h2>
          </div>
        </section>
        <section className="base_main main_bowl_summary main_order_list">
          <div
            className="box_bowl_summary content box_order_list"
            style={{ height: 1200, maxHeight: "unset" }}
          >
            <MyScrollBar
              scrollToTopStyle={
                window.innerWidth > 768
                  ? { bottom: 280, right: 50 }
                  : { right: -20 }
              }
            >
              <div className="bowl_summary_base bowl_summary_protein bowl_order_list">
                {!!searchOrder.bowls && searchOrder.bowls.length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="list_summary_base">
                        <div className="title_summary_base">
                          <div className="title_left pl-4">
                            <h4>BOWL</h4>
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          {searchOrder.bowls.map((a: ISearchOrderItem, b) => {
                            return (
                              <RenderBowlDetail
                                item={a}
                                calculatePrice={this.getBowlAmount}
                                index={b}
                                bowlImage={""}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!!searchOrder.drinks && searchOrder.drinks.length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="list_summary_base">
                        <div className="title_summary_base">
                          <div className="title_left pl-4">
                            <h4>DRINKS</h4>
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          {searchOrder.drinks.map((a: ISearchItem, b) => {
                            return <RenderDrink item={a} index={b} />;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!!searchOrder.dessert && searchOrder.dessert.length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="list_summary_base">
                        <div className="title_summary_base">
                          <div className="title_left pl-4">
                            <h4>DESSERTS</h4>
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          {searchOrder.dessert.map((a: ISearchItem, b) => {
                            return <RenderDrink item={a} index={b} />;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!!searchOrder.signature && searchOrder.signature.length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="list_summary_base">
                        <div className="title_summary_base">
                          <div className="title_left pl-4">
                            <h4>SIGNATURE</h4>
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          {searchOrder.signature.map((a: ISearchItem, b) => {
                            return <RenderDrink item={a} index={b} />;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </MyScrollBar>
          </div>
          <div className="w-100 border-top checkout-container">
            <div className="total-responsive">
              <p className="total-title">Total:</p>
              {!!searchOrder.coupon_code && (
                <p className="price price_discount">
                  <MoneyComponent money={this.getTotalAmount().originAmount} />
                </p>
              )}
              <p className="price">
                <MoneyComponent money={this.getTotalAmount().totalAmount} />
              </p>
            </div>
            <div className="row py-3 mx-0">
              <div className="col-4 flex-fill my-auto coupon-container">
                {!!searchOrder.coupon_code && (
                  <div className="">
                    <div className="coupon_box">
                      <img src={couponIcon} alt="coupon"/>
                      <input
                        type="text"
                        placeholder="ENTER COUPON"
                        value={searchOrder.coupon_code.code}
                        readOnly={!!searchOrder.coupon_code}
                        className={"has_coupon"}
                      />

                      <>
                        <button className="coupon_value">
                          {searchOrder.coupon_code.type ===
                            COUPON_TYPE.DISCOUNT_MONEY
                            ? `${searchOrder.coupon_code.discount} OFF`
                            : `${Number(searchOrder.coupon_code.percent)}% OFF`}
                        </button>
                      </>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-4 text-center border-left border-right total-box px-0">
                <p className="total-title">Total:</p>
                {!!searchOrder.coupon_code && (
                  <p className="price price_discount">
                    <MoneyComponent
                      money={this.getTotalAmount().originAmount}
                    />
                  </p>
                )}
                <p className="price">
                  <MoneyComponent money={this.getTotalAmount().totalAmount} />
                </p>
              </div>
            </div>
            <div
              className={`button_bowl_summary checkout_button `}
              onClick={this.onPayment}
            >
              <p>
                Proceed to checkout <img src={checkout} alt=""/>
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    searchOrder: state.order.searchResult,
  };
};

const mapDispatchToProp = () => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProp)(SearchResultScreen);
