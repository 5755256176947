import {
    ADD_NAVIGATION, ADD_MAIN_RIGHT_CLASSNAME, ADD_BOWL_IMAGE, ADD_DESSERT_IMAGE,
    ADD_DRINKS_IMAGE, OPENING_CHECKING, ADD_SIGNATURE_IMAGE, RESET, UPDATE_SESSION, FIRST_TIME_ACCESS, SET_VIDEO_INTRO, ADD_BOWL_COLOR
} from "./types";


export interface AppState {
    backNavigation: any
    nextNavigation: any
    mainRightClassName: string
    bowlImage: string
    drinkImage: string
    dessertImage: string,
    opening: boolean,
    openingMessage: string,
    lastCheck: string
    signatureImage: string,
    firstTimeAccess: boolean,
    time: string
    videoIntro: string
    bowlColor: string
}

const initialState: AppState = {
    nextNavigation: null,
    backNavigation: '/',
    mainRightClassName: '',
    bowlImage: '',
    drinkImage: '',
    dessertImage: '',
    opening: false,
    openingMessage: '',
    lastCheck: '',
    signatureImage: '',
    firstTimeAccess: true,
    time: '',
    videoIntro: '',
    bowlColor: ''
}

function app(state = Object.assign({}, initialState), action: any) {
    let { type, payload } = action
    switch (type) {
        case ADD_NAVIGATION:
            return Object.assign({}, state, payload)
        case ADD_MAIN_RIGHT_CLASSNAME:
            return Object.assign({}, state, payload)
        case ADD_BOWL_IMAGE:
            return Object.assign({}, state, payload)
        case OPENING_CHECKING:
            return Object.assign({}, state, payload)
        case UPDATE_SESSION:
            return Object.assign({}, state, payload)
        case ADD_DRINKS_IMAGE:
            return Object.assign({}, state, payload)
        case ADD_SIGNATURE_IMAGE:
            return Object.assign({}, state, payload)
        case ADD_DESSERT_IMAGE:
            return Object.assign({}, state, payload)
        case FIRST_TIME_ACCESS:
            return Object.assign({}, state, payload)
        case SET_VIDEO_INTRO:
            return Object.assign({}, state, payload)
        case ADD_BOWL_COLOR:
            return Object.assign({}, state, payload)
        case RESET:
            return Object.assign({}, state, {
                nextNavigation: null,
                backNavigation: '/',
                mainRightClassName: '',
                bowlImage: '',
                drinkImage: '',
                dessertImage: '',
                opening: false,
                openingMessage: '',
                lastCheck: '',
                signatureImage: '',
                firstTimeAccess: true,
                time: '',
                videoIntro: '',
                bowlColor: ''
            })
        default:
            return state
    }
}

export { app };