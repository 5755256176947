export interface IErrorDataItem {
  phone: Array<string>;
  clientName: Array<string>;
  bowls: Array<string>;
  coupon: Array<string>;
  type: Array<string>;
  items: Array<string>;
  bowlQuantity: Array<string>;
  payment_method: Array<string>;
}

export interface IErrorData {
  errors: IErrorDataItem;
}

export interface IError {
  code: number;
  message: string;
  data: IErrorData;
}

export class Error {
  code: number;
  message: string;
  data: IErrorData;

  constructor(e: IError) {
    this.code = e.code;
    this.message = e.message;
    this.data = e.data;
  }
}
