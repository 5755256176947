
export const ADD_NAVIGATION = 'APP/ADD_NAVIGATION'
export const ADD_MAIN_RIGHT_CLASSNAME = 'APP/ADD_MAIN_RIGHT_CLASSNAME'
export const ADD_BOWL_IMAGE = 'APP/ADD_BOWL_IMAGE'
export const ADD_DRINKS_IMAGE = 'APP/ADD_DRINKS_IMAGE'
export const ADD_DESSERT_IMAGE = 'APP/ADD_DESSERT_IMAGE'
export const OPENING_CHECKING = 'APP/OPENING_CHECKING'
export const ADD_SIGNATURE_IMAGE = 'APP/ADD_SIGNATURE_IMAGE'
export const UPDATE_SESSION = 'APP/UPDATE_SESSION'
export const FIRST_TIME_ACCESS = 'APP/FIRST_TIME_ACCESS'
export const SET_VIDEO_INTRO = 'APP/SET_VIDEO_INTRO'
export const ADD_BOWL_COLOR = 'APP/ADD_BOWL_COLOR'
export const RESET = 'APP/RESET'
