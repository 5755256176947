import { store } from "../redux"
import { IFoodQuantity } from "../entities"
import { NumberFlavourBoosterFree } from "../constants"

export function getCensoredPhone(phone?: string) {
    if (phone) {
        return `***${phone.substr(phone.length - 4)}`
    } else {
        return ``
    }
}

export function getAmountFromCart(type: number) {
    //Add on
    let addon = store.getState().cart.addon
    let proteinSize = store.getState().cart.proteinSize
    let flavourBooster = store.getState().cart.flavourBooster
    let amount = 0
    let quantity = 0
    if (type === 1) {
        if (addon.length > 0) {
            let amountAddonFree = proteinSize.length > 0 ? proteinSize[0].addons : 0
            addon.forEach((a: IFoodQuantity, b: number) => {
                if (a.premium === 1) {
                    quantity -= a.quantity
                    amount += Number(a.price) * a.quantity
                } else if (quantity < amountAddonFree && quantity + a.quantity > amountAddonFree) {
                    amount += Number(a.price) * (a.quantity + quantity - amountAddonFree)
                } else if (quantity < amountAddonFree) {

                } else {
                    amount += Number(a.price) * a.quantity
                }
                quantity = quantity + a.quantity
            })
            return {
                amount, quantity
            }
        } else {
            return {
                amount, quantity
            }
        }
    }
    //Flavour Booster
    else if (type === 2) {
        if (flavourBooster.length > 0) {
            let amountFree = NumberFlavourBoosterFree
            flavourBooster.forEach((a: IFoodQuantity, b: number) => {
                if (a.premium === 1) {
                    quantity -= a.quantity
                    amount += Number(a.price) * a.quantity
                } else if (quantity < amountFree && quantity + a.quantity > amountFree) {
                    amount += Number(a.price) * (a.quantity + quantity - amountFree)
                } else if (quantity < amountFree) {

                } else {
                    amount += Number(a.price) * a.quantity
                }
                quantity = quantity + a.quantity
            })
            return {
                amount, quantity
            }
        } else {
            return {
                amount, quantity
            }
        }
    } else {
        return {
            amount, quantity
        }
    }
}

export function truncateString(str: string, n: number = 13) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};