import React from 'react'
import { SuccessOrderHandler } from './SuccessOrder.logic';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { ReduxState } from '../../redux';
import header from '../../assets/image/header.png'
import logo_main from '../../assets/image/logo_main.png'
import success from '../../assets/image/payment/success.png'
import { FaCheckCircle } from "react-icons/fa";

class SuccessOrderScreen extends SuccessOrderHandler {
    render() {
        return (
            <div className="main_menu">
                <section className="header">
                    <img src={header} alt="Header" className="img-fluid" />
                    <div className="main_logo">
                        <img src={logo_main} alt="Logo" />
                    </div>
                </section>
                <section className="base_main main_bowl_summary order_list_main bowl_success">
                    <div className="box_order_success text-center position-relative">
                        <p><FaCheckCircle style={{ lineHeight: 32, color: '#1AA038', marginBottom: -4, marginRight: 7 }} />Success!</p>
                        <p className="sub-text">Your Order is being prepaired.</p>
                        <img src={success} style={{ marginTop: 0 }} alt=""/>
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        order: state.order
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProp)(SuccessOrderScreen)