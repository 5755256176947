import * as React from "react";
import { useSpring, animated } from "react-spring";

export const AnimationExtra = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, toggle] = React.useState(true);
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 800 },
  });
  return (
    <animated.div
      style={{
        position: "fixed",
        zIndex: 3,
        transform: x.interpolate({
          range: [0, 0.25, 0.5, 0.75, 1],
          output: [
            `scale(1)`,
            `scale(0.75)`,
            `scale(0.5)`,
            `scale(0.2)`,
            `scale(0.2)`,
          ],
        }),
        top: x.interpolate({
          range: [0, 0.3, 0.5, 0.6, 0.8, 1],
          output: [
            "60%",
            "40%",
            "30%",
            "25%",
            "16%",
            "13%",
            // "10%",
            // "13%",
            // "16%",
            // "50%",
            // "68%",
            // "70%",
          ],
        }),
        right: x.interpolate({
          range: [0, 0.3, 0.5, 0.6, 0.8, 1],
          output: ["40%", "25%", "15%", "10%", "5%", "0"],
        }),
        opacity: x.interpolate({
          range: [0, 0.1, 0.5, 0.8, 1],
          output: [1, 0.9, 0.7, 0.5, 0],
        }),
      }}
    >
      <img src={data.img} alt="" style={{ width: 90 }} />
    </animated.div>
  );
};
