import React from "react";
import { BowlSummaryHandler } from "./BowlSummary.logic";
import {
  Footer,
  Header,
  Loading,
  ConfirmModal,
  LogoutModal,
  MoneyComponent,
  MyScrollBar,
  BowlNoteModalIcon,
} from "../../components";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { BsX } from "react-icons/bs";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ReduxState, cartAction, orderAction, CartState } from "../../redux";
import {
  IFood,
  IFoodSize,
  IFoodQuantity,
  Error,
  IOrder,
  IOutOfStockCheckQuantity,
} from "../../entities";
import { appAction } from "../../redux/app";
import { AppService } from "../../services";
import PulseLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";
import bowlSummary from "../../assets/image/bowl-summary.png";
import sushiBurrito from "../../assets/image/Sushi Burrito_App1.png";
import arrowDown from "../../assets/image/arrow-down.png";
import arrowUp from "../../assets/image/arrowUp.png";
import sizeButton from "../../assets/image/sizeButton.png";
import sizeShadow from "../../assets/image/sizeShadow.png";
import itsabowl from "../../assets/image/itsabowl.png";
import itsaburrito from "../../assets/image/itsaburrito.png";
import summaryShadow from "../../assets/image/summaryShadow.png";
import summaryBottomShadow from "../../assets/image/summaryBottomShadow.png";
import listViewIcon from "../../assets/image/listView.png";
import { phoneRegex } from "../../constants";
import { Stage, Layer, Text, Image, Group } from "react-konva";
import useImage from "use-image";
import { Group as KonvaGroup } from "konva/types/Group";
import minius from "../../assets/image/orderList/minius_white.png";
import plus from "../../assets/image/orderList/plus_white.png";
import summaryEditButton from "../../assets/image/summaryEditButton.png";
import summaryConfirmButton from "../../assets/image/summaryConfirmButton.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAmountFromCart, truncateString } from "../../utils";
import DowloadImage from "../../assets/image/download.png";
import AddImage from "../../assets/image/add.png";

const scrollListItemsIntoView = () => {
  const listItems = document.getElementById("item-list-container-id");
  if (listItems) {
    listItems?.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollCanvasIntoView = () => {
  const canvasEl = document.getElementsByClassName("bowl-summary-canvas")?.[0];
  if (canvasEl) {
    console.log("canvasEl: ", canvasEl);
    canvasEl?.scrollIntoView({ behavior: "smooth" });
  }
};

const Canvas: React.FC<any> = ({
  canvasSize,
  bowlImage,
  cart,
}: {
  canvasSize: number;
  bowlImage: any;
  cart: CartState;
}) => {
  const marinade = cart.marinades.concat(cart.mixin);
  const [image] = useImage(cart.base[0]?.isBurrito ? sushiBurrito : bowlSummary);
  const imageHeight = 745;
  const imageWidth = 776;
  const centerPoint = 237;
  const fullPixel = cart.base[0]?.isBurrito ? 606 / 0.74 : 606;
  const decreaseSize = cart.base[0]?.isBurrito ? 0.64 : 1;
  const halfOfBowlImage = 206;
  const imageTrueHeight = ((canvasSize * 0.7) / imageWidth) * imageHeight * decreaseSize;
  const imageTrueWidth = canvasSize * 0.7 * decreaseSize;
  const yPositionChange = cart.base[0]?.isBurrito ? 40 : 0;

  const [button] = useImage(sizeButton);
  const [buttonShadow] = useImage(sizeShadow);
  const [arrowDownIcon] = useImage(arrowDown);
  const [arrowUpIcon] = useImage(arrowUp);
  const [imageProtein] = useImage(
    cart.proteinSize.length > 0 ? cart.proteinSize[0].image : ""
  );
  const [imageExtra] = useImage(
    cart.extraProteins.length > 0 ? cart.extraProteins[0].image : ""
  );
  const [imageFirstMarinade] = useImage(
    marinade.length > 0 ? marinade[0].image : ""
  );
  const [imageSecondMarinade] = useImage(
    marinade.length > 1 ? marinade[1].image : ""
  );
  const [imageFirstAddon] = useImage(
    cart.addon.length > 0 ? cart.addon[0].image : ""
  );
  const [imageSecondAddon] = useImage(
    cart.addon.length > 1 ? cart.addon[1].image : ""
  );
  const [imageFirstSauces] = useImage(
    cart.flavourBooster.length > 0 ? cart.flavourBooster[0].image : ""
  );
  const [imageSecondSauces] = useImage(
    cart.flavourBooster.length > 1 ? cart.flavourBooster[1].image : ""
  );
  const [imageFirstTopping] = useImage(
    cart.topping.length > 0 ? cart.topping[0].image : ""
  );
  const [imageSecondTopping] = useImage(
    cart.topping.length > 1 ? cart.topping[1].image : ""
  );

  const [proteinVisible, setProteinVisible] = React.useState(true);
  const [marinadeVisible, setMarinadeVisible] = React.useState(true);
  const [addonVisible, setAddonVisible] = React.useState(true);
  const [saucesVisible, setSaucesVisible] = React.useState(true);
  const [toppingVisible, setToppingVisible] = React.useState(true);
  const [clientCanvasTouchStart, setClientCanvasTouchStart] =
    React.useState<number>();
  const [clientCanvasTouchEnd, setClientCanvasTouchEnd] =
    React.useState<number>();

  let proteinRef: React.RefObject<KonvaGroup> = React.createRef();
  let proteinExtraRef: React.RefObject<KonvaGroup> = React.createRef();
  let marinadeFirstRef: React.RefObject<KonvaGroup> = React.createRef();
  let marinadeSecondRef: React.RefObject<KonvaGroup> = React.createRef();
  let addonFirstRef: React.RefObject<KonvaGroup> = React.createRef();
  let addonSecondRef: React.RefObject<KonvaGroup> = React.createRef();
  let toppingFirstRef: React.RefObject<KonvaGroup> = React.createRef();
  let toppingSecondRef: React.RefObject<KonvaGroup> = React.createRef();
  let saucesFirstRef: React.RefObject<KonvaGroup> = React.createRef();
  let saucesSecondRef: React.RefObject<KonvaGroup> = React.createRef();
  const DURATION = 0.3;
  const onClickProtein = () => {
    if (proteinVisible) {
      proteinRef.current &&
        proteinRef.current.to({
          offsetY: 50,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
      proteinExtraRef.current &&
        proteinExtraRef.current.to({
          offsetY: 70,
          offsetX: 120,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
    } else {
      proteinRef.current &&
        proteinRef.current.to({
          offsetY: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
      proteinExtraRef.current &&
        proteinExtraRef.current.to({
          offsetY: 0,
          offsetX: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
    }
    setProteinVisible(!proteinVisible);
  };

  const onClickMarinade = () => {
    if (marinadeVisible) {
      marinadeFirstRef.current &&
        marinadeFirstRef.current.to({
          offsetY: 50,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
      marinadeSecondRef.current &&
        marinadeSecondRef.current.to({
          offsetY: 120,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
    } else {
      marinadeFirstRef.current &&
        marinadeFirstRef.current.to({
          offsetY: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
      marinadeSecondRef.current &&
        marinadeSecondRef.current.to({
          offsetY: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
    }
    setMarinadeVisible(!marinadeVisible);
  };

  const onClickAddon = () => {
    if (addonVisible) {
      addonFirstRef.current &&
        addonFirstRef.current.to({
          offsetY: 50,
          offsetX: -30,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
      addonSecondRef.current &&
        addonSecondRef.current.to({
          offsetY: 120,
          offsetX: -200,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
    } else {
      addonFirstRef.current &&
        addonFirstRef.current.to({
          offsetY: 0,
          offsetX: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
      addonSecondRef.current &&
        addonSecondRef.current.to({
          offsetY: 0,
          offsetX: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
    }
    setAddonVisible(!addonVisible);
  };

  const onClickTopping = () => {
    if (toppingVisible) {
      toppingFirstRef.current &&
        toppingFirstRef.current.to({
          offsetY: 50,
          offsetX: 30,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
      toppingSecondRef.current &&
        toppingSecondRef.current.to({
          offsetY: 120,
          offsetX: 200,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
    } else {
      toppingFirstRef.current &&
        toppingFirstRef.current.to({
          offsetY: 0,
          offsetX: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
      toppingSecondRef.current &&
        toppingSecondRef.current.to({
          offsetY: 0,
          offsetX: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
    }
    setToppingVisible(!toppingVisible);
  };

  const onClickSauces = () => {
    if (saucesVisible) {
      saucesFirstRef.current &&
        saucesFirstRef.current.to({
          offsetY: 50,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
      saucesSecondRef.current &&
        saucesSecondRef.current.to({
          offsetY: 120,
          opacity: 0,
          zIndex: 0,
          duration: DURATION,
        });
    } else {
      saucesFirstRef.current &&
        saucesFirstRef.current.to({
          offsetY: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
      saucesSecondRef.current &&
        saucesSecondRef.current.to({
          offsetY: 0,
          opacity: 1,
          zIndex: 2,
          duration: DURATION,
        });
    }
    setSaucesVisible(!saucesVisible);
  };
  const scale = window.innerWidth > 768 ? 1 : (window.innerWidth / 864) * 0.8;
  const offsetX =
    window.innerWidth > 768 ? 0 : window.innerWidth >= 375 ? -100 : -70;
  const width = window.innerWidth > 768 ? canvasSize : window.innerWidth;

  // effects
  React.useEffect(() => {
    if (clientCanvasTouchStart && clientCanvasTouchEnd) {
      if (clientCanvasTouchStart > clientCanvasTouchEnd) {
        scrollListItemsIntoView();
      }
      if (clientCanvasTouchStart < clientCanvasTouchEnd) {
        scrollCanvasIntoView();
      }

      setClientCanvasTouchStart(undefined);
      setClientCanvasTouchEnd(undefined);
    }
  }, [clientCanvasTouchStart, clientCanvasTouchEnd]);

  // main return
  return (
    <Stage
      width={width}
      height={window.innerWidth > 768 ? width - 80 : width - 80}
      className="bowl-summary-canvas"
      scaleX={scale}
      scaleY={scale}
      offsetX={offsetX}
      onTouchStart={(e) => {
        setClientCanvasTouchStart(e?.evt?.changedTouches?.[0]?.clientY);
      }}
      onTouchEnd={(e) => {
        setClientCanvasTouchEnd(e?.evt?.changedTouches?.[0]?.clientY);
      }}
      // onTouchMove={(e) => {
      //   console.log(e);
      // }}
    >
      <Layer>
        <Image
          image={image}
          width={imageTrueWidth}
          height={imageTrueHeight}
          x={canvasSize / 2 - (canvasSize * 0.7 * centerPoint) / fullPixel}
          y={canvasSize / 2 - imageTrueHeight / 2 - yPositionChange}
          shadowEnabled={true}
          shadowColor="#666"
          shadowOffsetX={10}
          shadowOffsetY={30}
          shadowBlur={50}
          zIndex={0}
        />
        {/* Protein Start */}
        {cart.proteinSize.length > 0 && (
          <Group>
            <Image image={buttonShadow} x={canvasSize / 2 - 190} y={-20} />
            <Image
              image={button}
              width={76}
              height={149}
              x={canvasSize / 2 - 180}
              y={10}
            />
            <Text
              text={
                cart.proteinSize[0].name_size.length > 1
                  ? ""
                  : cart.proteinSize[0].name_size
              }
              x={canvasSize / 2 - 163}
              y={103}
              fill="#FFF"
              fontSize={35}
              fontFamily="MM"
              width={40}
              align="center"
            />
            <Text
              text={String(cart.proteinSize[0].addons)}
              x={canvasSize / 2 - 153}
              y={23}
              fill="#FFF"
              fontSize={35}
              fontFamily="MBold"
            />
            <Text
              text="pieces"
              x={canvasSize / 2 - 170}
              y={65}
              fill="#FFF"
              fontSize={18}
              fontFamily="MBold"
            />
          </Group>
        )}
        {cart.proteins.length > 0 && (
          <Group ref={proteinRef}>
            <Image
              image={imageProtein}
              width={70}
              height={70}
              x={canvasSize / 2 - 35}
              y={60}
              zIndex={0}
            />
            <Text
              text={`${truncateString(cart.proteins[0].name)}${
                cart.proteinSize[0].name_size.length > 4 ||
                cart.proteins[0].name.length > 10
                  ? ``
                  : `(${cart.proteinSize[0].name_size})`
              }`}
              x={
                cart.proteins[0].name.length > 14
                  ? canvasSize / 2 - 85
                  : canvasSize / 2 - 35
              }
              align="center"
              y={135}
              zIndex={0}
              fontFamily="MM"
              fontSize={18}
              fill="#544b49"
            />
            {(cart.proteinSize[0].name_size.length > 4 ||
              cart.proteins[0].name.length > 10) && (
              <Text
                text={`(${cart.proteinSize[0].name_size})`}
                x={canvasSize / 2 - 35}
                align="center"
                y={155}
                zIndex={0}
                fontFamily="MM"
                fontSize={18}
                fill="#544b49"
              />
            )}
          </Group>
        )}
        {cart.extraProteins.length > 0 && (
          <Group ref={proteinExtraRef}>
            <Image
              image={imageExtra}
              width={70}
              height={70}
              x={canvasSize / 2 + halfOfBowlImage / 2}
              y={90}
              zIndex={0}
            />
            <Text
              text={`${truncateString(cart.extraProteins[0].name)}(Extra)`}
              x={canvasSize / 2 + halfOfBowlImage / 2}
              y={165}
              fontFamily="MM"
              fontSize={18}
              zIndex={0}
              fill="#544b49"
            />
          </Group>
        )}

        {cart.proteins.length > 0 && (
          <Group>
            <Text
              text="Proteins"
              fontFamily="MB"
              width={200}
              zIndex={2}
              fontSize={32}
              x={canvasSize / 2 - 60}
              y={10}
              fill="#544b49"
              onClick={onClickProtein}
              onTap={onClickProtein}
            />
            <Image
              image={proteinVisible ? arrowUpIcon : arrowDownIcon}
              width={16}
              height={9}
              x={canvasSize / 2 + 90}
              y={22}
              onTap={onClickProtein}
              onClick={onClickProtein}
            />
          </Group>
        )}

        {/* Protein End */}
        {/* Marinade Start */}
        {marinade.length > 0 && (
          <Group ref={marinadeFirstRef} zIndex={0}>
            <Image
              image={imageFirstMarinade}
              width={60}
              height={60}
              x={canvasSize / 2 + halfOfBowlImage + 30}
              y={275}
              zIndex={0}
            />
            <Text
              text={`${truncateString(marinade[0].name)}`}
              x={canvasSize / 2 + halfOfBowlImage + 30}
              y={355}
              fontFamily="MM"
              fontSize={18}
              zIndex={0}
              fill="#544b49"
            />
          </Group>
        )}
        {marinade.length > 1 && (
          <Group ref={marinadeSecondRef}>
            <Image
              image={imageSecondMarinade}
              width={60}
              height={60}
              x={canvasSize / 2 + halfOfBowlImage + 30}
              y={385}
              zIndex={0}
            />
            <Text
              text={`${truncateString(marinade[1].name)}`}
              x={canvasSize / 2 + halfOfBowlImage + 20}
              height={20}
              y={455}
              fontFamily="MM"
              fontSize={18}
              zIndex={0}
              fill="#544b49"
            />
          </Group>
        )}
        {marinade.length > 0 && (
          <Group>
            <Text
              text="Marinades"
              fontFamily="MB"
              fontSize={32}
              x={canvasSize / 2 + halfOfBowlImage}
              y={235}
              fill="#544b49"
              zIndex={3}
              width={220}
              onTap={onClickMarinade}
              onClick={onClickMarinade}
            />
            <Image
              image={marinadeVisible ? arrowUpIcon : arrowDownIcon}
              width={16}
              height={9}
              x={canvasSize / 2 + halfOfBowlImage + 190}
              y={245}
              onTap={onClickMarinade}
              onClick={onClickMarinade}
            />
          </Group>
        )}

        {/* Marinade End */}
        {/* Add-on Start */}
        {cart.addon.length > 0 && (
          <Group ref={addonFirstRef}>
            <Image
              image={imageFirstAddon}
              width={60}
              height={60}
              x={canvasSize / 2 + halfOfBowlImage - 40}
              y={530}
            />
            <Text
              text={`${truncateString(cart.addon[0].name)}`}
              x={canvasSize / 2 + halfOfBowlImage - 70}
              y={595}
              fontFamily="MM"
              fontSize={18}
              fill="#544b49"
            />
          </Group>
        )}
        {cart.addon.length > 0 && (
          <Group>
            <Text
              text="Add-ons"
              fontFamily="MB"
              fontSize={32}
              x={canvasSize / 2 + halfOfBowlImage - 10}
              y={500}
              zIndex={2}
              fill="#544b49"
              width={200}
              onClick={onClickAddon}
              onTap={onClickAddon}
            />
            <Image
              image={addonVisible ? arrowUpIcon : arrowDownIcon}
              zIndex={2}
              width={16}
              height={9}
              x={canvasSize / 2 + halfOfBowlImage + 140}
              y={510}
              onTap={onClickAddon}
              onClick={onClickAddon}
            />
          </Group>
        )}

        {cart.addon.length > 1 && (
          <Group ref={addonSecondRef}>
            <Image
              image={imageSecondAddon}
              width={60}
              height={60}
              x={canvasSize / 2 + halfOfBowlImage / 4}
              y={595}
            />
            <Text
              text={`${cart.addon[1].name}`}
              x={canvasSize / 2 + halfOfBowlImage / 4}
              y={665}
              fontFamily="MM"
              fontSize={18}
              fill="#544b49"
            />
          </Group>
        )}
        {/* Add-on End */}
        {/* Sauces Start */}
        {cart.flavourBooster.length > 0 && (
          <Group ref={saucesFirstRef}>
            <Image
              image={imageFirstSauces}
              width={60}
              height={60}
              x={canvasSize / 2 - halfOfBowlImage * 1.5}
              y={275}
            />
            <Text
              text={`${truncateString(cart.flavourBooster[0].name)}`}
              x={
                cart.flavourBooster[0].name.length > 11
                  ? canvasSize / 2 - halfOfBowlImage * 1.7
                  : canvasSize / 2 - halfOfBowlImage * 1.6
              }
              y={355}
              fontFamily="MM"
              fontSize={18}
              align="left"
              fill="#544b49"
            />
          </Group>
        )}
        {cart.flavourBooster.length > 1 && (
          <Group ref={saucesSecondRef}>
            <Image
              image={imageSecondSauces}
              width={60}
              height={60}
              x={canvasSize / 2 - halfOfBowlImage * 1.5}
              y={385}
            />
            <Text
              text={`${truncateString(cart.flavourBooster[1].name)}`}
              x={
                cart.flavourBooster[1].name.length > 11
                  ? canvasSize / 2 - halfOfBowlImage * 1.75
                  : canvasSize / 2 - halfOfBowlImage * 1.55
              }
              y={455}
              fontFamily="MM"
              fontSize={18}
              align="left"
              fill="#544b49"
            />
          </Group>
        )}
        {cart.flavourBooster.length > 0 && (
          <Group>
            <Text
              text="Sauces"
              fontFamily="MB"
              fontSize={32}
              x={canvasSize / 2 - halfOfBowlImage * 1.5}
              y={235}
              width={180}
              fill="#544b49"
              onTap={onClickSauces}
              onClick={onClickSauces}
            />
            <Image
              image={saucesVisible ? arrowUpIcon : arrowDownIcon}
              zIndex={2}
              width={16}
              height={9}
              x={canvasSize / 2 - halfOfBowlImage * 1.5 + 130}
              y={245}
              onTap={onClickSauces}
              onClick={onClickSauces}
            />
          </Group>
        )}

        {/* Sauces End */}
        {/* Topping Start */}
        {cart.topping.length > 0 && (
          <Group ref={toppingFirstRef}>
            <Image
              image={imageFirstTopping}
              width={60}
              height={60}
              x={canvasSize / 2 - halfOfBowlImage * 1.1}
              y={540}
            />
            <Text
              text={`${truncateString(cart.topping[0].name)}`}
              x={canvasSize / 2 - halfOfBowlImage * 1.2}
              y={605}
              fontFamily="MM"
              fontSize={18}
              fill="#544b49"
            />
          </Group>
        )}
        {cart.topping.length > 1 && (
          <Group ref={toppingSecondRef}>
            <Image
              image={imageSecondTopping}
              width={60}
              height={60}
              x={canvasSize / 2 - halfOfBowlImage * 0.5}
              y={605}
            />
            <Text
              text={`${truncateString(cart.topping[1].name)}`}
              x={canvasSize / 2 - halfOfBowlImage * 0.55}
              y={675}
              fontFamily="MM"
              fontSize={18}
              fill="#544b49"
            />
          </Group>
        )}

        {cart.topping.length > 0 && (
          <Group>
            <Text
              text="Toppings"
              fontFamily="MB"
              fontSize={32}
              x={canvasSize / 2 - halfOfBowlImage * 1.7}
              y={500}
              width={200}
              fill="#544b49"
              onTap={onClickTopping}
              onClick={onClickTopping}
            />
            <Image
              image={toppingVisible ? arrowUpIcon : arrowDownIcon}
              zIndex={2}
              width={16}
              height={9}
              x={canvasSize / 2 - halfOfBowlImage * 1.7 + 165}
              y={510}
              onTap={onClickTopping}
              onClick={onClickTopping}
            />
          </Group>
        )}

        {/* Topping End */}
      </Layer>
    </Stage>
  );
};

class BowlSummaryScreen extends BowlSummaryHandler {
  render() {
    const { cart } = this.props;
    const { canvasSize } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let count = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let extraCount = 0;
    let baseAmount = 0;
    let extraAmount = 0;
    let addonAmount = getAmountFromCart(1).amount;
    let flavourBoosterAmount = getAmountFromCart(2).amount;
    let sizeAmount = 0;
    cart.proteinSize.forEach((a: IFoodSize) => {
      sizeAmount += Number(a.price);
    });
    cart.extraProteins.forEach((a: IFoodQuantity) => {
      extraCount += a.quantity;
      extraAmount += Number(a.price) * a.quantity;
    });
    cart.base.forEach((a: IFood) => {
      baseAmount += Number(a.price);
    });
    const totalAmount =
      baseAmount +
      extraAmount +
      addonAmount +
      sizeAmount +
      flavourBoosterAmount;
    let hasShowNori = false;

    // console.log("props debug: ", this.props); // for debug
    // console.log("states debug: ", this.state); // for debug

    // main return
    return (
      <>
        <Header centerText="MEAL SUMMARY" {...this.props} />
        <section className="base_main main_bowl_summary">
          <Loading
            loading={this.state.isLoading}
            style={{ zIndex: 3, position: "fixed" }}
          >
            <div className="container text-center loading-container">
              {" "}
              <ClipLoader
                size={window.innerWidth > 768 ? 150 : 70}
                color={"#D31710"}
                loading={true}
              />
            </div>
          </Loading>

          {cart.bowlSavedStatus === true && (
            <div className="bowl-name-saved">
              <p>
                <span className="bowl-title">Bowl name: </span>
                {cart.bowlName} <span className="saved-title">(Saved)</span>
              </p>
            </div>
          )}
          <div className="position-relative text-center">
            <img src={summaryShadow} className="w-100" alt="" />
            <img
              src={
                this?.props?.cart?.base?.[0]?.isBurrito ? itsaburrito : itsabowl
              }
              className="itsabowl-image"
              alt=""
            />
            <div className="box_bowl_summary box_bowl_summary_new content">
              <MyScrollBar
                classNameScrollToBottom="scroll-to-top-bowl-summary"
                alwaysShowScrollToBottom={true}
                scrollToBottomVisible
                scrollToBottomStyle={
                  window.innerWidth > 768
                    ? {}
                    : {
                        bottom: 80,
                        right: -20,
                      }
                }
              >
                {/* <div style={{ height: "1000px", background: "red" }}></div> */}

                <Canvas
                  canvasSize={canvasSize}
                  bowlImage={this.props.bowlImage}
                  cart={cart}
                />

                <div className="list-view pb-3 d-inline-block">
                  <p
                    className="list-view-title"
                    onClick={scrollListItemsIntoView}
                  >
                    List View <img src={listViewIcon} alt="" />
                  </p>
                  <div
                    className="item-list-container"
                    id="item-list-container-id"
                  >
                    {cart.base.length > 0 && (
                      <div className="item-list">
                        <p className="item-list-title">Base</p>
                        {cart.base.map((a, index) => {
                          let isShowNori = false;
                          if (
                            cart.noNori &&
                            !hasShowNori &&
                            (a.id === 471 || a.id === 473 || a.id === 474)
                          ) {
                            isShowNori = true;
                            hasShowNori = true;
                          }
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {a.hasOwnProperty("origin_name")
                                  ? truncateString(
                                      a.origin_name ? a.origin_name : "",
                                      20
                                    )
                                  : truncateString(a.name, 20)}{" "}
                                {isShowNori && (
                                  <span className="extra">(No Nori)</span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {(cart.proteins.length > 0 ||
                      cart.extraProteins.length > 0) && (
                      <div className="item-list">
                        <p className="item-list-title">Proteins</p>
                        {cart.proteins.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(a.name, 20)}{" "}
                                <span className="extra">
                                  ({cart.proteinSize[0].name_size})
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        {cart.extraProteins.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(a.name, 20)}{" "}
                                <span className="extra">
                                  (Extra){" "}
                                  {a.quantity > 1 ? <>x{a.quantity} </> : <></>}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {(cart.marinades.length > 0 || cart.mixin.length > 0) && (
                      <div className="item-list">
                        <p className="item-list-title">Marinades</p>
                        {cart.marinades.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(a.name, 20)}
                              </div>
                            </div>
                          );
                        })}
                        {cart.mixin.map((a) => {
                          return (
                            <div className="d-flex flex-wrap mt-2 mb-2">
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(a.name, 20)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {cart.addon.length > 0 && (
                      <div className="item-list">
                        <p className="item-list-title">Add-ons</p>
                        {cart.addon.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(
                                  a.name,
                                  window.innerWidth > 768 ? 20 : 13
                                )}{" "}
                                {a.quantity > 1 && (
                                  <span className="extra">x{a.quantity}</span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {cart.flavourBooster.length > 0 && (
                      <div className="item-list">
                        <p className="item-list-title">Sauces</p>
                        {cart.flavourBooster.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(
                                  a.name,
                                  window.innerWidth > 768 ? 20 : 13
                                )}{" "}
                                {a.quantity > 1 && (
                                  <span className="extra">x{a.quantity}</span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {cart.topping.length > 0 && (
                      <div className="item-list">
                        <p className="item-list-title">Toppings</p>
                        {cart.topping.map((a) => {
                          return (
                            <div
                              key={a?.id}
                              className="d-flex flex-wrap mt-2 mb-2"
                            >
                              <img src={a.image} className="image" alt="" />
                              <div className="item-list-name">
                                {truncateString(a.name, 20)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </MyScrollBar>
            </div>
            <img src={summaryBottomShadow} className="my-auto" alt="" />
          </div>
          <div className="button_bowl_summary">
            <div className="amount-container">
              <button
                className="minius-button"
                onClick={() =>
                  this.updateQuantityBowl(this.props.cart.quantityBowl - 1)
                }
              >
                <img src={minius} alt="" />
              </button>
              <input
                className="amount-input"
                readOnly
                value={this.props.cart.quantityBowl}
              />
              <input className="fake-input" />
              <button
                className="plus-button"
                onClick={() =>
                  this.updateQuantityBowl(this.props.cart.quantityBowl + 1)
                }
              >
                <img src={plus} alt="" />
              </button>
            </div>
            <div className="confirm-container">
              <button
                onClick={this.onClickEditBowlBtn}
                // onClick={this.onEditBowl} // old code
              >
                <img src={summaryEditButton} alt="edit" />
              </button>
              <div className="d-inline-block">
                <p className="title">Current Amount</p>
                <p className="price">
                  <MoneyComponent
                    money={totalAmount * this.props.cart.quantityBowl}
                  />
                </p>
              </div>
              <button
                onClick={this.checkQuantity}
                disabled={!this.state.isAddBowlAvailable}
              >
                <img src={summaryConfirmButton} alt="confirm" />
              </button>
            </div>
            {/* onClick edit modal */}
            <Dialog
              open={this.state.isCommentModalVisible}
              fullWidth
              maxWidth={"sm"}
              onClose={() => {
                this.setState({ isCommentModalVisible: false });
              }}
              PaperProps={{
                sx: {
                  borderRadius: { xs: "10px", md: "20px" },
                  background:
                    "linear-gradient(180deg, #F4EFEF 0%, #EDE9E9 100%)",
                  boxShadow:
                    "inset 0px -4px 4px #FFFFFF, inset 3px 3px 2px #D5D5D5",
                },
              }}
            >
              <DialogTitle
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() =>
                    this.setState({ isCommentModalVisible: false })
                  }
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ overflow: "visible" }}>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mb: { xs: "0px", md: "64px" },
                  }}
                >
                  <BowlNoteModalIcon />
                </Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: 600,
                    textAlign: "center",
                    fontSize: { xs: "26px", md: "36px" },
                    mb: { xs: "8px", md: "16px" },
                  }}
                >
                  Edit/ Comment
                </Typography>

                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "28px",
                    fontWeight: 300,
                    textAlign: "center",
                    px: { xs: "0px", md: "52px" },
                    mb: { xs: "16px", md: "48px" },

                    b: {
                      fontWeight: 600,
                    },
                  }}
                >
                  Leave a comment or press <b>“Edit”</b> to modify this meal
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    px: { xs: "0px", md: "52px" },
                    mb: { xs: "16px", md: "42px" },
                  }}
                >
                  <TextField
                    value={this.state.bowlNoteValue}
                    onChange={this.onChangeBowlNoteValue}
                    placeholder="Type anything"
                    multiline
                    rows={5}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.4)",
                      border: "1px solid #BFBFBF",
                      borderRadius: "10px",

                      ":focus": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />
                </Box>
              </DialogContent>

              <Grid
                container
                spacing={0}
                sx={
                  {
                    // paddingX: { xs: "1rem", sm: "3rem", md: "6rem" },
                    // pb: "1.5rem",
                  }
                }
              >
                <Grid item xs={6}>
                  <Button
                    sx={{
                      py: "28px",
                      px: "18px",
                      textTransform: "none",
                      width: "100%",
                      border: "1px solid #DFDFDF",
                      fontWeight: 600,
                      fontSize: "32px",
                      color: "#2D2725",
                      ":focus": {
                        outline: "none",
                      },
                    }}
                    onClick={this.onEditBowl}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    sx={{
                      py: "28px",
                      px: "18px",
                      textTransform: "none",
                      width: "100%",
                      border: "1px solid #DFDFDF",
                      fontWeight: 600,
                      fontSize: "32px",
                      color: "#2D2725",
                      ":focus": {
                        outline: "none",
                      },
                    }}
                    onClick={() => {
                      this.setState({ isCommentModalVisible: false }); // close dialog
                      this.addBowlNote(this.state.bowlNoteValue);
                      this.checkQuantity();
                    }}
                    disabled={!this.state.isAddBowlAvailable}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
            <div
              className="modal fade"
              id="saveBowlModal"
              role="dialog"
              aria-labelledby="saveBowlModal"
              aria-hidden="true"
            >
              <div className="modal-dialog " role="document">
                {this.state.isFirstStep ? (
                  <div className="modal-content position-relative modal-content-first-step">
                    <div
                      className="cancel-icon-bowlSummary"
                      onClick={() => {
                        //@ts-ignore
                        $("#saveBowlModal").modal("toggle");
                      }}
                    >
                      <BsX />
                    </div>
                    <div className="modal-header pb-0">
                      <div className="text-center w-100">
                        <div className="bowl-image-box position-relative">
                          <img
                            src={DowloadImage}
                            alt="DowloadImage"
                            width="30%"
                            height="30%"
                          />
                        </div>
                        <h5
                          className="modal-title text-center"
                          id="titleSaveBowlModal"
                        >
                          Would you like to <br />
                          save this meal to favorites ?
                        </h5>
                      </div>
                    </div>
                    <div className="modal-body pt-0">
                      <p
                        className="ml-0 mb-4 font-light"
                        id="subTitleSaveBowlModal"
                      >
                        Give it a unique name and search for it on your next
                        visit!
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary btn-cancel-savebowl btn-footer-saveBowl"
                        data-dismiss="modal"
                        onClick={this.onAddToOrder}
                        style={{ width: 295 }}
                      >
                        No
                      </button>
                      <span className="row-modal-footer row-modal-saveBowl"></span>
                      <button
                        type="button"
                        className="btn btn-primary btn-footer-saveBowl"
                        onClick={this.nextStepSaveBowl}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="modal-content position-relative">
                    <div
                      className="cancel-icon-bowlSummary"
                      onClick={() => {
                        //@ts-ignore
                        $("#saveBowlModal").modal("toggle");
                      }}
                    >
                      <BsX />
                    </div>
                    <Loading
                      loading={this.state.isSavingBowl}
                      style={{ textAlign: "center" }}
                    >
                      <ClipLoader
                        css={
                          window.innerWidth > 768
                            ? ` margin-top: 200px `
                            : ` margin-top: 100px `
                        }
                        size={window.innerWidth > 768 ? 150 : 70}
                        color={"#D31710"}
                        loading={true}
                      />
                    </Loading>
                    <div className="d-flex flex-column align-items-center mb-2">
                      <img
                        src={AddImage}
                        alt="AddImage"
                        width="35%"
                        height="35%"
                      />
                      <h5 className="modal-title" id="titleAddPhone">
                        Save meal to favorites
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="item-input">
                        <label>Client Name: </label>
                        <input
                          type="text"
                          className=""
                          maxLength={30}
                          placeholder=""
                          value={this.props.order.name}
                          readOnly
                          style={{ fontWeight: "bold" }}
                        />
                      </div>
                      <div
                        className="item-input"
                        onClick={() => {
                          this.nameInput.current?.focus();
                        }}
                      >
                        <label>Bowl Name: </label>
                        <input
                          type="text"
                          className=""
                          maxLength={30}
                          placeholder="Enter bowl name..."
                          ref={this.nameInput}
                          value={this.state.name}
                          onChange={this.onChangeText}
                        />
                      </div>
                      <div className="item-input">
                        <label
                          onClick={() => {
                            this.phoneInput.current?.focus();
                          }}
                        >
                          Phone:{" "}
                        </label>
                        <PhoneInput
                          country="cw"
                          value={this.state.phone}
                          preferredCountries={["cw", "nl", "us"]}
                          inputStyle={{ paddingLeft: 50 }}
                          onChange={this.onChangePhone}
                          autoFormat={true}
                        />
                      </div>
                      {!this.state.errMessage ? (
                        <p
                          className="text-center mt-2"
                          style={
                            this.state.checkDuplicate === true
                              ? { color: "#47AF52", height: 54 }
                              : { color: "#D31710", height: 54 }
                          }
                        >
                          {this.state.isChecked === true ? (
                            `${this.state.message}`
                          ) : this.state.isChecking === true ? (
                            <>
                              <PulseLoader
                                size={32}
                                color={"#D31710"}
                                loading={true}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      ) : (
                        <p
                          className="text-center mt-2"
                          style={{ color: "#D31710", height: 54 }}
                        >
                          {this.state.errMessage}
                        </p>
                      )}
                      {!this.state.phone ||
                      this.state.phone === "" ||
                      !this.state.phone.match(phoneRegex) ||
                      this.state.phone?.length < 4 ? (
                        <p
                          className="text-center mt-2"
                          style={{ color: "#D31710", height: 54 }}
                        >
                          Please enter your phone number
                        </p>
                      ) : null}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-footer-saveBowl"
                        disabled={
                          !this.state.checkDuplicate ||
                          !this.state.phone ||
                          this.state.phone === "" ||
                          !this.state.phone.match(phoneRegex)
                        }
                        onClick={this.onSaveBowl}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ConfirmModal
          content={``}
          title={"Out of stock"}
          id="bowlSummaryErrorModal"
          className="confirm-modal"
          cancelText=""
          iconHide
          onSubmit={this.toggleErrorMessage}
        >
          <p>{this.state.quantityOutOfStockCheckMessage}</p>
          <p>{this.state.quantityCheckMessage}</p>
        </ConfirmModal>
        <LogoutModal {...this.props} />
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
    bowlImage: state.app.bowlImage,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const addBowlNote = (note: string) => {
    dispatch(cartAction.addBowlNote(note));
  };

  const setNavigation = (
    backNavigation: string,
    nextNavigation: any,
    mainRightClassName: string
  ) => {
    dispatch(appAction.addNavigation(backNavigation, nextNavigation));
    dispatch(appAction.addMainRightClassName(mainRightClassName));
  };

  const checkDuplicateName = (
    name: string,
    CallBack: (check: boolean, message: string) => void,
    CallBackErr: () => void
  ) => {
    const service = new AppService();
    service
      .checkDuplicateName(name)
      .then((response) => {
        CallBack(response.check, response.message);
      })
      .catch((err) => {
        console.log(err);
        CallBackErr();
      });
  };

  const saveBowl = (
    name: string,
    body: any,
    CallBack: () => void,
    CallBackErr: (message: string) => void
  ) => {
    const service = new AppService();
    service
      .saveBowl(body)
      .then((response) => {
        // CallBack(response.check, response.message)
        dispatch(cartAction.addBowlSavedStatus(true));
        dispatch(cartAction.addBowlName(name, -1));
        CallBack();
      })
      .catch((err: Error) => {
        let message = err.data.hasOwnProperty("errors")
          ? (err.data.errors.hasOwnProperty("clientName")
              ? err.data.errors.clientName[0]
              : "") ||
            (err.data.errors.hasOwnProperty("phone")
              ? err.data.errors.phone[0]
              : "") ||
            (err.data.errors.hasOwnProperty("bowlQuantity")
              ? err.data.errors.bowlQuantity[0]
              : "") ||
            (err.data.errors.hasOwnProperty("items")
              ? err.data.errors.items[0]
              : "")
          : err.message;
        CallBackErr(message);
      });
  };

  const updateOrderInfo = (name: string, type: number, phone: string) => {
    dispatch(orderAction.addOrderInfo(name, type, phone));
  };

  const checkQuantity = (
    body: any,
    CallBack: (
      check: boolean,
      dataCheckOutOfStock: IOutOfStockCheckQuantity,
      dataCheckQuantity: IOutOfStockCheckQuantity
    ) => void
  ) => {
    const service = new AppService();
    service
      .checkQuantity(body)
      .then((response) => {
        CallBack(response.check, response.data, response.data_short);
      })
      .catch((err: Error) => {
        console.log("err", err);
      });
  };

  const updateQuantityBowl = (quantity: number) => {
    dispatch(cartAction.addQuantityBowl(quantity));
  };

  const addToOrder = (order: Array<IOrder>, callBack: () => void) => {
    dispatch(orderAction.addOrder(order));
    dispatch(cartAction.resetCart());
    callBack();
  };

  const addUpdateIndex = (index: number) => {
    dispatch(cartAction.addUpdateFlag(index));
  };
  return {
    setNavigation,
    checkDuplicateName,
    saveBowl,
    updateQuantityBowl,
    addToOrder,
    addUpdateIndex,
    checkQuantity,
    updateOrderInfo,
    addBowlNote,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(BowlSummaryScreen);
// export default (BowlSummaryScreen)
