import React from "react";
import { connect } from "react-redux";
import { Route, Switch, RouteComponentProps, NavLink } from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition,
  //@ts-ignore
} from "react-transition-group";
import { sideFood } from "../route";
import { Footer, Header, LogoutModal } from "../components";
import { ReduxState, OrderState } from "../redux";
import { Dispatch, Action } from "redux";

interface SideFoodRoutesProps extends RouteComponentProps {
  back: string;
  next: any;
  mainRightClassName: string;
  order: OrderState;
  stack: Array<any>;
  bowlImage: string;
}

class SideFoodRoutes extends React.Component<SideFoodRoutesProps> {
  state = {
    prevDepth: this.getPathDepth(this.props.location),
  };
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: SideFoodRoutesProps) {
    super(props);
  }

  componentDidMount() {
    if (this.props.order.name === "" || this.props.order.type === -1) {
      this.props.history.push("/start");
    }
  }

  componentWillReceiveProps() {
    setTimeout(() => {
      this.setState({ prevDepth: this.getPathDepth(this.props.location) });
    }, 1000);
  }

  getPathDepth(location: any) {
    let temp = sideFood.find((a) => a.path === location.pathname);
    if (temp) {
      return temp.key;
    } else {
      return -1;
    }
  }

  getQuantity = (data: Array<any>, property: string) => {
    let quantity = 0;
    if (data.length > 0) {
      data.forEach((a) => {
        quantity += a[property];
      });
      return quantity;
    } else {
      return 0;
    }
  };

  uniqueArr = (arr: Array<any>, k: string) => {
    return arr.filter((v, i, a) => a.findIndex((v2) => v2[k] === v[k]) === i);
  };

  itemQuantity = (
    arr: Array<any>,
    k: string,
    value: string | number | boolean
  ) => {
    return arr.filter((item) => item[k] === value)?.length;
  };

  render() {
    const drinksByCategory = this.uniqueArr(
      this.props.order.drinks,
      "category_id"
    );
    const { location } = this.props;
    const currentKey = location.pathname.split("/")[2] || "/";
    const timeout = { enter: 800, exit: 400 };

    let routeProperty = sideFood.find((a) => a.path === location.pathname);
    let drinkAmount = this.getQuantity(this.props.order.drinks, "quantity");
    let dessertAmount = this.getQuantity(this.props.order.desserts, "quantity");
    let signatureAmount = this.getQuantity(
      this.props.order.signature,
      "quantity"
    );
    let byoQuantity = this.getQuantity(this.props.order.order, "quantityBowl");
    return (
      <>
        <div className={routeProperty?.className}>
          <Header {...this.props} centerText={routeProperty?.name} />
          <section className="base_main">
            <div className="base_main_title">
              <div className="sidebar_left">
                <div className="bg_list">
                  <div className="box_sidebar" style={{ top: 20 }}>
                    <div
                      // className={
                      //   drinkAmount > 0 &&
                      //   drinksByCategory &&
                      //   drinksByCategory?.length
                      //     ? "box_sidebar_drink_container"
                      //     : ""
                      // }
                      className="box_sidebar_drink_container"
                    >
                      <NavLink to="/others/drinks" className="d-inline-block">
                        {drinkAmount > 0 &&
                        drinksByCategory &&
                        drinksByCategory?.length ? (
                          drinksByCategory?.map((item, index) => {
                            return (
                              <div
                                className="sidebar_base sidebar_drink position-relative"
                                key={item?.category_id}
                              >
                                <img src={item?.image} alt="" />
                                {index === drinksByCategory?.length - 1 ? (
                                  <p>Drinks</p>
                                ) : null}
                                {item?.category_id &&
                                  this.itemQuantity(
                                    this.props.order.drinks,
                                    "category_id",
                                    item?.category_id
                                  ) > 0 && (
                                    <p className="number_size">
                                      {this.itemQuantity(
                                        this.props.order.drinks,
                                        "category_id",
                                        item?.category_id
                                      )}
                                    </p>
                                  )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="sidebar_base sidebar_drink  position-relative">
                            <div className="border_base"></div>
                            <p>Drinks</p>
                          </div>
                        )}
                      </NavLink>
                    </div>
                    <NavLink to="/others/desserts" className="d-inline-block">
                      {dessertAmount > 0 ? (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <img
                            src={this.props.order.desserts[0].image}
                            alt=""
                          />
                          <p>Desserts</p>
                          {dessertAmount > 0 && (
                            <p className="number_size">{dessertAmount}</p>
                          )}
                        </div>
                      ) : (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <div className="border_base"></div>
                          <p>Desserts</p>
                        </div>
                      )}
                    </NavLink>
                    <NavLink to="/others/signature" className="d-inline-block">
                      {signatureAmount > 0 ? (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <img
                            src={this.props.order.signature[0].image}
                            alt=""
                          />
                          <p>Signature</p>
                          {signatureAmount > 0 && (
                            <p className="number_size">{signatureAmount}</p>
                          )}
                        </div>
                      ) : (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <div className="border_base"></div>
                          <p>Signature</p>
                        </div>
                      )}
                    </NavLink>
                    <NavLink to="/order/base" className="d-inline-block">
                      {byoQuantity > 0 ? (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <img src={this.props.bowlImage} alt="" />
                          <p>Build your own bowl</p>
                          {byoQuantity > 0 && (
                            <p className="number_size">{byoQuantity}</p>
                          )}
                        </div>
                      ) : (
                        <div className="sidebar_base sidebar_drink position-relative">
                          <div className="border_base"></div>
                          <p>Build your own bowl</p>
                        </div>
                      )}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className={`main_right`}>
                <TransitionGroup component="div" className="App">
                  <CSSTransition
                    key={currentKey}
                    timeout={timeout}
                    classNames="pageSideFoodSlider"
                    mountOnEnter={false}
                    unmountOnExit={true}
                  >
                    <div
                      className={
                        this.getPathDepth(location) - this.state.prevDepth >= 0
                          ? "left"
                          : "right"
                      }
                      key={currentKey}
                    >
                      <Switch location={location}>
                        {sideFood.map((prop: any, key) => {
                          return (
                            <Route
                              path={prop.path}
                              key={key}
                              render={(props) => (
                                <prop.component
                                  {...props}
                                  stack={this.props.stack}
                                />
                              )}
                            />
                          );
                        })}
                      </Switch>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
          </section>
          <LogoutModal {...this.props} />
          <Footer {...this.props} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    back: state.app.backNavigation,
    next: state.app.nextNavigation,
    mainRightClassName: state.app.mainRightClassName,
    order: state.order,
    bowlImage: state.app.bowlImage,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  return {};
};

export const SideFoodLayout = connect(
  mapStateToProps,
  mapDispatchToProp
)(SideFoodRoutes);
