import React from "react";
import { FlavourBoostersHandler } from "./FlavourBoosters.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { ReduxState, foodsAction, cartAction } from "../../redux";
import { AppService } from "../../services";
import { IFlavourBooster } from "../../entities";
import { appAction } from "../../redux/app";
import ClipLoader from "react-spinners/ClipLoader";
import {
  ConfirmModal,
  Loading,
  MyScrollBar,
  AnimationFood,
  MoneyComponent,
  AnimationExtra,
} from "../../components";
import confirmButton from "../../assets/image/confirmButton.png";
import { getAmountFromCart } from "../../utils";

class FlavourBoostersScreen extends FlavourBoostersHandler {
  handleBowlName = () => {
    const { cart } = this.props;
    if (!cart) return "Standard bowl";
    if (cart?.base[0]?.isBurrito) return "Burrito";
    if (cart?.proteinSize?.length) {
      switch (cart?.proteinSize[0]?.name_size) {
        case "S":
          return "Small bowl";
        case "M":
          return "Standard bowl";
        case "L":
          return "Large bowl";

        default:
          return "Standard bowl";
      }
    }
  };
  render() {
    const { selectedItem } = this.state;
    const amount = getAmountFromCart(2).amount;
    return (
      <div className="page position-relative main_right_child">
        <Loading
          loading={this.props.cart.isNonSelectedFlavourBoosters}
          style={{ right: -100 }}
        />
        <div className="switches switches_flavour">
          <div className="switch_box">
            <input
              className="switch right"
              type="checkbox"
              checked={!this.props.cart.isNonSelectedFlavourBoosters}
              onChange={this.toggleChange}
            />
          </div>
        </div>

        <div className="title_heading">
          <p className="title_chose">CHOOSE YOUR </p>
          <h2>SAUCES!</h2>
          <p className="title_include mt-3">{`${
            this.props.cart.proteinSize?.[0]?.addons || 0
          } included with your ${this.handleBowlName()}`}</p>
          <div className="price_add_on" style={{ opacity: this.state.opacity }}>
            <div className="title_price_add_on">
              <h4>extra</h4>
              <p>sauces</p>
            </div>
            <div className="number_price_add_on">
              <MoneyComponent money={amount} type={1} />
            </div>
          </div>
        </div>
        <div className="list_body_flavour content">
          {this.state.animationFood &&
            this.state.animationFood.map((a) => <AnimationFood img={a} />)}
          {this.state.animationExtra &&
            this.state.animationExtra.map((a) => <AnimationExtra img={a} />)}
          {this.state.isLoading === true ? (
            <div className="container text-center">
              <ClipLoader
                css={
                  window.innerWidth > 768
                    ? ` margin-top: 200px `
                    : ` margin-top: 100px `
                }
                size={window.innerWidth > 768 ? 150 : 70}
                color={"#D31710"}
                loading={true}
              />
            </div>
          ) : (
            <MyScrollBar timeout={700}>
              <div
                style={{ maxHeight: 1100, display: "flex", flexWrap: "wrap" }}
              >
                {this.props.foods.map((a: IFlavourBooster, b) => {
                  let selected = this.state.selectedItem.find(
                    (c) => c.id === a.id
                  );
                  return (
                    <div
                      key={`flavour-${a.id}`}
                      className={`box_flavour overflow-hidden ${
                        a.count <= 0 ? `disable-item` : `hover-cursor`
                      }`}
                    >
                      <div
                        className={`items_flavour ${
                          selectedItem.some(
                            (i: IFlavourBooster) => a.id === i.id
                          )
                            ? `active_flavour`
                            : ``
                        }`}
                        onClick={() => a.count !== 0 && this.onSelect(a, 1)}
                      >
                        {/* {a.premium === 1 && <div className="premium">
                                                    <img src={premium} alt=""/>
                                                </div>} */}
                        <div className="img_flavour">
                          <img src={a.image} alt="" />
                        </div>
                        <h4>{a.name}</h4>
                      </div>
                      {selected && (
                        <div
                          style={{
                            zIndex: 3,
                          }}
                        >
                          <div className="field1">
                            <div className="position-relative text-center">
                              <button
                                type="button"
                                id="sub"
                                className="sub"
                                onClick={(e) => {
                                  console.log(
                                    "FlavourBoostersScreen -> render -> selected",
                                    selected
                                  );
                                  if (selected && selected.quantity >= 1) {
                                    this.onSelect(a, selected.quantity - 1);
                                  } else {
                                  }
                                }}
                              >
                                -
                              </button>
                              <input
                                readOnly
                                type="text"
                                value={selected.quantity}
                              />
                              <button
                                type="button"
                                id="add"
                                className="add"
                                onClick={(e) => {
                                  if (selected && selected.quantity < a.count) {
                                    this.onSelect(a, selected.quantity + 1);
                                  } else if (
                                    selected &&
                                    selected.quantity >= a.count
                                  ) {
                                    // @ts-ignore
                                    $("#outOfStockAlertModal").modal("toggle");
                                    $(".modal-backdrop").css("z-index", 1051);
                                    $("#outOfStockAlertModal").on(
                                      "hidden.bs.modal",
                                      function () {
                                        $(".modal-backdrop").css("z-index", "");
                                      }
                                    );
                                  }
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="w-100 text-center">
                  <button className="confirm-next-button" onClick={this.onNext}>
                    <img src={confirmButton} alt="" />
                  </button>
                </div>
              </div>
            </MyScrollBar>
          )}
        </div>
        <ConfirmModal
          content="You have not selected all your free sauces"
          title="Confirm"
          id="freeSaucesConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.onConfirmFreeSaucesModal}
        />
        <ConfirmModal
          content="Are you sure you didn't choose any sauces?"
          title="Confirm"
          id="flavourBoosterConfirm"
          cancelText=""
          iconHide
          className="confirm-modal"
          onSubmit={this.onConfirmModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    foods: state.foods.flavourBooster,
    cart: state.cart,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getFlavourBoosters = async (callBack: () => void) => {
    const service = new AppService();
    service
      .getFlavourBoosters()
      .then((response) => {
        dispatch(foodsAction.addFlavourBooster(response.flavour));
        callBack();
      })
      .catch(console.log);
  };

  const addFlavourBoosterToCart = (
    item: Array<IFlavourBooster>,
    nonSelected: boolean,
    callBack: () => void
  ) => {
    dispatch(cartAction.addFlavourBooster(item, nonSelected));
    callBack();
  };

  const setNavigation = (
    backNavigation: any,
    nextNavigation: any,
    mainRightClassName: string
  ) => {
    dispatch(appAction.addNavigation(backNavigation, nextNavigation));
    dispatch(appAction.addMainRightClassName(mainRightClassName));
  };

  const resetSavedBowl = () => {
    dispatch(cartAction.addBowlName("", -1));
    dispatch(cartAction.addBowlSavedStatus(false));
  };

  return {
    getFlavourBoosters,
    addFlavourBoosterToCart,
    setNavigation,
    resetSavedBowl,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProp
)(FlavourBoostersScreen);
// export default (FlavourBoostersScreen)
