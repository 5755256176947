import React from 'react'
import { DessertOrderHandler } from './DessertOrder.logic';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { ReduxState, orderAction } from '../../redux';
import { MyScrollBar, MoneyComponent, AnimationFood } from '../../components';
import ClipLoader from "react-spinners/ClipLoader";
import { IDrinkCartItem, ICategoriesItem, IDrinkItem, IDessertCartItem, IDessertItem } from '../../entities';
import { AppService } from '../../services';


interface DessertsProps {
    item: IDessertItem
    onAddDrinks: (item: IDessertItem, quantity: number) => void
    onRemoveDrinks: (item: IDessertItem) => void
    selectedDessert: Array<IDessertCartItem>
}


const RenderDessert = (data: DessertsProps) => {
    const { item, onAddDrinks, onRemoveDrinks, selectedDessert } = data
    let count = 1
    let active = false
    selectedDessert.forEach((a: IDrinkCartItem) => {
        if (a.id === item.id) {
            count = a.quantity
            active = true
        }
    })
    const [isActive, setActive] = React.useState(active)
    const [quantity, setQuantity] = React.useState(count)

    return (
        <div className={`protein_list dessert_list ${item.count <= 0 ? `disable-item` : `hover-cursor`} ${selectedDessert.some((d) => item.id === d.category_id) ? `active_protein` : ``}`} >
            <div className={`dessert_item ${isActive === true ? `active-drink` : ``}`}>
                <div className={`img_box text-center `} style={{ height: 165, width: 'auto' }} onClick={() => { if (quantity <= 1 && item.count > 0) { setActive(true); setQuantity(1); onAddDrinks(item, 1) } }}>
                    <img src={item.image} style={{ maxHeight: 165, width: 'auto' }} alt=""/>
                </div>
                {isActive === true && <div style={{ zIndex: 2 }}>
                    <div className="field1">
                        <div className="position-relative text-center">
                            <button type="button" id="sub" className="sub" onClick={() => { if (quantity > 1) { setQuantity(quantity - 1); onAddDrinks(item, quantity - 1) } else { setQuantity(quantity - 1); setActive(false); onRemoveDrinks(item) } }}>-</button>
                            <input readOnly type="text" value={quantity} />
                            <button type="button" id="add" className="add" onClick={() => {
                                if (quantity < item.count) {
                                    setQuantity(quantity + 1); onAddDrinks(item, quantity + 1)
                                } else if (quantity >= item.count) {
                                    // @ts-ignore
                                    $('#outOfStockAlertModal').modal('toggle')
                                    $('.modal-backdrop').css('z-index', 1051)
                                    $('#outOfStockAlertModal').on('hidden.bs.modal', function () {
                                        $('.modal-backdrop').css('z-index', '')
                                    })
                                }
                            }}>+</button>
                        </div></div>

                </div>}
                <div className="text_protein pt-3">
                    <h4>{item.name}</h4>
                    <p className="p2"><MoneyComponent money={item.price} type={1} /></p>
                </div>
            </div>
        </div>
    )
}
class DessertOrderScreen extends DessertOrderHandler {


    render() {
        return (
            <>
                <div className="page position-relative main_right_child main_right_drink">
                    <div className="list_food_main protein_main content p-0">
                        <MyScrollBar>
                            {this.state.isLoading === true ? <div className="container text-center"> <ClipLoader
                                css={window.innerWidth > 768 ? ` margin-top: 200px ` : ` margin-top: 100px `}
                                size={window.innerWidth > 768 ? 150 : 70}
                                color={"#D31710"}
                                loading={true}
                            /></div> : <div className="list_food">
                                    {this.state.desserts.map((a: IDessertItem) => {
                                        return (
                                            <RenderDessert item={a} onAddDrinks={this.onAddDessert} onRemoveDrinks={this.onRemoveDessert} selectedDessert={this.props.desserts} />
                                        )
                                    })}
                                    {this.state.animationFood && this.state.animationFood.map(a =>
                                        <AnimationFood img={a} />)}
                                </div>}
                        </MyScrollBar>
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        cart: state.cart,
        order: state.order,
        desserts: state.order.desserts,
        dessertCategories: state.order.dessertCategories
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    const getDessert = (callBack: (dessert: Array<IDrinkItem>) => void) => {
        const service = new AppService()
        service.getDessert()
            .then((response) => {
                callBack(response.dessert)
            })
            .catch(console.log)
    }

    const addDessertToCart = (dessert: Array<IDessertCartItem>, categories: Array<ICategoriesItem>) => {
        dispatch(orderAction.addDesserts(dessert, categories))
    }
    return {
        getDessert,
        addDessertToCart
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(DessertOrderScreen)
