import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { ReduxState, OrderState, CartState, cartAction, orderAction } from '../../redux';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { BsX  } from "react-icons/bs";
import { VscBell } from "react-icons/vsc";

import { ConfirmModal } from '../confirmModal';
import { appAction } from '../../redux/app';

interface LogoutModalProps extends RouteComponentProps {
    cart?: CartState
    order?: OrderState
    reset?: (callBack: () => void) => void
}

class LogoutModalComponent extends React.PureComponent<LogoutModalProps> {
    render() {
        const { reset, history } = this.props
        return (
            <>
                <ConfirmModal
                    content=""
                    title=""
                    confirmText="Cancel order"
                    id="cancelOrderConfirm"
                    className=""
                    cancelText=''
                    noConfirmText={false}
                    onSubmit={() => {
                        //@ts-ignore
                        $('#cancelOrderConfirm').modal('toggle')
                        if (reset && history) {
                            reset(() => {
                                history.push('/')
                            })
                        }
                    }} 
                >
                    <div className="position-absolute icon-cancel-oder" onClick={() => {
                        //@ts-ignore
                        $('#cancelOrderConfirm').modal('hide')
                    }}>
                        <BsX />
                    </div>
                    <div className="d-flex justify-content-center bell-icon"><VscBell /></div>
                    <p className="ml-0 name-alert">Poke Alert</p>
                    <p className="text-center ml-0 title">Are you sure you want to <br /> cancel this order?</p>
                </ConfirmModal>
            </>
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        cart: state.cart,
        order: state.order
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    const reset = (callBack: () => void) => {
        dispatch(cartAction.resetCart())
        dispatch(orderAction.resetOrder())
        dispatch(appAction.resetApp())
        callBack()
    }

    return {
        reset
    }
}
export const LogoutModal = connect(mapStateToProps, mapDispatchToProp)(LogoutModalComponent)