import React from 'react'
import { SearchOrderScreenHandler } from './SearchOrderScreen.logic';
import logo_black from '../../assets/image/big_logo_black.png'
import btn from '../../assets/image/start/1btn.png'
import box2 from '../../assets/image/start/box2_without_button.png'
import { ReduxState, orderAction } from '../../redux';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { AppService } from '../../services';
import { ConfirmModal } from '../../components';
import { appAction } from '../../redux/app';
//@ts-ignore
import { IBowl, ISearchOrder } from '../../entities';
import { getCensoredPhone } from '../../utils';
//@ts-ignore
import { CSSTransition } from 'react-transition-group'

class SearchOrderScreenScreen extends SearchOrderScreenHandler {
    renderSuggestion = (suggestion: IBowl) => (
        <div className='py-2 suggest-text' onClick={() => this.onSelectClient(suggestion.name, suggestion.phone)}>
            <div className="info-box">
                <p className="my-2 suggest-phone">{`${suggestion.name}`}<span className="suggest-name d-inline ml-2">({getCensoredPhone(suggestion.phone)})</span></p>
            </div>
        </div>
    );
    render() {
        let { isToggleStart, } = this.state
        return (
            <div className={`start enter`}>
                <div className={`main_menu main_start main_enter`}>
                    <section className="header">
                        <div className="main_logo">
                            <img src={logo_black} alt="" style={{ width: window.innerWidth > 768 ? 497 : '95%' }} />
                        </div>
                    </section>
                    <CSSTransition timeout={0} in={isToggleStart} classNames="enter-transaction">
                        <div className="enter-transaction-div">
                            <section className={`base_main start_body enter_body`}>

                                <div className={`slider_start no-background`} style={{ zIndex: 2 }}>
                                    <div className={`slick_slide`}>
                                        {this.state.image.map((a: any, index: number) => {
                                            return (
                                                <div className="list_slider" key={index}>
                                                    <div className="img_list">
                                                        <img src={a.image} alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </CSSTransition>
                    <div>
                        <div className="bg_box" style={{ zIndex: 3 }}>
                            <div className="position_relative">
                                <img src={box2} alt="img-fluid" />

                                <div className={`box_input box_order_id_input`} onClick={this.focus}>
                                    <input
                                        placeholder='Enter your Order ID'
                                        value={this.state.searchOrderText}
                                        ref={this.input}
                                        onChange={this.onChangeSearchOrderText}
                                        maxLength={30}
                                        className="order_id_input"
                                    />
                                </div>
                            </div>
                        </div>
                        <section className={`footer_enter text-center ${this.state.isToggleStart ? `footer_enter_toggle` : ``}`} >
                            <div className="img_button">
                                <a href="/#" className="btn btn-start" onClick={this.toggleStart}>
                                    <img src={btn} alt="" className="img-fluid" />
                                    <p>{`Search`}</p>
                                </a>
                            </div>
                        </section>
                    </div>


                    <ConfirmModal content={this.props.app.openingMessage} confirmText="OK" title="Alert" id="opening-modal" className="confirm-modal" onSubmit={this.onToggleOpeningModal} cancelText='' iconHide/>
                    <ConfirmModal content="You need to enter your name" confirmText="OK" title="Alert" cancelText="" iconHide id="startConfirm" className="confirm-modal" onSubmit={this.onConfirmModal} />
                    <ConfirmModal content="You need to enter a valid Order ID" confirmText="OK" title="Alert" cancelText="" iconHide id="validOrderId" className="confirm-modal" onSubmit={this.onConfirmValidOrderIdModal} />
                    <ConfirmModal content="Your Order doesn't existed" confirmText="OK" title="Alert" cancelText="" iconHide id="orderNotFound" className="confirm-modal" onSubmit={this.toggleOrderNotFoundModal} />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state: ReduxState) => {

    return {
        order: state.order,
        app: state.app
    }
}

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {

    const getImage = (callBack: (image: Array<string>) => void) => {
        const service = new AppService()
        service.getStartImages()
            .then((response) => {
                callBack(response.images)
            })
            .catch(console.log)
    }

    const updateOrderInfo = (name: string, type: number, phone: string) => {
        dispatch(orderAction.addOrderInfo(name, type, phone))
    }

    const checkOpenTime = (callBack: (check: boolean) => void) => {
        const service = new AppService()
        service.checkOpenTime()
            .then((response) => {
                callBack(response.check)
                dispatch(appAction.addOpeningChecking(response.check, response.message, new Date().toISOString()))
            })
            .catch(console.log)
    }

    const getSuggestClient = (text: string, callBack: (clientList: Array<IBowl>) => void) => {
        const service = new AppService()
        service.getSuggestClient(text)
            .then((response) => {
                // dispatch(foodsAction.addProteinsFoods(response.food))
                callBack(response.bowls)
            })
            .catch(console.log)
    }

    const searchOrder = (orderId: number, callBack: (searchOrder: ISearchOrder) => void, callBackError: () => void) => {
        const service = new AppService()
        service.searchOrderByID(orderId)
            .then((response: any) => {
                callBack(response.order)
            })
            .catch(err => {
                if (err.code === 404) {
                    callBackError()
                }
            })
    }

    const addSearchResult = (searchResult: ISearchOrder, callBack: () => void) => {
        dispatch(orderAction.addSearchResult(searchResult))
        callBack()
    }

    return {
        getImage,
        updateOrderInfo,
        checkOpenTime,
        getSuggestClient,
        searchOrder,
        addSearchResult
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(SearchOrderScreenScreen)