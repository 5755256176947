import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { OrderState } from "../../redux";
// import { User } from '../../entities';
interface SuccessBowlInterface extends RouteComponentProps {
  order: OrderState;
  bowlImage: string;
  drinkImage: string;
  dessertImage: string;
  signatureImage: string;
}

export class SuccessBowlHandler extends React.Component<SuccessBowlInterface> {
  state = {};

  // constructor(props: SuccessBowlInterface) {
  //     super(props)
  // }

  componentDidMount() {}

  componentWillUnmount() {
    //@ts-ignore
    $("#cancelOrderConfirm").modal("hide");
  }
}
