import React from "react";
import { StartScreenHandler } from "./StartScreen.logic";
//@ts-ignore
import { CSSTransition } from "react-transition-group";
//@ts-ignore
import Autosuggest from "react-autosuggest";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { BsX } from "react-icons/bs";
// @ts-ignore
import DatePicker from "react-mobile-datepicker";
// import DatePicker from "react-datepicker";
import moment from "moment";

import { AppService } from "../../services";
import { ORDER_TYPE } from "../../constants";
import { ConfirmModal } from "../../components";
import { appAction } from "../../redux/app";
import { ReduxState, orderAction } from "../../redux";
import { IBowl, ISearchOrder, IOpeningHours } from "../../entities";
import { getCensoredPhone } from "../../utils";
import logo_black from "../../assets/image/big_logo_black.png";
import btn from "../../assets/image/start/1btn.png";
import box_onlyshadow_bottom from "../../assets/image/start/box_onlyshadow_bottom.png";
import box2 from "../../assets/image/start/box2_without_button.png";
import dinh from "../../assets/image/start/dinh start.png";
import dotGruop from "../../assets/image/start/dot gruop start sreen.png";
import calendar from "../../assets/image/calendar.png";

const getSuggestionValue = (suggestion: IBowl) => {
  return suggestion.name;
};

// const DatepickerInput = ({ ...props }) => (
//   <input type="text" {...props} readOnly />
// );

class StartScreenScreen extends StartScreenHandler {
  componentDidUpdate() {
    if (this.input) {
      // Show keyboard in mobile
      this.input.current?.focus();
    }
  }
  renderSuggestion = (suggestion: IBowl) => (
    <div
      className="py-2 suggest-text"
      onClick={() => this.onSelectClient(suggestion.name, suggestion.phone)}
    >
      <div className="info-box">
        <p className="my-2 suggest-phone">
          {`${suggestion.name}`}
          <span className="suggest-name d-inline ml-2">
            ({getCensoredPhone(suggestion.phone)})
          </span>
        </p>
      </div>
    </div>
  );
  render() {
    // const isWeekday = (date: Date) => {
    //   const data = new Date(date);
    //   const day = data.getDay();
    //   return day !== 0;
    // };

    let { isToggleStart, zIndexFlag } = this.state;
    
    return (
      <div className={`start enter`}>
        <div className={`main_menu main_start main_enter`}>
          <section className="header">
            <div className="main_logo">
              <img
                src={logo_black}
                alt=""
                style={{ width: window.innerWidth > 768 ? 497 : "95%" }}
              />
            </div>
          </section>
          <CSSTransition
            timeout={0}
            in={isToggleStart}
            classNames="enter-transaction"
          >
            <div className="enter-transaction-div">
              <section className={`base_main start_body enter_body`}>
                <div
                  className={`slider_start no-background`}
                  style={{ zIndex: 2 }}
                >
                  <div className={`slick_slide`}>
                    {this.state.image.map((a: any, index: number) => {
                      return (
                        <div className="list_slider" key={index}>
                          <div className="img_list">
                            <img src={a.image} alt="" className="img-fluid" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
          </CSSTransition>
          <div>
            <div className="bg_box" style={zIndexFlag ? { zIndex: 3 } : {}}>
              <div className="position_relative">
                <img
                  src={isToggleStart ? box2 : box_onlyshadow_bottom}
                  alt="img-fluid"
                />
                {isToggleStart && (
                  <>
                    <div
                      className={`box_input ${
                        this.state.isSearchingOrder ? `box_order_id_input` : ``
                      }`}
                      onClick={this.focus}
                    >
                      {this.state.isSearchingOrder === true ? (
                        <input
                          placeholder="Enter your Order ID"
                          value={this.state.searchOrderText}
                          ref={this.input}
                          onChange={this.onChangeSearchOrderText}
                          maxLength={30}
                          className="order_id_input"
                        />
                      ) : (
                        <Autosuggest
                          suggestions={this.state.suggestion}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={{
                            placeholder: "Type your name here",
                            value: this.state.name,
                            ref: this.input,
                            onChange: this.onChangeText,
                            maxLength: 30,
                          }}
                        />
                      )}
                    </div>
                    {this.state.isSearchingOrder === false && (
                      <>
                        <a
                          href="/#"
                          className="btn btn_dine"
                          onClick={() =>
                            this.onSelectOrderType(ORDER_TYPE.DINE_IN)
                          }
                        >
                          DINE IN
                        </a>
                        <a
                          href="/#"
                          className="btn btn_take"
                          onClick={() =>
                            this.onSelectOrderType(ORDER_TYPE.TAKE_OUT)
                          }
                        >
                          TAKE OUT
                        </a>
                        <a
                          href="/#"
                          className="btn btn_drive"
                          onClick={() =>
                            this.onSelectOrderType(ORDER_TYPE.DRIVE_THRU)
                          }
                        >
                          DRIVE - THRU
                        </a>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <section
              className={`footer_enter text-center ${
                this.state.isToggleStart ? `footer_enter_toggle` : ``
              }`}
              style={{ zIndex: 100 }}
            >
              <div className="img_button">
                <a
                  href="/#"
                  className="btn btn-start"
                  onClick={this.toggleStart}
                >
                  <img
                    src={btn}
                    alt=""
                    className="img-fluid"
                    onClick={
                      this.state.isToggleStart
                        ? this.onToggleChooseOrderType
                        : () => {}
                    }
                  />
                  {this.state.isToggleStart ? (
                    <p onClick={this.onToggleChooseOrderType}>
                      {this.state.isSearchingOrder ? `Search` : `Enter`}
                    </p>
                  ) : (
                    <p style={{ fontWeight: "bold" }}>Touch To Start</p>
                  )}
                </a>
              </div>
            </section>
            <div className="img_bottom_gruop">
              <div className="d-flex justify-content-start align-items-center">
                <img src={dotGruop} alt="" className="img_10" />
                <img src={dinh} alt="" className="img_30" />
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <img src={dinh} alt="" className="img_30" />
                <img src={dotGruop} alt="" className="img_10" />
              </div>
            </div>
          </div>

          <ConfirmModal
            content={""}
            confirmText="Schedule an order"
            title=""
            cancelText=""
            id="opening-modal"
            className="confirm-modal"
            onSubmit={this.onToggleOpeningModal}
          >
            <div
              className="position-absolute icon-cancel-greeting"
              onClick={() => {
                //@ts-ignore
                $("#opening-modal").modal("hide");
              }}
            >
              <BsX />
            </div>
            <div className="d-flex justify-content-center mb-3">
              <img src={calendar} alt="calendar" width="30%" height="30%" />
            </div>
            <div className="d-flex justify-content-center align-items-center mb-3 heading">
              Notification
            </div>
            <p className="text-center ml-0 title">
              Opening Hours <br />
            </p>
            <p className="text-center ml-0 hour"> Mon - Sun</p>
            {
               this.state.openingHourRaw.map((time : any, index) => {
                  return <p className="text-center ml-0 hour" key={index}>
                      Open: {moment(time.open_time, 'HH:mm:ss').format('h:mm A')} - Close: {moment(time.close_time, 'HH:mm:ss').format('h:mm A')} 
                  </p>
               })
            }
            <p className="text-center ml-0 subTitle" style={{ marginTop : '20px'}}>
              Sorry, We're currently closed,
              <br />
              please schedule your <br />
              order in advance.
            </p>
            <div className="modal-footer p-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.onToggleOpeningModal();
                  //@ts-ignore
                  $("#inDevelopmentModal").modal("show");
                }}
              >
                Schedule an order
              </button>
            </div>
          </ConfirmModal>
          <ConfirmModal
            modalContentStyle={{ overflow: "inherit" }}
            content={``}
            title=""
            id="inDevelopmentModal"
            confirmText="Ok"
            cancelText=""
            className="confirm-modal"
            onSubmit={() => {
              this.toggleInDevelopmentModal();
              this.setState({ successMessage: "Scheduled your order!" });
              this.props.addScheduleOrder(
                moment(this.state.scheduleDate).format("HH:mm:ss MM/DD/YYYY")
              );
              this.toggleStart();
            }}
            children={
              <>
                <div
                  className="position-absolute icon-cancel-schedule"
                  onClick={() => {
                    //@ts-ignore
                    $("#inDevelopmentModal").modal("hide");
                  }}
                >
                  <BsX />
                </div>
                <div
                  className="col-md-12 text-center px-0"
                  style={{ height: 276, width: 240, margin: "auto" }}
                >
                  <div className="schedule-icon-container d-flex justify-content-center mb-2">
                    <img
                      src={calendar}
                      alt="CarlenderIcon"
                      width="35%"
                      height="35%"
                    />
                  </div>
                  <div className="heading-schedule-container">
                    Schedule my order
                  </div>
                  <div onClick={this.onOpen} style={{ border : '1px solid #000' , padding : '12px 10px', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                    <h6 style={{margin : '-0.5rem'}}>
                      {moment(this.state.scheduleDate).format("MMM DD | HH:mm")}
                    </h6>
                  </div>

                  <ConfirmModal
                    content={``}
                    title=""
                    id="dateModal"
                    hideFooter
                    confirmText="Ok"
                    cancelText=""
                    onSubmit={this.toggleStart}
                    styleModalDialog={{ marginTop : '386px'}}
                    children={
                      <>
                        <div
                          style={{ height: 419, width: 240, margin: "auto"}}
                          className="modal-children"
                        >
                          <div
                            className="position-absolute icon-cancel-schedule"
                            onClick={() => {
                              //@ts-ignore
                              $("#dateModal").modal("hide");
                            }}
                          >
                            <BsX />
                          </div>
                          <DatePicker
                            value={this.state.scheduleDate}
                            isOpen={true}
                            theme={"android"}
                            confirmText={"Set"}
                            cancelText={"Cancel"}
                            min={new Date(this.state.minDate)}
                            dateConfig={this.state.dateConfig}
                            minTime={this.state.minTime}
                            maxTime={this.state.maxTime}
                            onSelect={this.handleSelect}
                            onCancel={this.handleCancel}
                            onChange={this.handleChange}
                            isPopup={false}
                            customHeader={"Set date and time"}
                          />
                        </div>
                      </>
                    }
                  />

                  {/* <DatePicker
                    selected={this.state.scheduleDate}
                    // showTimeSelect
                    // showTimeSelectOnly
                    // showYearDropdown={true}
                    // showMonthDropdown={false}
                    // timeIntervals={10}
                    // includeTimes={this.state.openingHour}
                    // fixedHeight={true}
                    // dateFormat="MMMM dd | HH:mm"
                    // timeFormat="HH:mm"

                    minDate={new Date()}
                    showTimeSelect
                    includeTimes={this.state.openingHour}
                    timeIntervals={10}
                    timeInputLabel="Time:"
                    onChangeRaw={this.handleDateChangeRaw}
                    className="text-center time-schedule"
                    // customInput={<DatepickerInput />}
                    dateFormat="MM/dd/yyyy | h:mm aa"
                    onChange={(date) => {
                      console.log("OrderListScreen -> render -> date", date);
                      this.setState({ scheduleDate: date });
                    }}
                  /> */}
                </div>
              </>
            }
          />
          <ConfirmModal
            // content="You need to enter your name"
            // confirmText="OK"
            // title="Alert"
            // cancelText=""

            content=""
            title=""
            confirmText="Cancel order"
            id="startConfirm"
            className=""
            noConfirmText={false}
            cancelText=""
            iconHide
            onSubmit={this.onConfirmModal}
            styleFooter={{ display: "none" }}
            modalContentStyle={{ background: "#fff ", width: "70vw" }}
            styleModalDialog={{ transform: "translate(0,200%)" }}
          >
            <p
              className="text-center ml-0 title"
              style={{
                fontFamily: "MR",
                fontWeight: 800,
                paddingBottom: "20px",
                fontSize: "40px",
                lineHeight: "40px",
              }}
            >
              You need to enter your name
            </p>
            <p
              onClick={this.onConfirmModal}
              style={{
                borderTop: "1px solid",
                textAlign: "center",
                paddingTop: "40px",
                fontFamily: "MR",
                fontSize: "40px",
                lineHeight: "40px",
              }}
            >
              OK
            </p>
          </ConfirmModal>
          <ConfirmModal
            content="You need to enter a valid Order ID"
            confirmText="OK"
            title="Alert"
            cancelText=""
            iconHide
            id="validOrderId"
            className="confirm-modal"
            onSubmit={this.onConfirmValidOrderIdModal}
          />
          <ConfirmModal
            content="Your Order doesn't existed"
            confirmText="OK"
            title="Alert"
            cancelText=""
            iconHide
            id="orderNotFound"
            className="confirm-modal"
            onSubmit={this.toggleOrderNotFoundModal}
          />
          <ConfirmModal
            content="You need to choose order type"
            confirmText="OK"
            title="Alert"
            cancelText=""
            iconHide
            id="chooseOderType"
            className="confirm-modal"
            onSubmit={this.onToggleChooseOrderType}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    order: state.order,
    app: state.app,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const getImage = (callBack: (image: Array<string>) => void) => {
    const service = new AppService();
    service
      .getStartImages()
      .then((response) => {
        callBack(response.images);
      })
      .catch(console.log);
  };

  const updateOrderInfo = (name: string, type: number, phone: string) => {
    dispatch(orderAction.addOrderInfo(name, type, phone));
  };

  const checkOpenTime = (callBack: (check: boolean) => void) => {
    const service = new AppService();
    service
      .checkOpenTime()
      .then((response) => {
        callBack(response.check);
        dispatch(
          appAction.addOpeningChecking(
            response.check,
            response.message,
            new Date().toISOString()
          )
        );
      })
      .catch(console.log);
  };

  const getSuggestClient = (
    text: string,
    callBack: (clientList: Array<IBowl>) => void
  ) => {
    const service = new AppService();
    service
      .getSuggestClient(text)
      .then((response) => {
        // dispatch(foodsAction.addProteinsFoods(response.food))
        callBack(response.bowls);
      })
      .catch(console.log);
  };

  const searchOrder = (
    orderId: number,
    callBack: (searchOrder: ISearchOrder) => void,
    callBackError: () => void
  ) => {
    const service = new AppService();
    service
      .searchOrderByID(orderId)
      .then((response: any) => {
        callBack(response.order);
      })
      .catch((err) => {
        if (err.code === 404) {
          callBackError();
        }
      });
  };

  const addSearchResult = (
    searchResult: ISearchOrder,
    callBack: () => void
  ) => {
    dispatch(orderAction.addSearchResult(searchResult));
    callBack();
  };

  const getOpeningTime = (callBack: (data: Array<IOpeningHours>) => void) => {
    const service = new AppService();
    service
      .getOpeningTime()
      .then((response) => {
        callBack(response.data);
      })
      .catch((err) => {});
  };

  const addScheduleOrder = (scheduleOrder: string) => {
    dispatch(orderAction.addScheduleOrder(scheduleOrder));
  };

  return {
    getImage,
    updateOrderInfo,
    checkOpenTime,
    getSuggestClient,
    searchOrder,
    addSearchResult,
    getOpeningTime,
    addScheduleOrder,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(StartScreenScreen);
