import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CartState, OrderState } from "../../redux";
import {
  ICard,
  ISearchOrder,
} from "../../entities";
import {
  PaymentMethod,
} from "../../constants";
interface PaymentExistedOrderInterface extends RouteComponentProps {
  cart: CartState;
  order: OrderState;
  createOrder: (
    id: string,
    body: any,
    CallBack: () => void,
    CallBackError: (message: string) => void
  ) => void;
  reset: (callBack: () => void) => void;
  getCard: (callBack: (cards: Array<ICard>, status: boolean) => void) => void;
  searchResult: ISearchOrder;
}

interface State {
  isLoading: boolean;
  errContent: string;
  errTitle: string;
  card: Array<ICard>;
  cardStatus: boolean;
}

export class PaymentExistedOrderHandler extends React.Component<
  PaymentExistedOrderInterface,
  State
> {
  state = {
    isLoading: false,
    errContent: "",
    errTitle: "Order failed",
    card: [],
    cardStatus: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: PaymentExistedOrderInterface) {
    super(props);
  }

  componentDidMount() {
    if (this.props.searchResult.order_id === "") {
      this.props.history.push("/start");
    }
    this.props.getCard((cards: Array<ICard>, status: boolean) => {
      this.setState({ card: cards, cardStatus: status });
    });
  }

  componentWillUnmount() {
    //@ts-ignore
    $("#cancelOrderConfirm").modal("hide");
    //@ts-ignore
    $("#orderErrorModal").modal("hide");
  }

  toggleErrorMessage = () => {
    //@ts-ignore
    $("#orderErrorModal").modal("toggle");
  };

  onCheckout = (type: PaymentMethod) => {
    this.setState({ isLoading: true });
    this.props.createOrder(
      this.props.searchResult.order_id,
      {
        payment_method: type,
      },
      () => {
        this.props.reset(() => {
          this.setState({ isLoading: false });
          this.props.history.push("/order-confirm");
        });
      },
      (message: string) => {
        this.setState({ isLoading: false });
        //@ts-ignore
        $("#orderErrorModal").modal("show");
        this.setState({
          isLoading: false,
          errContent: message,
          errTitle: "Order failed",
        });
      }
    );
  };
}
