import { ApiWorkerInterceptor } from "./ApiWorkerInterceptor";
import { ApiWorkerInterceptorImp } from "./ApiWorkerInterceptoImp";
import { ApiWorker } from "./ApiWorker";
import { ApiWorkerImp } from "./ApiWorkerImp";

export interface ApiWorkerUltilDelegate {
  onTokenExpried: () => void;
}

function getUrl(url: string) {
  if (url) {
    if (url.slice(-9) === "undefined") {
      return url.slice(0, -9);
    } else {
      return url;
    }
  }
}
class ApiWorkerUltil {
  static instance: ApiWorkerUltil;

  static default(): ApiWorkerUltil {
    if (!ApiWorkerUltil.instance) {
      ApiWorkerUltil.instance = new ApiWorkerUltil();
    }
    return ApiWorkerUltil.instance;
  }

  apiWorker: ApiWorker;

  interceptor: ApiWorkerInterceptor;

  constructor() {
    this.interceptor = new ApiWorkerInterceptorImp();
    const envUrl = process.env.REACT_APP_BASE_URL as string;

    const baseURL = getUrl(envUrl) + "api/";
    this.apiWorker = new ApiWorkerImp(this.interceptor, { baseURL: baseURL });
  }
}

export default ApiWorkerUltil.default();
