import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CartState } from '../../redux'
import { ITopping } from '../../entities/Topping'
// import { User } from '../../entities';
interface ToppingOrderInterface extends RouteComponentProps {
    foods: Array<ITopping>
    getTopping: (callBack: () => void) => void
    addToppingToCart: (item: Array<ITopping>, nonSelected: boolean, callBack: () => void) => void
    cart: CartState
    setNavigation: (backNavigation: any, nextNavigation: any, mainRightClassName: string) => void
    resetSavedBowl: () => void
}

interface ToppingOrderState {
    selectedItem: Array<ITopping>
    isLoading: boolean
    animationFood: Array<string>
    isSelecting: boolean
}

export class ToppingOrderHandler extends React.Component<ToppingOrderInterface, ToppingOrderState> {


    constructor(props: ToppingOrderInterface) {
        super(props)
        this.state = {
            selectedItem: [],
            isLoading: true,
            animationFood: [],
            isSelecting: false
        }
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            this.props.setNavigation({
                pathname: "/order/flavour-boosters",
                state: {
                    updateFlag: true
                }
            }, () => this.props.history.push('/bowl-summary'), 'main_right_protein detail_protein main_add_on')
        } else {
            this.props.setNavigation('/order/flavour-boosters', this.onNext, 'main_right_protein detail_protein main_add_on')
        }
    }

    componentDidMount() {
        this.props.getTopping(() => {
            let temp = this.props.cart.topping.slice()
            let removedIndex: Array<number> = []
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = this.props.foods.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        removedIndex.push(index)
                    }
                })
                if (removedIndex.length > 0) {
                    removedIndex.reverse().forEach(a => {
                        temp.splice(a, 1)
                    })
                    this.props.resetSavedBowl()
                    this.props.addToppingToCart(Array.from(temp), false, () => { })
                }
            }
            this.setState({ selectedItem: temp, isLoading: false })
        })
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
        // @ts-ignore
        $('#toppingConfirm').modal('hide')
    }

    onSelect = (item: ITopping) => {
        this.props.resetSavedBowl()
        const { selectedItem } = this.state
        if (selectedItem.some(a => {
            return a.id === item.id
        })) {
            let temp = selectedItem.filter((a: ITopping) => a.id !== item.id)
            this.setState({ selectedItem: temp })
            this.props.addToppingToCart(temp, false, () => { })
        } else {
            let temp = selectedItem
            temp.push(item)
            this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image) })
            this.props.addToppingToCart(temp, false, () => { })
        }
    }

    toggleChange = () => {
        this.props.resetSavedBowl()
        this.props.addToppingToCart([], !this.props.cart.isNonSelectedTopping, () => {
            this.setState({ selectedItem: [] })
        })
        if(this.props.cart.isNonSelectedTopping === false) {
            this.props.history.push('/bowl-summary')
        }
    }

    onNext = () => {
        if (this.state.selectedItem.length > 0) {
            this.props.addToppingToCart(this.state.selectedItem, false, () => {
                this.props.history.push('/bowl-summary')
            })
        } else {
            if (this.props.cart.isNonSelectedTopping === false) {
                // @ts-ignore
                $('#toppingConfirm').modal('toggle')
            } else {
                this.props.history.push('/bowl-summary')
            }
        }
    }

    onConfirmModal = () => {
        // @ts-ignore
        $('#toppingConfirm').modal('toggle')
        this.props.addToppingToCart([], true, () => {
            this.props.history.push('/bowl-summary')
        })
    }
}