import * as React from 'react'



export const MoneyComponent = (data: any) => {
    const { money, type, classProps = "" } = data
    let temp = String(money).split('.')

    if (type === 1) {
        return (
            <>
                <span className={classProps}>f</span><sub>{temp[0]}<sup>{temp[1] ? (temp[1].length === 1 ? `${temp[1]}0` : temp[1]) : '00'}</sup></sub>
            </>
        )
    } else if (type === 2) {
        return (
            <>
                <sub>{temp[0]}<sup>{temp[1] ? (temp[1].length === 1 ? `${temp[1]}0` : temp[1]) : '00'}</sup></sub><div className={classProps}>f</div>
            </>
        )
    } else {

        return (
            <>
                <span className={classProps}>f</span>{temp[0]}<sup>{temp[1] ? (temp[1].length === 1 ? `${temp[1]}0` : temp[1]) : '00'}</sup>
            </>

        )
    }
}