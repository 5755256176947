
import React from "react";

interface LoadingProps {
    loading: boolean
    style?: any
}

export class Loading extends React.PureComponent<LoadingProps> {
    render() {
        const { loading, style, children } = this.props
        if (loading) {
            return (
                <div style={{
                    position: 'absolute', top: 0, left: 0,
                    right: 0, bottom: 0, background: '#E8E1E1',
                    opacity: 0.5, zIndex: 2, ...style
                }}>
                    {children}
                </div>
            )
        } else {
            return <></>
        }
    }
}