import React from "react";
import { PaymentTypeHandler } from "./PaymentType.logic";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { BsX } from "react-icons/bs";
import back from "../../assets/image/back.png";
import header from "../../assets/image/header.png";
import logo_main from "../../assets/image/logo_main.png";
import shadow from "../../assets/image/payment/shadow_2.png";
import cashier from "../../assets/image/cashier.png";
import { ReduxState, cartAction, orderAction } from "../../redux";
import { CreditCard } from "../../components/creditCard";
import { AppService } from "../../services";
import {
  Loading,
  MoneyComponent,
  ConfirmModal,
  MyScrollBar,
} from "../../components";
import ClipLoader from "react-spinners/ClipLoader";
import { PayPalButton } from "react-paypal-button-v2";
import { Error, IOutOfStockCheckQuantity, ICard } from "../../entities";
import { appAction } from "../../redux/app";
import { PaymentMethod } from "../../constants";

class PaymentTypeScreen extends PaymentTypeHandler {
  render() {
    console.log("payment type state: ", this.state); // for debug
    console.log("payment type props: ", this.props); // for debug

    const { card } = this.state;
    return (
      <div className="main_menu">
        <Loading
          loading={this.state.isLoading}
          style={{ zIndex: 3, position: "fixed" }}
        >
          <div className="container text-center loading-container">
            {" "}
            <ClipLoader
              size={window.innerWidth > 768 ? 150 : 70}
              color={"#D31710"}
              loading={true}
            />
          </div>
        </Loading>
        <section className="header">
          <img src={header} alt="Header" className="img-fluid" />
          <div className="main_logo payment-back">
            <img src={logo_main} alt="Logo" />
          </div>
          <div className="d-block flex-nowrap order_list_heading order_list_search_result">
            <a
              href="/#"
              onClick={() => this.props.history.push("/order-list")}
              className="btn btn-back"
              style={{ bottom: "60px !important" }}
            >
              <img src={back} alt="" />
            </a>
            <h2 className="build payment-title" style={{}}>
              PLEASE SELECT A PAYMENT TYPE
            </h2>
          </div>
        </section>
        <section
          className="base_main main_bowl_summary box_order_list payment"
          style={{ paddingTop: 470 }}
        >
          <div className="box_bowl_summary content box_bowl_summary_payment">
            <MyScrollBar>
              <div>
                {card.length > 0 &&
                  window.innerWidth < 1090 &&
                  this.state.cardStatus === true && (
                    <div
                      className="payment-item mt-3"
                      onClick={() =>
                        this.onCheckQuantity(PaymentMethod.CREDIT_CARD)
                      }
                    >
                      <div className="payment-content-card payment-content">
                        <p>Credit / PayPal</p>
                        <p className="sub-text">Accept card type</p>
                        <div className="card-list">
                          {card.map((a: ICard) => (
                            <div
                              className="card-item"
                              key={`card-${a.id}`}
                              // onClick={() =>
                              //   this.onCheckQuantity(PaymentMethod.CREDIT_CARD)
                              // }
                            >
                              <img src={a.image} alt="" />
                              <p>{a.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="payment-item-box-shadow">
                        <img src={shadow} alt="" />
                      </div>
                    </div>
                  )}
                <div
                  className={`payment-item ${
                    card.length > 0 && this.state.cardStatus === true
                      ? ``
                      : `mt-3`
                  }`}
                  onClick={() => this.onCheckQuantity(PaymentMethod.CASH)}
                >
                  <div className="payment-content-cash payment-content" style={{ paddingBottom : '15px'}}>
                    <img src={cashier} alt="" />
                    <p>
                      In store <p className="sub-text">(Tap/ Swipe)</p>
                    </p>
                  </div>
                  <div className="payment-item-box-shadow payment-cash-shadow">
                    <img src={shadow} alt="" />
                  </div>
                </div>
              </div>
            </MyScrollBar>
          </div>
        </section>
        <section className="footer_payment">
          <div className="bottom_right text-right col-md-6 pl-0">
            <div className="total_price">
              <p className="p2">
                <span className="title_total" style={{ fontSize: 29 }}>
                  Total:
                </span>{" "}
                <MoneyComponent
                  money={this.getTotalAmount()}
                  classProps="text_price"
                  type={1}
                />
              </p>
            </div>
          </div>
        </section>
        <ConfirmModal
          content={this.state.errContent}
          title={this.state.errTitle}
          id="orderErrorModal"
          className="confirm-modal"
          cancelText=""
          iconHide
          onSubmit={this.toggleErrorMessage}
        />
        <ConfirmModal
          modalContentStyle={{ overflow: "unset", padding: "1rem" }}
          cancelText={""}
          noConfirmText={true}
          content=""
          title="Credit/Paypal"
          id="paymentMethod"
          className="confirm-modal"
          onSubmit={() => 1}
          children={
            <div className="col-md-12 text-center children-payment">
              <div style={{ fontSize: "2rem" }}>Pay with Credit Card</div>
              <CreditCard
                onCheckoutCredit={this.onCheckoutCredit}
                onLoading={this.onLoading}
                onError={this.orderError}
              />

              <div style={{ marginBottom: "3rem" }}>-----OR-----</div>

              <div style={{ fontSize: "2rem", marginBottom: "1rem" }}>
                Pay with Paypal
              </div>
              <div
                className="position-absolute icon-cancel-payment"
                onClick={() => {
                  //@ts-ignore
                  $("#paymentMethod").modal("hide");
                }}
              >
                <BsX />
              </div>
              <PayPalButton
                amount={this.getTotalAmount()}
                options={{
                  clientId:
                    "Aa0vQnQAdGC5RuN4uIMwByIwfDtUcXIiqV6zbbn3BH1bt7519kScH29FgscMFV5uijM15Op5mH3_zxtX",
                  disableFunding: "card",
                }}
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details: any, data: any) =>
                  this.paypalPayment(details, data)
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    cart: state.cart,
    order: state.order,
    searchResult: state.order.searchResult,
  };
};

const mapDispatchToProp = (dispatch: Dispatch<Action>) => {
  const createOrder = (
    body: any,
    CallBack: () => void,
    CallBackError: (message: string) => void
  ) => {
    const service = new AppService();
    service
      .createOrder(body)
      .then((response) => {
        CallBack();
      })
      .catch((err: Error) => {
        if (err && err.data && err.data.hasOwnProperty("errors")) {
          CallBackError(
            (err.data.errors.hasOwnProperty("bowls")
              ? err.data.errors.bowls[0]
              : ``) ||
              (err.data.errors.hasOwnProperty("clientName")
                ? err.data.errors.clientName[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("coupon")
                ? err.data.errors.coupon[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("phone")
                ? err.data.errors.phone[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("type")
                ? err.data.errors.type[0]
                : ``)
          );
        } else {
          CallBackError(err.message);
        }
      });
  };

  const creditOrder = (
    body: any,
    CallBack: () => void,
    CallBackError: (message: string) => void
  ) => {
    const service = new AppService();
    service
      .creditOrder(body)
      .then((response) => {
        CallBack();
      })
      .catch((err: Error) => {
        if (err && err.data && err.data.hasOwnProperty("errors")) {
          CallBackError(
            (err.data.errors.hasOwnProperty("bowls")
              ? err.data.errors.bowls[0]
              : ``) ||
              (err.data.errors.hasOwnProperty("clientName")
                ? err.data.errors.clientName[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("coupon")
                ? err.data.errors.coupon[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("phone")
                ? err.data.errors.phone[0]
                : ``) ||
              (err.data.errors.hasOwnProperty("type")
                ? err.data.errors.type[0]
                : ``)
          );
        } else {
          CallBackError(err.message);
        }
      });
  };

  const reset = (callBack: () => void) => {
    dispatch(cartAction.resetCart());
    dispatch(orderAction.resetOrder());
    dispatch(appAction.resetApp());
    callBack();
  };

  const getCard = (
    callBack: (cards: Array<ICard>, status: boolean) => void
  ) => {
    const service = new AppService();
    service
      .getCardPayment()
      .then((response) => {
        callBack(response.list, response.status.status === 1);
      })
      .catch((err: Error) => {
        console.log("err", err);
      });
  };

  const checkQuantity = (
    body: any,
    CallBack: (
      check: boolean,
      dataCheckOutOfStock: IOutOfStockCheckQuantity,
      dataCheckQuantity: IOutOfStockCheckQuantity
    ) => void
  ) => {
    const service = new AppService();
    service
      .checkQuantity(body)
      .then((response) => {
        CallBack(response.check, response.data, response.data_short);
      })
      .catch((err: Error) => {
        console.log("err", err);
      });
  };

  return {
    creditOrder,
    createOrder,
    reset,
    checkQuantity,
    getCard,
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(PaymentTypeScreen);
