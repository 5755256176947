import { ADD_BASE_FOOD, ADD_PROTEIN_FOOD, ADD_ADDON_FOOD, ADD_FLAVOUR_BOOSTER, ADD_TOPPING } from "./types";
import { IFood, IFlavourBooster, IFoodBase } from "../../entities";
import { ITopping } from "../../entities/Topping";

export interface FoodState {
    baseFood: Array<IFoodBase>,
    proteinFood: Array<IFood>,
    addonFood: Array<IFood>
    flavourBooster: Array<IFlavourBooster>
    topping: Array<ITopping>
}

const initialState: FoodState = {
    baseFood: [],
    proteinFood: [],
    addonFood: [],
    flavourBooster: [],
    topping: []
}

function foods(state = initialState, action: any) {
    let { type, payload } = action
    switch (type) {
        case ADD_BASE_FOOD:
            return Object.assign({}, state, payload)
        case ADD_PROTEIN_FOOD:
            return Object.assign({}, state, payload)
        case ADD_ADDON_FOOD:
            return Object.assign({}, state, payload)
        case ADD_TOPPING:
            return Object.assign({}, state, payload)
        case ADD_FLAVOUR_BOOSTER:
            return Object.assign({}, state, payload)
        default:
            return state
    }
}

export { foods };