import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {  IFlavourBooster, IFoodQuantity } from '../../entities'
import { CartState } from '../../redux'
import { NumberFlavourBoosterFree } from '../../constants'
import { getAmountFromCart } from '../../utils'
interface FlavourBoostersInterface extends RouteComponentProps {
    getFlavourBoosters: (callBack: () => void) => void
    foods: Array<IFlavourBooster>
    cart: CartState
    addFlavourBoosterToCart: (item: Array<IFlavourBooster>, nonSelected: boolean, callBack: () => void) => void
    setNavigation: (backNavigation: any, nextNavigation: any, mainRightClassName: string) => void
    resetSavedBowl: () => void
}
interface FlavourBoosterState {
    selectedItem: Array<IFoodQuantity>
    isLoading: boolean
    animationFood: Array<string>
    animationExtra: Array<string>
    isSelecting: boolean
    opacity: number
}

export class FlavourBoostersHandler extends React.Component<FlavourBoostersInterface, FlavourBoosterState> {

    nextLink: string
    param: any
    constructor(props: FlavourBoostersInterface) {
        super(props)
        this.state = {
            selectedItem: [],
            isLoading: true,
            animationFood: [],
            animationExtra: [],
            isSelecting: false,
            opacity: 0
        }
        this.nextLink = '/order/topping'
        //@ts-ignore
        if (this.props.location.state && this.props.location.state.updateFlag) {
            // this.nextLink = '/bowl-summary'
            this.param = { updateFlag: true }
            this.props.setNavigation({
                pathname: "/order/addon",
                state: {
                    updateFlag: true
                }
            }, this.onNext, 'main_right_protein detail_protein main_add_on')
        } else {
            this.props.setNavigation('/order/addon', this.onNext, 'main_right_protein detail_protein main_add_on')
        }
    }

    componentDidUpdate() {
        if (getAmountFromCart(2).amount > 0 && this.state.opacity === 0) {
            this.setState({ opacity: 1 })
        } else if (getAmountFromCart(2).amount <= 0 && this.state.opacity === 1) {
            this.setState({ opacity: 0 })
        }
    }

    componentDidMount() {
        this.props.getFlavourBoosters(() => {
            let temp = this.props.cart.flavourBooster.slice()
            let removedIndex: Array<number> = []
            if (temp.length > 0) {
                temp.forEach((a, index, object) => {
                    let item = this.props.foods.find((b) => b.id === a.id)
                    if (item && item.count <= 0) {
                        removedIndex.push(index)
                    }
                })
                if (removedIndex.length > 0) {
                    removedIndex.reverse().forEach(a => {
                        temp.splice(a, 1)
                    })
                    this.props.resetSavedBowl()
                    this.props.addFlavourBoosterToCart(Array.from(temp), false, () => { })
                }
            }
            this.setState({ selectedItem: temp, isLoading: false })

        })
    }

    componentWillUnmount() {
        //@ts-ignore
        $('#cancelOrderConfirm').modal('hide')
        //@ts-ignore
        $('#flavourBoosterConfirm').modal('hide')
        //@ts-ignore
        $('#freeSaucesConfirm').modal('hide')
    }


    onSelect = (item: IFlavourBooster, quantity: number) => {
        this.props.resetSavedBowl()
        console.log("FlavourBoostersHandler -> onSelect -> quantity", quantity)
        if (this.state.selectedItem.some(a => {
            return a.id === item.id
        })) {
            if (quantity === 0) {
                let temp = this.state.selectedItem.filter((a: IFlavourBooster) => a.id !== item.id)
                this.setState({ selectedItem: temp })
                this.props.addFlavourBoosterToCart(temp, false, () => {

                })
            } else {
                let temp = Array.from(this.state.selectedItem)
                temp.forEach((a: IFoodQuantity) => {
                    if (a.id === item.id && Math.abs(a.quantity - quantity) === 1) {
                        a.quantity = quantity
                    }
                })
                this.setState({ selectedItem: temp })
                this.props.addFlavourBoosterToCart(temp, false, () => {

                })
            }
        } else {
            const temp = this.state.selectedItem
            temp.push({ ...item, quantity })
            // this.setState({ selectedItem: temp, animationFood: this.state.animationFood.concat(item.image) })
            this.setState({
              selectedItem: temp,
            });
            // check extra
            if (getAmountFromCart(2).amount > 0) {
              console.log("extra..."); //for debug
              this.setState({
                animationExtra: this.state.animationExtra.concat(item.image),
              });
            } else {
              console.log("no extra..."); //for debug
              this.setState({
                animationFood: this.state.animationFood.concat(item.image),
              });
            }
            this.props.addFlavourBoosterToCart(temp, false, () => {})

        }
    }

    onNext = () => {
        if (this.state.selectedItem.length > 0) {
            if (getAmountFromCart(2).quantity >= NumberFlavourBoosterFree) {
                this.props.addFlavourBoosterToCart(this.state.selectedItem, false, () => {
                    this.props.history.push({
                        pathname: this.nextLink,
                        state: this.param
                    })
                })
            } else {
                //@ts-ignore
                // $('#freeSaucesConfirm').modal('toggle')
                this.props.addFlavourBoosterToCart(
                  this.state.selectedItem,
                  false,
                  () => {
                    this.props.history.push({
                      pathname: this.nextLink,
                      state: this.param,
                    });
                  }
                );
            }
        } else {
            if (this.props.cart.isNonSelectedFlavourBoosters === false) {
                //@ts-ignore
                $('#flavourBoosterConfirm').modal('toggle')
            } else {
                this.props.history.push({
                    pathname: this.nextLink,
                    state: this.param
                })
            }
        }
    }

    onConfirmFreeSaucesModal = () => {
        //@ts-ignore
        $('#freeSaucesConfirm').modal('toggle')
        this.props.addFlavourBoosterToCart(this.state.selectedItem, false, () => {
            this.props.history.push({
                pathname: this.nextLink,
                state: this.param
            })
        })
    }

    toggleChange = () => {
        this.props.resetSavedBowl()
        this.props.addFlavourBoosterToCart([], !this.props.cart.isNonSelectedFlavourBoosters, () => {
            this.setState({ selectedItem: [] })
        })

        if(this.props.cart.isNonSelectedFlavourBoosters === false) {
            this.props.history.push({
                pathname: this.nextLink,
            })
        }
    }

    onConfirmModal = () => {
        this.props.addFlavourBoosterToCart([], true, () => {
            //@ts-ignore
            $('#flavourBoosterConfirm').modal('toggle')
            this.props.history.push({
                pathname: this.nextLink,
                state: this.param
            })
        })
    }
}